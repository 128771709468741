/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import {Pill, Icon} from 'components';
import styles from './VersionPicker.css';

const iconStyle = {icon: styles.icon};

export default class VersionPicker extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['from', 'to']),
    value: PropTypes.string, // current value for the input
    showDropdown: PropTypes.bool,
    focused: PropTypes.bool, // whether or not the input is focused or not (used for blue border styling)
    presets: PropTypes.array,
    selectedPreset: PropTypes.string, // the menu item selected. if 'custom', text has been entered?
    placeholder: PropTypes.string, // string to display when there is no value
    onChange: PropTypes.func,
    setDropdown: PropTypes.func,
    setPreset: PropTypes.func,
    setActive: PropTypes.func,
    onRemoveValue: PropTypes.func, // fn to be executed when value is removed from the input. This fn accepts a type argument.
  };

  static defaultProps = {
    onChange: _.noop,
    setDropdown: _.noop,
    setPreset: _.noop,
    setActive: _.noop,
    onRemoveValue: _.noop,
  };

  state = {};

  getDisplayValue = () => {
    const {presets, selectedPreset, value} = this.props;

    if (selectedPreset === 'custom') {
      return value;
    }

    const currentPreset = presets.find(preset => preset === selectedPreset);

    return currentPreset;
  };

  handleRemoveValue = () => {
    const {setPreset, setActive, selectedPreset, onRemoveValue, type, setDropdown} = this.props;

    setActive(type);

    if (selectedPreset === 'custom') {
      onRemoveValue(type);
    }

    setPreset(type, null);
    setDropdown(type);
  };

  handleHover = event => {
    const current = event.currentTarget.getAttribute('value');

    this.setState({currentHover: current});
  };

  handleClickItem = event => {
    event.stopPropagation();

    const {setPreset, setDropdown, type, selectedPreset, onChange} = this.props;
    const selection = event.currentTarget.getAttribute('value');

    if (selection !== selectedPreset) {
      if (selection === 'custom') {
        onChange(null);
      }

      onChange(selection);
      setPreset(type, selection);
    }

    setDropdown('none');
  };

  handleClickCaret = event => {
    event.stopPropagation();

    const {type, setActive, showDropdown, setDropdown} = this.props;

    this.setState({currentHover: null});
    setActive(type);
    setDropdown(showDropdown ? 'none' : type);
  };

  handleClickInput = event => {
    event.stopPropagation();

    const {type, focused, selectedPreset, setActive, setDropdown} = this.props;

    setActive(type);

    if (focused || selectedPreset !== 'custom') {
      setDropdown(type);
    }
  };

  handleKeyDown = event => {
    const {presets, showDropdown, type, selectedPreset, onDateTimeChange, setPreset, setDropdown} = this.props;
    const {currentHover} = this.state;

    if (event.key === 'Backspace') {
      this.handleRemoveValue();
    } else if (event.key === 'Enter') {
      if (currentHover) {
        if (currentHover !== selectedPreset) {
          if (currentHover === 'custom') {
            onDateTimeChange(new Date());
          }

          setPreset(type, currentHover);
        }

        this.setState({currentHover: null});
        setDropdown('none');
      } else {
        this.handleClickInput(event);
      }
    } else if (event.key === 'Escape' || event.key === 'Tab') {
      this.setState({currentHover: null});
      this.props.setDropdown('none');
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();

      if (!currentHover) {
        this.props.setDropdown('none');
      } else {
        const hoverIndex = presets.indexOf(currentHover);

        if (hoverIndex > 0) {
          this.setState({currentHover: presets[hoverIndex - 1]});
        }
      }
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();

      if (!showDropdown) {
        this.props.setDropdown(type);
      } else if (!currentHover) {
        this.setState({currentHover: presets[0]});
      } else {
        const hoverIndex = presets.indexOf(currentHover);

        if (hoverIndex < presets.length - 1) {
          this.setState({currentHover: presets[hoverIndex + 1]});
        }
      }
    }
  };

  render() {
    const {focused, showDropdown, presets, selectedPreset, placeholder} = this.props;
    const {currentHover} = this.state;
    const selectorStyle = cx(styles.selector, {
      [styles.focused]: focused,
    });

    return (
      <div className={selectorStyle} onClick={this.handleClickInput} onKeyDown={this.handleKeyDown} tabIndex={0}>
        <div className={styles.item} data-tid="comp-combobox-selected-value">
          {selectedPreset ? (
            <Pill.Label onClose={this.handleRemoveValue}>{this.getDisplayValue()}</Pill.Label>
          ) : (
            placeholder
          )}
        </div>
        <div className={styles.dropdownArrow} data-tid="comp-icon comp-icon-caret-down">
          <Icon theme={iconStyle} name={showDropdown ? 'up' : 'down'} onClick={this.handleClickCaret} />
        </div>

        {showDropdown && (
          <ul className={styles.dropdown}>
            {presets.map(preset => (
              <li
                key={preset}
                tabIndex={-1}
                value={preset}
                className={preset === currentHover ? styles.dropdownItemHovered : styles.dropdownItem}
                onClick={this.handleClickItem}
                onMouseOver={this.handleHover}
              >
                {preset}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
