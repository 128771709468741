/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import Pill from '../Pill';
import type {PillDiffProps} from '../PillDiff';
import type {Label} from 'illumio';

export type LabelDiffProps = {
  value?: Label | Label[];
  oldValue?: Label | Label[];
  // policy version
  pversion?: string | number;
} & Omit<PillDiffProps, 'value' | 'oldValue'>;

export default function LabelDiff(props: LabelDiffProps): JSX.Element {
  const {value, oldValue, pversion, ...extra} = props;

  const getLabelPill = (label?: Label) =>
    label && {
      key: label.href || label.value,
      pill: (
        <Pill.Label type={label.key} href={label.href} pversion={pversion}>
          {label.value}
        </Pill.Label>
      ),
    };

  const pillDiffProps: PillDiffProps = extra;

  pillDiffProps.value = Array.isArray(value) ? value.map(label => getLabelPill(label)) : [getLabelPill(value)];
  pillDiffProps.oldValue = Array.isArray(oldValue)
    ? oldValue.map(label => getLabelPill(label))
    : [getLabelPill(oldValue)];

  return <Pill.Diff {...pillDiffProps} />;
}
