/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {hrefUtils} from 'utils';
import {HeaderProps} from 'containers';
import {fetchLoadBalancerList, removeLoadBalancer} from './LoadBalancerListSaga';
import {getLoadBalancersPage} from './LoadBalancerListState';
import {Button, ButtonRefresh, Grid, Link, ModalMachineAuto, Notifications, ToolBar, ToolGroup} from 'components';
import styles from './LoadBalancerList.css';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import {getMaxPageNotificationList} from 'components/Grid/GridUtils';
import VirtualServerReducers from '../../VirtualServer/VirtualServerState';

const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};
const removeRowHighLight = {className: stylesGridUtils.rowToRemove};

// Statically create add item link for grid to avoid its rerender on each page render (Grid is PureComponent)
const addLoadBalancerLink = <Link to="loadBalancers.create">{intl('LoadBalancers.AddNew')}</Link>;

const getEmptyState = () => ({
  extraPropsKeyMap: new Map(),
  selectedKeySet: new Set(),
  selectedKeySetToRemove: new Set(), // subset that are removable because the SLB is not in use
  selectedKeySetToRemoveHrefs: new Set(),
  remove: false,
});

@connect(getLoadBalancersPage)
export default class LoadBalancerList extends Component {
  static prefetch = fetchLoadBalancerList;
  static contextType = AppContext;
  static reducers = VirtualServerReducers;

  constructor(props) {
    super(props);

    this.state = getEmptyState();

    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleRemoveEnter = this.handleRemoveEnter.bind(this);
    this.handleRemoveLeave = this.handleRemoveLeave.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.grid.rows !== prevState.rows) {
      const nextState = {rows: nextProps.grid.rows};

      if (prevState.selectedKeySet.size) {
        // If page or sorting have changed, find selected items that remain on current page
        nextState.selectedKeySet = new Set();
        nextState.selectedKeySetToRemove = new Set();
        nextState.selectedKeySetToRemoveHrefs = new Set();

        for (const row of nextProps.grid.rows) {
          if (row.selectable && prevState.selectedKeySet.has(row.key)) {
            nextState.selectedKeySet.add(row.key);

            if (row.removable) {
              nextState.selectedKeySetToRemove.add(row.id);
              nextState.selectedKeySetToRemoveHrefs.add(row.key);
            }
          }
        }
      }

      return nextState;
    }

    return null;
  }

  handleClick(evt, row) {
    this.context.navigate({evt, to: 'loadBalancers.item.view', params: {id: hrefUtils.getId(row.key)}});
  }

  handleSelect({affectedRows, selecting}) {
    this.setState(state => {
      const selectedKeySet = new Set(state.selectedKeySet);
      const selectedKeySetToRemove = new Set(state.selectedKeySetToRemove);
      const selectedKeySetToRemoveHrefs = new Set(state.selectedKeySetToRemoveHrefs);

      for (const row of affectedRows) {
        selectedKeySet[selecting ? 'add' : 'delete'](row.key);

        if (selecting) {
          if (row.removable) {
            selectedKeySetToRemove.add(row.id);
            selectedKeySetToRemoveHrefs.add(row.key);
          }
        } else {
          selectedKeySetToRemove.delete(row.id);
          selectedKeySetToRemoveHrefs.delete(row.key);
        }
      }

      return {selectedKeySet, selectedKeySetToRemove, selectedKeySetToRemoveHrefs};
    });
  }

  handleRefresh() {
    // Refetch loadBalancer list, cancelable on page leave
    // Return promise, so Pagination component can publish event for QA
    return this.context.fetcher.fork(fetchLoadBalancerList.refetch);
  }

  handleRemove() {
    this.setState(({remove}) => ({remove: !remove}));
  }

  handleRemoveEnter() {
    if (this.state.selectedKeySetToRemove.size) {
      this.setState(state => ({
        extraPropsKeyMap: new Map(Array.from(state.selectedKeySetToRemoveHrefs, key => [key, removeRowHighLight])),
      }));
    }
  }

  handleRemoveLeave() {
    // Drop highlight if user moved out cursor and haven't opened remove dialog
    if (!this.state.remove && this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  renderRemove() {
    const {
      props: {
        grid: {rowsMap},
      },
      state: {selectedKeySetToRemoveHrefs},
    } = this;

    return (
      <ModalMachineAuto
        settled
        onClose={this.handleRemove}
        onDone={this.handleRefresh}
        saga={removeLoadBalancer}
        hrefs={[...selectedKeySetToRemoveHrefs]}
        rowsMap={rowsMap}
        listItem={['name']}
      >
        {{
          title: ({selectedHrefs}) => intl('LoadBalancers.Delete', {count: selectedHrefs.length}),
          confirmMessage: ({selectedHrefs}) => intl('LoadBalancers.DeleteConfirm', {count: selectedHrefs.length}),
          itemSuccessMessage: ({successHrefs}) =>
            intl('LoadBalancers.List.RemoveSuccess', {count: successHrefs.length}),
          error: {
            title: ({selectedHrefs}) => intl('LoadBalancers.Delete', {count: selectedHrefs.length}),
          },
        }}
      </ModalMachineAuto>
    );
  }

  render() {
    const {
      props: {grid, count, deviceTypes, loadBalancerDocLink, enableSLBControls},
      state: {extraPropsKeyMap, selectedKeySet, selectedKeySetToRemove, remove},
    } = this;

    const notifications = getMaxPageNotificationList({page: grid.page, capacity: grid.capacity, count});

    if (deviceTypes.length === 0) {
      notifications.push({
        type: 'instruction',
        message: intl(
          'LoadBalancers.List.NotSupportedDesc',
          {
            link: (
              <Link href={loadBalancerDocLink} target="_blank">
                {intl('Help.Title.UserGuide')}
              </Link>
            ),
          },
          {jsx: true},
        ),
      });
    }

    return (
      <>
        <HeaderProps title={intl('VirtualServers.Detail.ServerLoadBalancers')} />
        {notifications.length > 0 && <Notifications sidebar>{notifications}</Notifications>}
        <ToolBar>
          <ToolGroup>
            <Button.Link
              textIsHideable
              icon="add"
              text={intl('Common.Add')}
              link="loadBalancers.create"
              tid="add"
              theme={buttonsTheme}
              disabled={!enableSLBControls}
            />
            <Button
              color="standard"
              textIsHideable
              icon="remove"
              text={intl('Common.Remove')}
              tid="remove"
              theme={buttonsTheme}
              counter={selectedKeySetToRemove.size}
              counterColor="red"
              disabled={!enableSLBControls || !selectedKeySetToRemove.size}
              onClick={this.handleRemove}
              onMouseEnter={this.handleRemoveEnter}
              onMouseLeave={this.handleRemoveLeave}
            />
          </ToolGroup>
          <ToolGroup>
            <ButtonRefresh color="standard" textIsHideable onRefresh={this.handleRefresh} theme={buttonsTheme} />
          </ToolGroup>
        </ToolBar>
        <Grid
          grid={grid}
          theme={styles}
          count={count}
          selectedKeySet={selectedKeySet}
          dontHighlightSelected={extraPropsKeyMap.size > 0}
          extraPropsKeyMap={extraPropsKeyMap}
          onClick={this.handleClick}
          onSelect={this.handleSelect}
          emptyMessage={intl('Common.NoDataLoadBalancer')}
          addItemLink={enableSLBControls ? addLoadBalancerLink : null}
        />

        {remove && this.renderRemove()}
      </>
    );
  }
}
