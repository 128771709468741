/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useRef, useContext} from 'react';
import {Link, Modal} from 'components';
import {getPCEBuild, getVersion, isEdge} from 'containers/App/AppState';
import styles from './ASPVersion.css';
import stylesUtils from 'utils.css';
import {AppContext} from 'containers/App/AppUtils';

const linkToPatents = 'https://www.illumio.com/patents';
const linkToTerms = 'https://www.illumio.com/eula';
const linkToPrivacy = 'https://www.illumio.com/privacy-policy';

export default connect(state => ({
  ASPVersion: getVersion(state),
  PCEBuild: getPCEBuild(state),
  isEdge: isEdge(state),
}))(ASPVersion);

ASPVersion.propTypes = {
  // Handler on close
  onClose: PropTypes.func,
  // Or link on close
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

function ASPVersion({link, onClose, ASPVersion, PCEBuild, isEdge}) {
  const buttonRef = useRef();

  const {navigate} = useContext(AppContext);

  const handleClose = () => {
    if (link) {
      navigate(link);
    }

    onClose?.();
  };

  return (
    <Modal.Alert
      large
      gap="gapLarge"
      closeRef={buttonRef}
      buttonProps={{tid: 'ok', text: intl('Common.OK'), onClick: handleClose, ref: buttonRef}}
      title={<img className={styles.logo} src="/images/logo-color.svg" alt={intl('Illumio.Logo')} />}
    >
      <div className={stylesUtils.gapXSmall}>
        <div className={styles.version}>
          {intl('VersionMismatch.PCE')}
          {isEdge ? `: ${intl('Edge.ProductName')}` : ''} {ASPVersion}-{PCEBuild}
        </div>
        <div className={styles.version}>
          {intl('VersionMismatch.UI')}
          {isEdge ? `: ${intl('Edge.ProductName')}` : ''} {process.env.UI_VERSION}
        </div>
      </div>
      <div className={stylesUtils.gapMedium}>
        <div>
          {intl(
            'Illumio.Patent',
            {
              link: (
                <Link href={linkToPatents} target="_blank" theme={styles} themeCompose="merge">
                  {linkToPatents}
                </Link>
              ),
            },
            {jsx: true},
          )}
        </div>
        <div>
          {intl(
            'Illumio.Terms',
            {
              link: (
                <Link href={linkToTerms} target="_blank" theme={styles} themeCompose="merge">
                  {linkToTerms}
                </Link>
              ),
            },
            {jsx: true},
          )}
        </div>
        <div>
          {intl(
            'Illumio.Privacy',
            {
              link: (
                <Link href={linkToPrivacy} target="_blank" theme={styles} themeCompose="merge">
                  {linkToPrivacy}
                </Link>
              ),
            },
            {jsx: true},
          )}
        </div>
        <div>{intl('Illumio.Copyright')}</div>
      </div>
    </Modal.Alert>
  );
}
