/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {Component} from 'react';
import {shallowEqualLooseByProps} from 'utils/general';
import Cell from './GridCellBody';

// Selection of props, changes of which should lead to area rerender
const propsToCompare = ['row', 'column', 'breakpoint', 'theme', 'error', 'warning', 'info', 'loading'];

export default class GridAreaBody extends Component {
  shouldComponentUpdate(nextProps) {
    // Quickly check if area should be rerendered.
    // For instance, if only row props have changed, like extraProps on buttons hover, need to prevent rerendering of all grid areas
    let equal = shallowEqualLooseByProps(this.props, nextProps, propsToCompare);

    // If rest of the props are equal and area contains cell that should react to checkbox change and selection changed, need to rerender
    if (equal && this.props.selected !== nextProps.selected && nextProps.column.reactsToSelection) {
      equal = false;
    }

    return !equal;
  }

  render() {
    const {
      column,
      grid: {columns},
      spanColumn,
      theme,
    } = this.props;

    const style = {};

    if (spanColumn) {
      style.gridColumn = `${spanColumn.gridColumn[0]} / ${spanColumn.gridColumn[1]}`;
    }

    return (
      <div style={style} className={cx(theme.areaBody, column.extraClass)}>
        {!column.isExpander &&
          column.cells.map(cell => {
            if (!cell.templates || (cell.format && typeof cell.format !== 'function')) {
              return <Cell key={cell.id || cell} {...this.props} cell={cell} />;
            }

            const cellContent = cell.templates.map(subColumnId => {
              const subColumn = columns.get(`${cell.id}.${subColumnId}`);

              return <Cell key={subColumn.id || subColumn} {...this.props} cell={subColumn} />;
            });

            return cell.format?.({row: this.props.row, content: cellContent}) ?? cellContent;
          })}
      </div>
    );
  }
}
