/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import styles from './CertReminderPopup.css';
import {Component} from 'react';
import ReminderPopup, {type Duration, type ReminderPopupProps} from '../ReminderPopup';
import type {ReactStrictNode} from 'utils/types';

type CertReminderPopupProps = {
  selfSignedPeriod?: number;
  trustedCertLink: string;
  selfSignedCertLink: string;
  selfSigned?: boolean;
  expiration: string;
  onClose: (type: 'certWarning', dateString: string) => void;
} & typeof CertReminderPopup.defaultProps;

export default class CertReminderPopup extends Component<CertReminderPopupProps> {
  static defaultProps = {
    selfSignedPeriod: 30,
  };

  constructor(props: CertReminderPopupProps) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  private handleClose() {
    return this.props.onClose('certWarning', intl.date(new Date(), 'L'));
  }

  render() {
    const {selfSigned, expiration, selfSignedCertLink, selfSignedPeriod, trustedCertLink} = this.props;
    const expDate = new Date(expiration);

    if (selfSigned) {
      expDate.setDate(expDate.getDate() - selfSignedPeriod);
    }

    const daysLeft = intl.utils.diffInDays(expDate);

    if (!selfSigned && daysLeft > 30) {
      return null;
    }

    let title: ReactStrictNode;
    let message: string;
    let duration: Duration | null = {};
    let expired = daysLeft < 0;

    // show number of days left until expiration in the reminder counter
    // if it expires later today, show "Today"
    // if it expired yesterday or before that, don't show counter
    if (daysLeft && !expired) {
      duration.time = daysLeft;
      duration.unit = intl('Certificate.Days', {count: daysLeft});
    } else if (!daysLeft && !expired) {
      const hoursLeft = intl.utils.diffInHours(expDate);

      if (hoursLeft < 0) {
        expired = true;
      } else {
        duration.time = 0;
      }
    }

    const href = selfSigned ? selfSignedCertLink : trustedCertLink;

    let type: ReminderPopupProps['type'] = 'critical';

    if (selfSigned && daysLeft > 10 && !expired) {
      type = 'warning';
    }

    if (expired) {
      duration = null;
      title = selfSigned ? intl('Certificate.TrialEnded') : intl('Certificate.CertExpired');
      message = selfSigned ? intl('Certificate.TrialEndedMessage') : intl('Certificate.CertExpiredMessage');
    } else {
      title = intl(
        selfSigned ? 'Certificate.ValidTrial' : 'Certificate.ValidCert',
        {
          when: expDate,
          className: (type === 'critical' && styles.date) || '',
        },
        {html: true},
      );
      message = selfSigned ? intl('Certificate.MessageSelfSigned') : intl('Certificate.MessageRealCert');
    }

    return (
      <ReminderPopup
        theme={styles}
        title={title}
        type={type}
        message={message}
        duration={duration}
        onClose={this.handleClose}
        linkLabel={intl('Common.ClickHere')}
        link={{href, target: '_blank'}}
      />
    );
  }
}
