/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {hrefUtils, ipUtils} from 'utils';
import {isAPIAvailable} from 'api/apiUtils';
import {getRouteName} from 'containers/App/AppState';

export default {
  network(state = {}, action) {
    switch (action.type) {
      case 'NETWORK_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getNetworkInstance = state => state.networks.network;

export const isNetworkEditEnabled = createSelector(
  [getNetworkInstance],
  ({user_managed}) => user_managed && isAPIAvailable('network.update'),
);

export const getNetworkPage = createSelector(
  [getNetworkInstance, isNetworkEditEnabled, getRouteName],
  (network, enableEdit, routeName) => {
    const {cidrs = [], ip_version, href} = network;
    const formattedCidrs = ipUtils.getIpFormat(cidrs || []);

    return {
      ...network,
      ...(href && {network_id: hrefUtils.getId(href)}),
      isEdit: routeName.endsWith('edit'),
      enableEdit,
      cidrs4: ip_version === 4 ? formattedCidrs.ipFormat : [],
      cidrs6: ip_version === 6 ? formattedCidrs.ipFormat : [],
      formattedCidrs,
    };
  },
);
