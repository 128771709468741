/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {call, put, select} from 'redux-saga/effects';
import {fetchKvpairs, updateKvpairs} from 'containers/User/Settings/SettingsSaga';
import BaseOnboardingSteps, {stepsToIndexMap, flattenSteps} from './OnboardingSteps';
import {getOnboardingState} from './OnboardingStepsState';
import produce from 'immer';

export function* fetchOnboardingSteps() {
  // This logic is to be determined, but eventually we want to do a check (maybe with the metrics API) to
  // determine if anyone in an org has onboarded. This combination could be based on if a workload / ven / label exits.
  const stepsState = yield call(fetchKvpairs, {key: 'onboarding_steps', force: true});

  if (stepsState === undefined || _.isEmpty(stepsState)) {
    yield call(updateOnboardingSteps, {stepsState: BaseOnboardingSteps, dispatch: true});
  } else {
    yield put({type: 'SET_ONBOARDING_DATA', data: stepsState});
  }
}

export function* updateOnboardingSteps({stepsState = {}, dispatch = false} = {}) {
  // consider onboarding as done when the status of all steps are either done or skipped
  const hasOnboarded = flattenSteps(stepsState?.steps ?? []).every(step => step.status !== null);

  const newStepsState = produce(stepsState, draft => {
    draft.hasOnboarded = hasOnboarded;
  });

  if (dispatch) {
    yield put({type: 'SET_ONBOARDING_DATA', data: newStepsState});
  }

  yield call(updateKvpairs, {key: 'onboarding_steps', data: newStepsState});
}

/**
 * Transition step handles updating the state to that step. If moving forward the current step will be marked as completed.
 * If moving backwards the most recently completed step is stored as well. By default we move to the next step.
 *
 * `skip` let you skip to the target step. The steps from the current step to the one before the target step are marked skipped
 * @param {{
 *  step?: string,
 *  dispatch?: boolean,
 *  skip?: boolean
 * }} options
 */
export function* transitionStep({step = null, dispatch = true, skip = false} = {}) {
  const stepsState = yield select(getOnboardingState);

  if (!step) {
    const newStepsState = produce(stepsState, draft => {
      const viewingStepInfo = stepsToIndexMap.get(draft.currentStep);

      const viewStepLocation = viewingStepInfo.location.reduce((result, index) => `${result}steps[${index}]`, '');
      const viewStep = _.get(draft, viewStepLocation);

      if (skip) {
        viewStep.status = 'skipped';
      } else if (viewStep.status !== 'done') {
        viewStep.status = 'done';
      }

      draft.currentStep = viewingStepInfo.next ?? viewStep.name;
      draft.lastStep = viewStep.name;
    });

    yield call(updateOnboardingSteps, {
      stepsState: newStepsState,
      dispatch,
    });
  } else {
    const lastStep = _.findLast(flattenSteps(stepsState.steps || []), step => step.status !== null);
    const currentStep = stepsToIndexMap.get(stepsState.currentStep);
    const targetStep = stepsToIndexMap.get(step);

    const newStepsState = produce(stepsState, draft => {
      if (skip) {
        let cur = currentStep;

        // mark all the steps between the current and the target step as 'skipped'
        while (cur !== targetStep) {
          const accessor = cur.location.reduce((result, index) => `${result}steps[${index}]`, '');
          const stepState = _.get(draft, accessor);

          if (stepState) {
            stepState.status = 'skipped';
          }

          cur = stepsToIndexMap.get(cur.next);
        }
      }

      draft.currentStep = step;
      draft.lastStep = lastStep?.name || step;
    });

    yield call(updateOnboardingSteps, {
      stepsState: newStepsState,
      dispatch,
    });
  }
}

export function* setOnboarded() {
  yield call(updateOnboardingSteps, {stepsState: {...BaseOnboardingSteps, steps: [], hasOnboarded: true}});
}

/**
 * Exit the onboarding process
 */
export function* hideOnboarding() {
  const stepsState = yield select(getOnboardingState);

  yield call(updateOnboardingSteps, {
    stepsState: {
      ...stepsState,
      hideOnboarding: true,
    },
    dispatch: true,
  });
}

export function* resetOnboarding() {
  yield call(updateOnboardingSteps, {stepsState: BaseOnboardingSteps});
  yield call(fetchOnboardingSteps);
}
