/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {getPolicyState, getPolicyStateContent} from 'containers/Workload/WorkloadUtils';
import intl from 'intl';

export const getContainerWorkloadStatusIntl = type =>
  ({
    none: intl('Common.NA'),
    online: intl('Common.Online'),
    offline: intl('Workloads.Status.Offline'),
    out_of_sync: intl('Workloads.Status.Syncing'),
    suspended: intl('Workloads.Status.Suspended'),
    syncing: intl('Workloads.Status.Syncing'),
    unmanaged: intl('Workloads.Status.NotInstalled'),
    uninstalling: intl('Workloads.Status.Uninstalling'),
    applied: intl('Common.Active'),
    staged: intl('Common.Staged'),
  }[type]);

export const formatContainerWorkloadStateValue = containerWorkload => {
  const policyState = getPolicyState(containerWorkload.data.mode, containerWorkload.data.log_traffic);
  const policyContent = getPolicyStateContent(policyState, containerWorkload.data.visibility_level);

  return policyContent.title + (policyContent.visibilityLevel ? `: + ${policyContent.visibilityLevel}` : '');
};
