/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';

const restrictions = ['user_sessions', 'api_keys'];

export const restrictionsMap = new Map([
  ['all', intl('AccessRestriction.UserSessionAndAPIKey')],
  ['user_sessions', intl('AccessRestriction.UserSession')],
  ['api_keys', intl('AccessRestriction.APIKey')],
]);

export const getRestrictionFromExclusions = exclusionList =>
  _.isEmpty(exclusionList) ? 'all' : restrictions.find(restriction => !exclusionList.includes(restriction));

export const getRestrictionTextFromExclusions = exclusionList =>
  restrictionsMap.get(getRestrictionFromExclusions(exclusionList));

export const getExclusionsFromRestriction = option =>
  option === 'all' ? null : restrictions.filter(restriction => restriction !== option);
