/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

export interface Stack<T = unknown> {
  key: string;
  value: T;
  maxAge: number;
  expires: number;
}

/**
 * Cache that can be persistent or expiration-based (with lru option).
 *
 * Becomes fully persistent if maxValues/defaultMaxValueAge are set to Infinity.
 * Otherwise items are expirable, but not pro-actively pruned out when expired - expiration is checked on adding new item,
 * or if expired item is requested by 'find' method it will be dropped from the cache and nothing will be returned.
 *
 * @param lru Whether prune model should be lru-based, when expiration time extends every time item is requested (found).
 *                               Otherwise expiration counts from item put time.
 * @param maxValues Limits the number of items in cache.
 *                                   When limit is reached, nearest to expiration time item gets removed.
 * @param defaultMaxValueAge Set lifetime of an item in milliseconds,
 *                                            from adding time (non-lru) or from last used time (lru model).
 *                                            maxAge can be set separately for each item in add method.
 */
export default class Cache<T = unknown> {
  map: Map<string, Stack<T>>;
  stack: Stack<T>[];
  lru: boolean;
  maxValuesCount: number;
  defaultMaxValueAge: number;

  constructor({lru = true, maxValues = 100, defaultMaxValueAge = 60_000} = {}) {
    this.map = new Map(); // Key-value storage for fast lookups
    this.stack = []; // Array for sorting and pruning

    this.lru = lru;
    this.maxValuesCount = maxValues;
    this.defaultMaxValueAge = defaultMaxValueAge;
  }

  add(key: string, value: T, maxAge = this.defaultMaxValueAge): T {
    const now = Date.now();
    const content: Stack<T> = {key, value, maxAge, expires: now + maxAge};

    // On each add, clear all expired values, and also prune oldest one if number of values has reached the limit
    this.clearExpired(now, this.map.size === this.maxValuesCount);

    this.map.set(key, content);
    this.stack.push(content);

    if (this.stack.length > 1) {
      this.stack.sort(sortByExpires);
    }

    return value;
  }

  find(key: string): T | undefined {
    const now = Date.now();
    const content = this.map.get(key);

    if (content) {
      if (content.expires >= now) {
        if (this.lru) {
          // In case of lru, extend lifetime of the item and resort stack
          content.expires = now + content.maxAge;
          this.stack.sort(sortByExpires);
        }

        return content.value;
      }

      // If content expired, remove it from cache
      this.remove(key);
    }
  }

  // Returns true if item existed and has been removed, or false if item does not exist
  remove(key: string, removeFromStack = true): boolean {
    const content = this.map.get(key);

    if (content) {
      if (removeFromStack) {
        this.stack = this.stack.filter(item => item !== content);
      }

      return this.map.delete(key);
    }

    return false;
  }

  // Returns true if item existed and has been removed, or false if item does not exist
  removeIfStartsWith(string: string): boolean {
    const content = this.stack.find(item => item.key.startsWith(string));

    if (content) {
      return this.remove(content.key);
    }

    return false;
  }

  // Returns number of pruned items
  clearExpired(now: number, forciblyPruneOldest: boolean): number {
    let numberOfRemoved = 0;

    if (this.stack.length > 0) {
      const oldest = this.stack[0];

      if (oldest.expires < now) {
        // If at least one first object is expired, start filtering stack
        this.stack = this.stack.filter(item => {
          if (item.expires >= now) {
            return true;
          }

          this.remove(item.key, false);
          numberOfRemoved++;

          return false;
        });
      } else if (forciblyPruneOldest) {
        // If have not expired, but we've been asked to drop the oldest (if we reached items limit in cache), remove it
        this.remove(oldest.key);
        numberOfRemoved = 1;
      }
    }

    return numberOfRemoved;
  }

  // Returns number of pruned items
  clearAll(): number {
    const numberOfRemoved = this.map.size;

    this.map.clear();

    return numberOfRemoved;
  }
}

function sortByExpires(a: Stack, b: Stack): number {
  return a.expires > b.expires ? 1 : a.expires < b.expires ? -1 : 0;
}
