/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {PureComponent} from 'react';
import type {ReactStrictNode} from 'utils/types';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import {classSplitter} from 'utils/general';
import {Icon} from 'components';
import styles from './Notifications.css';
import stylesUtils from 'utils.css';

const iconName = {
  instruction: 'info',
  success: 'inuse',
  warning: 'warning',
  error: 'error',
  notice: 'cancel',
} as const;

type NotificationType = keyof typeof iconName;

type Notification = {
  type: NotificationType;
  title?: ReactStrictNode;
  message?: ReactStrictNode;
  button?: ReactStrictNode;
  handleClose?: () => void;
  hideIcon?: boolean;
};
type NotificationProps = {
  shrink?: boolean; // If notification is NOT 100% of container width
  sidebar?: boolean; // Adds a colored left sidebar
  compact?: boolean; //If notification is in compact version
  gap?: string;
  children: Notification[];
} & ThemeProps;

export default class Notifications extends PureComponent<NotificationProps> {
  render() {
    const {shrink = false, sidebar = false, compact = false, children, gap = 'gapSmall'} = this.props;
    const theme = composeThemeFromProps(styles, this.props);
    const containerClasses = cx(theme.notifications, classSplitter(stylesUtils, gap), {
      [theme.shrink]: shrink,
    });

    return (
      <div className={containerClasses}>
        {children.map((notification, idx) => {
          const {type, title, message, button, handleClose, hideIcon} = notification;
          const childClasses = cx(theme[type], {
            [theme.sidebar]: sidebar,
            [theme.compact]: compact,
          });

          return (
            <div key={idx} className={childClasses} data-tid={`comp-notification-${type}`}>
              {hideIcon ? null : <Icon name={iconName[type]} theme={theme} themePrefix="status-" />}
              <span className={theme.mainItem}>
                {title && (
                  <span
                    className={theme[message ? 'titleWithMessage' : 'titleWithoutMessage']}
                    data-tid="comp-notification-title"
                  >
                    {title}
                  </span>
                )}
                {message && (
                  <span className={theme.message} data-tid="comp-notification-message">
                    {message}
                  </span>
                )}
              </span>
              {button && <span className={theme.buttonItem}>{button}</span>}
              {handleClose && <Icon name="close" onClick={handleClose} theme={theme} themePrefix="close-" />}
            </div>
          );
        })}
      </div>
    );
  }
}
