/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {Component, createRef} from 'react';
import {object, string, boolean, mixed, array} from 'yup';
import {AttributeList, Button, Modal, ToolBar, ToolGroup, TypedMessages, Form, InfoCard} from 'components';
import {HeaderProps} from 'containers';
import {getWorkloadPolicyLocks} from 'containers/Workload/WorkloadUtils';
import {getPairingProfileEdit} from './PairingProfileEditState';
import {
  fetchPairingProfileItem,
  fetchPairingProfileEdit,
  createPairingProfile,
  updatePairingProfile,
} from '../PairingProfileItemSaga';
import {
  getWriteAbleData,
  lifespanOptions,
  labelLocks,
  formatVenDropdown,
  formatReleaseValue,
  getKeyLifespanText,
  lifespanTimeOptions,
  getTimeValue,
  getUsesPerKeyText,
} from '../../PairingProfileUtils';
import {reactUtils, hrefUtils} from 'utils';
import {SingleItemSelect} from 'containers/Selectors';
import {
  getEnforcementBoundariesVisibilitySchema,
  getEnforcementBoundariesVisibility,
  getEnforcementBoundariesInitialValues,
} from 'containers/EnforcementBoundaries/EnforcementBoundariesVisibilityFormUtils';
import {labelFields} from 'components/Pill/Label/LabelUtils';

// Get formik's mandatory initialValues props for form setup
const getInitialValues = (props, selectorOptions) => {
  const {
    isEdit,
    pairingProfile,
    lifespanTimeType,
    lifespanTimeValue,
    keyLifeSpanField,
    stateLockValue,
    role,
    app,
    env,
    loc,
    labelsLockValue,
    venReleases,
    edgeEnabled,
  } = props;

  // Need to set venRelease selectorOptions here because this can change dynamically
  selectorOptions.venReleases = formatVenDropdown(venReleases);

  return {
    // A required input name use empty string
    name: pairingProfile.name || '',
    description: pairingProfile.description || '',
    // Uses Per Key
    allowed_uses_per_key:
      Form.Utils.findSelectedOption(selectorOptions.usesOptions, pairingProfile.allowed_uses_per_key) ||
      selectorOptions.usesOptions[0],
    // Key Usage and Lifespan to map 'key_lifespan'
    keyLifeSpanField: Form.Utils.findSelectedOption(lifespanOptions(), keyLifeSpanField),
    // Key life span can be string or number
    key_lifespan: pairingProfile.key_lifespan,
    // lifespanTimeType is the type selected  when key_lifespan is set as custom
    lifespanTimeType: Form.Utils.findSelectedOption(lifespanTimeOptions(), lifespanTimeType),
    // lifespanTimeValue is the numeric value when when key_lifespan is set as custom
    lifespanTimeValue,
    // Selector role, app, env, loc
    role,
    app,
    env,
    loc,
    // This is a required flag for api. true is always default when creating new pairing profiles
    enabled: !isEdit || pairingProfile.enabled,
    // Workload Policy State - Command Line Overrides
    stateLockValue: stateLockValue ? 'stateoverride-lock' : 'stateoverride-allow',
    // These are grouped with 'stateLockValue' to send to backend API
    enforcement_mode_lock: pairingProfile.enforcement_mode_lock,
    log_traffic_lock: pairingProfile.log_traffic_lock,
    visibility_level_lock: pairingProfile.visibility_level_lock,
    labelsLockValue: labelsLockValue ? 'labeloverride-lock' : 'labeloverride-allow',
    // [app, env, loc, role]_label_locks depends on 'labelsLockValue' selection
    labelLockGrp: labelsLockValue ? [] : labelLocks.filter(label => props[label] === false),
    // VEN releases
    ven_software_release: Form.Utils.findSelectedOption(
      selectorOptions.venReleases,
      pairingProfile.agent_software_release,
    ),
    // Enforcement and Visibility initial values
    ...getEnforcementBoundariesInitialValues(pairingProfile, edgeEnabled),
  };
};

// Initial State
const getInitialState = (props, selectorOptions) => ({
  showVisibilityLevelSelect: props.workloadState === 'enforced',
  showLifeSpan: Boolean(Number(props.pairingProfile.key_lifespan)),
  showVen: props.venReleases.length > 0,
  initialValues: getInitialValues(props, selectorOptions),
  cancel: false,
  saving: false,
  error: null,
  href: props.pairingProfile.href,
  selectedLabels: props.initialSelectedLabels,
});

@connect(getPairingProfileEdit)
export default class PairingProfileEdit extends Component {
  static prefetch = fetchPairingProfileEdit;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.selectorOptions = {
      usesOptions: [
        {value: intl('PairingProfiles.Unlimited'), label: intl('PairingProfiles.UnlimitedUses')},
        {value: 1, label: intl('PairingProfiles.OneUse')},
      ],
      lifespanOptions: lifespanOptions(),
      lifespanTimeOptions: lifespanTimeOptions(),
    };

    this.state = getInitialState(props, this.selectorOptions);

    this.infoCardIconRef = createRef();
    this.infoCardIconRef2 = createRef();

    this.schemas = object({
      name: string().max(255, intl('Common.NameIsTooLong')).required(intl('Common.AddValidName')),
      description: string(),
      enabled: boolean().required(),
      allowed_uses_per_key: object().nullable(),
      keyLifeSpanField: object(),
      key_lifespan: mixed(), // integer or string
      // check for valid Number - use mixed() because there is a Yup number issue
      lifespanTimeValue: mixed().test('is-number', intl('PairingProfiles.Mixin.AddValidLifespan'), value =>
        Number(value),
      ),
      stateLockValue: Form.Radio.schema, // string or boolean
      // '' = default for creating new, true/false(boolean) - user clicked on selection or edit had default
      labelsLockValue: Form.Radio.schema,
      labelLockGrp: array(),
      role: array(
        object({
          href: string(),
        }),
      ),
      app: array(
        object({
          href: string(),
        }),
      ),
      loc: array(
        object({
          href: string(),
        }),
      ),
      env: array(
        object({
          href: string(),
        }),
      ),
      ven_software_release: object().nullable(),
      ...getEnforcementBoundariesVisibilitySchema,
    });

    this.renderForm = this.renderForm.bind(this);
    this.renderEditAlert = this.renderEditAlert.bind(this);

    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleLockChange = this.handleLockChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);
    this.handleOnSetLifeSpanField = this.handleOnSetLifeSpanField.bind(this);
    this.visibilityInitWhenIsTrue = this.visibilityInitWhenIsTrue.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // When a different pairing profile name is needed to reinitialized the state. API
    // returns name as unique.
    if (prevState.href !== nextProps.pairingProfile.href) {
      return getInitialState(nextProps);
    }

    return null;
  }

  componentDidMount() {
    this.updateHeader();
  }

  componentDidUpdate() {
    this.updateHeader();
  }

  // Get Key Life Span hints
  getKeyLifeSpanHints(values) {
    let lifespanContent;

    if (values.keyLifeSpanField) {
      if (values.keyLifeSpanField.value === 'custom') {
        // customized the time
        // Get time value for custom
        const lifeSpanValue = getTimeValue(values.lifespanTimeType.value, values.lifespanTimeValue);

        lifespanContent = getKeyLifespanText(lifeSpanValue);
      } else {
        lifespanContent = getKeyLifespanText(values.keyLifeSpanField.value);
      }

      return [
        {
          header: lifespanContent.title,
          message: lifespanContent.content,
        },
      ];
    }

    return null;
  }

  // Get Key Uses hints
  getKeyUsesHints(allowedUsesPerKey) {
    if (allowedUsesPerKey) {
      const usesContent = getUsesPerKeyText(String(allowedUsesPerKey.value));

      return [
        {
          header: usesContent.title,
          message: usesContent.content,
        },
      ];
    }

    return null;
  }

  getLabelAssignments(values) {
    const {edgeEnabled} = this.props;
    const selectedLabels = Object.values(this.state.selectedLabels);

    if (!edgeEnabled) {
      return [
        {divider: true},
        {title: intl('PairingProfiles.LabelAssignment')},
        {
          tid: 'role',
          key: <Form.Label name="role" title={intl('Common.Role')} lineHeight="var(--30px)" />,
          value: (
            <SingleItemSelect
              showResultsFooter
              initialItems={values.role}
              allowCreateTypes={['labels']}
              placeholder={intl('Labels.Select')}
              objects={[{type: 'labels', key: 'role'}]}
              categories={[{value: intl('Role.Label'), categoryKey: 'labels'}]}
              onSelectionChange={this.handleSelect({key: 'role'})}
              selectedLabels={selectedLabels}
            />
          ),
        },
        {
          tid: 'app',
          key: <Form.Label name="app" title={intl('Common.Application')} lineHeight="var(--30px)" />,
          value: (
            <SingleItemSelect
              resourceType="pairing_profiles"
              initialItems={values.app}
              showResultsFooter
              allowCreateTypes={['labels']}
              placeholder={intl('Labels.Select')}
              objects={[{type: 'labels', key: 'app'}]}
              categories={[{value: intl('Labels.Application'), categoryKey: 'labels'}]}
              onSelectionChange={this.handleSelect({key: 'app'})}
              selectedLabels={selectedLabels}
            />
          ),
        },
        {
          tid: 'env',
          key: <Form.Label name="env" title={intl('Common.Environment')} lineHeight="var(--30px)" />,
          value: (
            <SingleItemSelect
              resourceType="pairing_profiles"
              showResultsFooter
              initialItems={values.env}
              allowCreateTypes={['labels']}
              placeholder={intl('Labels.Select')}
              objects={[{type: 'labels', key: 'env'}]}
              categories={[{value: intl('Labels.Environment'), categoryKey: 'labels'}]}
              onSelectionChange={this.handleSelect({key: 'env'})}
              selectedLabels={selectedLabels}
            />
          ),
        },
        {
          tid: 'loc',
          key: <Form.Label name="loc" title={intl('Common.Location')} lineHeight="var(--30px)" />,
          value: (
            <SingleItemSelect
              resourceType="pairing_profiles"
              initialItems={values.loc}
              showResultsFooter
              allowCreateTypes={['labels']}
              placeholder={intl('Labels.Select')}
              objects={[{type: 'labels', key: 'loc'}]}
              categories={[{value: intl('Labels.Location'), categoryKey: 'labels'}]}
              onSelectionChange={this.handleSelect({key: 'loc'})}
              selectedLabels={selectedLabels}
            />
          ),
        },
      ];
    }

    return [];
  }

  // Set Key Life Span
  handleOnSetLifeSpanField(evt, item, name) {
    const {setFieldValue} = this.form;

    const {value} = item;

    setFieldValue(name, item);

    if (value === 'custom') {
      this.setState({showLifeSpan: true});
    } else {
      // Reset 'lifespanTimeValue' with default this.initialValues object during constructor initialization
      // This is helpful when user is Editing to toggle formik's isValid property
      setFieldValue('lifespanTimeValue', this.state.initialValues.lifespanTimeValue);
      this.setState({showLifeSpan: false});
    }
  }

  async handleOnSave() {
    // Note: Need to set proper payload for API request. The API is very strict on the particular
    // value and datatype for request. Thus need to only pass selected values that exist.
    try {
      const {values, setSubmitting} = this.form;
      const {isEdit} = this.props;
      const {fetcher, navigate} = this.context;

      // Call formik method to set isSubmitting to true
      setSubmitting(true);

      let payload = {
        name: values.name,
        description: values.description,
        // This flag is needed to create pairing profile and update pairing profile
        enabled: values.enabled,
      };

      // Enforcement Mode
      payload.enforcement_mode = values.enforcementMode.value;

      // API will default visibililty_level as 'flow_summary' when visibility_level is not passed
      if (values.enforcementMode.value !== 'idle') {
        payload.visibility_level = values.visibility_level;
      }

      // Role, App, Loc and Env Form Fields
      const labels = labelFields.reduce((label, cur) => {
        if (values[cur] && values[cur].length) {
          // API only needs href
          const href = values[cur][0].href;

          label.push({href});
        }

        return label;
      }, []);

      // Set labels: role, app, loc, env as a collection to pass to the backend API
      payload.labels = labels;

      // Key Usage: Uses Per Key
      if (values.allowed_uses_per_key) {
        payload.allowed_uses_per_key = values.allowed_uses_per_key.value;
      }

      // Workload Policy State Locks
      if (values.stateLockValue === 'stateoverride-lock') {
        //  Need to pass these specific values to the API when stateoverride-lock selected
        // 'enforcement_mode_lock: true', 'visibility_level_lock: true' by calling getWorkloadPolicyLocks(true)
        Object.assign(payload, {...getWorkloadPolicyLocks(true)});
      } else {
        // Need to pass these specific values to the API when stateoverride-allow selected
        // 'enforcement_mode_lock: false', 'visibility_level_lock: false' by calling getWorkloadPolicyLocks(false)
        Object.assign(payload, {...getWorkloadPolicyLocks(false)});
      }

      // Label Assignment Lock and Allow
      if (values.labelsLockValue === 'labeloverride-allow') {
        // Label Locks checkboxes
        labelLocks.forEach(key => {
          payload[key] = !values.labelLockGrp.includes(key);
        });
      } else if (values.labelsLockValue === 'labeloverride-lock') {
        // Label Locks checkboxes
        labelLocks.forEach(key => {
          // Turn off all the label locks by setting true
          payload[key] = true;
        });
      }

      // Key Life Span with optional customized Time
      if (values.keyLifeSpanField) {
        let value = '';

        if (values.keyLifeSpanField.value === 'custom') {
          // customized the time
          // Get the proper customized time
          value = getTimeValue(values.lifespanTimeType.value, values.lifespanTimeValue);

          if (!value) {
            value = 'invalid';
          }
        } else {
          value = values.keyLifeSpanField.value;
        }

        payload.key_lifespan = value;
      }

      // Check for Ven: agent_software_release
      if (values.ven_software_release) {
        // Set the proper data to pass to the API
        payload.agent_software_release = values.ven_software_release
          ? formatReleaseValue(values.ven_software_release.value)
          : null;
      }

      // Check all the payload before making the API request
      payload = getWriteAbleData(payload);

      let id;

      await reactUtils.setStateAsync({saving: true}, this);

      if (isEdit) {
        id = this.props.currentRouteParams.id;
        await fetcher.spawn(updatePairingProfile, id, payload);
      } else {
        const {
          data: {href},
        } = await fetcher.spawn(createPairingProfile, payload);

        id = hrefUtils.getId(href);
      }

      await fetcher.fork(fetchPairingProfileItem, {params: {id}}, true);

      // Wait progress on save button to finish
      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false}));
      // Navigate to a view page
      navigate({to: 'pairingProfiles.item', params: {id}});
    } catch (error) {
      this.setState({error, saving: false});

      // Call formik method to set isSubmitting to false
      this.form.setSubmitting(false);
    }
  }

  // Handle Edit Error
  handleErrorClose() {
    this.setState({error: null});
  }

  // Handle the <Selector> callback for: role, loc, app , env options
  handleSelect({key}) {
    const {setFieldValue} = this.form;

    return selection => {
      if (selection.length) {
        const matches = selection[0];
        const label = {href: matches.href, key, value: matches.value};

        // Data structure needed to send to API backend
        setFieldValue(key, [label]);

        this.setState(prevState => {
          const selectedLabels = {...prevState.selectedLabels, [key]: label};

          return {selectedLabels};
        });
      } else {
        // Reset when selection is empty
        setFieldValue(key, []);

        this.setState(prevState => {
          const {[key]: _, ...selectedLabels} = prevState.selectedLabels;

          return {selectedLabels};
        });
      }
    };
  }

  // Toggle the checkbox options. Make changes to the 'labelsLockValue'
  // depending on the values that is checked.
  handleLockChange() {
    const {setFieldValue, values, setFieldTouched} = this.form;

    if (values.labelLockGrp.length > 0) {
      if (values.labelsLockValue !== 'labeloverride-allow') {
        setFieldValue('labelsLockValue', 'labeloverride-allow');
        setFieldTouched('labelsLockValue', true);
      }
    } else if (values.labelsLockValue !== 'labeloverride-lock') {
      setFieldValue('labelsLockValue', 'labeloverride-lock');
      setFieldTouched('labelsLockValue', true);
    }
  }

  // handleVisibleChange
  handleVisibleChange(evt, value, name) {
    const {setFieldValue} = this.form;

    setFieldValue(name, value);
  }

  updateHeader() {
    const {pairingProfile, dispatch} = this.props;

    if (pairingProfile.name) {
      dispatch({type: 'HEADER_SET', payload: {subtitle: pairingProfile.name}});
    }
  }

  lockChangeInit(form, field) {
    // If user clicked on one of the role/app/env/loc checkboxes while labelsLockValue radio was in 'lock' state,
    // then field.values array already has this clicked checkbox value and we don't need to do anything.
    // Otherwise, if user clicked on `allow` radio, then `values` array is empty
    // and we need to show initially selected checkboxes or select all role/app/env/loc checkboxes
    if (!field.values.length) {
      if (form.initialValues.labelLockGrp.length) {
        return 'initial';
      }

      form.setFieldValue('labelLockGrp', labelLocks);
      form.setFieldTouched('labelLockGrp', []);
    }
  }

  // Set specific initial value
  visibilityInitWhenIsTrue(form, field) {
    // Important to maintain the 'touched[name]: true' thus don't need to call setFieldTouched()
    // <FormEnforcementBoundariesVisibility> will handle setFieldTouched to show the error on the <Form.Label>
    // Need to update value back to initialState
    form.setFieldValue([field.name], form.initialValues.visibility_level);
  }

  // Render alert message when error or create fails
  renderEditAlert() {
    const {error, isEdit} = this.state;

    const token = _.get(error, 'data[0].token');
    const title = isEdit ? intl('PairingProfiles.Errors.Edit') : intl('PairingProfiles.Errors.Create');
    const message = (token && intl(`ErrorsAPI.err:${token}`)) || _.get(error, 'data[0].message', error.message);

    return (
      <Modal.Alert title={title} onClose={this.handleErrorClose} buttonProps={{tid: 'ok', text: intl('Common.OK')}}>
        <TypedMessages>{[{icon: 'error', content: message}]}</TypedMessages>
      </Modal.Alert>
    );
  }

  renderForm(form) {
    const {values, isValid} = form;
    const {saving, onSaveDone, error, cancel, showLifeSpan, showVen} = this.state;
    const {
      isEdit,
      currentRouteParams: {id},
      hasWritePermission,
      edgeEnabled,
      crowdstrikeEnabled,
    } = this.props;

    this.form = form;

    return (
      <>
        <ToolBar>
          <ToolGroup>
            <Button
              icon="save"
              tid="save"
              disabled={!hasWritePermission || isValid === false}
              text={intl('Common.Save')}
              progressCompleteWithCheckmark
              progress={saving}
              progressError={error !== null}
              onClick={this.handleOnSave}
              onProgressDone={onSaveDone}
            />
            <Button.Link
              color="standard"
              disabled={saving || Boolean(onSaveDone)}
              icon="cancel"
              tid="cancel"
              text={intl('Common.Cancel')}
              link={isEdit ? {to: 'pairingProfiles.item.view', params: {id}} : 'pairingProfiles'}
            />
          </ToolGroup>
        </ToolBar>
        <AttributeList valuesGap="gapLarge" hintColumnWidth="300px">
          {[
            {divider: true},
            {title: intl('Common.General')},
            {
              tid: 'name',
              key: <Form.Label name="name" title={intl('Common.Name')} />,
              value: (
                <Form.Input
                  name="name"
                  tid="name"
                  disabled={edgeEnabled}
                  placeholder={
                    edgeEnabled
                      ? intl('Edge.InstallScript.NamePlaceholder')
                      : intl('PairingProfiles.Mixin.Placeholder.PairingProfileName')
                  }
                />
              ),
            },
            {
              tid: 'desc',
              key: <Form.Label name="description" title={intl('Common.Description')} />,
              value: (
                <Form.Textarea
                  name="description"
                  tid="description"
                  placeholder={
                    edgeEnabled
                      ? intl('Edge.InstallScript.DescriptionPlaceholder')
                      : intl('PairingProfiles.Mixin.Placeholder.PairingProfileDescription')
                  }
                />
              ),
            },
            ...getEnforcementBoundariesVisibility({form: this.form, edgeEnabled, crowdstrikeEnabled}),
            showVen && {
              tid: 'ven-version',
              key: <Form.Label name="ven_software_release" title={intl('PairingProfiles.InitialVenVersion')} />,
              value: (
                <Form.Selector
                  name="ven_software_release"
                  tid="ven-software-release"
                  options={this.selectorOptions.venReleases}
                />
              ),
              secondary:
                values.ven_software_release &&
                (isEdit ? intl('PairingProfiles.Install') : intl('PairingProfiles.CreateWithVEN')),
              valueGap: 'gapXSmall',
            },
            ...this.getLabelAssignments(values),
            {divider: true},
            {title: intl('PairingProfiles.KeyUsage')},
            {
              tid: 'uses',
              key: <Form.Label name="allowed_uses_per_key" title={intl('PairingProfiles.Mixin.UsesPerKey')} />,
              value: (
                <>
                  <Form.Selector
                    name="allowed_uses_per_key"
                    tid="allowed-uses-per-key"
                    options={this.selectorOptions.usesOptions}
                  />
                  <InfoCard trigger={this.infoCardIconRef}>
                    {() => this.getKeyUsesHints(values.allowed_uses_per_key)}
                  </InfoCard>
                </>
              ),
              icon: <InfoCard.Icon ref={this.infoCardIconRef} />,
            },
            {
              tid: 'lifespan',
              key: <Form.Label name="keyLifeSpanField" title={intl('PairingProfiles.Mixin.KeyLifespan')} />,
              value: (
                <>
                  <Form.Selector
                    name="keyLifeSpanField"
                    tid="keylifespan"
                    options={this.selectorOptions.lifespanOptions}
                    onChange={this.handleOnSetLifeSpanField}
                  />
                  {showLifeSpan ? (
                    <>
                      <Form.Input name="lifespanTimeValue" tid="lifespantime" />
                      <Form.Selector
                        name="lifespanTimeType"
                        options={this.selectorOptions.lifespanTimeOptions}
                        tid="lifespantimetype"
                      />
                    </>
                  ) : null}
                  <InfoCard trigger={this.infoCardIconRef2}>{() => this.getKeyLifeSpanHints(values)}</InfoCard>
                </>
              ),
              icon: <InfoCard.Icon ref={this.infoCardIconRef2} />,
            },
            {divider: true},
            {title: intl('PairingProfiles.CommandLineOverrides')},
            {
              tid: 'stateoverride',
              key: <Form.Label name="stateLockValue" title={intl('Common.Enforcement')} />,
              value: (
                <Form.RadioGroup name="stateLockValue">
                  <Form.Radio
                    tid="stateoverride-lock"
                    value="stateoverride-lock"
                    label={intl('Common.Locked')}
                    subLabel={intl('PairingProfiles.Workloads.LockPolicyAssignmentDesc')}
                  />
                  <Form.Radio
                    tid="stateoverride-allow"
                    value="stateoverride-allow"
                    label={intl('Common.Unlocked')}
                    subLabel={intl('PairingProfiles.Workloads.AllowPolicyAssignmentDesc')}
                  />
                </Form.RadioGroup>
              ),
            },
            !edgeEnabled && {
              tid: 'labeloverride',
              key: <Form.Label name="labelsLockValue" title={intl('PairingProfiles.LabelAssignment')} />,
              value: (
                <Form.RadioGroup name="labelsLockValue">
                  <Form.Radio
                    tid="labeloverride-lock"
                    value="labeloverride-lock"
                    label={intl('PairingProfiles.Workloads.LockLabelAssignment')}
                    subLabel={intl('PairingProfiles.Workloads.LockLabelAssignmentDesc')}
                  />
                  <Form.Radio
                    tid="labeloverride-allow"
                    value="labeloverride-allow"
                    label={intl('PairingProfiles.Workloads.AllowCustomLabels')}
                    subLabel={intl('PairingProfiles.Workloads.AllowCustomLabelsDesc')}
                    nested={
                      <Form.CheckboxGroup
                        name="labelLockGrp"
                        when={{labelsLockValue: 'labeloverride-allow'}}
                        whenBecomesTrue={this.lockChangeInit}
                        whenBecomesFalse="empty"
                      >
                        <Form.Checkbox
                          name="labelLockGrp"
                          value="role_label_lock"
                          tid="role"
                          label={intl('PairingProfiles.Mixin.CanSetRoleLabel')}
                          onAfterChange={this.handleLockChange}
                        />
                        <Form.Checkbox
                          name="labelLockGrp"
                          value="app_label_lock"
                          tid="app"
                          label={intl('PairingProfiles.Mixin.CanSetApplicationLabel')}
                          onAfterChange={this.handleLockChange}
                        />
                        <Form.Checkbox
                          name="labelLockGrp"
                          value="env_label_lock"
                          tid="env"
                          label={intl('PairingProfiles.Mixin.CanSetEnvironmentLabel')}
                          onAfterChange={this.handleLockChange}
                        />
                        <Form.Checkbox
                          name="labelLockGrp"
                          value="loc_label_lock"
                          tid="loc"
                          label={intl('PairingProfiles.Mixin.CanSetLocationLabel')}
                          onAfterChange={this.handleLockChange}
                        />
                      </Form.CheckboxGroup>
                    }
                  />
                </Form.RadioGroup>
              ),
            },
          ]}
        </AttributeList>
        {cancel && this.renderCancel()}
        {error && this.renderEditAlert()}
      </>
    );
  }

  render() {
    const {
      pairingProfile,
      isEdit,
      currentRouteParams: {id},
      edgeEnabled,
    } = this.props;

    return (
      <>
        <HeaderProps
          title={edgeEnabled ? intl('Edge.InstallScript.Name') : intl('PairingProfiles.Profiles')}
          subtitle={pairingProfile.name}
          label={`(${intl(isEdit ? 'Common.Edit' : 'Common.Create')})`}
          up={isEdit ? {to: 'pairingProfiles.item.view', params: {id}} : 'pairingProfiles'}
        />
        <Form enableReinitialize schemas={this.schemas} initialValues={this.state.initialValues}>
          {this.renderForm}
        </Form>
      </>
    );
  }
}
