/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {HeaderProps} from 'containers';
import {getSecureGatewayItem} from './SecureGatewayItemState';
import {fetchSecureGatewayItem, fetchSecureGatewaySummary, fetchSecureGatewayEdit} from './SecureGatewayItemSaga';
import ProvisioningNotification from 'containers/Provisioning/ProvisioningNotification';

@connect(getSecureGatewayItem)
export default class SecureGatewayItem extends Component {
  static prefetch = fetchSecureGatewayItem;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.handleProvisioning = this.handleProvisioning.bind(this);
  }

  async handleProvisioning() {
    const {
      route: {name},
    } = this.props;

    await this.context.fetcher.fork(fetchSecureGatewayItem.refetch);

    if (name.endsWith('.view')) {
      await this.context.fetcher.fork(fetchSecureGatewaySummary.refetch);
    } else if (name.endsWith('.edit')) {
      await this.context.fetcher.fork(fetchSecureGatewayEdit.refetch);
    }
  }

  render() {
    const {
      versions: {active, draft, pversionObj},
      isOldVersion,
      pversionObjIsDeleted,
      provisional,
      pversion,
      route: {name},
    } = this.props;
    const isEdit = name.endsWith('.edit');

    return (
      <>
        <HeaderProps
          title={intl('Common.SecureConnectGateways')}
          subtitle={intl('SecureGateway.Name', {name: pversionObj.name})}
          up="secureGateways"
        />
        {(isOldVersion || (!isEdit && provisional)) && (
          <ProvisioningNotification
            params={{active: {pversion: 'active'}, draft: {pversion: 'draft'}}}
            pversion={pversion}
            onDone={this.handleProvisioning}
            active={active}
            draft={draft}
            pversionObjIsDeleted={pversionObjIsDeleted}
            objectsToProvision={{secure_connect_gateways: [{href: draft?.href}]}}
          />
        )}
        {this.props.children}
      </>
    );
  }
}
