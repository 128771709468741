/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component, createRef} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {hrefUtils, reactUtils} from 'utils';
import {parseAddressLine} from 'utils/ip';
import {object, string, array} from 'yup';
import {AttributeList, Button, Modal, ToolBar, ToolGroup, TypedMessages, Form, Notifications} from 'components';
import {
  getEnforcementBoundariesVisibilitySchema,
  getEnforcementBoundariesInitialValues,
  getEnforcementBoundariesVisibility,
  edgeOptions,
} from 'containers/EnforcementBoundaries/EnforcementBoundariesVisibilityFormUtils';
import {enforcementModeView, visibilityLevelView} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {friendlyName} from 'containers/Workload/WorkloadUtils';
import {HeaderProps} from 'containers';
import {SingleItemSelect} from 'containers/Selectors';
import {getWorkloadEdit} from './WorkloadEditState';
import {createUnmanagedWorkload, updateWorkload, fetchInitialValues} from './WorkloadEditSaga';
import {fetchWorkloadItem} from '../../WorkloadSaga';
import {actionOptions} from './WorkloadEditConfig';
import styles from './WorkloadEdit.css';
import {fetchPairingProfileByLabel} from 'edge/containers/Group/GroupSaga';

// Role, App, Loc and Env labels
const labelFields = ['role', 'app', 'loc', 'env'];

const getInitialValues = props => {
  const {routeParamsAll, workload, role, app, env, loc, isEdit, managedWorkload, dnsReverseLookupMap, edgeEnabled} =
    props;

  const actionOptionsObj = actionOptions();
  let address;
  let dnsName;

  if (routeParamsAll) {
    address = routeParamsAll.address;
    dnsName = routeParamsAll.dnsName;
  }

  const interfaces = address
    ? [
        {
          selectable: !managedWorkload,
          data: {
            isEdit,
            managedWorkload,
            interfacename: 'eth0',
            action: Form.Utils.findSelectedOption(actionOptionsObj, 'managed'),
            subnets: [address],
          },
        },
      ]
    : workload?.interfaces;

  return {
    name: workload.name || workload.hostname || dnsName || dnsReverseLookupMap[address] || '',
    description: workload.description || '',
    role,
    app,
    env,
    loc,
    hostname: workload.hostname || dnsName || dnsReverseLookupMap[address] || '',
    data_center: workload.data_center || '',
    os_id: workload.os_id || '',
    os_detail: workload.os_detail || '',
    public_ip: workload.public_ip || '',
    distinguished_name: workload.distinguished_name || '',
    service_principal_name: workload.service_principal_name || '',
    agent_to_pce_certificate_authentication_id: workload.agent_to_pce_certificate_authentication_id || '',
    interfaces: interfaces || [],
    ...getEnforcementBoundariesInitialValues(workload, edgeEnabled),
  };
};

// Initial State
const getInitialState = props => ({
  initialValues: getInitialValues(props),
  cancel: false,
  saving: false,
  error: null,
  href: props.workload.href,
  // Edge property
  enforcementContent: null,
  // Edge property: pairingProfile is the source of truth for 'Install Script' when Moving to different Group (aka role)
  pairingProfile: null,
});

@connect(getWorkloadEdit)
export default class WorkloadEdit extends Component {
  static prefetch = fetchInitialValues;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = getInitialState(props);

    let editschema = {};

    if (!this.props.isEdit || !(!this.props.workload || this.props.workload.agent.status)) {
      editschema = {
        hostname: string().max(255, intl('Common.NameIsTooLong')),
        data_center: string(),
        os_id: string(),
        os_detail: string(),
        public_ip: string(),
        distinguished_name: string(),
        service_principal_name: string(),
        agent_to_pce_certificate_authentication_id: string(),
      };
    }

    const schema = {
      name: string().max(255, intl('Common.NameIsTooLong')).required(intl('Common.AddValidName')),
      description: string(),
      role: array(
        object({
          href: string(),
        }),
      ),
      app: array(
        object({
          href: string(),
        }),
      ),
      loc: array(
        object({
          href: string(),
        }),
      ),
      env: array(
        object({
          href: string(),
        }),
      ),
      ...editschema,
      interfaces: array().of(
        object({
          data: object({
            interfacename: string().required(intl('Common.AddValidName')),

            // we don't need to validate the IPs because IPListEditor handles it
            subnets: array().of(string()).required(Form.emptyMessage),
          }),
        }),
      ),
      ...(props.workload?.agent?.status ? getEnforcementBoundariesVisibilitySchema : {}),
    };

    this.schemas = object(schema);

    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);
    this.handleLabelSelectorChange = this.handleLabelSelectorChange.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);

    this.infoCardIconRef = createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // When a different workload reinitialized the state. API
    // returns name as unique.
    if (prevState.href !== nextProps.workload.href) {
      return getInitialState(nextProps);
    }

    return null;
  }

  getHint(enforcementContent) {
    const {crowdstrikeEnabled, workload} = this.props;

    const unmanaged = !workload?.agent?.status;

    if (enforcementContent) {
      const enforcementData = enforcementModeView()[enforcementContent.enforcement_mode]?.name;
      const visibilityData = visibilityLevelView()[enforcementContent.visibility_level]?.name;

      let hint;

      if (enforcementData && visibilityData && !crowdstrikeEnabled) {
        hint = intl(
          'Workloads.WorkloadToEnforcementNotification',
          {enforcement: enforcementData, visibility: visibilityData},
          {html: true},
        );
      } else if (enforcementData && visibilityData && crowdstrikeEnabled) {
        hint = intl('Workloads.WorkloadToEnforcementNotificationCS', {enforcement: enforcementData}, {html: true});
      }

      if (crowdstrikeEnabled && unmanaged) {
        hint = intl('Labels.ModifyForWorkloadsEdgeUnmanaged');
      }

      return <div>{hint}</div>;
    }

    return null;
  }

  getSelectedLabels(values) {
    return labelFields.reduce((label, key) => {
      if (values[key]?.length) {
        const href = values[key][0].href;

        label.push({key, href});
      }

      return label;
    }, []);
  }

  async handleLabelChange(type, value) {
    const {setFieldValue} = this.form;
    // Policy Content details to appear under group selection
    let enforcementContent = null;

    if (value === 'clear') {
      // Only show if initial selection exists e.g. going from group to no group via 'clear' action.
      if (this.props.crowdstrikeEnabled && this.props.role.length === 1) {
        enforcementContent = {enforcement_mode: 'idle', visibility_level: 'idle'};
      }

      // Note: Edge will default to initial value when clearing the Group but for CS will default to 'Idle'
      setFieldValue(
        'enforcementMode',
        Form.Utils.findSelectedOption(
          edgeOptions(),
          enforcementContent?.enforcement_mode ?? this.state.initialValues.enforcementMode.value,
        ),
      );
      setFieldValue(
        'visibility_level',
        enforcementContent?.visibility_level ?? this.state.initialValues.visibility_level,
      );

      this.setState(prevState => ({
        finalLabels: {
          ...prevState.finalLabels,
          [type]: new Set(),
        },
        enforcementContent,
      }));
    } else {
      await reactUtils.setStateAsync({fetchingPairingProfile: true}, this);

      // Fetch policy state for group
      const pairingProfile = await this.context.fetcher.spawn(fetchPairingProfileByLabel, {
        labelHref: value,
        force: true,
        dispatch: false,
      });

      //  For empty install script default to initialValues
      const {enforcement_mode: enforcementMode, visibility_level: visibilityLevel} = pairingProfile;

      // Set policy state content for group selection
      enforcementContent = {enforcement_mode: enforcementMode, visibility_level: visibilityLevel};
      enforcementContent.group = this.state.labelValueMap[value];

      setFieldValue(
        'enforcementMode',
        Form.Utils.findSelectedOption(edgeOptions(), enforcementMode ?? this.state.initialValues.enforcementMode.value),
      );
      setFieldValue(
        'visibility_level',
        enforcementContent.visibility_level ?? this.state.initialValues.visibility_level,
      );

      this.setState(prevState => ({
        fetchingPairingProfile: false,
        finalLabels: {
          ...prevState.finalLabels,
          [type]: new Set([value]),
        },
        pairingProfile,
        enforcementContent,
      }));
    }
  }

  async handleLabelSelectorChange(type, selection) {
    const {setFieldValue} = this.form;

    const selectionLabelValueMap = selection.reduce((result, label) => {
      result[label.href] = label.value;

      return result;
    }, {});

    await reactUtils.setStateAsync(
      ({labelValueMap}) => ({
        labelValueMap: {
          ...labelValueMap,
          ...selectionLabelValueMap,
        },
      }),
      this,
    );

    if (selection.length) {
      const matches = selection[0];

      // Data structure needed to send to API backend
      setFieldValue(type, [{href: matches.href, type, value: matches.value}]);
    } else {
      // Reset when selection is empty
      setFieldValue(type, []);
    }

    if (selection.length > 0) {
      await this.handleLabelChange(type, selection[0].href);
    } else {
      await this.handleLabelChange(type, 'clear');
    }
  }

  async handleOnSave() {
    const {
      context: {fetcher, navigate},
      form: {values, setSubmitting},
      props: {isEdit, workload, optionalFeatures},
    } = this;

    let payload = {};

    // Role, App, Loc and Env Form Fields
    const labels = labelFields.reduce((label, cur) => {
      if (values[cur] && values[cur].length) {
        // API only needs href
        const href = values[cur][0].href;

        label.push({href});
      }

      return label;
    }, []);

    payload = {
      name: values.name.toString().trim(),
      labels,
    };

    const optionalAttributes = [
      'description',
      'hostname',
      'data_center',
      'os_id',
      'os_detail',
      'public_ip',
      'distinguished_name',
      'service_principal_name', // can only be a string (but needs minimum of 1 character) or null
      'agent_to_pce_certificate_authentication_id', // can only be a string (but needs minimum of 1 character) or null
    ];

    optionalAttributes.forEach(attribute => {
      if (values.hasOwnProperty(attribute)) {
        if (
          !values[attribute] &&
          ['service_principal_name', 'agent_to_pce_certificate_authentication_id', 'public_ip'].includes(attribute)
        ) {
          // If the value is service_principal_name or agent_to_pce_certificate_authentication_id and is empty
          // Set property to null instead of default empty string to avoid minimum char error.
          payload[attribute] = null;
        } else {
          payload[attribute] = values[attribute];
        }
      }
    });

    if (!optionalFeatures.pki_authentication_enabled || !optionalFeatures.pki_authentication_enabled.enabled) {
      payload = _.omit(payload, 'agent_to_pce_certificate_authentication_id');
    }

    // Enforcement Mode
    if (values.enforcementMode) {
      payload.enforcement_mode = values.enforcementMode.value;
    }

    // Visibility Level
    // API will default visibility_level as 'flow_summary' when visibility_level is not passed
    if (values.enforcementMode?.value !== 'idle') {
      payload.visibility_level = values.visibility_level;
    }

    if (values.interfaces && !_.isEqual(values.interfaces, workload?.interfaces)) {
      const ignored = new Set();

      payload.interfaces = values.interfaces.flatMap(interfacevalue =>
        interfacevalue.data.subnets.map(subnet => {
          const line = interfacevalue.data.interfacename + ': ' + subnet;
          const result = parseAddressLine(line, false, true);

          if (interfacevalue.data.action.value === 'ignored') {
            ignored.add(interfacevalue.data.interfacename);
          }

          return {
            address: result.address,
            name: result.name,
            cidr_block: result.cidr_block,
          };
        }),
      );

      payload.ignored_interface_names = Array.from(ignored.values());
    }

    try {
      let id;

      await reactUtils.setStateAsync({saving: true}, this);
      // Call formik method to set isSubmitting to true
      setSubmitting(true);

      if (isEdit) {
        id = this.props.currentRouteParams.id;

        const omitAttributes = [
          'hostname',
          'data_center',
          'os_id',
          'os_detail',
          'public_ip',
          'distinguished_name',
          'interfaces',
        ];

        if (!workload || workload.agent.status) {
          await fetcher.spawn(updateWorkload, id, _.omit(payload, omitAttributes));
        } else {
          await fetcher.spawn(updateWorkload, id, payload);
        }
      } else {
        const {
          data: {href},
        } = await fetcher.spawn(createUnmanagedWorkload, _.omit(payload, ['enforcement_mode', 'visibility_level']));

        id = hrefUtils.getId(href);
      }

      await fetcher.fork(fetchWorkloadItem, {params: {id}}, true);

      // Wait progress on save button to finish
      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false}));
      // Navigate to a view page
      navigate({to: 'workloads.item', params: {id}});
    } catch (error) {
      this.setState({error, saving: false});
      setSubmitting(false);
    }
  }

  // Handle IPListEditor onchange
  handleOnChange(defaultNames) {
    return (editorState, name, {ipErrors}) => {
      const {setFieldValue} = this.form;

      // Pass in the initialValues.[ipNames] for formik to update 'isValid' flag properly. Since
      // ipNames is an array changing the ordering even though data is the same is considered dirty
      const {currentValue} = Form.IPListEditor.Utils.getCurrentDataCheck(defaultNames, editorState);

      // Since IPListEditor is uncontrolled, we can re-use the value to pass the validation error to Formik.
      // Any non-string-array value causes the schema validation fails for subnets
      if (ipErrors[name]?.error) {
        setFieldValue(name, null);
      } else {
        setFieldValue(name, currentValue);
      }
    };
  }

  // Handle Edit Error
  handleErrorClose() {
    this.setState({error: null});
  }

  // Handle the <Selector> callback for: role, loc, app , env options
  handleSelect({key}) {
    const {setFieldValue} = this.form;

    return selection => {
      if (selection.length) {
        const matches = selection[0];

        // Data structure needed to send to API backend
        setFieldValue(key, [{href: matches.href, key, value: matches.value}]);
      } else {
        // Reset when selection is empty
        setFieldValue(key, []);
      }
    };
  }

  // Render alert message when edit or create fails
  renderErrorAlert() {
    const {error} = this.state;
    const {isEdit} = this.props;

    const title = isEdit ? intl('Workloads.Errors.Edit') : intl('Workloads.Errors.Create');
    const message = _.get(error, 'data[0].message', error.message);

    return (
      <Modal.Alert title={title} onClose={this.handleErrorClose} buttonProps={{tid: 'ok', text: intl('Common.OK')}}>
        <TypedMessages>{[{icon: 'error', content: message}]}</TypedMessages>
      </Modal.Alert>
    );
  }

  renderForm(form) {
    const {
      workload,
      isEdit,
      managedWorkload,
      currentRouteParams: {id},
      hasWritePermission,
      optionalFeatures,
      interfacegrid,
      edgeEnabled,
      crowdstrikeEnabled,
      role,
    } = this.props;

    const subtitle = friendlyName(workload);
    const classNameContent = styles.content;
    const unmanaged = !workload?.agent?.status;

    const title = unmanaged ? intl('Common.UnmanagedWorkload') : intl('Common.Workloads');

    const {values, isValid} = form;
    const {saving, onSaveDone, error, enforcementContent: groupPolicyContent} = this.state;

    const selectedLabels = this.getSelectedLabels(values);
    const showNetworkInterfaces = !edgeEnabled || (!crowdstrikeEnabled && managedWorkload);

    this.form = form;

    const attributes = [];
    const machine = [];

    let excludeOptions = [];
    let disableEnhancementMode;
    let enforcementContent;

    if (edgeEnabled) {
      if (this.props.crowdstrikeEnabled) {
        // When there is at least a role selected do not show 'idle'
        if (this.form.values.role?.length > 0) {
          // Don't include idle in enhancement option
          excludeOptions = ['idle'];
        } else {
          // When in idle mode don't show these options
          excludeOptions = ['visibility_only', 'full'];
          // Disable the option since it is only one
          disableEnhancementMode = true;
        }
      }

      enforcementContent = groupPolicyContent;

      if (enforcementContent) {
        if (
          values.enforcementMode?.value !== enforcementContent?.enforcement_mode ||
          values.visibility_level !== enforcementContent?.visibility_level
        ) {
          enforcementContent.enforcement_mode = values.enforcementMode?.value;
          enforcementContent.visibility_level = values.visibility_level;
        }

        if (enforcementContent?.enforcement_mode === 'idle') {
          enforcementContent.visibility_level = 'idle';
        }

        if (enforcementContent && enforcementContent?.enforcement_mode === 'full') {
          enforcementContent.enforcement_mode = 'enforced';
        }

        if (values.role[0]?.href === role[0]?.href) {
          enforcementContent = null;
        }
      }

      if (values.role.length === 0 && crowdstrikeEnabled && role.length !== 0) {
        enforcementContent = {
          enforcement_mode: 'idle',
          visibility_level: 'idle',
        };
      }
    }

    if (!isEdit || !(!workload || workload.agent.status)) {
      attributes.push(
        {divider: true},
        {title: intl('Common.HostAttributes')},
        {
          tid: 'hostname',
          key: <Form.Label name="hostname" title={intl('Common.Hostname')} />,
          value: (
            <Form.Input
              name="hostname"
              tid="hostname"
              placeholder={intl('Workloads.Create.Placeholder.WorkloadHostName')}
            />
          ),
        },
        {
          tid: 'location',
          key: <Form.Label name="data_center" title={intl('Common.Location')} />,
          value: (
            <Form.Input
              name="data_center"
              tid="location"
              placeholder={intl('Workloads.Create.Placeholder.WorkloadLocation')}
            />
          ),
        },
        {
          tid: 'os',
          key: <Form.Label name="os_id" title={intl('Common.OSFamily')} />,
          value: (
            <Form.Input name="os_id" tid="os" placeholder={intl('Workloads.Create.Placeholder.WorkloadOSFamily')} />
          ),
        },
        {
          tid: 'release',
          key: <Form.Label name="os_detail" title={intl('Workloads.Release')} />,
          value: (
            <Form.Input
              name="os_detail"
              tid="release"
              placeholder={intl('Workloads.Create.Placeholder.WorkloadRelease')}
            />
          ),
        },
        !edgeEnabled
          ? {
              tid: 'publicip',
              key: <Form.Label name="public_ip" title={intl('Workloads.PublicIP')} />,
              value: (
                <Form.Input
                  name="public_ip"
                  tid="publicip"
                  placeholder={intl('Workloads.Create.Placeholder.WorkloadPublicIP')}
                />
              ),
            }
          : null,
      );

      if (!edgeEnabled) {
        machine.push(
          {divider: true},
          {title: intl('Common.MachineAuthentication')},
          {
            tid: 'machineauth',
            key: <Form.Label name="distinguished_name" title={intl('Workloads.MachineAuthenticationId')} />,
            value: (
              <>
                <Form.Input
                  name="distinguished_name"
                  tid="machineauth"
                  placeholder={intl('Workloads.Create.Placeholder.MachineAuthenticationId')}
                />
                <div>
                  <div>{intl('Workloads.MachineAuthenticationExample')}</div>
                  <div>
                    {intl('Workloads.VenToPCEAuthentication.PKICertificateIdExampleWithDN')}
                    <div className={classNameContent}>
                      C=US, ST=California, L=San Francisco, O=Example, CN=workload1.example.net
                    </div>
                  </div>
                </div>
              </>
            ),
          },
        );
      }
    }

    return (
      <>
        <HeaderProps
          title={title}
          subtitle={subtitle}
          label={`(${intl(isEdit ? 'Common.Edit' : 'Common.Create')})`}
          up={isEdit ? {to: 'workloads.item.view', params: {id}} : 'workloads'}
        />
        <ToolBar>
          <ToolGroup>
            <Button
              icon="save"
              tid="save"
              disabled={!hasWritePermission || isValid === false}
              text={intl('Common.Save')}
              progressCompleteWithCheckmark
              progress={saving}
              progressError={error !== null}
              onClick={this.handleOnSave}
              onProgressDone={onSaveDone}
            />
            <Button.Link
              color="standard"
              disabled={saving || Boolean(onSaveDone)}
              icon="cancel"
              tid="cancel"
              text={intl('Common.Cancel')}
              link={isEdit ? {to: 'workloads.item.view', params: {id}} : 'workloads'}
            />
          </ToolGroup>
        </ToolBar>
        <AttributeList valuesGap="gapLarge">
          {[
            {divider: true},
            {title: intl('Common.General')},
            {
              tid: 'name',
              key: <Form.Label name="name" title={intl('Common.Name')} />,
              value: (
                <Form.Input name="name" tid="name" placeholder={intl('Workloads.Create.Placeholder.WorkloadName')} />
              ),
            },
            {
              tid: 'desc',
              key: <Form.Label name="description" title={intl('Common.Description')} />,
              value: <Form.Textarea name="description" tid="description" />,
            },
            ...(!unmanaged
              ? getEnforcementBoundariesVisibility({
                  form: this.form,
                  edgeEnabled,
                  crowdstrikeEnabled,
                  options: {
                    excludeOptions,
                    disabled: disableEnhancementMode,
                  },
                })
              : []),
            {divider: true},
            {title: edgeEnabled ? intl('Edge.InstallScript.GroupAssignment') : intl('PairingProfiles.LabelAssignment')},
            {
              tid: 'role',
              key: (
                <Form.Label
                  name="role"
                  title={edgeEnabled ? intl('Common.Group') : intl('Common.Role')}
                  lineHeight="var(--30px)"
                />
              ),
              value: (
                <SingleItemSelect
                  showResultsFooter
                  initialItems={values.role}
                  allowCreateTypes={edgeEnabled ? undefined : ['labels']}
                  placeholder={edgeEnabled ? intl('Labels.SelectGroup') : intl('Labels.Select')}
                  objects={[{type: 'labels', key: 'role'}]}
                  categories={[{value: edgeEnabled ? intl('Common.Group') : intl('Role.Label'), categoryKey: 'labels'}]}
                  onSelectionChange={
                    edgeEnabled ? _.partial(this.handleLabelSelectorChange, 'role') : this.handleSelect({key: 'role'})
                  }
                  selectedLabels={selectedLabels}
                />
              ),
              ...(edgeEnabled && {hint: this.getHint(enforcementContent)}),
            },
            !edgeEnabled
              ? {
                  tid: 'app',
                  key: <Form.Label name="app" title={intl('Common.Application')} lineHeight="var(--30px)" />,
                  value: (
                    <SingleItemSelect
                      resourceType="pairing_profiles"
                      initialItems={values.app}
                      showResultsFooter
                      allowCreateTypes={['labels']}
                      placeholder={intl('Labels.Select')}
                      objects={[{type: 'labels', key: 'app'}]}
                      categories={[{value: intl('Labels.Application'), categoryKey: 'labels'}]}
                      onSelectionChange={this.handleSelect({key: 'app'})}
                      selectedLabels={selectedLabels}
                    />
                  ),
                }
              : null,
            !edgeEnabled
              ? {
                  tid: 'env',
                  key: <Form.Label name="env" title={intl('Common.Environment')} lineHeight="var(--30px)" />,
                  value: (
                    <SingleItemSelect
                      resourceType="pairing_profiles"
                      showResultsFooter
                      initialItems={values.env}
                      allowCreateTypes={['labels']}
                      placeholder={intl('Labels.Select')}
                      objects={[{type: 'labels', key: 'env'}]}
                      categories={[{value: intl('Labels.Environment'), categoryKey: 'labels'}]}
                      onSelectionChange={this.handleSelect({key: 'env'})}
                      selectedLabels={selectedLabels}
                    />
                  ),
                }
              : null,
            !edgeEnabled
              ? {
                  tid: 'loc',
                  key: <Form.Label name="loc" title={intl('Common.Location')} lineHeight="var(--30px)" />,
                  value: (
                    <SingleItemSelect
                      resourceType="pairing_profiles"
                      initialItems={values.loc}
                      showResultsFooter
                      allowCreateTypes={['labels']}
                      placeholder={intl('Labels.Select')}
                      objects={[{type: 'labels', key: 'loc'}]}
                      categories={[{value: intl('Labels.Location'), categoryKey: 'labels'}]}
                      onSelectionChange={this.handleSelect({key: 'loc'})}
                      selectedLabels={selectedLabels}
                    />
                  ),
                }
              : null,
            showNetworkInterfaces ? {divider: true} : null,
            showNetworkInterfaces ? {title: intl('Common.NetworkInterfaces')} : null,
            showNetworkInterfaces
              ? {
                  value: (
                    <Notifications>
                      {[
                        {
                          type: 'instruction',
                          message: (
                            <div>
                              <div>{intl('Workloads.ManagedInterfaces')}</div>
                              <div>{intl('Workloads.ManagedIgnored')}</div>
                            </div>
                          ),
                        },
                      ]}
                    </Notifications>
                  ),
                }
              : null,
            showNetworkInterfaces
              ? {
                  tid: 'network interfaces',
                  value: (
                    <Form.Grid
                      theme={styles}
                      name="interfaces"
                      settings={interfacegrid.settings}
                      noAdd={managedWorkload}
                      noRemove={managedWorkload}
                      addButtonProps={{tid: 'interfaces'}}
                      removeButtonProps={{tid: 'remove-interfaces'}}
                      gridProps={{
                        component: this,
                      }}
                    />
                  ),
                }
              : null,
            ...attributes,
            ...machine,
            !edgeEnabled && {divider: true},
            !edgeEnabled && {title: intl('Workloads.VenToPCEAuthentication.Title')},
            !edgeEnabled && {
              tid: 'kerberos',
              key: (
                <Form.Label
                  name="service_principal_name"
                  title={intl('Workloads.Summary.KerberosServicePrincipalName')}
                />
              ),
              value: (
                <Form.Input
                  name="service_principal_name"
                  tid="kerberos"
                  placeholder={intl('Workloads.Create.Placeholder.SPN')}
                />
              ),
            },
            !edgeEnabled &&
              optionalFeatures.pki_authentication_enabled &&
              optionalFeatures.pki_authentication_enabled.enabled && {
                tid: 'pkiid',
                key: (
                  <Form.Label
                    name="agent_to_pce_certificate_authentication_id"
                    title={intl('Workloads.VenToPCEAuthentication.PKICertificateId')}
                  />
                ),
                value: (
                  <Form.Input
                    name="agent_to_pce_certificate_authentication_id"
                    tid="pkiid"
                    placeholder={intl('Workloads.Create.Placeholder.PKICertificateId')}
                  />
                ),
              },
          ]}
        </AttributeList>
        {error && this.renderErrorAlert()}
      </>
    );
  }

  render() {
    return (
      <Form enableReinitialize schemas={this.schemas} initialValues={this.state.initialValues}>
        {this.renderForm}
      </Form>
    );
  }
}
