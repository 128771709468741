/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {lookupProtocol} from 'containers/Service/ServiceUtils';
import {formatVulnerability} from '../Vulnerabilities/WorkloadVulnerabilitiesUtils';
import {createSelector} from 'reselect';

export const gridSettings = createSelector([], () => ({
  id: 'workloadprocesses',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    name: {
      header: intl('Workloads.ProcessName'),
      value: ({row}) => row.full_process_name,
    },
    path: {
      header: intl('Port.ProcessPath'),
      value: ({row}) => row.process_path,
    },
    port: {
      header: intl('Port.Ports'),
      value: ({row}) => row.port,
    },
    protocol: {
      header: intl('Common.Protocol'),
      value: ({row}) => row.protocol,
      format: ({value}) => lookupProtocol(value),
    },
    vulnerability: {
      header: intl('Vulnerability.VEScore'),
      value: ({row}) => row.vulnerability,
      format: ({value}) => formatVulnerability(value),
    },
  },

  templates: [
    [
      {columns: ['vulnerability'], size: 'minmax(100px, auto)'},
      {columns: ['name'], size: 'minmax(100px, auto)'},
      {columns: ['path'], size: 'minmax(100px, auto)'},
      {columns: ['port'], size: 'minmax(100px, auto)'},
      {columns: ['protocol'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['vulnerability'], size: 'minmax(100px, auto)'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['path'], size: 'minmax(100px, auto)'},
            {columns: ['port'], size: 'minmax(100px, auto)'},
            {columns: ['protocol'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['vulnerability'], size: 'minmax(100px, auto)'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['path'], size: 'minmax(100px, auto)'},
          {columns: ['port'], size: 'minmax(100px, auto)'},
          {columns: ['protocol'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['vulnerability', 'name', 'path'], size: 'minmax(200px, auto)'},
            {columns: ['port', 'protocol'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['vulnerability', 'name', 'path'], size: 'minmax(200px, auto)'},
          {columns: ['port', 'protocol'], size: 'minmax(200px, auto)'},
        ];
      },
    },
  ],
}));
