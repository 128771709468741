/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {hrefUtils} from 'utils';
import apiSaga from 'api/apiSaga';
import {call, all, select, take, put} from 'redux-saga/effects';
import {getSwitches, getSwitchesCount, getGridSettings} from './SwitchListState';
import {getSelectorSettings} from './SwitchListConfig';
import {getUrlScopeValue} from 'components/Grid/GridSelectors';
import {fetchNetworkEnforcementNodes} from '../NetworkEnforcementNodeSaga';
import gridSaga from 'components/Grid/GridSaga';
import {RedirectError} from 'errors';
import {isSwitchEnabled} from '../SwitchState';
import {isEdge} from 'containers/App/AppState';

export function* fetchSwitches({filter, force = false} = {}) {
  yield call(fetchNetworkEnforcementNodes, force);

  const query = {};

  if (filter) {
    for (const [name, [value]] of Object.entries(filter)) {
      if (value) {
        query[name] = value;
      }
    }
  }

  const {switchList} = yield call(apiSaga, 'network_devices.get_collection', {
    query,
    cache: !force,
    beforeFetch: put({type: 'SWITCH_GETTING_LIST', payload: {filter}}),
    *onDone({data: switchList, count}) {
      if (switchList !== (yield select(getSwitches)) || count !== (yield select(getSwitchesCount))) {
        yield put({type: 'SWITCH_GET_LIST', data: {switchList, count}});
      }

      return {switchList, count};
    },
  });

  const interfaceResponses = yield all(
    switchList.map(switchInst =>
      call(apiSaga, 'network_endpoints.get_collection', {
        params: {nd_uuid: hrefUtils.getId(switchInst.href)},
        cache: !force,
      }),
    ),
  );

  const interfaceMap = interfaceResponses.reduce((result, interfaceResp) => {
    const interfaceList = interfaceResp && interfaceResp.data;

    interfaceList.forEach(interfaceInst => {
      result[interfaceInst.href] = interfaceInst;
    });

    return result;
  }, {});

  yield put({type: 'INTERFACES_GET_MAP', data: {interfaceMap}});

  return {switchList, interfaceMap};
}

export function* refreshSwitch() {
  while (true) {
    yield take('SWITCH_REFRESH_LIST');

    yield call(fetchSwitches);
  }
}

export function* removeSwitch({href}) {
  const switchList = yield select(getSwitches);
  const switchInst = switchList.find(switchListInst => switchListInst.href === href);

  try {
    if (switchInst) {
      const interfaceUuids = switchInst.network_endpoints.map(interfaceHrefWrapper =>
        hrefUtils.getId(interfaceHrefWrapper.href),
      );

      yield all(
        interfaceUuids.map(interfaceUuid =>
          call(apiSaga, 'network_endpoint.delete', {
            params: {nd_uuid: hrefUtils.getId(href), ep_uuid: interfaceUuid},
          }),
        ),
      );
    }

    yield call(apiSaga, 'network_device.delete', {params: {nd_uuid: hrefUtils.getId(href)}});
  } catch (err) {
    err.href = href;
    throw err;
  }
}

export function* fetchSwitchList(route, refetch = false) {
  const {params} = route;
  const switchIsEnabled = yield select(isSwitchEnabled);
  const edgeEnabled = yield select(isEdge);
  const selectorSettingsObject = yield select(getSelectorSettings);

  if (!switchIsEnabled || edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: selectorSettingsObject.filterMap,
    *onSaga({filterParams}) {
      const scopeParams = yield select(getUrlScopeValue, params);
      const scope = scopeParams.isEmpty ? undefined : scopeParams.valid;

      const {switchList} = yield call(fetchSwitches, {
        params,
        filter: filterParams.isEmpty ? undefined : filterParams.valid,
        scope,
        force: refetch,
      });

      return switchList.length;
    },
  });
}

export function* generateSwitchesAcls({switchIds}) {
  yield call(apiSaga, 'network_devices.multi_enforcement_instructions_request', {
    data: {network_devices: switchIds},
  });
}

export function* markApplied({switchIds}) {
  yield call(apiSaga, 'network_devices.multi_enforcement_instructions_applied', {
    data: {network_devices: switchIds},
  });
}
