/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {all, call, put, select} from 'redux-saga/effects';
import {areVulnerabilitiesEnabled} from 'containers/App/AppState';
import {fetchVulnerabilities} from '../Vulnerabilities/WorkloadVulnerabilitiesSaga';
import {fetchWorkloadPolicyMode} from '../../List/WorkloadListSaga';

export function* fetchSummary() {
  const count = yield call(fetchWorkloadPolicyMode, {mode: 'static', force: true});

  yield put({type: 'WORKLOAD_STATIC_COUNT', data: {count}});

  const VulnerabilitiesEnabled = yield select(areVulnerabilitiesEnabled);

  if (VulnerabilitiesEnabled) {
    return yield all([
      call(apiSaga, 'vulnerability_reports.get_collection', {
        *onDone({data: list, count}) {
          yield put({type: 'VULNERABILITY_REPORTS_LIST', data: {list, count}});
          yield call(fetchVulnerabilities);

          return {list, count};
        },
      }),
    ]);
  }
}
