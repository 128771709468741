/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {hrefUtils} from 'utils';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {HeaderProps} from 'containers';
import {fetchContainerClusterList} from './ContainerClusterListSaga';
import {getContainerClustersPage} from './ContainerClusterListState';
import ContainerClusterReducers from '../ContainerClusterState';
import {ButtonRefresh, Button, Grid, ToolBar, ToolGroup, Link, Notifications} from 'components';
import styles from './ContainerClusterList.css';
import {cachedResponses} from 'api/apiCache';
import {ComboSelect} from 'containers/Selectors';
import {isAPIAvailable} from 'api/apiUtils';
import {getMaxPageNotificationList} from 'components/Grid/GridUtils';

const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};
const addLink = <Link to="containerClusters.create">{intl('ContainerClusters.AddNew')}</Link>;

@connect(getContainerClustersPage)
export default class ContainerClusterList extends Component {
  static prefetch = fetchContainerClusterList;
  static contextType = AppContext;
  static reducers = ContainerClusterReducers;

  handleClick = (evt, row) => {
    this.context.navigate({evt, to: 'containerClusters.item', params: {id: hrefUtils.getId(row.key)}});
  };

  handleFilterChange = selection => {
    const {
      context: {navigate},
      props: {grid},
    } = this;

    const filter = selection.reduce((result, {categoryKey, value}) => {
      result[categoryKey] = [value];

      return result;
    }, {});

    navigate({params: {[grid.settings.id]: {...grid.params, filter, page: null}}});
  };

  handleRefresh = () => {
    cachedResponses.removeByMethodName('container_clusters.get_instance');
    cachedResponses.removeByMethodName('health.get');
    cachedResponses.removeByMethodName('container_workload_profiles.get_collection');
    cachedResponses.removeByMethodName('container_workload_profiles.get_instance');
    cachedResponses.removeByMethodName('workloads.get_collection');
    cachedResponses.removeByMethodName('container_workloads.get_collection');
    cachedResponses.removeByMethodName('container_cluster.service_backends');

    return this.context.fetcher.fork(fetchContainerClusterList.refetch);
  };

  render() {
    const {
      props: {grid, count, selector},
    } = this;

    const notifications = getMaxPageNotificationList({page: grid.page, capacity: grid.capacity, count});

    return (
      <>
        <HeaderProps title={intl('Menu.ContainerClusters', {multiple: true})} />
        {notifications.length > 0 && <Notifications sidebar>{notifications}</Notifications>}
        <ToolBar>
          <ToolGroup>
            <Button.Link
              icon="add"
              text={intl('Common.Add')}
              textIsHideable
              disabled={!isAPIAvailable('container_clusters.create')}
              link="containerClusters.create"
              tid="add"
              theme={buttonsTheme}
            />
          </ToolGroup>
          <ToolGroup>
            <ButtonRefresh color="standard" textIsHideable onRefresh={this.handleRefresh} theme={buttonsTheme} />
          </ToolGroup>
        </ToolBar>
        <ToolBar>
          <ToolGroup expand tid="page-filter">
            <ComboSelect
              scrollable
              objects={selector.objects}
              placeholder={intl('Common.FilterView')}
              initialItems={selector.filterItems}
              categories={selector.categories}
              activeCategoryKey="name"
              partials={selector.partials}
              facets={selector.facets}
              statics={selector.statics}
              customPickers={selector.customPickers}
              onSelectionChange={this.handleFilterChange}
            />
          </ToolGroup>
        </ToolBar>

        <Grid
          grid={grid}
          count={count}
          onClick={this.handleClick}
          emptyMessage={intl('Common.NoDataContainerCluster')}
          addItemLink={isAPIAvailable('container_clusters.create') && count && !count.total && addLink}
        />
      </>
    );
  }
}
