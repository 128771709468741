/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import ApplicationError from './Application';
import type {ApplicationErrorOptions} from './Application';
import type {RequestInfo} from './Request';

export interface RequestStatusErrorOptions<T = unknown> extends ApplicationErrorOptions {
  data?: T;
  response?: Partial<Response>;
  request?: RequestInfo;
  timeout?: boolean;
}

/**
 * Request fetch error
 */
export default class RequestStatusError<T = unknown> extends ApplicationError {
  data?: T;
  response?: Partial<Response>;
  timeout?: boolean;

  constructor(params: RequestStatusErrorOptions<T> | string = {}) {
    if (typeof params === 'string') {
      params = {code: params};
    }

    const {data, response = {}, timeout, ...details} = params;

    _.defaults(details, {
      code: 'REQUEST_STATUS_ERROR',
      statusCode: response.status || 400,
      message: response.statusText,
    });

    super(details);
    this.name = 'RequestStatusError';

    this.data = data;
    this.timeout = timeout;
    this.response = response;
  }
}
