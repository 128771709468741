/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {composeThemeFromProps, type ThemeProps, Compose} from '@css-modules-theme/react';
import styles from './ReminderPopup.css';
import {Icon, Link, type LinkProps} from 'components';
import type {ReactStrictNode} from 'utils/types';
import type {IconProps} from 'components/Icon/Icon';

export interface Duration {
  time?: number;
  unit?: string;
}

export type ReminderPopupProps = {
  tid?: string;
  type: 'warning' | 'critical';
  title?: ReactStrictNode;
  message?: ReactStrictNode | (() => ReactStrictNode);
  duration?: Duration | null;
  linkLabel: string;

  /** Link parameters */
  link?: string | Partial<LinkProps>;
  onClose: IconProps['onClick'];
} & ThemeProps;

export default function ReminderPopup(props: ReminderPopupProps): JSX.Element {
  const {tid, type, title, message, duration, link, linkLabel, onClose} = props;
  const theme = composeThemeFromProps(styles, props);

  const counter = duration && (
    <div data-tid="elem-counter" className={type === 'warning' ? theme.counterOrange : theme.counterRed}>
      {duration.time === 0 ? (
        <div className={theme.counterToday}>{intl('Common.Today')}</div>
      ) : (
        <>
          <div className={theme.counterTimeLeft}>{duration.time}</div>
          {duration.unit}
        </>
      )}
    </div>
  );

  let linkElement;

  if (link) {
    const linkProps = {
      'data-tid': 'elem-link',
      ...(!duration && {theme, themePrefix: 'expired-', themeCompose: 'merge' as Compose}),
      ...(typeof link === 'string' ? {to: link} : link),
      'children': linkLabel,
    };

    linkElement = <Link {...linkProps} />;
  }

  return (
    <div className={theme.reminder} data-tid={tid ? `comp-reminder-${tid}` : 'comp-reminder'}>
      {counter}
      <div className={duration ? theme.withCounter : theme.withoutCounter}>
        <div className={theme.title} data-tid="elem-title">
          {title}
        </div>
        <Icon name="close" onClick={onClose} theme={theme} themePrefix="close-" />
        <div className={theme.message} data-tid="elem-subtitle">
          {typeof message === 'function' ? message() : message}
        </div>
        {linkElement}
      </div>
    </div>
  );
}
