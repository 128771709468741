/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import {RedirectError} from 'errors';
import {all, call, select, put} from 'redux-saga/effects';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {gridSettings} from './SupportReportsListConfig';
import {getSupportReports} from './SupportReportsListState';
import {isSupportReportsEnabled} from '../SupportReportsState';
import gridSaga from 'components/Grid/GridSaga';

export function* createSupportReport({ven}) {
  if (ven) {
    return yield call(apiSaga, 'support_report_requests.create', {
      data: {ven: {href: ven}},
      onDone() {
        apiCachedResponses.removeByMethodName('support_report_requests.get_collection');
      },
    });
  }
}

export function* fetchSupportReportsList({ven, force = false} = {}) {
  const query = {representation: 'support_report_request_with_names'};

  if (ven) {
    query.ven = ven;
  }

  return yield all([
    call(apiSaga, 'support_report_requests.get_collection', {
      query,
      cache: !force,
      *onDone({data}) {
        const vensOnly = data.filter(item => item.hasOwnProperty('ven')); //EYE-69625 filter until we can display other report types

        if (force || vensOnly !== (yield select(getSupportReports))) {
          yield put({type: 'SUPPORTREPORTSLIST_GET_LIST', data: vensOnly});
        }
      },
    }),
  ]);
}

export function* fetchSupportReportsListPage(route, refetch = false, options = {}) {
  const supportReportsIsEnabled = yield select(isSupportReportsEnabled);
  const {ven} = options;

  if (!supportReportsIsEnabled) {
    throw new RedirectError({to: 'landing'});
  }

  yield call(gridSaga, {
    route,
    settings: gridSettings,
    *onSaga() {
      const data = yield all([
        call(fetchSupportReportsList, {ven, force: refetch}),
        call(fetchAllUsers, {force: refetch}),
      ]);

      return data.length;
    },
  });
}
