/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {all, call, put, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {getContainerClusterDetail, isLocalContainerCluster} from './ContainerClusterDetailState';
import {RedirectError} from 'errors';
import {fetchHealth} from 'containers/Health/HealthSaga';
import {isUserReadOnlyClusterInsensitive} from 'containers/User/UserState';
import {isContainerClustersEnabled} from '../ContainerClusterState';

export function* fetchContainerClusterDetail({params, force = false} = {}) {
  let detail = null;

  if (params.id) {
    // for add, no profileId
    try {
      yield call(apiSaga, 'container_clusters.get_instance', {
        params: {container_cluster_id: params.id},
        cache: !force,
        *onDone({data}) {
          detail = data;
        },
      });
    } catch {
      // if not able to find the item, go back to list page
      throw new RedirectError({
        to: 'containerClusters.list',
        params: {},
        proceedFetching: true,
        thisFetchIsDone: true,
      });
    }
  }

  if (force || (yield select(getContainerClusterDetail) !== detail)) {
    yield put({type: 'CONTAINER_CLUSTER_GET_DETAIL', data: {detail}});
  }

  return {detail};
}

export function* fetchContainerClusterInstance({params, route = {}}, refetch) {
  const readOnly = yield select(isUserReadOnlyClusterInsensitive);
  const containerClustersIsEnabled = yield select(isContainerClustersEnabled);

  if (!containerClustersIsEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  if (readOnly && route.name === 'app.containerClusters.create') {
    throw new RedirectError({
      to: 'containerClusters.list',
      params,
      proceedFetching: true,
      thisFetchIsDone: true,
    });
  }

  if (route.name === 'app.containerClusters.item.edit') {
    // prefetch occurs on item. /containerclusters/:id
    if (readOnly || !(yield select(isLocalContainerCluster))) {
      throw new RedirectError({
        to: 'containerClusters.item.summary',
        params,
        proceedFetching: true,
        thisFetchIsDone: true,
      });
    }
  }

  yield all([call(fetchContainerClusterDetail, {params, force: refetch}), call(fetchHealth, {force: refetch})]);
}
