/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import cx from 'classnames';
import {PureComponent} from 'react';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import {tidUtils, typesUtils} from 'utils';
import styles from './CounterBadge.css';

export type CounterBadgeColors = 'default' | 'gray' | 'yellow' | 'orange' | 'red';

type CounterBadgePropsIn = {
  count?: number;
  tid?: string;
  color?: CounterBadgeColors;
  disabled?: boolean;

  /** Whether counter should be emerged or incremented instantly (true) or using animation (false - dafault) */
  instant?: boolean;

  /** Do not animate only counter's appearance */
  instantAppearance?: boolean;

  /** Do not animate only counter's incremental change */
  instantIncrement?: boolean;
} & ThemeProps &
  typeof CounterBadge.defaultProps;

export type CounterBadgeProps = typesUtils.ComponentExternalPropsWithoutRef<typeof CounterBadge>;

interface CounterBadgeState {
  count: number;
  animate: boolean;
}

const defaultTid = 'count';

export default class CounterBadge extends PureComponent<CounterBadgePropsIn, CounterBadgeState> {
  static defaultProps = {
    count: 0,
    color: 'default' as CounterBadgeColors,
    disabled: false,

    instant: false,
    instantAppearance: false,
    instantIncrement: false,
  };

  constructor(props: CounterBadgePropsIn) {
    super(props);

    // firstRender is true if it is a first render (cDM on App.js is yet to come) or if first render was < 500ms ago
    const firstRender = !window.renderedAt || Date.now() - window.renderedAt < 500;

    this.state = {
      count: props.count,
      // Animate appearance if it's not first page render and it's should not be instant by props
      animate: !firstRender && !props.instant && !props.instantAppearance,
    };

    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
  }

  static getDerivedStateFromProps(nextProps: Readonly<CounterBadgePropsIn>, prevState: CounterBadgeState) {
    // Animate counter increment if it's should not be instant
    if (!nextProps.instant && !nextProps.instantIncrement && nextProps.count > prevState.count && !prevState.animate) {
      return {count: nextProps.count, animate: true};
    }

    if (nextProps.count !== prevState.count) {
      return {count: nextProps.count};
    }

    return null;
  }

  private handleAnimationEnd() {
    this.setState({animate: false});
  }

  render() {
    const {
      props: {count, color, tid, disabled},
      state: {animate},
    } = this;

    if (!count) {
      return null;
    }

    const theme = composeThemeFromProps(styles, this.props);
    const classNames = cx(theme.counter, theme[color], {
      [theme.animate]: animate,
      [theme.disabled]: disabled,
      [theme.dozens]: count > 9 && count <= 99,
      [theme.hundreds]: count > 99,
    });

    return (
      <div className={classNames} onAnimationEnd={this.handleAnimationEnd} data-tid={tidUtils.getTid(defaultTid, tid)}>
        {intl.num(count)}
      </div>
    );
  }
}
