/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {call} from 'redux-saga/effects';
import {types, generateDefaultReportName} from './ReportsUtils';
import apiSaga from 'api/apiSaga';
import {getId} from 'utils/href';

/**
 * Start async job to generate a report for a given type
 * Returns id of a generated job
 */
export function* startAsyncJobCollection(options) {
  const {cache = false, params, query, typeName, filename, format, headers} = options;
  const type = types()[typeName];

  return yield call(apiSaga, type.method, {
    cache,
    params: {...type.params, ...params},
    query: {format, usage: type.usage, ...type.query, ...query},
    headers: {
      ...headers,
      'Prefer': `respond-async, job_type=${typeName}, description=${
        filename || generateDefaultReportName(type.prefix, format)
      }`,
      'Content-Type': format === 'csv' ? 'text/csv' : 'application/json',
    },
    *onDone(data) {
      return getId(data.headers.get('location'));
    },
  });
}
