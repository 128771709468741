/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {cachedResponses} from 'api/apiCache';
import {Button, ButtonRefresh, Grid, Pill, Link, Notifications, ToolBar, ToolGroup} from 'components';
import {getLabelTypeName, labelFields} from 'components/Pill/Label/LabelUtils';
import {fetchContainerClusterWorkloadProfilesPage} from './ContainerClusterContainerWorkloadProfileListSaga';
import {getContainerClusterContainerWorkloadProfilesPage} from './ContainerClusterContainerWorkloadProfileListState';
import {hrefUtils} from 'utils';
import DefaultContainerWorkloadProfileEditModal from '../Config/DefaultContainerWorkloadProfileEditModal';
import RemoveButton from 'containers/Remove/RemoveButton';
import {ComboSelect} from 'containers/Selectors';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import {enforcementModeView, visibilityLevelView} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {formatAllowLabels} from '../../ContainerClusterDetailUtils';
import styles from './ContainerClusterContainerWorkloadProfileList.css';
import stylesUtils from 'utils.css';

const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};
const removeRowHighLight = {className: stylesGridUtils.rowToRemove};

@connect(getContainerClusterContainerWorkloadProfilesPage)
export default class ContainerClusterContainerWorkloadProfileList extends PureComponent {
  static prefetch = fetchContainerClusterWorkloadProfilesPage;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      showEditDefault: false,
      extraPropsKeyMap: new Map(),
      selectedKeySet: new Set(),
      selectedKeySetToRemove: new Set(),
    };
    this.handleEditDefaultClick = this.handleEditDefaultClick.bind(this);
    this.handleEditDefaultSubmit = this.handleEditDefaultSubmit.bind(this);
    this.handleEditDefaultClose = this.handleEditDefaultClose.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleRemoveDone = this.handleRemoveDone.bind(this);
    this.handleRemoveEnter = this.handleRemoveEnter.bind(this);
    this.handleRemoveLeave = this.handleRemoveLeave.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.getNotification = this.getNotification.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const defaultChanged = !prevState.defaultProfile || nextProps.defaultProfile !== prevState.defaultProfile;
    const rowChanged = nextProps.grid.rows !== prevState.rows;

    if (defaultChanged || rowChanged) {
      const nextState = defaultChanged
        ? {defaultProfile: nextProps.defaultProfile, defaultLabels: nextProps.defaultLabels}
        : {};

      if (rowChanged) {
        nextState.rows = nextProps.grid.rows;

        if (prevState.selectedKeySet.size) {
          nextState.selectedKeySet = new Set();
          nextState.selectedKeySetToRemove = new Set();

          for (const row of nextProps.grid.rows) {
            if (row.selectable && prevState.selectedKeySet.has(row.key)) {
              nextState.selectedKeySet.add(row.key);

              if (row.removable) {
                nextState.selectedKeySetToRemove.add(row.key);
              }
            }
          }
        }
      }

      return nextState;
    }

    return null;
  }

  getNotification() {
    const {
      defaultProfile: {enforcement_mode: enforcementMode, visibility_level: visibilityLevel, managed},
    } = this.state;

    return [
      {
        type: 'instruction',
        message: managed ? (
          <div className={stylesUtils.gapSmall}>
            <div>
              {intl('ContainerClusters.ContainerWorkloadProfilesNote')}
              {enforcementMode
                ? ` - ${intl('ContainerClusters.EnforcementVisibilityNote', {
                    info: enforcementModeView()[enforcementMode].name,
                    context: intl('Common.Enforcement'),
                  })}`
                : null}
              {visibilityLevel ? (
                // When in enforcementMode === 'idle' visibility_level is Limited
                // The backend doesn't have any instance of visibility_level = 'limited' thus need to add this logic
                // Backend API only allows: flow_summary, flow_drops, flow_off, enhanced_data_collection for visibility_level field
                <>
                  , {intl('Common.Visibility')}: "
                  {visibilityLevelView()[enforcementMode === 'idle' ? enforcementMode : visibilityLevel].name}"
                </>
              ) : null}
            </div>

            <div className={`${stylesUtils.gapSmall} ${stylesUtils.gapHorizontalWrap} ${stylesUtils.centerFlexAlign}`}>
              {labelFields.map(key => {
                const item = this.props[key];

                return (
                  <div key={key} className={stylesUtils.centeredFlex}>
                    {getLabelTypeName(key)}:{' '}
                    {item?.assignment ? (
                      <Pill.Label type={key} href={item.assignment.href}>
                        {item.assignment.value}
                      </Pill.Label>
                    ) : (
                      formatAllowLabels(item, key, managed)
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          intl('ContainerClusters.ContainerWorkloadProfilesUnmanaged', {isOpenshift: this.props.isOpenshift})
        ),
      },
    ];
  }

  handleEditDefaultClick() {
    this.setState({showEditDefault: true});
  }

  handleEditDefaultSubmit({enforcementMode, managed, visibilityLevel, role, app, env, loc}) {
    this.setState({
      showEditDefault: false,
      defaultProfile: {
        managed,
        enforcement_mode: enforcementMode,
        visibility_level: visibilityLevel,
      },
      defaultLabels: {
        role,
        app,
        env,
        loc,
      },
    });
    this.handleRefresh();
  }

  handleEditDefaultClose() {
    this.setState({showEditDefault: false});
  }

  handleRefresh() {
    cachedResponses.removeByMethodName('container_workload_profiles.get_instance');

    return this.context.fetcher.fork(fetchContainerClusterWorkloadProfilesPage.refetch);
  }

  handleClick(evt, row) {
    const {
      props: {id},
      context: {navigate},
    } = this;

    navigate({evt, to: 'containerClusters.item.profiles.item', params: {id, profileId: hrefUtils.getId(row.key)}});
  }

  handleSelect({affectedRows, selecting}) {
    this.setState(state => {
      const selectedKeySet = new Set(state.selectedKeySet);
      const selectedKeySetToRemove = new Set(state.selectedKeySetToRemove);

      for (const row of affectedRows) {
        selectedKeySet[selecting ? 'add' : 'delete'](row.key);

        if (selecting) {
          if (row.removable) {
            selectedKeySetToRemove.add(row.key);
          }
        } else {
          selectedKeySetToRemove.delete(row.key);
        }
      }

      return {selectedKeySet, selectedKeySetToRemove};
    });
  }

  handleRemoveEnter() {
    if (this.state.selectedKeySetToRemove.size) {
      this.setState(state => ({
        extraPropsKeyMap: new Map(Array.from(state.selectedKeySetToRemove, key => [key, removeRowHighLight])),
      }));
    }
  }

  handleRemoveLeave() {
    if (this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  handleRemoveDone() {
    this.handleRefresh();
  }

  handleFilterChange(selection) {
    const {
      context: {navigate},
      props: {grid},
    } = this;

    const filter = selection.reduce((result, {categoryKey, href, value}) => {
      if (labelFields.includes(categoryKey)) {
        result[categoryKey] = [{value, href}];
      } else {
        result[categoryKey] = [value];
      }

      return result;
    }, {});

    navigate({params: {[grid.settings.id]: {...grid.params, filter, page: null}}});
  }

  render() {
    const {
      grid,
      id,
      count,
      selector,
      userIsReadOnlyClusterInsensitive,
      isLocalPce,
      allowCreateTypes,
      roleSource,
      appSource,
      envSource,
      locSource,
    } = this.props;
    const {
      showEditDefault,
      defaultProfile: {href, managed, enforcement_mode: enforcementMode, visibility_level: visibilityLevel},
      defaultLabels,
      selectedKeySet,
      selectedKeySetToRemove,
      extraPropsKeyMap,
    } = this.state;

    return (
      <>
        <Notifications sidebar>{this.getNotification()}</Notifications>
        <ToolBar>
          <ToolGroup>
            <Button.Link
              icon="add"
              text={intl('Common.Add')}
              textIsHideable
              link={{to: 'containerClusters.item.profiles.create', params: {id}}}
              tid="add"
              theme={buttonsTheme}
              disabled={userIsReadOnlyClusterInsensitive || !isLocalPce}
            />
            <RemoveButton
              theme={buttonsTheme}
              rowsMap={grid.rowsMap}
              counter={selectedKeySetToRemove.size}
              target="containerWorkloadProfile"
              selectedKeySet={selectedKeySet}
              selectedKeySetToRemove={selectedKeySetToRemove}
              onMouseEnter={this.handleRemoveEnter}
              onMouseLeave={this.handleRemoveLeave}
              onRemoveDone={this.handleRemoveDone}
              disabled={userIsReadOnlyClusterInsensitive || !isLocalPce}
            />
            <Button
              onClick={this.handleEditDefaultClick}
              tid="edit"
              disabled={userIsReadOnlyClusterInsensitive || !isLocalPce}
              color="standard"
            >
              {intl('ContainerClusters.EditDefaultSettings')}
            </Button>
          </ToolGroup>
          <ToolGroup>
            <ButtonRefresh color="standard" onRefresh={this.handleRefresh} />
          </ToolGroup>
        </ToolBar>
        <ToolBar>
          <ToolGroup expand tid="page-filter">
            <ComboSelect
              scrollable
              objects={selector.objects}
              placeholder={intl('Common.FilterView')}
              initialItems={selector.filterItems}
              categories={selector.categories}
              activeCategoryKey="name"
              partials={selector.partials}
              facets={selector.facets}
              statics={selector.statics}
              customPickers={selector.customPickers}
              onSelectionChange={this.handleFilterChange}
            />
          </ToolGroup>
        </ToolBar>
        <Grid
          grid={grid}
          count={count}
          dontHighlightSelected={extraPropsKeyMap.size > 0}
          extraPropsKeyMap={extraPropsKeyMap}
          selectedKeySet={selectedKeySet}
          onClick={this.handleClick}
          onSelect={this.handleSelect}
          emptyMessage={
            selector.filterItems.length
              ? intl('Common.NoMatchDataContainerClusters')
              : intl('Common.NoDataContainerWorkloadProfiles')
          }
          addItemLink={
            !userIsReadOnlyClusterInsensitive &&
            selector.filterItems.length === 0 && (
              <Link to="containerClusters.item.profiles.create" params={{id}}>
                {intl('ContainerClusters.AddNewContainerWorkloadProfile')}
              </Link>
            )
          }
        />
        {showEditDefault && (
          <DefaultContainerWorkloadProfileEditModal
            clusterId={id}
            enforcementMode={enforcementMode}
            visibilityLevel={visibilityLevel}
            managed={managed}
            href={href}
            initLabels={defaultLabels}
            allowCreateTypes={allowCreateTypes}
            roleSource={roleSource}
            appSource={appSource}
            envSource={envSource}
            locSource={locSource}
            onSubmit={this.handleEditDefaultSubmit}
            onClose={this.handleEditDefaultClose}
          />
        )}
      </>
    );
  }
}
