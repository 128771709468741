/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';

export default {
  onboardingStepsState(state = {}, action) {
    switch (action.type) {
      case 'SET_ONBOARDING_DATA':
        return {...state, ...action.data};
      default:
        return state;
    }
  },
};

const getOnboarding = state => state.onboardingStepsState;

export const getOnboardingState = createSelector(getOnboarding, onboardingStepsState => onboardingStepsState);

export const hideOnboarding = createSelector(
  getOnboardingState,
  ({hideOnboarding, hasOnboarded} = {}) => !__ANTMAN__ || hideOnboarding || hasOnboarded,
);

export const getOnboardingCurrentStep = createSelector(getOnboardingState, props => {
  return props?.currentStep;
});

export const getComputedCurrentStep = createSelector(
  [hideOnboarding, getOnboardingCurrentStep],
  (hideOnboarding, currentStep) => (hideOnboarding ? null : currentStep),
);

export const canRestartOnboarding = createSelector(
  [getOnboardingCurrentStep, hideOnboarding],
  (currentStep, hideOnboarding) => currentStep !== 'ONBOARDING_START' || hideOnboarding,
);
