/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {Component} from 'react';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {Button, ModalMachineAuto} from 'components';
import {createRowsMapForOneItem} from 'components/Grid/GridUtils';
import {removeOneItem} from './RemoveSaga';
import {removeSwitch} from '../Switch/List/SwitchListSaga';
import styles from 'containers/App/App.css';

const targets = {
  containerCluster: {
    idKey: 'container_cluster_id',
    action: removeOneItem,
    actionId: 'container_cluster.delete',
    cleanCaches: ['container_clusters.get_collection', 'container_clusters.get_instance'],
    getTitle: ({count}) => intl('ContainerClusters.Delete', {count}),
    getConfirmMessage: ({count}) => intl('ContainerClusters.DeleteConfirm', {count}),
    getSuccessMessage: ({count}) => intl('ContainerClusters.DeletedSuccessfully', {count}),
    getFailMessage: ({count}) => intl('ContainerClusters.CannotDelete', {count}),
  },
  containerWorkloadProfile: {
    idKey: 'container_workload_profile_id',
    action: removeOneItem,
    actionId: 'container_workload_profile.delete',
    cleanCaches: ['container_workload_profiles.get_collection', 'container_workload_profiles.get_instance'],
    getTitle: ({count}) => intl('ContainerClusters.DeleteContainerWorkloadProfile', {count}),
    getConfirmMessage: ({count}) => intl('ContainerClusters.DeleteContainerWorkloadProfileConfirm', {count}),
    getSuccessMessage: ({count}) => intl('ContainerClusters.DeletedContainerWorkloadProfileSuccessfully', {count}),
    getFailMessage: ({count}) => intl('ContainerClusters.CannotDeleteContainerWorkloadProfile', {count}),
    getParams: hrefs => {
      if (Array.isArray(hrefs) && hrefs.length > 0) {
        let clusterId = hrefs[0].substr(hrefs[0].indexOf('/container_clusters/') + '/container_clusters/'.length);

        clusterId = clusterId.slice(0, clusterId.indexOf('/'));

        return {
          container_cluster_id: clusterId,
        };
      }
    },
  },
  switch: {
    action: removeSwitch,
    getTitle: ({count}) => intl('Switches.Delete', {count}),
    getConfirmMessage: ({count}) => intl('Switches.DeleteConfirm', {count}),
    getSuccessMessage: ({count}) => intl('Switches.DeletedSuccessfully', {count}),
    getFailMessage: ({count}) => intl('Switches.CannotDelete', {count}),
  },
};

const getEmptyState = () => ({
  remove: null, // {running: <boolean>, removed: [], errors: []}
  rowsMap: null,
  selectedKeySet: null,
  selectedKeySetToRemove: null,
  unableToRemove: null,
  name: null,
  href: null,
});

export default class Remove extends Component {
  static propTypes = {
    target: PropTypes.string.isRequired,
    theme: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    rowsMap: PropTypes.object, // real rows from grid
    nameKey: PropTypes.string,
    name: PropTypes.string, // name for the detail page
    href: PropTypes.string, // href for the detail page
    selectedKeySet: PropTypes.object,
    selectedKeySetToRemove: PropTypes.object,
    counter: PropTypes.number,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onRemoveDone: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    nameKey: 'name',
    onRemoveDone: _.noop,
  };

  constructor(props) {
    super(props);

    this.state = getEmptyState();
    this.handleRemoveClose = this.handleRemoveClose.bind(this);
    this.handleRemoveCloseAlert = this.handleRemoveCloseAlert.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {rowsMap, selectedKeySet, selectedKeySetToRemove, name, href} = nextProps;

    if (rowsMap) {
      // real rows from the grid
      if (
        rowsMap !== prevState.rowsMap ||
        selectedKeySet !== prevState.selectedKeySet ||
        selectedKeySetToRemove !== prevState.selectedKeySetToRemove
      ) {
        const nextState = {};

        if (rowsMap !== prevState.rowsMap) {
          nextState.rowsMap = rowsMap;
        }

        if (selectedKeySet !== prevState.selectedKeySet) {
          nextState.selectedKeySet = selectedKeySet;
        }

        if (selectedKeySetToRemove !== prevState.selectedKeySetToRemove) {
          nextState.selectedKeySetToRemove = selectedKeySetToRemove;
        }

        if (
          selectedKeySet !== prevState.selectedKeySet ||
          selectedKeySetToRemove !== prevState.selectedKeySetToRemove
        ) {
          nextState.unableToRemove = _.difference([...selectedKeySet], [...selectedKeySetToRemove]);
        }

        return nextState;
      }
    } else if (name && href) {
      // for the detail page, try to fake a row
      if (name !== prevState.name || href !== prevState.href) {
        const hrefSet = new Set([href]);

        return {
          rowsMap: createRowsMapForOneItem({name, href}),
          selectedKeySet: hrefSet,
          selectedKeySetToRemove: hrefSet,
          unableToRemove: [],
          name,
          href,
        };
      }
    }

    return null;
  }

  handleRemoveClose() {
    this.setState({remove: false});
  }

  handleRemoveCloseAlert() {
    this.props.onRemoveDone();
    this.handleRemoveClose();
  }

  handleRemoveConfirm() {
    this.setState({remove: true});
  }

  renderConfirmation() {
    const {
      props: {target, href},
      state: {selectedKeySetToRemove, rowsMap},
    } = this;

    const {action, getParams, actionId, idKey} = targets[target];
    const hrefs = selectedKeySetToRemove.size > 0 ? [...selectedKeySetToRemove] : [href];
    const params = typeof getParams === 'function' ? getParams(hrefs) : {};

    return (
      <ModalMachineAuto
        settled
        onClose={this.handleRemoveClose}
        saga={action}
        onDone={this.handleRemoveCloseAlert}
        hrefs={hrefs}
        rowsMap={rowsMap}
        listItem={['name']}
        initialContext={{args: {params, actionId, idKey}}}
      >
        {{
          title: ({selectedHrefs}) => targets[target].getTitle({count: selectedHrefs?.length}),
          confirmMessage: ({selectedHrefs}) => targets[target].getConfirmMessage({count: selectedHrefs?.length}),
          error: {
            title: ({selectedHrefs}) => targets[target].getTitle({count: selectedHrefs?.length}),
            itemSuccessMessage: ({successHrefs}) => targets[target].getSuccessMessage({count: successHrefs?.length}),
          },
        }}
      </ModalMachineAuto>
    );
  }

  render() {
    const buttonsTheme = composeThemeFromProps(styles, this.props);
    const {
      props: {disabled, onMouseEnter, onMouseLeave, counter},
      state: {remove, selectedKeySetToRemove},
    } = this;

    return (
      <>
        <Button
          color="standard"
          icon="remove"
          text={intl('Common.Remove')}
          textIsHideable
          tid="remove"
          theme={buttonsTheme}
          counter={counter}
          counterColor="red"
          disabled={(selectedKeySetToRemove && selectedKeySetToRemove.size === 0) || disabled}
          onClick={this.handleRemoveConfirm}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
        {remove && this.renderConfirmation()}
      </>
    );
  }
}
