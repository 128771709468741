/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import ApplicationError from './Application';
import type {ApplicationErrorOptions} from './Application';
import type {RequestInfo} from './Request';

interface RequestAbortErrorOptions extends ApplicationErrorOptions {
  request?: RequestInfo;
}

/**
 * Request fetch error
 */
export default class RequestAbortError extends ApplicationError {
  constructor(data: string | RequestAbortErrorOptions = {}) {
    if (typeof data === 'string') {
      data = {code: data};
    }

    const {...details} = data;

    _.defaults(details, {
      code: 'REQUEST_ABORT',
    });

    super(details);
    this.name = 'RequestAbortError';
  }
}
