/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {all, call, select, put} from 'redux-saga/effects';
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import {deletePairingKeysForPairingProfile} from 'containers/PairingProfile/Item/PairingProfileItemSaga';
import {getGroupsList, getGroupsCount, getGroupsProfilesList} from './GroupState';
import {RedirectError} from 'errors';
import {getSelectorSettings} from './Dashboard/GroupDashboardConfig';

export function* fetchGroups({filter, force = false, admin = false} = {}) {
  const selectorSettingsObject = yield select(getSelectorSettings);
  const query = {max_results: 500, ...(admin && {admin: true})};

  if (filter) {
    for (const [name, [value]] of Object.entries(filter)) {
      if (value) {
        if (selectorSettingsObject.staticValues.hasOwnProperty(name)) {
          query.update_type = selectorSettingsObject.staticValues[name][value];
        } else {
          query[name] = value;
        }
      }
    }
  }

  return yield call(apiSaga, 'groups.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getGroupsList)) || count !== (yield select(getGroupsCount))) {
        yield put({type: 'GROUPS_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* fetchVENCounts({groups, force = false, dispatch = true} = {}) {
  groups ??= yield select(getGroupsList);

  const counts = {};

  yield all(
    groups.map(function* (group) {
      try {
        const {count} = yield call(apiSaga, 'vens.get_collection', {
          cache: !force,
          query: {xxxlabels: [[group.roleLabel.href]], max_results: 0},
        });

        counts[group.id] = count.matched;
      } catch {
        if (!group) {
          throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
        }

        counts[group.id] = 0;
      }
    }),
  );

  if (dispatch) {
    yield put({type: 'GROUP_VENS_COUNTS', data: {counts}});
  }

  return counts;
}

export function* fetchPairingProfileByLabel({labelHref, force = false, dispatch = true} = {}) {
  const {data} = yield call(apiSaga, 'pairing_profiles.get_collection', {
    query: {xxxlabels: [[labelHref]], representation: 'pairing_profile_labels'},
    cache: !force,
  });

  // If more than one profile exist for the given label, take the latest one
  const profile = data?.length > 1 ? _.sortBy(data, 'created_at').lastItem : data?.[0];

  if (
    dispatch &&
    profile &&
    (force || profile !== (yield select(getGroupsProfilesList)).find(item => item.href === profile.href))
  ) {
    yield put({type: 'GROUP_PROFILES_GET_ITEM', profile});
  }

  return profile ?? {};
}

export function* revokeInstallScript(groupId) {
  yield call(deletePairingKeysForPairingProfile, groupId);
}

export function* updateGroup(id, payload) {
  yield call(apiSaga, 'group.update', {
    params: {group_id: id},
    data: payload,
    *onDone() {
      // Invalidate instance
      apiCachedResponses.removeByMethodName('group.get_instance');
      // Invalidate list
      apiCachedResponses.removeByMethodName('group.get_collection');
    },
  });
}

export function* deleteGroup(id) {
  yield call(apiSaga, 'group.delete', {
    params: {group_id: id},
    *onDone() {
      // Invalidate instance
      apiCachedResponses.removeByMethodName('group.get_instance');
      // Invalidate list
      apiCachedResponses.removeByMethodName('group.get_collection');
    },
  });
}
