/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {Button, HorizontalTrain, ToolBar} from 'components';
import PropTypes from 'prop-types';
import styles from './ProgressActionBar.css';

ProgressActionBar.propTypes = {
  previousProps: PropTypes.object,
  cancelProps: PropTypes.object,
  nextProps: PropTypes.object,
  saveProps: PropTypes.object,
};

export default function ProgressActionBar({previousProps, cancelProps, nextProps, trainProps, saveProps}) {
  return (
    <ToolBar theme={styles} justify="space-between">
      <div className={styles.actionButtons}>
        {previousProps && <Button theme={styles} {...previousProps} tid="previous" themePrefix="button-" />}
      </div>

      <HorizontalTrain theme={styles} {...trainProps} />

      <div className={cx(styles.actionButtons, styles.nextButtons)}>
        <Button theme={styles} themePrefix="button-" tid="cancel" {...cancelProps} />
        {saveProps && <Button theme={styles} {...saveProps} themePrefix="button-" />}
        {nextProps && <Button theme={styles} {...nextProps} themePrefix="button-" />}
      </div>
    </ToolBar>
  );
}
