/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import produce, {original} from 'immer';
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {getId} from 'utils/href';
import itemReducers from './GroupViewState';

export default {
  group: combineReducers({
    ...itemReducers,

    profiles: produce((draft, action) => {
      if (action.type === 'GROUP_PROFILES_GET_LIST') {
        return action.profiles.every((profile, index) => profile === draft[index]) ? draft : action.profiles;
      }

      if (action.type === 'GROUP_PROFILES_GET_ITEM') {
        const existingIndex = draft.findIndex(profile => profile.href === action.profile.href);

        if (existingIndex < 0) {
          draft.push(action.profile);
        } else if (!_.isEqual(action.profile, original(draft[existingIndex]))) {
          draft[existingIndex] = action.profile;
        }
      }
    }, []),

    venCounts(state = {}, action) {
      switch (action.type) {
        case 'GROUP_VENS_COUNTS':
          return action.data.counts;
        default:
          return state;
      }
    },
    list(state = [], action) {
      switch (action.type) {
        case 'GROUPS_GET_LIST':
          return action.data.list;
        default:
          return state;
      }
    },

    count(state = {}, action) {
      switch (action.type) {
        case 'GROUPS_GET_LIST':
          return action.data.count;
        default:
          return state;
      }
    },
  }),
};

export const getGroups = state => state.group.list;
export const getGroupsProfilesList = state => state.group.profiles;
export const getGroupsCount = state => state.group.count;
export const getWorkloadCounts = state => state.group.workloadCounts;
export const getVenCounts = state => state.group.venCounts;

export const getGroupsList = createSelector([getGroups], groups =>
  groups.reduce((result, group) => {
    result.push({
      name: group.name,
      id: getId(group.href),
      href: group.href,
      isAdmin: group.admin,
      //Administered Groups Example:
      /* [
        {
          'href': '/orgs/1/groups/1',
          'name': 'RND',
        },
        {
          'href': '/orgs/1/groups/2',
          'name': 'Marketing',
        },
      ]; */
      administeredGroups: group.administered_groups,
      adminsteredGroupsCount: group.administered_groups.length,
      roleLabel: {...group.label, id: getId(group.label.href)},
      pairingProfile: {...group.pairing_profile, id: getId(group.pairing_profile.href)},
      workloadCount: group.num_workloads,
      created_by: group.created_by,
      updated_by: group.updated_by,
      ruleset: {...group.rule_set, id: getId(group.rule_set?.href)},
      updateType: group.rule_set?.update_type,
    });

    return result;
  }, []),
);

// Groups populated with ven counts for each groups
export const getGroupsWithVens = createSelector([getGroupsList, getVenCounts], (groups, counts) =>
  groups.map(group => ({...group, vens: counts[group.id]})),
);
