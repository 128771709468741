/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';

/** Nominal Types */
type DateField = string & {_brand: 'datefield'};

export const getBadgeLabelByUpdateType = (updateType: string, version: string): string => {
  const draft = !version || version === 'draft';

  switch (updateType) {
    case 'create':
      return draft ? intl('Rulesets.Rules.AdditionPending') : intl('Rulesets.Rules.Added');
    case 'update':
      return draft ? intl('Rulesets.Rules.ModifiedPending') : intl('Rulesets.Rules.Modified');
    case 'delete':
      return draft ? intl('Rulesets.Rules.DeletionPending') : intl('Rulesets.Rules.Deleted');
  }

  return '';
};

export const getBadgeTypeByUpdateType = (updateType: string): string | null => {
  switch (updateType) {
    case 'create':
      return 'created';
    case 'update':
      return 'updated';
    case 'delete':
      return 'deleted';
  }

  return null;
};

export const getUpdateTypeByBadgeType = (badgeType: string): string | null => {
  switch (badgeType) {
    case 'created':
      return 'create';
    case 'updated':
      return 'update';
    case 'deleted':
      return 'delete';
  }

  return null;
};

const isDateField = (name: string): name is DateField => {
  if (typeof name !== 'string') {
    return false;
  }

  return name.includes('date') || name.endsWith('_at') || name.endsWith('_on');
};

/** Given a minimum and maximum (date, version string), produce a string descriptor of the range.
 *
 * @param min minimum date or minimum date list
 * @param max maximum date or maximum date list
 * @param fieldName field name of the category being looked at
 * @returns
 */
export const getRangeExpression = (min: Date | Date[], max: Date | Date[], fieldName: string): string => {
  let minVal = _.toString(Array.isArray(min) ? min[0] : min);
  let maxVal = _.toString(Array.isArray(max) ? max[0] : max);

  if (isDateField(fieldName)) {
    minVal = minVal ? intl.date(new Date(minVal), 'l_HH_mm') : intl('DateTimeInput.Anytime');
    maxVal = maxVal ? intl.date(new Date(maxVal), 'l_HH_mm') : intl.date(new Date(), 'l_HH_mm');
  }

  if (maxVal === minVal) {
    return maxVal; // point, rather than range. Or both empty.
  }

  if (maxVal === '') {
    return `>= ${minVal}`;
  }

  if (minVal === '') {
    return `<= ${maxVal}`;
  }

  return `${minVal} - ${maxVal}`;
};
