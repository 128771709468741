/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import {
  getContainerClusters,
  getContainerClustersCount,
  getGridSettings,
  getCalculatedRouteFilters,
  getCalculatedStaticValues,
} from './ContainerClusterListState';
import gridSaga from 'components/Grid/GridSaga';
import {fetchHealth} from 'containers/Health/HealthSaga';
import {isContainerClustersEnabled} from '../ContainerClusterState';
import {RedirectError} from 'errors';
import {isEdge} from 'containers/App/AppState';

function* fetchContainerClusters({filter, force = false} = {}) {
  const staticValues = yield select(getCalculatedStaticValues);
  const query = {max_results: 500};

  if (filter) {
    for (const [name, [value]] of Object.entries(filter)) {
      if (staticValues.hasOwnProperty(name)) {
        // If there is more than one parameter to make up the query
        if (Array.isArray(staticValues[name][value])) {
          staticValues[name][value].forEach(filter => {
            query[filter.name] = filter.value;
          });
        } else {
          query[name] = staticValues[name][value];
        }
      } else {
        query[name] = value;
      }
    }
  }

  return yield call(apiSaga, 'container_clusters.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      if (
        force ||
        list !== (yield select(getContainerClusters)) ||
        count !== (yield select(getContainerClustersCount))
      ) {
        yield put({type: 'CONTAINER_CLUSTER_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* fetchContainerClusterList(route, refetch = false, options = {}) {
  const {params} = route;
  const {getFilters = getCalculatedRouteFilters} = options;
  const containerClustersIsEnabled = yield select(isContainerClustersEnabled);
  const edgeEnabled = yield select(isEdge);

  if (!containerClustersIsEnabled || edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getFilters,
    *onSaga({filterParams}) {
      const [{list}] = yield all([
        call(fetchContainerClusters, {
          params,
          filter: filterParams.isEmpty ? undefined : filterParams.valid,
          force: refetch,
        }),
        call(fetchHealth, {force: refetch}),
      ]);

      return list.length;
    },
  });
}
