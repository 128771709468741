/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import styles from './ScopePicker.css';
import _ from 'lodash';
import intl from 'intl';
import {PureComponent} from 'react';
import {SingleGroupSingleItemSelect} from 'containers/Selectors';
import * as PropTypes from 'prop-types';
import {allLabelDataMap, labelsSelectorValues} from 'containers/RBAC/RBACUtils';
import {composeThemeFromProps} from '@css-modules-theme/react';

export default class ScopePicker extends PureComponent {
  static propTypes = {
    scopeHrefs: PropTypes.object,
    labelHrefMap: PropTypes.object,
    onScopeChange: PropTypes.func,
    // create - determines if this is a new RBAC creation
    create: PropTypes.bool,

    // labelCreation - determine if label is being created
    // true: lable being created, false: not being created
    labelCreation: PropTypes.bool,
  };

  static defaultProps = {
    create: false,
    labelCreation: false,
  };

  constructor(props) {
    super(props);

    this.allLabelDataMap = allLabelDataMap();
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.getSelectorValue = this.getSelectorValue.bind(this);
    this.getScopeHrefKeyMap = this.getScopeHrefKeyMap.bind(this);
  }

  getScopeHrefKeyMap() {
    const {labelHrefMap, scopeHrefs} = this.props;

    return [...scopeHrefs].reduce(
      (res, href) => {
        if (labelHrefMap[href] && labelHrefMap[href].key) {
          res[labelHrefMap[href].key] = href;
        }

        return res;
      },
      {app: 'all', env: 'all', loc: 'all'},
    );
  }

  getSelectorValue(key) {
    const {labelHrefMap, blankScopeLabels, labelCreation} = this.props;
    const scopeHrefKeyMap = this.getScopeHrefKeyMap();
    const href = scopeHrefKeyMap[key];

    if (href === 'all') {
      // blankScopeLabels.has(key): indicates particular key is empty
      // labelCreation: true - label is being created, false: label created.
      if (blankScopeLabels.has(key) || labelCreation) {
        /*
         *  1) Return empty set when creating new rbac rule from wizard
         *  2) When User removed an item from the Selector input then show empty by return [] base on specific key
         *  3) During the process of creating a label, show empty
         */
        return [];
      }

      const allLabelData = this.allLabelDataMap[key];

      const allData = [
        {
          categoryName: allLabelData.value,
          categoryKey: allLabelData.categoryKey,
          hidden: false,
          scope: undefined,
          value: allLabelData.value,
        },
      ];

      return allData;
    }

    return [
      {
        categoryKey: intl('Common.Labels'),
        categoryName: intl('Common.Labels'),
        href,
        key,
        scope: undefined,
        value: labelHrefMap[href].value,
      },
    ];
  }

  /*
   * Handle the logic to store the selected or deselected scopes: all_[application, location, environment]
   * selected with <Selector>.
   */
  handleLabelChange(key, value) {
    const {blankScopeLabels, onScopeChange, create} = this.props;
    const newBlankScopeLabels = new Set(blankScopeLabels);
    const scopeHrefKeyMap = this.getScopeHrefKeyMap();
    const selectorValue = value[0];

    // save new selected scopes
    const newScopeHrefs = Object.keys(scopeHrefKeyMap).reduce((res, labelKey) => {
      if (labelKey === key) {
        // when value change key match the previous scope role
        if (selectorValue) {
          if (selectorValue.hasOwnProperty('key') && labelsSelectorValues.includes(selectorValue.key)) {
            res.push(selectorValue.href);
          } else if (
            labelsSelectorValues.includes(key) &&
            selectorValue.categoryKey === this.allLabelDataMap[key].categoryKey &&
            !create
          ) {
            // add either: All Application, All Location, All Environment's categoryKey as the unique key to indicate
            // the value was selected for parsing value for getting initialValues, etc...
            // Note:
            // 1) categoryKey was chosen since it probably won't change much and it is needed for Selector
            // 2) when creating a new RBAC don't add any items to newScopeHrefs. the backend accepts
            // res = [] to indicate "All|ALL|ALL" thus need to check for !create
            //
            res.push(selectorValue.categoryKey);
          }
        } else {
          // when value is empty but the label === key then save the all_[application, environment, location] category
          res.push(this.allLabelDataMap[labelKey].categoryKey);
        }
      } else if (scopeHrefKeyMap[labelKey] !== 'all') {
        res.push(scopeHrefKeyMap[labelKey]);
      } else if (!create) {
        // don't set this when creating new rbac
        // push any 'all' labelKey e.g. {app: 'all', env: 'all', loc: 'loc'} to categoryKey: 'all_location', 'all_environment'
        // Use to show All Location, All Application, All Environment options to show properly
        res.push(this.allLabelDataMap[labelKey].categoryKey);
      }

      return res;
    }, []);

    // keep track of empty scope selection and its type
    if (value.length === 0) {
      newBlankScopeLabels.add(key);
    } else {
      newBlankScopeLabels.delete(key);
    }

    onScopeChange(new Set(newScopeHrefs), newBlankScopeLabels, selectorValue);
  }

  render() {
    const theme = composeThemeFromProps(styles, this.props);
    const scopePickerClass = theme.scopePicker;

    // TODO: Need to convert them to use AttributeList to make it responsive and consistent.
    return (
      <div className={scopePickerClass}>
        <div className={theme.labelSelector}>
          <div>{intl('Common.Application')}</div>
          <div data-tid="comp-labelselect comp-labelselect-applabel">
            <SingleGroupSingleItemSelect
              initialItems={this.getSelectorValue('app')}
              allowCreateTypes={['labels']}
              placeholder={intl('Labels.Select')}
              objects={[{type: 'labels', key: 'app'}]}
              onSelectionChange={_.partial(this.handleLabelChange, 'app')}
              categories={[{categoryKey: 'labels', value: intl('Common.Labels')}, {...this.allLabelDataMap.app}]}
            />
          </div>
        </div>
        <div className={theme.labelSelector}>
          <div>{intl('Common.Environment')}</div>
          <div data-tid="comp-labelselect comp-labelselect-envlabel">
            <SingleGroupSingleItemSelect
              allowCreateTypes={['labels']}
              initialItems={this.getSelectorValue('env')}
              placeholder={intl('Labels.Select')}
              objects={[{type: 'labels', key: 'env'}]}
              onSelectionChange={_.partial(this.handleLabelChange, 'env')}
              categories={[{categoryKey: 'labels', value: intl('Common.Labels')}, {...this.allLabelDataMap.env}]}
            />
          </div>
        </div>
        <div className={theme.labelSelector}>
          <div>{intl('Common.Location')}</div>
          <div data-tid="comp-labelselect comp-labelselect-loclabel">
            <SingleGroupSingleItemSelect
              allowCreateTypes={['labels']}
              initialItems={this.getSelectorValue('loc')}
              placeholder={intl('Labels.Select')}
              objects={[{type: 'labels', key: 'loc'}]}
              onSelectionChange={_.partial(this.handleLabelChange, 'loc')}
              categories={[{categoryKey: 'labels', value: intl('Common.Labels')}, {...this.allLabelDataMap.loc}]}
            />
          </div>
        </div>
      </div>
    );
  }
}
