/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getPairingProfiles} from '../../List/PairingProfileListState';
import {getPairingProfileInstance, getPairingKey} from '../PairingProfileItemState';
import {getOSFamilies} from 'containers/Workload/WorkloadState';
import {isEdge, isCrowdstrike} from 'containers/App/AppState';

export const getPairingProfilePairAccessList = createSelector(getPairingProfiles, pairingProfileList =>
  pairingProfileList.filter(
    pairingProfile =>
      // Don't allow pairing to profiles that have been disabled
      Array.isArray(pairingProfile.caps) &&
      pairingProfile.caps.includes('generate_pairing_key') &&
      pairingProfile.enabled,
  ),
);

export const getPairingProfilePairs = createSelector(
  [getPairingProfilePairAccessList, getPairingProfileInstance, getPairingKey, getOSFamilies, isEdge, isCrowdstrike],
  (pairingProfileAccessList, pairingProfile, activationCode, workloadOsFamilies, edgeEnabled, crowdstrikeEnabled) => ({
    // All write-able Pairing Profile List
    pairingProfileAccessList,
    // Activation code used for Pairing Profile
    activationCode,
    // Specific pairing profile instance
    pairingProfile,
    // Workload OS Families
    workloadOsFamilies,
    edgeEnabled,
    crowdstrikeEnabled,
  }),
);
