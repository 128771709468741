/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {call, put, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import gridSaga from 'components/Grid/GridSaga';
import {getContainerWorkloadInstance} from '../ContainerWokloadItemState';
import {inboundGridSettings, outboundGridSettings, customIpGridSettings} from './ContainerWorkloadRulesState';
import {isUserWithReducedScope} from 'containers/User/UserState';
import {RedirectError} from 'errors';

export function* fetchContainerWorkloadRules(route, refetch = false) {
  const userIsWithReducedScope = yield select(isUserWithReducedScope);

  // Users with limited scoped read are not allowed to access this component.
  if (userIsWithReducedScope) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {route, settings: inboundGridSettings});
  yield call(gridSaga, {route, settings: outboundGridSettings});
  yield call(gridSaga, {route, settings: customIpGridSettings});

  const {href} = yield select(getContainerWorkloadInstance);

  const {data} = yield call(apiSaga, 'sec_policy.policy_view', {
    cache: !refetch,
    params: {pversion: 'active'},
    query: {
      container_workload: href,
    },
  });

  yield put({type: 'CONTAINERWORKLOAD_GET_RULES', data});
}
