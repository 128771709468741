/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {
  getRouteCurrentParams,
  isHealthEnabled,
  getAppGroupLabelTypes,
  areVulnerabilitiesEnabled,
  isEdge,
  isCrowdstrike,
} from 'containers/App/AppState';
import {getClusters, getLocalCluster, getLocalClusterBasic} from 'containers/Health/HealthState';
import {isSuperclusterMember} from 'containers/User/UserState';
import {getVulnerabilities} from '../Vulnerabilities/WorkloadVulnerabilitiesState';
import {getWorkloadInstance} from '../WorkloadItemState';

export default {
  vreportList(state = {}, action) {
    switch (action.type) {
      case 'VULNERABILITY_REPORTS_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  staticCount(state = {}, action) {
    switch (action.type) {
      case 'WORKLOAD_STATIC_COUNT':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getVulnerabilityReportslist = state => state.workload.vreportList;
export const getOptionalFeatures = state => state.optionalFeatures;
export const getStaticCount = state => state.workload.staticCount;

export const getWorkloadSummaryPage = createSelector(
  [
    getWorkloadInstance,
    getRouteCurrentParams,
    getClusters,
    getStaticCount,
    getVulnerabilities,
    getVulnerabilityReportslist,
    getOptionalFeatures,
    getLocalCluster,
    isHealthEnabled,
    getLocalClusterBasic,
    isSuperclusterMember,
    getAppGroupLabelTypes,
    areVulnerabilitiesEnabled,
    isEdge,
    isCrowdstrike,
  ],
  (
    workload,
    currentRouteParams,
    allClusters,
    staticCount,
    vulnerabilities,
    vulnerabilityReports,
    optionalFeatures,
    localData,
    ishealthEnabled,
    localCluster = {},
    userIsSuperclusterMember,
    appGroupType,
    areVulnerabilitiesEnabled,
    edgeEnabled,
    crowdstrikeEnabled,
  ) => {
    const hasWritePermission = Array.isArray(workload.caps) && workload.caps.includes('write');

    return {
      workload,
      currentRouteParams,
      allClusters,
      staticCount,
      vulnerabilities,
      vulnerabilityReports,
      optionalFeatures,
      localData,
      ishealthEnabled,
      localCluster,
      userIsSuperclusterMember,
      appGroupType,
      areVulnerabilitiesEnabled,
      hasWritePermission,
      edgeEnabled,
      crowdstrikeEnabled,
    };
  },
);
