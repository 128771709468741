/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import {fetchContainerClusterWorkloads} from './ContainerClusterWorkloadsSaga';
import WorkloadList from 'containers/Workload/List/WorkloadList';
import WorkloadListReducers from 'containers/Workload/WorkloadState';
import LabelReducers from 'containers/Label/LabelState';

export default class ContainerClusterWorkloads extends PureComponent {
  static prefetch = fetchContainerClusterWorkloads;
  static contextType = AppContext;
  static reducers = [WorkloadListReducers, LabelReducers];

  constructor(props) {
    super(props);

    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh() {
    return this.context.fetcher.fork(fetchContainerClusterWorkloads.refetch);
  }

  render() {
    return (
      <WorkloadList
        noScope
        hideHeaderProps
        hideRemove
        hideAdd
        hidePolicyApplyAll
        hideReports
        showGlobalLink
        onRefresh={this.handleRefresh}
      />
    );
  }
}
