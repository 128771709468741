/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import {useContext} from 'react';
import {Field} from 'formik';
import {forwardRefFactory, forwardRefSymbol} from 'react-forwardref-utils';
import {Form, TypedMessages} from 'components';
import EditorGrid from './EditorGrid';
import {areArraysEqualWhenSorted, randomString} from 'utils/general';
import {whenPropTypes, useWhen, isRequiredField} from '../FormUtils';

FormGrid.propTypes = {
  name: PropTypes.string, // Name that corresponds to your form's schema
  settings: PropTypes.object.isRequired, // Grid config

  onAddOrRemoveRows: PropTypes.func,

  gridProps: PropTypes.object, // object with custom props for grid
  addButtonProps: PropTypes.object, // object with custom props for add button, will be merged with defaultAddButtonProps
  removeButtonProps: PropTypes.object, // object with custom props for remove button, will be merged with defaultRemoveButtonProps

  // Might depend on other fields
  ...whenPropTypes,
};

export function FormGrid(props) {
  const {
    [forwardRefSymbol]: ref,
    form,
    field,
    onAddOrRemoveRows,
    children,
    gridProps,
    ...editorGridProps
  } = useWhen(props.form, props.field, props);
  const schema = useContext(Form.SchemaContext);
  const {name, value} = field;
  const error = form.touched[name] && form.errors.hasOwnProperty(name);
  let errorMessage = error && form.errors[name];

  editorGridProps.ref = ref;
  editorGridProps.rows = value || [];

  if (Array.isArray(errorMessage)) {
    editorGridProps.errors = errorMessage;
    errorMessage = undefined;
  }

  const isRequired = isRequiredField(schema, name, form.values);

  editorGridProps.onAddOrRemoveRows = ({action, settings, selectedKeySetToRemove}) => {
    const rows = form.values[name];

    if (onAddOrRemoveRows) {
      return onAddOrRemoveRows({form, action, settings, selectedKeySetToRemove});
    }

    const emptyRow = settings.getInitialRow(randomString(5, true));

    if (action === 'add') {
      // Set the new value
      form.setFieldValue(name, [emptyRow, ...rows]);
      form.setFieldTouched(name, []);

      return;
    }

    const initialRows = form.initialValues[name];
    let newRows = rows.filter(row => !selectedKeySetToRemove.has(row.key));

    if (areArraysEqualWhenSorted(newRows, initialRows)) {
      // If selected option is equal to default one,
      // then revert to default one to keep object reference, so formik can unmark it as changed
      return form.setFieldValue(name, initialRows);
    }

    if (isRequired && newRows.length === 0) {
      newRows = [emptyRow];
    }

    // Set the new value
    form.setFieldValue(name, newRows);
    form.setFieldTouched(name, []);
  };

  return (
    <>
      <EditorGrid gridProps={gridProps} {...editorGridProps} />
      <TypedMessages>
        {[
          errorMessage
            ? {
                color: 'error',
                content: errorMessage,
              }
            : null,
        ]}
      </TypedMessages>
    </>
  );
}

// Temporary Field wrapper until we get hooks in Formik 2.0
export default forwardRefFactory(props => <Field {...props} component={FormGrid} />, {hoistSource: FormGrid});
