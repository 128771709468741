/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import ServiceEdit from 'containers/Service/Item/Edit/ServiceEdit';
import {getDiscardChangesModalProps} from 'components/UnsavedPendingWarning/UnsavedPendingWarningUtils';

export const getServiceDefinitions = state => state.service.serviceDefinitions;

// raw data
export const getSuggestedServices = createSelector(getServiceDefinitions, suggestedServices => ({suggestedServices}));

// format suggested services for selector components 'statics' prop
export const getSuggestedServicesStatics = createSelector(getSuggestedServices, ({suggestedServices}) => {
  return {
    suggestedServices: suggestedServices.map(service => ({
      value: service.name,
      pageInvokerProps: {
        containerProps: {
          controlled: true,
          versions: {pversionObj: {...service}},
        },
        container: ServiceEdit,
        title: intl('Services.AddNew'),
        unsavedWarningData: getDiscardChangesModalProps('service'),
      },
    })),
  };
});
