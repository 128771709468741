/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {call, put, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import gridSaga from 'components/Grid/GridSaga';
import {getWorkloadInstance} from '../WorkloadItemState';
import {inboundGridSettings, outboundGridSettings, customIpGridSettings} from './WorkloadRulesState';

export function* fetchWorkloadRules(route, refetch = false) {
  yield call(gridSaga, {route, settings: inboundGridSettings});
  yield call(gridSaga, {route, settings: outboundGridSettings});
  yield call(gridSaga, {route, settings: customIpGridSettings});

  const {href} = yield select(getWorkloadInstance);

  const {data} = yield call(apiSaga, 'sec_policy.policy_view', {
    cache: !refetch,
    params: {pversion: 'active'},
    query: {
      workload: href,
    },
  });

  yield put({type: 'WORKLOAD_GET_RULES', data});
}
