/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as PropTypes from 'prop-types';
import Selectors from '../Selectors';
import styles from '../Selectors.css';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {createNewObject} from 'containers/Selectors/SelectorSaga';
import {getSelectorMatches, getMatchCount, isLoadingMatches} from 'containers/Selectors/SelectorState';

@connect((state, props) => ({
  loading: isLoadingMatches(state),
  matches: getSelectorMatches(state, props.objects[0].type, props.objects[0].key),
  count: getMatchCount(state, props.objects[0].type, props.objects[0].key),
}))
export default class MultiItemSelect extends PureComponent {
  static contextType = AppContext;
  static propTypes = {
    initialItems: PropTypes.array,
    disabled: PropTypes.bool,
    objects: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    all: PropTypes.bool,
    addValue: PropTypes.func,
    placeholder: PropTypes.string,
    allowCreateTypes: PropTypes.array,
    renderOption: PropTypes.func,
    showMatchingResults: PropTypes.bool,
    footer: PropTypes.any,
    onSelectionChange: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    showMatchingResults: false,
    initialItems: [],
  };

  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  getDropdownValues(matches = []) {
    const type = this.props.objects?.[0]?.type;

    return matches.map(match => {
      if (match.label && match.href.includes('groups')) {
        match = {
          href: match.label.href,
          key: 'role',
          value: match.name,
          groupHref: match.href,
        };
      }

      if (typeof match === 'string') {
        return {categoryKey: type, value: match};
      }

      return {categoryKey: type, ...match};
    });
  }

  getFooter() {
    const {footer, count, showResultsFooter} = this.props;

    if (footer) {
      return footer;
    }

    if (showResultsFooter) {
      return (
        <li className={styles.count} data-tid="comp-select-results-item" disabled>
          {intl('ObjectSelector.NumMatchingResults', {count})}
        </li>
      );
    }
  }

  handleInputChange(input, object) {
    this.fetchData(input, object);
  }

  async handleCreate(input, object) {
    const {store} = this.context;
    const {type, key} = object;
    const data = {key, value: input};
    const query = {query: '', key};
    const created = await store.runSaga(createNewObject, type, data, query).toPromise();
    const prevValues = this.props.initialItems;

    this.props.onSelectionChange([...prevValues, {key: created.key, value: created.value, href: created.href}]);
  }

  fetchData(input, object) {
    const {type, key, ...params} = object;

    const query = {query: input, key};

    this.props.dispatch({type: `${type.toUpperCase()}_MATCHES_REQUEST`, object: type, query, params});
  }

  render() {
    const {
      initialItems,
      allowCreateTypes,
      placeholder,
      loading,
      disabled,
      objects,
      categories,
      count,
      showResultsFooter,
      renderOption,
      tid,
    } = this.props;
    const matches = this.getDropdownValues(this.props.matches);
    const type = objects[0].type;

    const props = {
      tid,
      initialItems,
      placeholder,
      categories,
      objects,
      disabled,
      activeCategoryKey: categories[0].categoryKey,
      matches: {[type]: {matches, count}},
      footer: this.getFooter(),
      allowCreateTypes,
      onCreate: this.handleCreate,
      loading,
      maxResults: 5,
      renderOption,
      showTotal: true,
      showContainerTitle: false,
      allowMultipleItems: true,
      showResultsFooter,
      onInputChange: this.handleInputChange,
      onSelectionChange: this.props.onSelectionChange,
    };

    return <Selectors {...props} />;
  }
}
