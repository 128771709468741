/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {formatDate} from 'components/Grid/GridUtils';
import {ButtonRefresh, Grid, ToolBar, ToolGroup, Notifications} from 'components';
import {getWorkloadProcessesPage} from './WorkloadProcessesState';
import {fetchWorkloadItem} from '../../WorkloadSaga';
import {fetchVulnerabilities} from '../Vulnerabilities/WorkloadVulnerabilitiesSaga';

@connect(getWorkloadProcessesPage)
export default class WorkloadProcesses extends Component {
  static prefetch = fetchVulnerabilities;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh() {
    return this.context.fetcher.fork(fetchWorkloadItem.refetch);
  }

  render() {
    const {processesGrid, workload} = this.props;

    return (
      <>
        <Notifications>
          {[
            {
              type: 'instruction',
              message: intl('Workloads.ProcessesNotification', {time: formatDate(workload.services.created_at)}),
            },
          ]}
        </Notifications>
        <ToolBar justify="flex-end">
          <ToolGroup>
            <ButtonRefresh color="standard" onRefresh={this.handleRefresh} />
          </ToolGroup>
        </ToolBar>
        <Grid grid={processesGrid} emptyMessage={intl('Common.NoData')} />
      </>
    );
  }
}
