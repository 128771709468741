/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {assign, send} from 'xstate';
import _ from 'lodash';
import intl from 'intl';
import {TimeoutError, RedirectError} from 'errors';

// default config consists of three machines:
//   1. modal machine ~ all UI states in a given modal
//   2. app machine ~ all API requests and saves errors to context
//   3. error processor machine ~ error parsing from app machine.
//      It is replaceable and we also have extra configurations in errorMachines.js
export const defaultConfig = () => ({
  id: 'modalMachine',
  // starts all machines at the same time at their respective initial state
  type: 'parallel',
  states: {
    // main 'UI' machine to handle visual states
    modal: {
      id: 'modal',
      on: {
        RESET: 'modal.open',
      },
      // first state of machine after a launch
      initial: 'open',
      states: {
        open: {
          // 'on' ~ machine listens to Events and can do following things:
          //   1. can transition to other state as for SUBMIT Event
          //   2. can do some Actions declared as actions props in ModalMachine component
          on: {
            SUBMIT: 'submitting',
            CANCEL: [{target: '#app.onProcessDone', actions: 'close', cond: 'hasUITimedout'}, {actions: 'close'}],
          },
        },
        submitting: {
          initial: 'progress',
          // sub-states have been declared here to isolate two distinct states:
          //   1. progress: we are waiting for process ~ Promise in app machine to complete
          //   2. completeProgress: process is complete and we are creating Promise through context for us to complete progress state
          states: {
            progress: {
              on: {
                // It will exit this nested state and redirect it to the main error state
                ERROR: '#error',
              },
              // as soon as we enter 'progress' state 'entry' will be executed, which could be an action or any other pure function
              // assign is a helper function that allows us to mutate context
              // we are creating Promise here and assigning it to context so then we can use it in next state
              entry: 'createProgressPromise',
            },
            completeProgress: {
              on: {
                ERROR: '#error',
              },
              // invoke property can be a machine or function that returns Promise
              // machine 'pauses' here until we resolve Promise that we created earlier in context
              invoke: {
                id: 'completeProgress',
                src: 'resolveProgressPromise',
                // when Promise is completed we can execute all actions in the following declaration
                onDone: [
                  // we can conditionally transition to 'error' state if there are any errors in context
                  {target: '#error', cond: 'haveErrors'},
                  {target: 'close'},
                ],
              },
            },
            close: {
              entry: 'close',
            },
          },
          on: {
            // whenever app machine completes all states then it will transition UI state from 'progress'
            'done.invoke.onProcessDone': 'submitting.completeProgress',
            'DONE.WITH.ERRORS': 'error',
            'CLOSE': {actions: 'close'},
          },
        },
        error: {
          // allows us to transition from any nested states with '#error'
          id: 'error',
          on: {
            CLOSE: {actions: 'close'},
          },
        },
      },
    },
    app: {
      id: 'app',
      initial: 'idle',
      on: {
        // global Event that allows us to restart all machines from initial state
        RESET: {
          target: 'app.idle',
          actions: 'resetErrors',
        },
        ERROR: [
          {
            // assign no internet notification if there is no connection
            actions: ['assignNoInternetNotification', send('RESET')],
            cond: 'noInternetConnection',
          },
          {
            actions: ['assignUITimeoutNotification', send('RESET')],
            cond: 'hasUITimedout',
          },
        ],
      },
      states: {
        idle: {
          on: {
            SUBMIT: 'process',
          },
        },
        process: {
          entry: 'resetNotifications',
          invoke: {
            id: 'process',
            // get assigned here from services props declaration
            src: 'process',
            // if Promise gets rejected with an error we will assign response to context and we will send 'ERROR' Event to other machines
            onError: {
              target: 'complete',
              actions: ['assignResult', send('ERROR')],
            },
            onDone: {
              target: 'onProcessDone',
              actions: ['assignResult', send('DONE')],
            },
          },
        },
        onProcessDone: {
          invoke: {
            id: 'onProcessDone',
            src: 'onProcessDone',
            onDone: 'complete',
            onError: [
              {
                target: 'complete',
                actions: ['assignRedirectError', send('ERROR')],
                cond: 'isRedirectError',
              },
              'complete',
            ],
          },
        },
        complete: {},
      },
    },
    errorProcessor: {
      id: 'defaultErrorProcessor',
      initial: 'idle',
      on: {
        RESET: 'errorProcessor.idle',
      },
      states: {
        idle: {
          on: {
            ERROR: 'processError',
          },
        },
        // parsing errors from Promise rejection, default configuration allows us to parse one API call
        processError: {
          entry: 'parseErrorsFromProcessResult',
          always: [{target: 'doneWithErrors', actions: send('DONE.WITH.ERRORS')}],
        },
        doneWithErrors: {},
      },
    },
  },
});

export const defaultActions = props => ({
  close: props.onClose,

  createProgressPromise: assign(() => {
    let onProgressDone;
    const completeProgress = new Promise(resolve => {
      onProgressDone = resolve;
    });

    return {
      onProgressDone,
      completeProgress,
    };
  }),

  resetErrors: assign({errors: () => ({})}),

  assignNoInternetNotification: assign({
    notifications: () => [{type: 'error', message: intl('Common.NoInternetConnectionAvailable')}],
  }),
  assignUITimeoutNotification: assign({
    notifications: ({processResult}) => [{type: 'warning', message: processResult.data.toString()}],
  }),

  resetNotifications: assign({notifications: () => []}),

  assignResult: assign({processResult: (ctx, event) => event}),

  assignRedirectError: assign((_, event) => ({
    redirectError: event?.data,
  })),

  parseErrorsFromProcessResult: assign(({processResult}) => {
    let errors;
    let message = processResult?.data?.data?.[0]?.message ?? processResult?.data?.toString();
    const token = processResult?.data?.data?.[0]?.token;

    if (processResult?.code === 'API_ERROR') {
      message = processResult.errors;
    }

    if (props.modalProps?.multiErrors && processResult?.data?.code === 'API_ERROR') {
      errors = processResult.data.data;
    } else {
      errors = {
        message,
        token,
      };
    }

    return {
      // Pass back offline info
      noConnection: processResult?.data?.noConnection,
      errors,
    };
  }),
});

export const defaultGuards = () => ({
  noInternetConnection: ({processResult}) => {
    if (processResult) {
      const {data} = processResult;

      return data?.code === 'REQUEST_ERROR' && data.noConnection;
    }

    return false;
  },
  hasUITimedout: ({processResult}) => processResult?.data instanceof TimeoutError,
  isRedirectError: (_, event) => event?.data instanceof RedirectError,
  haveErrors: ({errors}) => !_.isEmpty(errors),
});

export const defaultServices = () => ({
  resolveProgressPromise: ctx => ctx.completeProgress,
  process: () => Promise.resolve(),
  onProcessDone: () => Promise.resolve(),
});
