/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSelector, getUrlFilterParam} from 'components/Grid/GridSelectors';
import {gridSettings, getSelectorSettings} from './ScopeListConfig';
import {getOrgId} from 'containers/User/UserState';
import {getLabelObjects, isHrefLabelGroups, getScopeIdStringFromScope} from 'containers/RBAC/RBACUtils';
import {isAPIAvailable} from 'api/apiUtils';
import * as GridUtils from 'components/Grid/GridUtils';

export const labelfilterItems = (currentItems, newItems) => {
  // When All label selection exists and a label type is selected, clear All label selection
  if (newItems.some(item => item.categoryKey !== 'all') && currentItems.some(item => item.categoryKey === 'all')) {
    return newItems.filter(item => item.categoryKey !== 'all');
  }

  return newItems;
};

export const allFilterItems = (currentItems, newItems) => {
  // When labels selection exists and All label is selected, clear all labels selection
  if (
    newItems.some(item => item.categoryKey === 'all') &&
    currentItems.some(item => ['labels', 'label_groups', 'noLabel'].includes(item.categoryKey))
  ) {
    return newItems.filter(item => !['labels', 'label_groups', 'noLabel'].includes(item.categoryKey));
  }

  return newItems;
};

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'SCOPE_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },
  count(state = {}, action) {
    switch (action.type) {
      case 'SCOPE_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getScopes = state => state.permissions.scope.list;
export const getScopesCount = state => state.permissions.scope.count;
export const getScopeRows = createSelector(getScopes, scopes =>
  scopes.map(({scope, ...rest}) => {
    const isAllowed = isAPIAvailable('org_permission.delete');
    const labelObjects = getLabelObjects(scope);

    return {
      key: getScopeIdStringFromScope(labelObjects),
      selectable: isAllowed,
      removable: isAllowed,
      scope: labelObjects,
      labels: GridUtils.getLabelsMap(labelObjects),
      data: {
        ...rest,
      },
    };
  }),
);

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getScopeRows,
    filterMap: getSelectorSettings().filterMap,
  });

export const getFilters = state =>
  getUrlFilterParam(state, {
    settings: gridSettings,
    filterMap: getSelectorSettings().filterMap,
  });

export const getScopeListPage = createSelector(
  [getGrid, getScopesCount, getFilters, getOrgId, getSelectorSettings],
  (grid, count, filter, orgId, {scopeMap, filterMap, objectMap, facetMap, staticValues}) => {
    const initialItems = Object.keys(filter.valid).reduce((result, categoryKey) => {
      const filterItems = filter.valid[categoryKey];

      if (categoryKey === 'labels') {
        filterItems.forEach(({href, value, key, name}) => {
          const catKey = isHrefLabelGroups(href) ? 'label_groups' : 'labels';

          result.push({
            href,
            ...(value && {value}),
            ...(name && {name}),
            categoryKey: catKey,
            key,
            categoryName: filterMap[catKey].value,
          });
        });
      } else if (categoryKey === 'noLabel') {
        filterItems.forEach(value => {
          result.push({
            value,
            categoryKey: 'noLabel',
            categoryName: filterMap.noLabel.value,
          });
        });
      } else {
        result.push({
          categoryKey,
          value: filter.valid[categoryKey][0]?.value || filter.valid[categoryKey][0],
          ...(categoryKey !== 'all' && {categoryName: filterMap[categoryKey].value || filterMap[categoryKey]}),
        });
      }

      return result;
    }, []);

    // Selector parameters based on filter and config
    const selector = {
      initialItems,
      objects: Object.values(objectMap),
      categories: Object.entries(filterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value: value.value || value,
        ...(typeof value === 'object' && value),
        ...(['labels', 'label_groups', 'noLabel', 'all'].includes(categoryKey) && {
          filterItems: categoryKey === 'all' ? allFilterItems : labelfilterItems,
        }),
      })),
      facets: Object.keys(facetMap),
      statics: Object.entries(staticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
    };

    return {count, grid, selector, orgId, scopeMap, staticValues};
  },
);
