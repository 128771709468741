/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';

export const formatVenVersion = venRelease => {
  const vens = venRelease.split('(')[1];

  if (venRelease.includes('Default') && Array.isArray(vens)) {
    const venVersion = vens.slice(0, -1);

    return intl('PairingProfiles.VenRelease', {venVersion});
  }

  return venRelease;
};
