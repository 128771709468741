/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {getStatusElement} from 'containers/ContainerCluster/ContainerClusterUtils';
import {AttributeList, Button, CopyButton, Notifications, StatusIcon, ToolBar, ToolGroup} from 'components';
import {hrefUtils} from 'utils';
import {fetchContainerClusterList} from 'containers/ContainerCluster/List/ContainerClusterListSaga';
import {getContainerClusterDetailPage} from '../ContainerClusterDetailState';
import RemoveButton from 'containers/Remove/RemoveButton';
import styles from './ContainerClusterSummary.css';
import {getEventType} from 'utils/events';

const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};
const statusIcons = {
  normal: 'inuse',
  warning: 'warning',
  critical: 'error',
};

@connect(getContainerClusterDetailPage)
export default class ContainerClusterSummary extends PureComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.handleRemoveDone = this.handleRemoveDone.bind(this);
  }

  async handleRemoveDone() {
    const {fetcher, navigate} = this.context;

    await fetcher.fork(fetchContainerClusterList.refetch);
    navigate({to: 'containerClusters.list'});
  }

  renderTokenInfo(attributes) {
    const {id, token} = this.props;

    if (!token) {
      return;
    }

    const notifications = [
      {
        type: 'instruction',
        title: intl('ContainerClusters.CopyTokenMessage'),
      },
    ];

    attributes.push(
      {divider: true},
      {content: <Notifications>{notifications}</Notifications>},
      {title: intl('ContainerClusters.ClusterPairingToken')},
      {
        tid: 'clusterid',
        key: intl('ContainerClusters.ClusterId'),
        value: (
          <div className={styles.command}>
            <div className={styles.text}>{id}</div>
            <CopyButton copyText={id} />
          </div>
        ),
      },
      {
        tid: 'tokenvalue',
        key: intl('ContainerClusters.ClusterToken'),
        value: (
          <div className={styles.command}>
            <div className={styles.text}>{token}</div>
            <CopyButton copyText={token} />
          </div>
        ),
      },
    );
  }

  renderPceInfo(attributes) {
    const {pce_fqdn: pceFqdn, health} = this.props;

    attributes.push(
      {divider: true},
      {title: intl('Common.PCE')},
      {
        tid: 'pce-fqdn',
        key: intl('Common.PCE'),
        value: pceFqdn,
      },
      {
        tid: 'pce-health',
        key: intl('Health.PCEHealth'),
        value: (
          <StatusIcon
            theme={styles}
            status={statusIcons[health]}
            label={_.capitalize(health)}
            noTextColor={health === 'normal'}
          />
        ),
      },
    );
  }

  render() {
    const {
      name,
      href,
      online,
      manager_type: managerType,
      kubelink_version: kubelinkVersion,
      description,
      container_runtime: runtime,
      last_connected: lastConnected,
      errors,
      userIsReadOnlyClusterInsensitive,
      clusters,
      isLocalPce,
    } = this.props;

    const attributes = [
      {title: intl('Common.General')},
      {
        tid: 'name',
        key: intl('Common.Name'),
        value: name,
      },
      {
        tid: 'status',
        key: intl('Common.Status'),
        value: getStatusElement({online, lastConnected, errors}),
      },
      {
        tid: 'uptime',
        key: intl('Workloads.HeartbeatLastReceived'),
        value: intl.utils.format.dateAtTimeBy(lastConnected),
      },
      {
        tid: 'manager',
        key: intl('Containers.PlatformVersion'),
        value: managerType,
      },
      {
        tid: 'version',
        key: intl('ContainerClusters.KubelinkVersion'),
        value: kubelinkVersion,
      },
      {
        tid: 'runtime',
        key: intl('ContainerClusters.ContainerRuntime'),
        value: runtime,
      },
      {
        tid: 'desc',
        key: intl('Common.Description'),
        value: description,
      },
    ];

    if (clusters.length > 1) {
      this.renderPceInfo(attributes);
    }

    this.renderTokenInfo(attributes);

    const notifications = Array.isArray(errors)
      ? errors.reduce((result, error) => {
          if (error.error_type === 'container_cluster.duplicate_machine_id') {
            result.push({
              type: 'error',
              title: (
                <>
                  <div>{intl('ContainerClusters.DuplicateMachineIDsError')}</div>
                  <div>
                    {(() => {
                      if (Array.isArray(error.duplicate_ids) && error.duplicate_ids.length > 0) {
                        return intl('ContainerClusters.DuplicatedIdList', {list: error.duplicate_ids.join(', ')});
                      }

                      if (Array.isArray(error.duplicate_node_names) && error.duplicate_node_names.length > 0) {
                        return intl('ContainerClusters.DuplicatedIdNodeList', {
                          list: error.duplicate_node_names.join(', '),
                        });
                      }
                    })()}
                  </div>
                </>
              ),
            });
          } else {
            result.push({
              type: 'error',
              title: getEventType(error.error_type) || intl(`ErrorsAPI.err:${error.error_type}`),
            });
          }

          return result;
        }, [])
      : [];

    return (
      <>
        {!isLocalPce && (
          <Notifications sidebar>
            {[
              {
                message: intl('PCE.ItemUnderDifferentPCE'),
                type: 'instruction',
              },
            ]}
          </Notifications>
        )}
        {notifications.length > 0 && <Notifications sidebar>{notifications}</Notifications>}
        <ToolBar>
          <ToolGroup>
            <Button.Link
              icon="edit"
              text={intl('Common.Edit')}
              textIsHideable
              disabled={userIsReadOnlyClusterInsensitive || !isLocalPce}
              link={{to: 'containerClusters.item.edit', params: {id: hrefUtils.getId(href)}}}
              tid="edit"
              theme={buttonsTheme}
            />
            <RemoveButton
              theme={buttonsTheme}
              name={name}
              href={href}
              disabled={userIsReadOnlyClusterInsensitive || !isLocalPce}
              target="containerCluster"
              onRemoveDone={this.handleRemoveDone}
            />
          </ToolGroup>
        </ToolBar>
        <AttributeList>{attributes}</AttributeList>
        <div className={styles.bottomSpacer} />
      </>
    );
  }
}
