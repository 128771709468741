/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import * as GridUtils from 'components/Grid/GridUtils';
import {formatUtils} from 'utils/index';
import {Badge, Button} from 'components';
import styles from './SecuritySettings.css';
import {allScopeLabels} from 'containers/Selectors/SelectorUtils';
import {edge} from 'api/apiUtils';
import {createSelector} from 'reselect';

const objectMap = edge
  ? {
      roleLabels: {type: 'labels', key: 'role'},
      label_groups: {type: 'label_groups', pversion: 'draft'},
    }
  : {
      roleLabels: {type: 'labels', key: 'role'},
      appLabels: {type: 'labels', key: 'app'},
      envLabels: {type: 'labels', key: 'env'},
      locLabels: {type: 'labels', key: 'loc'},
      label_groups: {type: 'label_groups', pversion: 'draft'},
    };

export const getSelectorSettings = createSelector([], () => ({
  objectMap,
  staticMap: {
    status: intl('Provision.Status'),
    state: intl('Policy.State'),
    primaryFirewall: intl('Firewall.Coexistence.IllumioAsPrimaryFirewall'),
  },
  scopeMap: {
    role: {value: intl('Labels.RoleLabels'), object: objectMap.roleLabels, statics: allScopeLabels().role},
    app: {value: intl('Labels.ApplicationLabels'), object: objectMap.appLabels, statics: allScopeLabels().app},
    env: {value: intl('Labels.EnvironmentLabels'), object: objectMap.envLabels, statics: allScopeLabels().env},
    loc: {value: intl('Labels.LocationLabels'), object: objectMap.locLabels, statics: allScopeLabels().loc},
    label_groups: {value: intl('Labels.Groups'), object: objectMap.label_groups},
  },
  filterMap: {
    status: {value: intl('Provision.Status'), isStatic: true},
    state: {value: intl('Policy.State'), isStatic: true},
    primaryFirewall: {value: intl('Firewall.Coexistence.IllumioAsPrimaryFirewall'), isStatic: true},
    role: {value: intl('Labels.RoleLabels'), object: objectMap.roleLabels, statics: allScopeLabels().role},
    app: {value: intl('Labels.ApplicationLabels'), object: objectMap.appLabels, statics: allScopeLabels().app},
    env: {value: intl('Labels.EnvironmentLabels'), object: objectMap.envLabels, statics: allScopeLabels().env},
    loc: {value: intl('Labels.LocationLabels'), object: objectMap.locLabels, statics: allScopeLabels().loc},
    label_groups: {value: intl('Labels.Groups'), object: objectMap.label_groups},
  },
  staticValues: {
    status: {
      [intl('Provision.PendingAddition')]: 'create',
      [intl('Provision.PendingDeletion')]: 'delete',
    },
    state: {
      [intl('Common.All')]: 'all',
      [intl('Common.Enforced')]: 'enforced',
      [intl('Common.Illuminated')]: 'illuminated',
    },
    primaryFirewall: {
      [intl('Common.Yes')]: 'yes',
      [intl('Common.No')]: 'no',
    },
  },
}));

export const policyState = () => ({
  enforced: intl('Common.Enforced'),
  illuminated: intl('Common.Illuminated'),
  all: intl('Common.All'),
});

export const gridSettings = createSelector([], () => ({
  id: 'securitysettings',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  showCapacity: true,
  showPagination: true,
  maxPage: Number.MAX_SAFE_INTEGER,
  columns: {
    checkboxes: {},
    status: {
      header: intl('Provision.Status'),
      value: ({row}) => formatUtils.getBadgeLabelByUpdateType(row.data.status),
      format: ({row, value}) =>
        value ? (
          <Badge type={formatUtils.getBadgeTypeByUpdateType(row.data.status)} theme={styles} themePrefix="status-">
            {value}
          </Badge>
        ) : null,
    },
    role: {
      header: edge ? intl('Common.Group') : intl('Common.Role'),
      ...GridUtils.clickableLabelColumn,
    },
    app: {
      header: intl('Common.Application'),
      ...GridUtils.clickableLabelColumn,
    },
    env: {
      header: intl('Common.Environment'),
      ...GridUtils.clickableLabelColumn,
    },
    loc: {
      header: intl('Common.Location'),
      ...GridUtils.clickableLabelColumn,
    },
    state: {
      header: intl('Policy.State'),
      value: 'state',
      format: ({value}) => (value ? policyState()[value] : intl('Common.All')),
    },
    primaryFirewall: {
      header: intl('Firewall.Coexistence.IllumioAsPrimaryFirewall'),
      value: 'primaryFirewall',
      format: ({value}) => (value ? intl('Common.Yes') : intl('Common.No')),
      sort: ({value}) => (value ? intl('Common.Yes') : intl('Common.No')),
    },
    action: {
      value: 'action',
      format: ({component, row}) =>
        row.selectable && (
          <Button
            color="standard"
            noFill
            icon="edit"
            tid="edit"
            disabled={!row.data.action}
            onClick={component && _.partial(component.handleModifySingleScope, row)}
          />
        ),
      sortable: false,
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'min-content'},
      {columns: ['role'], size: 'minmax(150px, auto)'},
      {columns: ['app'], size: 'minmax(150px, auto)'},
      {columns: ['env'], size: 'minmax(150px, auto)'},
      {columns: ['loc'], size: 'minmax(150px, auto)'},
      {columns: ['state'], size: 'minmax(100px, auto)'},
      {columns: ['primaryFirewall'], size: 'minmax(250px, auto)'},
      {columns: ['action'], size: 'max-content'},
    ],
    {
      maxWidth: 1152,
      template(columns) {
        if (columns.get('state').disabled && columns.get('primaryFirewall').disabled) {
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'minmax(130px, auto)'},
            {columns: ['role'], size: 'minmax(150px, auto)'},
            {columns: ['app'], size: 'minmax(150px, auto)'},
            {columns: ['env'], size: 'minmax(150px, auto)'},
            {columns: ['loc'], size: 'minmax(150px, auto)'},
            {columns: ['action'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'minmax(130px, auto)'},
          {columns: ['role'], size: 'minmax(120px, auto)'},
          {columns: ['app'], size: 'minmax(120px, auto)'},
          {columns: ['env'], size: 'minmax(120px, auto)'},
          {columns: ['loc'], size: 'minmax(120px, auto)'},
          {columns: ['state'], size: 'minmax(100px, auto)'},
          {columns: ['primaryFirewall'], size: 'minmax(200px, auto)'},
          {columns: ['action'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (columns.get('state').disabled && columns.get('primaryFirewall').disabled) {
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'minmax(130px, auto)'},
            {columns: ['role', 'app'], size: 'minmax(200px, auto)'},
            {columns: ['env', 'loc'], size: 'minmax(200px, auto)'},
            {columns: ['action'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'minmax(130px, auto)'},
          {columns: ['role', 'app'], size: 'minmax(200px, auto)'},
          {columns: ['env', 'loc'], size: 'minmax(200px, auto)'},
          {columns: ['state', 'primaryFirewall'], size: 'minmax(200px, auto)'},
          {columns: ['action'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (columns.get('state').disabled && columns.get('primaryFirewall').disabled) {
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'minmax(130px, auto)'},
            {columns: ['role', 'app'], size: 'minmax(200px, auto)'},
            {columns: ['env', 'loc'], size: 'minmax(200px, auto)'},
            {columns: ['action'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'minmax(130px, auto)'},
          {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(250px, auto)'},
          {columns: ['state', 'primaryFirewall'], size: 'minmax(150px, auto)'},
          {columns: ['action'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (columns.get('state').disabled && columns.get('primaryFirewall').disabled) {
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'minmax(130px, auto)'},
            {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(200px, auto)'},
            {columns: ['action'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status', 'role', 'app', 'env', 'loc'], size: 'minmax(100px, auto)'},
          {columns: ['state', 'primaryFirewall'], size: 'minmax(100px, auto)'},
          {columns: ['action'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 450,
      template(columns) {
        if (columns.get('state').disabled && columns.get('primaryFirewall').disabled) {
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status', 'role', 'app', 'env', 'loc'], size: 'minmax(100px, auto)'},
            {columns: ['action'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status', 'role', 'app', 'env', 'loc', 'state', 'primaryFirewall'], size: 'minmax(100px, auto)'},
          {columns: ['action'], size: 'max-content'},
        ];
      },
    },
  ],
}));
