/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {hrefUtils, webStorageUtils, ipUtils} from 'utils';
import {Button, ToolBar, ToolGroup, AttributeList, Icon, Link, Pill} from 'components';
import {getLabelTypeName} from 'components/Pill/Label/LabelUtils';
import {getWorkloadStatusIntl} from 'intl/dynamic';
import {areVulnerabilitiesEnabled, isIlluminationApiEnabled, getOrg} from 'containers/App/AppState';
import {getContainerWorkloadItemPage} from '../ContainerWokloadItemState';
import {fetchContainerWorkloadSummary} from './ContainerWorkloadSummarySaga';
import {getNodeAppGroupParent} from 'containers/Explorer/ExplorerUtils';
import {enforcementModeView, visibilityLevelView} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import styles from 'containers/PairingProfile/Item/View/PairingProfileView.css';

@connect(state => ({
  ...getContainerWorkloadItemPage(state),
  orgs: getOrg(state),
  vulnerabilitiesAreEnabled: areVulnerabilitiesEnabled(state),
}))
export default class ContainerWorkloadSummary extends Component {
  static prefetch = fetchContainerWorkloadSummary;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.enforcementModeView = enforcementModeView();
    this.visibilityLevelView = visibilityLevelView();
    this.handleGoToAppMap = this.handleGoToAppMap.bind(this);
  }

  handleGoToAppMap(type) {
    const {orgs, containerWorkload} = this.props;
    const appGroupType = orgs.app_groups_default || [];

    const id = getNodeAppGroupParent(containerWorkload, appGroupType);

    webStorageUtils.setSessionItem('MapPageStore', {appMapVersion: type});

    this.context.navigate({to: 'appMap.level', params: {type: 'focused', id}});
  }

  renderLabel(type) {
    const {
      containerWorkload: {labels},
    } = this.props;
    const currentLabel = labels.find(item => item.key === type);

    return currentLabel ? (
      <Pill.Label type={type} href={currentLabel.href}>
        {currentLabel.value}
      </Pill.Label>
    ) : null;
  }

  render() {
    const {
      containerWorkload,
      vulnerabilitiesAreEnabled,
      containerClustersEnabled,
      containerWorkload: {created_at: createdAt, updated_at: updatedAt},
    } = this.props;
    const policySync = containerWorkload.security_policy_sync_state; // null if not in region, or during lag EYE-71948
    const label = getWorkloadStatusIntl(policySync);
    const appGroupType = this.props.orgs.app_groups_default || [];
    const showAppGroup =
      containerWorkload.rulesetCaps &&
      appGroupType.length &&
      appGroupType.every(type => containerWorkload.labels.find(label => label.key === type));
    const showVulnerability = vulnerabilitiesAreEnabled && showAppGroup;
    const classNameContent = styles.content;

    // Show Visiblity View
    let visibilityValue;

    if (containerWorkload.enforcement_mode === 'idle') {
      visibilityValue = <div>{this.visibilityLevelView[containerWorkload.enforcement_mode].name}</div>;
    } else {
      visibilityValue = (
        <>
          {this.visibilityLevelView[containerWorkload.visibility_level]?.name}
          <div className={classNameContent}>{this.visibilityLevelView[containerWorkload.visibility_level]?.desc}</div>
        </>
      );
    }

    return (
      <>
        <ToolBar>
          <ToolGroup>
            {showAppGroup && isIlluminationApiEnabled ? (
              <Button
                textIsHideable
                tid="appgroup-map"
                text={intl('Common.AppGroupMap')}
                onClick={_.partial(this.handleGoToAppMap, 'policy')}
                color="standard"
                icon="external-link"
              />
            ) : null}
            {showVulnerability && isIlluminationApiEnabled ? (
              <Button
                text={intl('Map.Vulnerability')}
                icon="external-link"
                color="standard"
                tid="vulnerability-map"
                onClick={_.partial(this.handleGoToAppMap, 'vulnerability')}
              />
            ) : null}
          </ToolGroup>
        </ToolBar>
        <AttributeList>
          {[
            {divider: showAppGroup},
            {title: intl('Common.General')},
            {
              tid: 'name',
              key: intl('Common.Name'),
              value: containerWorkload.name,
            },
            {
              tid: 'namespace',
              key: intl('Common.NamespaceOrProject'),
              value: containerWorkload.namespace,
            },
            {
              tid: 'enforcement-mode',
              key: intl('Common.Enforcement'),
              value: (
                <>
                  {this.enforcementModeView[containerWorkload.enforcement_mode]?.name}
                  <div className={classNameContent}>
                    {this.enforcementModeView[containerWorkload.enforcement_mode]?.desc}
                  </div>
                </>
              ),
            },
            {
              tid: 'visibility-level',
              key: intl('Common.Visibility'),
              value: visibilityValue,
            },
            {
              tid: 'policysync',
              key: intl('Workloads.PolicySync'),
              value: !policySync ? null : policySync === 'applied' ? (
                label
              ) : (
                <>
                  <Icon size="large" name={policySync} position="before" />
                  {label}
                </>
              ),
            },
            {
              tid: 'mode',
              key: intl('Firewall.Mode'),
              value: containerWorkload.firewall_coexistence ? intl('Common.Coexistence') : intl('Common.Exclusive'),
            },
            containerWorkload.firewall_coexistence
              ? {
                  tid: 'containerworkload-firewallcoexistence',
                  key: intl('Firewall.Coexistence.IllumioAsPrimaryFirewall'),
                  value: containerWorkload.firewall_coexistence.illumio_primary
                    ? intl('Common.Yes')
                    : intl('Common.No'),
                }
              : null,
            containerWorkload.container_cluster
              ? {
                  tid: 'containerworkload-containercluster',
                  key: intl('Menu.ContainerClusters', {multiple: false}),
                  value: containerClustersEnabled ? (
                    <Link
                      to="containerClusters.item.summary"
                      params={{id: hrefUtils.getId(containerWorkload.container_cluster.href)}}
                    >
                      {containerWorkload.container_cluster.name}
                    </Link>
                  ) : (
                    containerWorkload.container_cluster.name
                  ),
                }
              : null,
            {
              tid: 'created',
              key: intl('RuleSearch.CreatedAtUc'),
              value: intl.utils.format.dateAtTimeBy(createdAt),
            },
            ...(updatedAt && updatedAt !== createdAt
              ? [
                  {
                    tid: 'last-modified',
                    key: intl('Common.LastModifiedAt'),
                    value: intl.utils.format.dateAtTimeBy(updatedAt),
                  },
                ]
              : []),
            {divider: true},
            {title: intl('Common.Labels')},
            {
              tid: 'labelrole',
              key: getLabelTypeName('role'),
              value: this.renderLabel('role'),
            },
            {
              tid: 'labelapp',
              key: getLabelTypeName('app'),
              value: this.renderLabel('app'),
            },
            {
              tid: 'labelenv',
              key: getLabelTypeName('env'),
              value: this.renderLabel('env'),
            },
            {
              tid: 'labelloc',
              key: getLabelTypeName('loc'),
              value: this.renderLabel('loc'),
            },
            {divider: true},
            {title: intl('Common.Attributes')},
            {
              tid: 'interfaces',
              key: intl('Workloads.Interfaces'),
              value: Array.isArray(containerWorkload.interfaces)
                ? _.sortBy(containerWorkload.interfaces, ['name', 'address', 'default_gateway_address']).map(iface => {
                    const str = ipUtils.stringifyAddressObject({
                      ...iface,
                      from_ip: iface.cidr_block ? iface.address + '/' + iface.cidr_block : iface.address,
                    });

                    return <div key={str}>{str}</div>;
                  })
                : null,
            },
            containerWorkload.ven
              ? {
                  tid: 'workloads',
                  key: intl('Common.VEN'),
                  value: (
                    <Link to="workloads.vens.item" params={{id: hrefUtils.getId(containerWorkload.ven.href)}}>
                      {containerWorkload.ven.name || containerWorkload.ven.hostname}
                    </Link>
                  ),
                }
              : null,
          ]}
        </AttributeList>
      </>
    );
  }
}
