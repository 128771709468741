/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {useState, useEffect, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {areOptionsEqual, getFormattedDateTimeRange, formatCustomDateTimeValue} from './DateTimeRangePickerUtils';
import OptionSelector from 'components/Form/OptionSelector/OptionSelector';
import styles from './DateTimeRangePicker.css';

const custom = {customPicker: styles.customPicker};

DateTimeRangePicker.propTypes = {
  name: PropTypes.string,
  initialValue: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default function DateTimeRangePicker(props) {
  const {name, initialValue, options, onChange} = props;
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const selectedOption = useMemo(() => {
    return initialValue.key !== 'preset'
      ? options.find(option => option.key === 'custom')
      : options.find(option => areOptionsEqual(option, initialValue));
  }, [initialValue, options]);

  const customPickerName = useMemo(() => {
    return options.find(option => option.key === 'custom')?.value;
  }, [options]);

  const handleCancel = useCallback(() => {
    if (onChange) {
      onChange(selectedValue);
    }
  }, [onChange, selectedValue]);

  const customPicker = props.customPickers?.[customPickerName];
  const range = customPicker ? getFormattedDateTimeRange(selectedValue) : {};
  const customPickerProps = {
    ...(customPicker && {
      onCancel: handleCancel,
      customPickers: {
        [customPickerName]: {
          component: customPicker,
          props: {
            initFrom: range.from,
            initTo: range.to,
          },
          label: selectedValue.label,
        },
      },
    }),
  };

  // For custom value, it is saved when users click the Apply button.
  useEffect(() => {
    if (initialValue.key !== 'custom') {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = useCallback(
    (event, value) => {
      if (value && onChange) {
        onChange(formatCustomDateTimeValue(value));
      }
    },
    [onChange],
  );

  return (
    <OptionSelector
      name={name}
      theme={custom}
      onChange={handleChange}
      options={options}
      value={selectedOption}
      disableErrorMessage
      {...customPickerProps}
    />
  );
}
