/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {getLoadBalancerItemPage} from '../LoadBalancerItemState';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {ToolBar, ToolGroup, Button, AttributeList} from 'components';
import {UserName} from 'containers';
import SizeWatcher from 'react-size-watcher';
import stylesUtils from 'utils.css';

// Triggers responsiveness to Table (AttributeList) views.
const breakpoints = [{data: {isSmall: false}}, {maxWidth: 720, data: {isSmall: true}}];

const connectionStates = {
  pending: intl('Common.Pending'),
  successful: intl('LoadBalancers.Detail.Successful'),
  cannot_resolve: intl('LoadBalancers.Detail.CannotResolve'),
  cannot_connect: intl('LoadBalancers.Detail.CannotConnect'),
  bad_credentials: intl('LoadBalancers.Detail.BadCredentials'),
  bad_request: intl('LoadBalancers.Detail.BadRequest'),
  bad_certificate: intl('LoadBalancers.Detail.BadCertificate'),
  dup_device: intl('LoadBalancers.Detail.DupDevice'),
};

const labelArray = ['slbDevice', 'slbHost', 'slbPort', 'slbUsername', 'slbVtls', 'slbSoftware', 'slbConnect'];

@connect(getLoadBalancerItemPage)
export default class LoadBalancerSummary extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.handleEdit = this.handleEdit.bind(this);
    this.getDevices = this.getDevices.bind(this);
    this.getAllHints = this.getAllHints.bind(this);
    this.getAllDevices = this.getAllDevices.bind(this);
  }

  getAllHints() {
    const {loadBalancer} = this.props;

    const {
      config: {host: host2, port: port2, username: username2, check_certificate: checkCertificate2},
      status: {software_version: softwareVersion2, connection_state: connectionState2},
    } = loadBalancer.devices[1];

    return {
      slbDevice: {
        hint: <div className={stylesUtils.bold}>{intl('LoadBalancers.SecondDeviceProperties')}</div>,
      },
      slbHost: {
        hint: host2,
      },
      slbPort: {
        hint: port2,
      },
      slbUsername: {
        hint: username2,
      },
      slbVtls: {
        hint: checkCertificate2 === true ? 'Yes' : 'No',
      },
      slbSoftware: {
        hint: softwareVersion2 || intl('Common.NA'),
      },
      slbConnect: {
        hint: connectionStates[connectionState2],
      },
    };
  }

  getAllDevices(showDeviceHints = []) {
    const {loadBalancer} = this.props;

    const {
      config: {host: host1, port: port1, username: username1, check_certificate: checkCertificate1},
      status: {software_version: softwareVersion1, connection_state: connectionState1},
    } = loadBalancer.devices[0];

    const devices = {
      slbDevice: {
        tid: 'slb-devices',
        value: <div className={stylesUtils.bold}>{intl('LoadBalancers.FirstDeviceProperties')}</div>,
      },
      slbHost: {
        tid: 'slb-host',
        key: intl('LoadBalancers.Detail.DeviceManagement'),
        value: host1,
      },
      slbPort: {
        tid: 'slb-port',
        key: intl('Port.Port'),
        value: port1,
      },
      slbUsername: {
        tid: 'slb-username',
        key: intl('Common.Username'),
        value: username1,
      },
      slbVtls: {
        tid: 'slb-vtls',
        key: intl('LoadBalancers.Detail.TLSVerified'),
        value: checkCertificate1 === true ? 'Yes' : 'No',
      },
      slbSoftware: {
        tid: 'slb-software',
        key: intl('LoadBalancers.Detail.Software'),
        value: softwareVersion1 || intl('Common.NA'),
      },
      slbConnect: {
        tid: 'slb-connectionstate',
        key: intl('Common.ConnectionState'),
        value: connectionStates[connectionState1],
      },
    };

    const finalDevice = [];

    if (showDeviceHints.length) {
      const hints = this.getAllHints();

      showDeviceHints.forEach(item => {
        if (devices[item] && hints[item]) {
          devices[item].hint = hints[item].hint;
          finalDevice.push(devices[item]);
        }
      });
    } else {
      labelArray.forEach(item => {
        finalDevice.push(devices[item]);
      });
    }

    return finalDevice;
  }

  getDevices() {
    const {loadBalancer} = this.props;
    const numberOfDevices = loadBalancer.devices.length;
    let multipleDevices;

    if (numberOfDevices > 0) {
      multipleDevices = numberOfDevices === 2 ? this.getAllDevices(labelArray) : this.getAllDevices();
    }

    return multipleDevices;
  }

  getSmallDevices() {
    const {loadBalancer} = this.props;
    const numberOfDevices = loadBalancer.devices.length;
    let multipleDevices;
    const {
      config: {host: host1, port: port1, username: username1, check_certificate: checkCertificate1},
      status: {software_version: softwareVersion1, connection_state: connectionState1},
    } = loadBalancer.devices[0];

    const firstDevice = [
      {
        tid: 'slb-devices',
        value: <div className={stylesUtils.bold}>{intl('LoadBalancers.FirstDeviceProperties')}</div>,
      },
      {
        tid: 'slb-host1',
        key: intl('LoadBalancers.Detail.DeviceManagement'),
        value: host1,
      },
      {
        tid: 'slb-port1',
        key: intl('Port.Port'),
        value: port1,
      },
      {
        tid: 'slb-username1',
        key: intl('Common.Username'),
        value: username1,
      },
      {
        tid: 'slb-vtls1',
        key: intl('Common.VerifyTLS'),
        value: checkCertificate1 === true ? 'Yes' : 'No',
      },
      {
        tid: 'slb-software1',
        key: intl('LoadBalancers.Detail.Software'),
        value: softwareVersion1 || intl('Common.NA'),
      },
      {
        tid: 'slb-connectionstate1',
        key: intl('Common.ConnectionState'),
        value: connectionStates[connectionState1],
      },
    ];

    if (numberOfDevices > 0) {
      if (numberOfDevices === 2) {
        const {
          config: {host: host2, port: port2, username: username2, check_certificate: checkCertificate2},
          status: {software_version: softwareVersion2, connection_state: connectionState2},
        } = loadBalancer.devices[1];

        multipleDevices = firstDevice.concat([
          {
            tid: 'slb-device2',
            value: <div className={stylesUtils.bold}>{intl('LoadBalancers.SecondDeviceProperties')}</div>,
          },
          {
            tid: 'slb-host2',
            key: intl('LoadBalancers.Detail.DeviceManagement'),
            value: host2,
          },
          {
            tid: 'slb-port2',
            key: intl('Port.Port'),
            value: port2,
          },
          {
            tid: 'slb-username2',
            key: intl('Common.Username'),
            value: username2,
          },
          {
            tid: 'slb-vtls2',
            key: intl('LoadBalancers.Detail.TLSVerified'),
            value: checkCertificate2 === true ? 'Yes' : 'No',
          },
          {
            tid: 'slb-software2',
            key: intl('LoadBalancers.Detail.Software'),
            value: softwareVersion2 || intl('Common.NA'),
          },
          {
            tid: 'slb-connectionstate2',
            key: intl('Common.ConnectionState'),
            value: connectionStates[connectionState2],
          },
        ]);
      } else {
        multipleDevices = firstDevice;
      }
    }

    return multipleDevices;
  }

  handleEdit() {
    const {
      context: {navigate},
      props: {currentRouteParams},
    } = this;

    navigate({to: 'loadBalancers.item.edit', params: {id: currentRouteParams.id}});
  }

  render() {
    const {
      loadBalancer,
      loadBalancer: {created_at: createdAt, updated_at: updatedAt},
      createdBy,
      updatedBy,
      enableSLBControls,
      nfcHostname,
    } = this.props;
    const deviceNums = [
      {value: 1, label: intl('LoadBalancers.DeviceNumber', {count: 1})},
      {value: 2, label: intl('LoadBalancers.DeviceNumber', {count: 2})},
    ];

    return (
      <>
        <ToolBar>
          <ToolGroup>
            <Button
              textIsHideable
              text={intl('Common.Edit')}
              icon="edit"
              onClick={this.handleEdit}
              tid="edit"
              disabled={!enableSLBControls}
            />
          </ToolGroup>
        </ToolBar>
        <SizeWatcher breakpoints={breakpoints}>
          {({data}) => {
            const {isSmall} = data;

            const deviceDetail = [
              {divider: true},
              {title: intl('Common.General')},
              {
                tid: 'slb-name',
                key: intl('Common.Name'),
                value: loadBalancer.name,
              },
              {
                tid: 'slb-desc',
                key: intl('Common.Description'),
                value: loadBalancer.description,
              },
              {
                tid: 'slb-created',
                key: intl('Common.Created'),
                value: UserName.dateAtTimeBy(createdAt, createdBy, 'full_name'),
              },
              ...((updatedAt && updatedAt !== createdAt) || (updatedBy && updatedBy.href !== createdBy.href)
                ? [
                    {
                      tid: 'slb-modified',
                      key: intl('Common.LastModified'),
                      value: UserName.dateAtTimeBy(updatedAt, updatedBy, 'full_name'),
                    },
                  ]
                : []),
              {
                tid: 'slb-nfc',
                key: intl('Switches.NENHostname'),
                value: nfcHostname,
              },
              {divider: true},
              {title: intl('Common.Attributes')},
              {
                tid: 'slb-devicetype',
                key: intl('Common.DeviceType'),
                value: loadBalancer.device_type,
              },
              {
                tid: 'slb-numberofdevices',
                key: intl('LoadBalancers.Detail.NumberOfDevices'),
                value: deviceNums[loadBalancer.devices.length - 1].label,
              },
              {divider: true},
              {title: intl('LoadBalancers.Properties')},
            ];

            deviceDetail.push(...(isSmall ? this.getSmallDevices() : this.getDevices()));

            return (
              <AttributeList valueColumnWidth="minmax(auto, 400px)" hintColumnWidth="minmax(auto, 400px)">
                {deviceDetail}
              </AttributeList>
            );
          }}
        </SizeWatcher>
      </>
    );
  }
}
