/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import apiSaga from 'api/apiSaga';
import {call, put, all} from 'redux-saga/effects';
import {cachedResponses} from 'api/apiCache';

export function* createUnmanagedWorkload(payload) {
  return yield call(apiSaga, 'workloads.create', {
    data: payload,
    *onDone() {
      // After load balancer is created invalidate load balancer list cache
      cachedResponses.removeByMethodName('workloads.get_collection');
    },
  });
}

export function* updateWorkload(id, data) {
  return yield call(apiSaga, 'workload.update', {
    params: {workload_id: id},
    data,
    *onDone() {
      cachedResponses.removeByMethodName('workloads.get_instance');
      cachedResponses.removeByMethodName('workloads.get_collection');
      cachedResponses.removeByMethodName('sec_policy.policy_view');
    },
  });
}

export function* updateWorkloadServiceReports(id, reports) {
  return yield call(apiSaga, 'workload.service_reports', {
    params: {workload_id: id},
    data: reports,
    *onDone() {
      cachedResponses.removeByMethodName('workloads.get_instance');
      cachedResponses.removeByMethodName('workloads.get_collection');
    },
  });
}

export function* fetchInitialValues({params}) {
  let results = [];

  if (params.address || params.dnsName) {
    yield all(
      _.chunk([params.address], 100).map(function* (dataChunk) {
        try {
          yield call(apiSaga, 'dns_reverse_lookup.create', {
            data: {ips: dataChunk},
            *onDone({data}) {
              results = results.concat(data);
            },
          });
        } catch (error) {
          error.errorData = _.get(error, 'data[0]');

          throw error;
        }
      }),
    );
  }

  yield put({type: 'DNS_REVERSE_LOOKUP', data: results});
}
