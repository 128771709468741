/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import listReducers from './List/IPListListState';
import instanceReducers from './Item/IPListItemState';

export default {
  iplist: combineReducers({
    ...listReducers,
    ...instanceReducers,
  }),
};
