/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Notifications, ToolBar, ToolGroup, Grid, Drawer, Banner} from 'components';
import fileSaver from 'file-saver';
import {getWorkloadRulesPage} from './WorkloadRulesState';
import {fetchWorkloadRules} from './WorkloadRulesSaga';

@connect(getWorkloadRulesPage)
export default class WorkloadRules extends Component {
  static prefetch = fetchWorkloadRules;

  constructor(props) {
    super(props);

    this.handleDownload = this.handleDownload.bind(this);
  }

  handleDownload() {
    const {ruleView, workload} = this.props;

    const fileName = `${workload.hostname}Rules.txt`;
    const blob = new Blob([JSON.stringify(ruleView)], {type: 'text/plain;charset=utf-8'});

    fileSaver.saveAs(blob, fileName, true);
  }

  render() {
    const {inboundGrid, outboundGrid, customIpGrid, forwardGrid, edgeEnabled, adminGrid, ruleView} = this.props;

    if (ruleView.workload_instructions[0].enforcement_mode === 'idle') {
      return (
        <div style={{margin: 'auto'}}>
          <Banner type="plain">{intl('Workloads.RulesIdle')}</Banner>
        </div>
      );
    }

    return (
      <>
        <Notifications>
          {[
            {
              type: 'instruction',
              title: intl('Policy.ActiveVersion'),
              message: edgeEnabled ? intl('Policy.ViewingActiveVersionEdge') : intl('Policy.ViewingActiveVersion'),
            },
          ]}
        </Notifications>
        <ToolBar>
          <ToolGroup>
            <Button
              textIsHideable
              icon="export"
              text={intl('Common.ExportJSON')}
              autoFocus
              onClick={this.handleDownload}
              tid="export"
            />
          </ToolGroup>
        </ToolBar>
        <Drawer text={intl('Rulesets.Rules.Inbound')}>
          <Grid
            grid={inboundGrid}
            tid="inbound"
            inlineSize
            offset="var(--0px)"
            emptyMessage={intl('Rulesets.Rules.NoInboundRulesWorkload')}
          />
        </Drawer>
        {edgeEnabled && (
          <Drawer text={intl('Rulesets.Rules.InboundAdminAccessRules')}>
            <Grid
              grid={adminGrid}
              tid="admin"
              inlineSize
              offset="var(--0px)"
              emptyMessage={intl('Rulesets.Rules.NoInboundRulesWorkload')}
            />
          </Drawer>
        )}
        <Drawer text={intl('Rulesets.Rules.Outbound')}>
          <Grid
            grid={outboundGrid}
            tid="outbound"
            inlineSize
            offset="var(--0px)"
            emptyMessage={intl('Rulesets.Rules.NoOutboundRulesWorkload')}
          />
        </Drawer>
        {!edgeEnabled && (
          <>
            <Drawer text={intl('Workloads.Summary.ForwardRules')}>
              <Grid
                grid={forwardGrid}
                tid="forward"
                inlineSize
                offset="var(--0px)"
                emptyMessage={intl('Rulesets.Rules.NoForwardRulesWorkload')}
              />
            </Drawer>
            <Drawer text={intl('Help.Title.CustomiptablesRules')}>
              <Grid
                grid={customIpGrid}
                tid="custom"
                inlineSize
                offset="var(--0px)"
                emptyMessage={intl('Rulesets.Rules.NoCustomRulesWorkload')}
              />
            </Drawer>
          </>
        )}
      </>
    );
  }
}
