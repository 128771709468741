/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

const steps = [
  {
    name: 'ONBOARDING_START',
    status: null,
  },
  {
    name: 'ONBOARDING_CLICK_WORKLOADS_TILE',
    status: null,
  },
  {
    name: 'ONBOARDING_WORKLOADS',
    status: null,
    steps: [
      {
        name: 'ONBOARDING_COPY_PAIRING_SCRIPT',
        status: null,
      },
      {
        name: 'ONBOARDING_ADD_LABELS',
        status: null,
      },
      {
        name: 'ONBOARDING_CLICK_CSV_IMPORT',
        status: null,
      },
      {
        name: 'ONBOARDING_CSV_IMPORT',
        status: null,
      },
    ],
  },
  {
    name: 'ONBOARDING_NAVIGATE_TO_RULESETS',
    status: null,
    steps: [
      {
        name: 'ONBOARDING_CREATE_RULESETS',
        status: null,
      },
    ],
  },
  {
    name: 'ONBOARDING_CONCLUSION',
    status: null,
  },
];

export const getOnboardingGuideStep = step => {
  switch (step) {
    case 'ONBOARDING_START':
    case 'ONBOARDING_CLICK_WORKLOADS_TILE':
      return 0;
    case 'ONBOARDING_WORKLOADS':
    case 'ONBOARDING_COPY_PAIRING_SCRIPT':
      return 1;
    case 'ONBOARDING_ADD_LABELS':
    case 'ONBOARDING_CLICK_CSV_IMPORT':
    case 'ONBOARDING_CSV_IMPORT':
      return 2;
    case 'ONBOARDING_NAVIGATE_TO_RULESETS':
    case 'ONBOARDING_CREATE_RULESETS':
      return 3;
    case 'ONBOARDING_CONCLUSION':
      return 4;
    default:
      return 0;
  }
};

/**
 * A tree that lays out the paths to steps bi-directionally
 */
export const stepsToIndexMap = steps.reduce((result, current, index) => {
  result.set(current.name, {
    location: [index],
    previous: steps[index - 1]?.steps ? steps[index - 1].steps.at(-1)?.name : steps[index - 1]?.name,
    next: current.steps?.[0]?.name ?? steps[index + 1]?.name,
  });

  if (current.steps) {
    current.steps.forEach((step, subIndex) => {
      result.set(step.name, {
        location: [index, subIndex],
        previous: current.steps[subIndex - 1]?.name ?? steps[index]?.name,
        next: current.steps[subIndex + 1]?.name ?? steps[index + 1]?.name,
      });
    });
  }

  return result;
}, new Map());

export const flattenSteps = allSteps =>
  allSteps.reduce((flattenedSteps, current) => {
    const {steps, ...rest} = current;

    flattenedSteps.push(rest);

    if (steps) {
      flattenedSteps = flattenedSteps.concat(steps);
    }

    return flattenedSteps;
  }, []);

export default {
  steps, // Onboarding steps script
  currentStep: 'ONBOARDING_START', // Current step
  lastStep: null, // Last completed step
  hideOnboarding: false, // Set when a user wants to exit onboarding / not see it
  hasOnboarded: false, // If we want to easily track onboarded users
};
