/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {getWorkloadName} from 'containers/Workload/WorkloadUtils';
import {getContainerWorkloadStatusIntl} from '../ContainerWorkloadUtils';
import {noScopeLabels} from 'containers/Selectors/SelectorUtils';
import {enforcementModeView, visibilityLevelView} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {createSelector} from 'reselect';

export const resourceType = 'container_workloads';
const objectMap = {
  container_workloads: {type: 'container_workloads'},
  container_clusters: {type: 'container_clusters'},
  roleLabels: {type: 'labels', key: 'role'},
  appLabels: {type: 'labels', key: 'app'},
  envLabels: {type: 'labels', key: 'env'},
  locLabels: {type: 'labels', key: 'loc'},
};

export const getSelectorSettings = createSelector([], () => ({
  objectMap,
  facetMap: {
    name: {value: intl('Common.Name'), object: objectMap.container_workloads, isPartial: true},
    namespace: {value: intl('Common.NamespaceOrProject'), object: objectMap.container_workloads, isPartial: true},
    ip_address: {value: intl('Common.IPAddress'), object: objectMap.container_workloads, isPartial: true},
    // The following configs are for workloads, they converted in Selectors, and they are converted again in ContainerWorkloadListSaga
    workload: {value: intl('Common.Workload'), object: objectMap.container_workloads},
    hostname: {value: intl('Common.Hostname'), object: objectMap.container_workloads},
    hostip: {value: intl('ContainerWorkloads.HostIpAddress'), object: objectMap.container_workloads},
  },
  scopeMap: {
    role: {value: intl('Labels.RoleLabels'), object: objectMap.roleLabels, scope: true, statics: noScopeLabels().role},
    app: {
      value: intl('Labels.ApplicationLabels'),
      object: objectMap.appLabels,
      scope: true,
      statics: noScopeLabels().app,
    },
    env: {
      value: intl('Labels.EnvironmentLabels'),
      object: objectMap.envLabels,
      scope: true,
      statics: noScopeLabels().env,
    },
    loc: {value: intl('Labels.LocationLabels'), object: objectMap.locLabels, scope: true, statics: noScopeLabels().loc},
  },
  autocompleteMap: {
    container_clusters: {value: intl('VEN.ContainerCluster'), object: objectMap.container_clusters},
  },
  staticMap: {
    enforcement_mode: intl('Common.Enforcement'),
  },
  filterMap: {
    name: {value: intl('Common.Name'), object: objectMap.container_workloads, isPartial: true},
    namespace: {value: intl('Common.NamespaceOrProject'), object: objectMap.container_workloads, isPartial: true},
    ip_address: {value: intl('Common.IPAddress'), object: objectMap.container_workloads, isPartial: true},
    // The following configs are for workloads, they converted in Selectors, and they are converted again in ContainerWorkloadListSaga
    workload: {value: intl('Common.Workload'), object: objectMap.container_workloads},
    hostname: {value: intl('Common.Hostname'), object: objectMap.container_workloads},
    hostip: {value: intl('ContainerWorkloads.HostIpAddress'), object: objectMap.container_workloads},
    role: {value: intl('Labels.RoleLabels'), object: objectMap.roleLabels, scope: true, statics: noScopeLabels().role},
    app: {
      value: intl('Labels.ApplicationLabels'),
      object: objectMap.appLabels,
      scope: true,
      statics: noScopeLabels().app,
    },
    env: {
      value: intl('Labels.EnvironmentLabels'),
      object: objectMap.envLabels,
      scope: true,
      statics: noScopeLabels().env,
    },
    loc: {value: intl('Labels.LocationLabels'), object: objectMap.locLabels, scope: true, statics: noScopeLabels().loc},
    enforcement_mode: intl('Common.Enforcement'),
  },
  staticValues: {
    enforcement_mode: {
      [intl('Workloads.Full')]: 'full',
      [intl('Workloads.EnforcementBoundaries')]: 'selective',
      [intl('Common.VisibilityOnly')]: 'visibility_only',
      [intl('Common.Idle')]: 'idle',
    },
  },
}));

/**
[{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: node | Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
*/
export const gridSettings = createSelector([], () => ({
  id: 'cclusterworkloadlist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    sync: {
      header: intl('Workloads.PolicySync'),
      value: 'security_policy_sync_state',
      format: ({value}) => <span data-tid="elem-text">{getContainerWorkloadStatusIntl(value)}</span>,
    },
    enforcementMode: {
      header: intl('Common.Enforcement'),
      value: ({row}) => enforcementModeView()[row.data.enforcement_mode].name,
    },
    visibilityLevel: {
      header: intl('Common.Visibility'),
      value: ({row}) =>
        row.data.enforcement_mode === 'idle'
          ? visibilityLevelView()[row.data.enforcement_mode].name
          : visibilityLevelView()[row.data.visibility_level].name,
    },
    namespace: {
      header: intl('Common.NamespaceOrProject'),
      value: 'namespace',
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: ({row}) => getWorkloadName(row.data),
    },
    role: {
      header: intl('Common.Role'),
      ...GridUtils.clickableLabelColumn,
    },
    app: {
      header: intl('Common.Application'),
      ...GridUtils.clickableLabelColumn,
    },
    env: {
      header: intl('Common.Environment'),
      ...GridUtils.clickableLabelColumn,
    },
    loc: {
      header: intl('Common.Location'),
      ...GridUtils.clickableLabelColumn,
    },
    last: {
      isDate: true,
      header: intl('Workloads.LastAppliedPolicy'),
      value: ({row}) => row.data.security_policy_applied_at,
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to format function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to format function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to format function
    data: ?object,
  }];
   */
  templates: [
    [
      {columns: ['sync'], size: 'minmax(150px, auto)'},
      {columns: ['namespace'], size: 'minmax(150px, auto)'},
      {columns: ['name'], size: 'minmax(150px, auto)'},
      {columns: ['enforcementMode'], size: 'minmax(150px, auto)'},
      {columns: ['visibilityLevel'], size: 'minmax(150px, auto)'},
      {columns: ['role'], size: 'minmax(80px, auto)'},
      {columns: ['app'], size: 'minmax(100px, auto)'},
      {columns: ['env'], size: 'minmax(120px, auto)'},
      {columns: ['loc'], size: 'minmax(90px, auto)'},
      {columns: ['last'], size: 'minmax(170px, auto)'},
    ],
    {
      maxWidth: 1366,
      template() {
        return [
          {columns: ['sync'], size: 'minmax(150px, auto)'},
          {columns: ['namespace'], size: 'minmax(150px, auto)'},
          {columns: ['name'], size: 'minmax(150px, auto)'},
          {columns: ['enforcementMode'], size: 'minmax(150px, auto)'},
          {columns: ['visibilityLevel'], size: 'minmax(150px, auto)'},
          {columns: ['role'], size: 'minmax(80px, auto)'},
          {columns: ['app'], size: 'minmax(100px, auto)'},
          {columns: ['env'], size: 'minmax(120px, auto)'},
          {columns: ['loc'], size: 'minmax(90px, auto)'},
          {columns: ['last'], size: 'minmax(150px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1290,
      template() {
        return [
          {columns: ['sync'], size: 'minmax(200px, auto)'},
          {columns: ['namespace', 'name'], size: 'minmax(150px, auto)'},
          {columns: ['enforcementMode', 'visibilityLevel'], size: 'minmax(150px, auto)'},
          {columns: ['role', 'app'], size: 'minmax(120px, auto)'},
          {columns: ['env', 'loc'], size: 'minmax(120px, auto)'},
          {columns: ['last'], size: 'minmax(150px, auto)'},
        ];
      },
    },
    {
      maxWidth: 900,
      template() {
        return [
          {columns: ['sync'], size: 'minmax(100px, auto)'},
          {columns: ['namespace', 'name', 'last'], size: 'minmax(150px, auto)'},
          {columns: ['enforcementMode', 'visibilityLevel'], size: 'minmax(120px, auto)'},
          {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(120px, auto)'},
        ];
      },
    },
    {
      maxWidth: 500,
      template() {
        return [
          {columns: ['namespace', 'name', 'sync', 'last'], size: 'minmax(150px, auto)'},
          {columns: ['enforcementMode', 'visibilityLevel', 'role', 'app', 'env', 'loc'], size: 'minmax(120px, auto)'},
        ];
      },
    },
  ],
}));
