/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {getId} from 'utils/href';

function getComparableHref(href) {
  // make the href version agnostic for the purposes of diffing
  if (!href) {
    return null;
  }

  const version = 'version';

  // Use '\b' as boundary to match 'active' and 'draft' only
  // e.g. href = /orgs/2/sec_policy/[active, draft]/ip_lists/165
  const regex = /\b(draft|active)\b/;

  return (regex.test(href) && href.replace(regex, version)) || href;
}

//convert API datastructure to UI ready datastructure.
export const mapSecureAPIDataToUIFriendlyVersion = gateway => {
  if (!gateway) {
    return;
  }

  const secureNetworks = [];
  const resultGateway = _.clone(gateway);

  gateway.secure_networks.forEach(subnet => {
    subnet.actor_sets.forEach(actorSet => {
      const labelsObject = {};

      if (actorSet.actors !== 'ams') {
        actorSet.actors.forEach(actor => {
          if (actor.label) {
            labelsObject[actor.label.key] = actor.label;
          }
        });
      }

      if (!labelsObject.app) {
        labelsObject.app = {key: 'app', type: 'all', value: intl('Common.AllApplications')};
      }

      if (!labelsObject.env) {
        labelsObject.env = {key: 'env', type: 'all', value: intl('Common.AllEnvironments')};
      }

      if (!labelsObject.loc) {
        labelsObject.loc = {key: 'loc', type: 'all', value: intl('Common.AllLocations')};
      }

      if (!labelsObject.role) {
        labelsObject.role = {key: 'role', type: 'all', value: intl('Common.AllRoles')};
      }

      secureNetworks.push({
        id: secureNetworks.length + 1,
        ipList: subnet.subnets,
        labels: labelsObject,
        update_type: actorSet.update_type,
      });
    });
  });

  resultGateway.addresses = gateway.addresses[0];
  resultGateway.idle_timeout_min = String(gateway.idle_timeout_min);
  resultGateway.secure_networks = secureNetworks;

  return resultGateway;
};

//used to compare equality of a subnet
export const areSubnetEqual = (subnet, subnetCompare) => {
  const subnetRole = subnet.labels.role ? getComparableHref(subnet.labels.role.href) : null;
  const subnetApp = subnet.labels.app ? getComparableHref(subnet.labels.app.href) : null;
  const subnetEnv = subnet.labels.env ? getComparableHref(subnet.labels.env.href) : null;
  const subnetLoc = subnet.labels.loc ? getComparableHref(subnet.labels.loc.href) : null;
  const subnetCompareRole = subnetCompare.labels.role ? getComparableHref(subnetCompare.labels.role.href) : null;
  const subnetCompareApp = subnetCompare.labels.app ? getComparableHref(subnetCompare.labels.app.href) : null;
  const subnetCompareEnv = subnetCompare.labels.env ? getComparableHref(subnetCompare.labels.env.href) : null;
  const subnetCompareLoc = subnetCompare.labels.loc ? getComparableHref(subnetCompare.labels.loc.href) : null;

  return (
    getComparableHref(subnet.ipList.href) === getComparableHref(subnetCompare.ipList.href) &&
    subnetRole === subnetCompareRole &&
    subnetApp === subnetCompareApp &&
    subnetEnv === subnetCompareEnv &&
    subnetLoc === subnetCompareLoc
  );
};

//used to diff current version against previous version, or current edits against draft
export const diffGateways = (gateway, prevGateway) => {
  if (!gateway) {
    return null;
  }

  const diff = {};

  const properties = ['name', 'description', 'addresses', 'psk', 'ca_id', 'idle_timeout_min', 'local_id', 'remote_id'];

  properties.forEach(property => {
    diff[property] = {value: gateway[property]};
  });

  const secureNetworks = _.clone(gateway.secure_networks);
  const prevSecureNetworks = prevGateway ? _.clone(prevGateway.secure_networks) : [];

  diff.secure_networks = [];

  let secureNetwork = secureNetworks.shift();

  while (secureNetwork !== undefined) {
    const inverseMatchIndex = _.findIndex(secureNetworks, compareSecureNetwork => {
      if (
        (secureNetwork.update_type === 'delete' && compareSecureNetwork.update_type === 'create') ||
        (secureNetwork.update_type === 'create' && compareSecureNetwork.update_type === 'delete')
      ) {
        return areSubnetEqual(secureNetwork, compareSecureNetwork);
      }

      return false;
    });
    const prevMatchIndex = _.findIndex(prevSecureNetworks, prevSecureNetwork =>
      areSubnetEqual(secureNetwork, prevSecureNetwork),
    );

    if (prevMatchIndex !== -1) {
      prevSecureNetworks.splice(prevMatchIndex, 1);
    }

    if (inverseMatchIndex !== -1) {
      diff.secure_networks.push({
        ipList: secureNetwork.ipList,
        labels: secureNetwork.labels,
        update_type: null,
      });
      secureNetworks.splice(inverseMatchIndex, 1);
    } else {
      let updateType;

      if (prevGateway) {
        updateType = prevMatchIndex !== -1 ? secureNetwork.update_type : 'create';
      }

      diff.secure_networks.push({
        ipList: secureNetwork.ipList,
        labels: secureNetwork.labels,
        update_type: updateType,
      });
    }

    secureNetwork = secureNetworks.shift();
  }

  if (prevGateway) {
    prevSecureNetworks.forEach(secureNetwork => {
      diff.secure_networks.push({
        ipList: secureNetwork.ipList,
        labels: secureNetwork.labels,
        update_type: 'delete',
      });
    });

    if (prevGateway) {
      properties.forEach(property => {
        if (gateway[property] !== prevGateway[property]) {
          diff[property].prevValue = prevGateway[property];

          if (prevGateway[property]) {
            diff[property].updateType = gateway[property] ? 'update' : 'remove';
          } else {
            diff[property].updateType = 'create';
          }
        }
      });
    } else {
      properties.forEach(property => {
        if (gateway[property]) {
          diff[property].updateType = 'create';
        }
      });
    }
  }

  return diff;
};

export const formatEditedRows = (values, rows, oldKey) => {
  let modifiedRows = [...rows];
  const newRow = {
    key: `${getId(values.ip_lists.href)}${getId(values.labels.role.href)}${getId(values.labels.app.href)}${getId(
      values.labels.loc.href,
    )}${getId(values.labels.env.href)}`,
    selectable: true,
    removable: true,
    action: true,
    data: {
      ipList: values.ip_lists,
      labels: {
        role: values.labels.role,
        app: values.labels.app,
        loc: values.labels.loc,
        env: values.labels.env,
      },
      update_type: 'create',
    },
  };

  modifiedRows = modifiedRows.map(row => {
    if (oldKey === row.key) {
      return newRow;
    }

    return row;
  });

  return modifiedRows;
};

export const formatNewRow = ({values} = {}) => {
  const {
    ip_lists,
    labels: {role, app, loc, env},
  } = values;

  return {
    key: `${getId(ip_lists.href)}${getId(role.href)}${getId(app.href)}${getId(loc.href)}${getId(env.href)}`,
    selectable: true,
    removable: true,
    action: true,
    data: {
      labels: {
        role,
        app,
        loc,
        env,
      },
      ipList: values.ip_lists,
      update_type: 'create',
    },
  };
};

//convert UI datastructure to API ready datastructure
export const getWriteableData = (gateway, secureNetworks) => {
  let data = _.pick(gateway, [
    'name',
    'description',
    'addresses',
    'psk',
    'ca_id',
    'idle_timeout_min',
    'local_id',
    'remote_id',
  ]);

  if (gateway.authMode === 'certificate') {
    data.ca_id = data.ca_id.replace(/\s/g, '');
    data = _.omit(data, 'psk');
  } else {
    data.psk = data.psk.replace(/\s/g, '');
    data = _.omit(data, ['ca_id', 'local_id', 'remote_id']);
  }

  if (data.psk === '') {
    data = _.omit(data, 'psk');
  }

  data.addresses = [data.addresses];

  if (data.idle_timeout_min) {
    data.idle_timeout_min = parseInt(data.idle_timeout_min, 10);
  }

  const ipListTable = {};

  secureNetworks.forEach(row => {
    if (row.update_type === 'delete') {
      return;
    }

    if (!ipListTable[row.ipList.href]) {
      ipListTable[row.ipList.href] = {subnets: {href: row.ipList.href}, actor_sets: []};
    }

    const isLabelAllRAEL = Object.values(row.labels).every(({href}) => href === 'all');

    if (isLabelAllRAEL) {
      ipListTable[row.ipList.href].actor_sets.push([{actors: 'ams'}]);
    } else if (Object.keys(row.labels).length) {
      const labels = Object.values(row.labels).reduce((result, {href, type}) => {
        if (href === 'all' || type === 'all') {
          return result;
        }

        result.push({label: {href}});

        return result;
      }, []);

      ipListTable[row.ipList.href].actor_sets.push(labels);
    }
  });

  data.secure_networks = _.map(ipListTable, row => row);

  return data;
};
