/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {call, select} from 'redux-saga/effects';
import {getContainerClusterDetail} from '../ContainerClusterDetailState';
import {fetchWorkloadList} from 'containers/Workload/List/WorkloadListSaga';

export function* fetchContainerClusterWorkloads(route, refetch = false) {
  const detail = yield select(getContainerClusterDetail);

  if (detail && detail.href) {
    yield call(fetchWorkloadList, route, refetch, {containerCluster: detail.href, ignoreScope: true});
  }
}
