/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {Notifications, Link} from 'components';
import {webStorageUtils} from 'utils';
import styles from './BrowserWarning.css';

const storageCloseKey = `${browser.code}WarningClosed`;

export default class BrowserWarning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: !browser.isSupported && !webStorageUtils.getItem(storageCloseKey),
    };

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    webStorageUtils.setItem(storageCloseKey, true);

    this.setState({show: false});
  }

  render() {
    if (!this.state.show) {
      return null;
    }

    return (
      <Notifications shrink sidebar theme={styles}>
        {[
          {
            type: 'warning',
            title: intl('Common.Warning'),
            handleClose: this.handleClose,
            message: (
              <Link href="/static/browsersupport.html" target="_blank">
                {intl('Common.BrowserGrayWarning')}
              </Link>
            ),
          },
        ]}
      </Notifications>
    );
  }
}
