/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {combineReducers} from 'redux';
import {getAppGroupLabelTypes} from 'containers/App/AppState';
import {
  getParsedEndpoint,
  getParsedService,
  getConnectionStateName,
  getPolicyDecisionName,
  getServiceKey,
  getLinkKey,
} from './ExplorerUtils';

export default {
  explorer: combineReducers({
    links(state = [], action) {
      switch (action.type) {
        case 'EXPLORER_GET_LINKS':
          return action.data;
        default:
          return state;
      }
    },

    rules(state = [], action) {
      switch (action.type) {
        case 'RULE_COVERAGE_GET':
          return [...state, {rules: action.data, linksForRules: action.links}];
        case 'EXPLORER_GET_LINKS':
          return [];
        default:
          return state;
      }
    },

    excludedIpRange(state = null, action) {
      switch (action.type) {
        case 'EXPLORER_DEFAULT_EXCLUDED_IPRANGE':
          return action.data;
        default:
          return state;
      }
    },

    excludedService(state = null, action) {
      switch (action.type) {
        case 'EXPLORER_DEFAULT_EXCLUDED_SERVICE':
          return action.data;
        default:
          return state;
      }
    },

    error(state = false, action) {
      switch (action.type) {
        case 'EXPLORER_ERROR':
          return action.data;
        case 'EXPLORER_GET_LINKS':
          return false;
        default:
          return state;
      }
    },
  }),
};

export const getLinks = state => state.explorer.links;
export const getRules = state => state.explorer.rules;
export const getExcludedIpRange = state => state.explorer.excludedIpRange;
export const getExcludedService = state => state.explorer.excludedService;
export const getError = state => state.explorer.error;

export const getParsedLinks = createSelector(getLinks, getAppGroupLabelTypes, (links, appGroupTypes) =>
  (links || []).map((link, index) => {
    const parsedLink = {
      index,
      source: getParsedEndpoint(link.src, appGroupTypes),
      target: getParsedEndpoint(link.dst, appGroupTypes, link.transmission),
      service: getParsedService(link),
      direction: link.flow_direction,
      flows: link.num_connections,
      policy: getPolicyDecisionName(link.policy_decision),
      state: getConnectionStateName(link.state),
      firstDetected: link.timestamp_range.first_detected,
      lastDetected: link.timestamp_range.last_detected,
      network: link.network,
    };

    parsedLink.linkKey = getLinkKey(parsedLink);
    parsedLink.serviceKey = getServiceKey(parsedLink);

    return parsedLink;
  }),
);

export const getLinksWithRules = createSelector(getParsedLinks, getRules, (parsedLinks, rulesets) => {
  if (!rulesets.length) {
    return parsedLinks;
  }

  const linksWithRules = [...parsedLinks];

  for (const {rules, linksForRules} of rulesets) {
    for (const [linkIndex, link] of linksForRules.entries()) {
      for (const [serviceIndex] of link.query.services.entries()) {
        link.indicies[serviceIndex].forEach(index => {
          const linkWithRules = linksWithRules[index];

          if (!linkWithRules.rules) {
            linksWithRules[index].rules = new Set();
            linksWithRules[index].denyRules = new Set();
          }

          if (linkWithRules && rules.edges[linkIndex][serviceIndex]) {
            rules.edges[linkIndex][serviceIndex].forEach(rule => linkWithRules.rules.add(rules.rules[rule]));
          }

          if (linkWithRules && rules.deny_edges && rules.deny_edges[linkIndex][serviceIndex]) {
            rules.deny_edges[linkIndex][serviceIndex].forEach(rule =>
              linkWithRules.denyRules.add(rules.deny_rules[rule]),
            );
          }
        });
      }
    }
  }

  return linksWithRules;
});
