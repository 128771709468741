/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {call} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {cachedResponses} from 'api/apiCache';
import {targets} from './SubmitButton';

export function* submitAction({target, obj}) {
  let data = {};
  let error = null;

  try {
    yield call(apiSaga, targets[target].actionId, {
      ...obj,
      *onDone(response) {
        data = response.data;
      },
    });
    targets[target].cleanCaches.forEach(name => cachedResponses.removeByMethodName(name));
  } catch (err) {
    const errData = _.get(err, 'data[0]');

    error = (errData && errData.message) || err.message;
  }

  return {data, error};
}

export function* submitActionModalMachine({target, obj}) {
  try {
    yield call(apiSaga, targets[target].actionId, {...obj});
    targets[target].cleanCaches.forEach(name => cachedResponses.removeByMethodName(name));
  } catch (err) {
    err.href = obj;
    throw err;
  }
}
