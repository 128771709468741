/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {ToolBar, ToolGroup, Grid, ButtonRefresh} from 'components';
import {getVirtualServiceWorkloadPage} from './WorkloadVirtualServicesState';
import {fetchVirtualServiceWorkloadsBind} from './WorkloadVirtualServicesSaga';

@connect(getVirtualServiceWorkloadPage)
export default class WorkloadVirtualServices extends Component {
  static prefetch = fetchVirtualServiceWorkloadsBind;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh() {
    return this.context.fetcher.fork(fetchVirtualServiceWorkloadsBind.refetch);
  }

  render() {
    const {virtualservicesgrid} = this.props;

    return (
      <>
        <ToolBar justify="flex-end">
          <ToolGroup>
            <ButtonRefresh color="standard" onRefresh={this.handleRefresh} />
          </ToolGroup>
        </ToolBar>
        <Grid grid={virtualservicesgrid} emptyMessage={intl('Common.NoData')} />
      </>
    );
  }
}
