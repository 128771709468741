/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call, put} from 'redux-saga/effects';
import {RedirectError} from 'errors';

export function* fetchRunningContainers({id, force = false}) {
  return yield call(apiSaga, 'container_workload.running_containers', {
    params: {container_workload_id: id},
    cache: !force,
    *onDone({data}) {
      yield put({type: 'CONTAINERWORKLOAD_RUNNING_CONTAINERS', data});

      return data;
    },
  });
}

export function* fetchContainerWorkloadContainer({params: {id}}, refetch = false) {
  try {
    yield call(fetchRunningContainers, {id, force: refetch});
  } catch {
    throw new RedirectError({to: 'workloads.containers.list', proceedFetching: true, thisFetchIsDone: true});
  }
}
