/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createSelector} from 'reselect';
import {Badge, Button, MenuItem, MenuDelimiter, Pill, Link} from 'components';
import {getBadgeTypeByUpdateType, getBadgeLabelByUpdateType} from 'utils/formats';
import styles from './GroupDashboard.css';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import {clickableColumn} from 'components/Grid/GridUtils';

export const discoveredWLGridId = 'discoveredWlList';
export const getSelectorSettings = createSelector([], () => {
  const facetMap = {
    name: intl('Common.Name'),
  };

  const staticMap = {
    provision: intl('Provision.Status'),
  };

  const filterMap = {...facetMap, ...staticMap};

  const staticValues = {
    provision: {
      [intl('Provision.PendingAddition')]: 'create',
      [intl('Provision.PendingModification')]: 'update',
    },
  };

  return {facetMap, staticMap, filterMap, staticValues};
});

export const gridSettings = createSelector([], () => ({
  id: 'grouplist',
  capacities: [25, 50, 100],
  capacity: 50,
  sort: 'name',
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    status: {
      header: intl('Provision.Status'),
      value: ({row}) => getBadgeLabelByUpdateType(row.data.ruleset.update_type),
      format: ({row, value}) =>
        value ? (
          <Badge type={getBadgeTypeByUpdateType(row.data.ruleset.update_type)} theme={styles} themePrefix="status-">
            {value}
          </Badge>
        ) : null,
    },
    name: clickableColumn({
      header: intl('Common.Group'),
      value: 'name',
      format: ({row: {data: group}, clickableRef}) => (
        <Pill.Group value={group} ref={clickableRef} tooltip={intl('Tooltip.ViewGroup')} />
      ),
    }),
    workloads: clickableColumn({
      header: intl('Common.Workloads'),
      value: 'workloadCount',
      format: ({row, value, clickableRef}) =>
        value ? (
          <Link
            params={{
              scope: {scope: [{key: 'role', href: row.data.roleLabel.href}]},
              group: row.data.id,
              pversion: 'draft',
              tab: 'workloadlist',
            }}
            ref={clickableRef}
          >
            {intl.num(value)}
          </Link>
        ) : value === 0 ? (
          intl('Common.None')
        ) : null,
    }),
    actions: {
      sortable: false,
      headerManager: intl('Common.Actions'),
      refs: {
        add: add => add?.button,
        more: more => more?.button,
      },
      onClick: ({evt, elements}) => !elements.add?.contains(evt.target) && !elements.more?.contains(evt.target),
      format: ({row, refs, component}) => {
        const crowdstrikeEnabled = component.props.crowdstrikeEnabled;
        const userIsReadOnly = component.props.userIsReadOnly;
        const group = row.data;
        const {pairingProfile, isAdmin, administeredGroups, ruleset} = group;

        const menuItem = [
          !userIsReadOnly ? (
            <MenuItem
              text={intl('Users.EditGroup')}
              link={{to: 'groups.edit', params: {id: ruleset.id}}}
              tid="edit-group"
            />
          ) : null,
          !crowdstrikeEnabled && (
            <>
              <MenuDelimiter />
              {!isAdmin ? (
                <MenuItem
                  text={intl('Edge.AdminGroup.MarkAdminGroup')}
                  onClick={_.partial(component.handleAdminGroupToggle, group)}
                  tid="mark-admingroup"
                />
              ) : (
                <MenuItem
                  text={intl('Edge.AdminGroup.ClearAdminGroup')}
                  onClick={_.partial(component.handleAdminGroupToggle, group)}
                  tid="clear-admingroup"
                  notSelectable={administeredGroups.length > 0}
                  disabled={administeredGroups.length > 0}
                />
              )}
            </>
          ),
          !crowdstrikeEnabled && row.pairing && (
            <>
              <MenuDelimiter />
              {pairingProfile ? (
                <>
                  <MenuItem
                    text={intl(pairingProfile.enabled ? 'PairingProfiles.Stop' : 'PairingProfiles.Start')}
                    tid={pairingProfile.enabled ? 'stop' : 'start'}
                    onClick={_.partial(component.handlePairingToggle, group)}
                  />
                  <MenuItem
                    text={intl('Edge.GroupDashboard.EditPairing')}
                    tid="edit-install"
                    link={{to: 'pairingProfiles.item.edit', params: {id: pairingProfile.id}}}
                  />
                </>
              ) : null}
              <MenuItem
                tid="revoke"
                text={intl('Edge.GroupDashboard.RevokeScript')}
                onClick={_.partial(component.handleToggleRevokeModal, group)}
              />
            </>
          ),
          !userIsReadOnly ? (
            <MenuItem
              tid="delete"
              text={intl('Common.RemoveGroup')}
              onClick={_.partial(component.handleToggleDeleteGroupModal, group)}
            />
          ) : null,
        ];

        // Sanitize the array without any null, undefined holes in the MenuItem array. By passing array with holes will create
        // an unwanted empty dropdown menu item when passing to like <Button menu={[null]}/>
        const filterMenuItem = menuItem.filter(Boolean);

        return (
          <>
            {!crowdstrikeEnabled ? (
              <Button.Link
                size="small"
                text={intl('Edge.GroupDashboard.PairVens')}
                tid="pair"
                ref={refs.add}
                tooltip={
                  pairingProfile && !pairingProfile.enabled
                    ? intl('Edge.GroupDashboard.StoppedPairing', {}, {html: true})
                    : intl('Edge.GroupDashboard.PairVensTooltip')
                }
                disabled={userIsReadOnly || (pairingProfile && !pairingProfile.enabled)}
                link={{to: 'pairingProfiles.pair', params: {id: pairingProfile.id}}}
              />
            ) : null}
            {filterMenuItem.length > 0 && (
              <Button.Menu
                size="small"
                color="standard"
                noFill
                icon="overflow"
                tid="more"
                ref={refs.more}
                menu={filterMenuItem}
                tooltip={userIsReadOnly && intl('Edge.GroupDashboard.DisabledScriptTooltip')}
                disabled={userIsReadOnly}
                menuNoDropdownIcon
              />
            )}
          </>
        );
      },
    },
  },
  templates: [
    [
      {columns: ['status'], size: 'min-content', sizeOld: '120px'},
      {columns: ['name'], size: 'minmax(80px, auto)', extraClass: stylesGridUtils.vCenter},
      {columns: ['workloads'], size: 'minmax(50px, auto)', extraClass: stylesGridUtils.vCenter},
      {columns: ['actions'], size: 'min-content', sizeOld: '100px', extraClass: stylesGridUtils.vCenter},
    ],
    {
      maxWidth: 360,
      template() {
        return [
          {columns: ['status'], size: 'min-content', sizeOld: '120px'},
          {columns: ['name'], size: 'minmax(80px, auto)', extraClass: stylesGridUtils.vCenter},
          {columns: ['workloads'], size: 'minmax(50px, auto)', extraClass: stylesGridUtils.vCenter},
          {columns: ['actions'], size: 'min-content', sizeOld: '100px', extraClass: stylesGridUtils.vCenter},
        ];
      },
    },
  ],
}));
