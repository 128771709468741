/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {hrefUtils} from 'utils';
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import createCachedSelector from 're-reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {getRouteParams, reverseProviderConsumer} from 'containers/App/AppState';
import {doesUserHaveGlobalObjectPermissions, isUserReadOnly} from 'containers/User/UserState';
import {getSuggestedServicesStatics} from 'containers/Service/List/ServiceListUtils';
import {
  allowRulesGridSettings,
  denyRulesGridSettings,
  defaultRuleGridSettings,
  getSelectorSettings,
} from './OutboundPolicyConfig';
import {getId} from 'utils/href';
import {
  newRuleHref,
  allowRulesGridId,
  denyRulesGridId,
  defaultRulesGridId,
  defaultRuleKey,
  getDiffData,
  getDraftObjects,
} from './OutboundPolicyUtils';
import {diffStatusIcon} from 'containers/Provisioning/ProvisioningUtils';
import {getOutboundPolicyRow} from 'containers/Provisioning/Pending/List/PendingListState';

export default {
  outboundpolicy: combineReducers({
    outboundAllowRuleset(state = null, action) {
      switch (action.type) {
        case 'OUTBOUND_POLICY_ALLOWRULESET':
          return action.data;
        default:
          return state;
      }
    },

    allowRulesDetail(state = {}, action) {
      switch (action.type) {
        case 'OUTBOUND_POLICY_ALLOW_RULES':
          return action.data;
        default:
          return state;
      }
    },

    denyRulesDetail(state = {}, action) {
      switch (action.type) {
        case 'OUTBOUND_POLICY_DENY_RULES':
          return action.data;
        default:
          return state;
      }
    },

    ruleEditor(state = {}, action) {
      switch (action.type) {
        case 'OUTBOUND_POLICY_RULE_EDITOR':
          return action.data;
        default:
          return state;
      }
    },

    allServiceAndAnyIPHrefs(state = {}, action) {
      switch (action.type) {
        case 'GET_ALL_SERVICES_AND_ANYIP':
          return action.data;
        default:
          return state;
      }
    },
  }),
};

export const getOutboundAllowRulesetId = state => getId(state.outboundpolicy?.outboundAllowRuleset?.href);
export const getOutboundAllowRulesetHref = state => state.outboundpolicy?.outboundAllowRuleset?.href;
export const getAllowRulesDetail = state => state.outboundpolicy.allowRulesDetail;
export const getDenyRulesDetail = state => state.outboundpolicy.denyRulesDetail;
export const getRuleEditor = state => state.outboundpolicy.ruleEditor;
export const getAllServicesAllIPsHref = state => state.outboundpolicy.allServiceAndAnyIPHrefs;

const getAllowRuleEditRow = createSelector(getRuleEditor, ruleEditorData =>
  ruleEditorData.gridId === allowRulesGridId ? ruleEditorData : null,
);

const getDenyRuleEditRow = createSelector(getRuleEditor, ruleEditorData =>
  ruleEditorData.gridId === denyRulesGridId ? ruleEditorData : null,
);

const getAllowRulesRows = createSelector(
  [getAllowRulesDetail, getAllowRuleEditRow, getRouteParams, isUserReadOnly],
  ({pversionRules, prevPversionRules}, allowRuleEditor, {pversion}, isUserReadOnly) =>
    (allowRuleEditor?.href === newRuleHref ? [{href: newRuleHref}, ...pversionRules] : pversionRules)?.map(rule => ({
      key: rule.href,
      diffStatus: rule.update_type ? diffStatusIcon[rule.update_type] : null,
      isInEditMode: allowRuleEditor?.href === rule.href && rule.update_type !== 'delete',
      data: {
        ...getDiffData({action: 'Deny', rule, prevPversionRules}),
        pversion,
        type: 'allow',
      },
      selectable: !isUserReadOnly && rule.update_type !== 'delete' && rule.href !== newRuleHref,
    })),
);

const getDenyRulesRows = createSelector(
  [getDenyRulesDetail, getDenyRuleEditRow, getRouteParams, isUserReadOnly],
  ({pversionRules, prevPversionRules}, denyRuleEditor, {pversion}, isUserReadOnly) =>
    (denyRuleEditor?.href === newRuleHref ? [{href: newRuleHref}, ...pversionRules] : pversionRules)?.map(rule => ({
      key: rule.href,
      diffStatus: rule.update_type ? diffStatusIcon[rule.update_type] : null,
      isInEditMode: denyRuleEditor?.href === rule.href && rule.update_type !== 'delete',
      data: {...getDiffData({action: 'Deny', rule, prevPversionRules}), pversion, type: 'deny'},
      selectable: !isUserReadOnly && rule.update_type !== 'delete' && rule.href !== newRuleHref,
    })),
);

const getDefaultRulesGridRows = createSelector(getAllServicesAllIPsHref, ({allServicesHref, allIPsHref} = {}) => [
  {
    key: defaultRuleKey,
    data: {
      rule: {
        consumers: [{actors: 'ams'}],
        providers: [{ip_list: {id: hrefUtils.getId(allIPsHref), name: intl('IPLists.Any')}}],
        ingress_services: [{id: hrefUtils.getId(allServicesHref), name: intl('Common.AllServices')}],
        network_type: 'all',
      },
      type: 'allow',
      href: defaultRuleKey,
    },
  },
]);

const getAllowRulesCount = createSelector(
  [getAllowRulesDetail, getAllowRulesRows],
  ({pversionRules}, allowRulesRows) => ({
    matched: allowRulesRows?.length ?? 0,
    total: pversionRules?.length ?? 0,
  }),
);

const getDenyRulesCount = createSelector(
  [getDenyRulesDetail, getDenyRulesRows],
  ({pversionRules}, denyRulesRows = []) => ({
    matched: denyRulesRows?.length ?? 0,
    total: pversionRules?.length ?? 0,
  }),
);

const isFilteredView = createSelector(getRouteParams, params => {
  // This selector is used to determine whether the page view is filtered/unfiltered. Use any Grid id since same filter is added to both the Grids
  try {
    return !_.isEmpty(JSON.parse(params[allowRulesGridId]).filter);
  } catch {
    return false;
  }
});

const getAllowGridSettings = createCachedSelector(
  [allowRulesGridSettings, getRouteParams, (_, props) => props.isInGroupView, isFilteredView, isUserReadOnly],
  (gridSettings, {pversion}, isInGroupView, isFiltered, isUserReadOnly) => {
    const columns = {...gridSettings.columns};

    columns.checkboxes.disabled = isInGroupView || pversion !== 'draft';
    columns.group.disabled = isInGroupView ?? false;
    columns.buttons.disabled = isUserReadOnly || (isInGroupView ?? false);
    columns.arrowIcon.disabled = isInGroupView ?? false;

    return {...gridSettings, paginationTextType: isFiltered ? 'matched' : 'total', columns};
  },
)((state, props) => props.gridId ?? allowRulesGridId);

const getDenyGridSettings = createCachedSelector(
  [denyRulesGridSettings, getRouteParams, (_, props) => props.isInGroupView, isFilteredView, isUserReadOnly],
  (gridSettings, {pversion}, isInGroupView, isFiltered, isUserReadOnly) => {
    const columns = {...gridSettings.columns};

    columns.checkboxes.disabled = isInGroupView || pversion !== 'draft';
    columns.group.disabled = isInGroupView ?? false;
    columns.buttons.disabled = isUserReadOnly || (isInGroupView ?? false);
    columns.arrowIcon.disabled = isInGroupView ?? false;

    return {...gridSettings, paginationTextType: isFiltered ? 'matched' : 'total', columns};
  },
)((state, props) => props.gridId ?? denyRulesGridId);

const getDefaultGridSettings = createCachedSelector(
  [defaultRuleGridSettings, (_, props) => props.isInGroupView],
  (gridSettings, isInGroupView) => {
    const columns = {...gridSettings.columns};

    columns.checkboxes.disabled = isInGroupView ?? false;
    columns.group.disabled = isInGroupView ?? false;
    columns.buttons.disabled = isInGroupView ?? false;
    columns.arrowIcon.disabled = isInGroupView ?? false;

    return {...gridSettings, columns};
  },
)((state, props) => props.gridId ?? defaultRulesGridId);

const getAllowRulesGrid = (state, props) =>
  getGridSelector(state, {
    settings: state => getAllowGridSettings(state, props),
    rows: getAllowRulesRows,
    filterMap: getSelectorSettings().filterMap,
  });

const getDenyRulesGrid = (state, props) =>
  getGridSelector(state, {
    settings: state => getDenyGridSettings(state, props),
    rows: getDenyRulesRows,
    filterMap: getSelectorSettings().filterMap,
  });

const getDefaultRuleGrid = (state, props) =>
  getGridSelector(state, {
    settings: state => getDefaultGridSettings(state, props),
    rows: getDefaultRulesGridRows,
  });

export const getChangeSubsets = createSelector(
  [getAllowRulesDetail, getDenyRulesDetail, getOutboundAllowRulesetHref, getOutboundPolicyRow],
  ({draftRules: allowRules}, {draftRules: denyRules}, outboundAllowRulesetHref, outboundPolicyRow) => {
    const {objectsToProvision, objectsToRevert} = getDraftObjects(allowRules, denyRules, outboundAllowRulesetHref);

    //This is for the case when an allow rule is added and same allow rule is deleted in outbound policy page: EYE-79389
    if (!objectsToProvision.rule_sets && outboundPolicyRow?.data?.rule_sets) {
      objectsToProvision.rule_sets = outboundPolicyRow.data.rule_sets;
      objectsToRevert.rule_sets = outboundPolicyRow.data.rule_sets;
    }

    return {objectsToProvision, objectsToRevert};
  },
);

export const getOutboundPolicyPage = createSelector(
  [
    getAllowRulesGrid,
    getAllowRulesCount,
    getDenyRulesGrid,
    getDenyRulesCount,
    getDefaultRuleGrid,
    getRuleEditor,
    getSuggestedServicesStatics,
    getSelectorSettings,
    doesUserHaveGlobalObjectPermissions,
    getRouteParams,
    isUserReadOnly,
    getChangeSubsets,
    reverseProviderConsumer,
    getAllServicesAllIPsHref,
    (_, props) => props.isInGroupView,
  ],
  (
    allowRulesGrid,
    allowRulesCount,
    denyRulesGrid,
    denyRulesCount,
    defaultRuleGrid,
    ruleEditor,
    {suggestedServices},
    {objectMap, filterMap, staticValues},
    userHasGlobalObjectPermission,
    params,
    userIsReadOnly,
    {objectsToProvision = {}, objectsToRevert = {}},
    reverseProviderConsumer,
    allServicesAllIPsHref,
    isInGroupView,
  ) => {
    let newObjectMap = objectMap;
    let newFilterMap = filterMap;

    if (isInGroupView) {
      newObjectMap = _.omit(objectMap, 'labels');
      newFilterMap = _.omit(filterMap, ['labels', 'all_groups']);
    }

    // Selector parameters based on filter and config
    const selector = {
      initialItems: Object.values(allowRulesGrid.filter).flat(),
      objects: Object.values(newObjectMap),
      categories: Object.entries(newFilterMap).map(([categoryKey, value]) => ({
        categoryKey,
        ...(categoryKey === 'ip_lists' ? {noStartCase: true} : {}),
        ...value,
      })),
      statics: Object.entries(staticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
    };

    const isOldVersion = Number.isInteger(Number(params.pversion));
    const policyVersion = isOldVersion ? Number(params.pversion) : params.pversion;
    // check provision option and not a system generated user
    const disableProvision = userIsReadOnly;

    return {
      allowRulesGrid,
      allowRulesCount,
      denyRulesGrid,
      denyRulesCount,
      defaultRuleGrid,
      suggestedServices: suggestedServices.filter(
        // remove windows based services - can't be used with ip lists in providers
        service => !service.pageInvokerProps.containerProps.versions.pversionObj.windows_services,
      ),
      ruleEditor: {...ruleEditor, isSaveDisabled: ruleEditor?.isSaveDisabled ?? true},
      selector,
      disableProvision,
      userIsReadOnly,
      objectsToProvision,
      objectsToRevert,
      policyVersion,
      isOldVersion,
      reverseProviderConsumer,
      ...allServicesAllIPsHref,
    };
  },
);
