/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {useCallback, useMemo} from 'react';
import * as PropTypes from 'prop-types';
import {ComboSelect} from 'containers/Selectors';
import {getSelectorSettings} from './ScopeSelectorConfig';

ScopeSelector.propTypes = {
  scope: PropTypes.instanceOf(Map),
  onSelectionChange: PropTypes.func.isRequired,
};

// useMemo dependency
const emptyMap = new Map();

export default function ScopeSelector({scope = emptyMap, onSelectionChange}) {
  const {objectMap, filterMap} = getSelectorSettings();
  const initialItems = useMemo(() => (scope ? _.orderBy(Array.from(scope.values()), 'key') : []), [scope]);
  const selector = useMemo(
    () => ({
      objects: Object.values(objectMap),
      categories: Object.entries(filterMap).map(([categoryKey, categoryValue]) => ({
        categoryKey,
        value: categoryValue?.value ?? categoryValue,
        object: categoryValue.object,
        scope: categoryValue.scope,
      })),
    }),
    [objectMap, filterMap],
  );

  const handleScopeChange = useCallback(
    selection => {
      const final = selection.reduce(
        (result, {key, categoryKey, categoryName, href, value, name}) =>
          result.set(key, {key, categoryKey, categoryName, value, href, name}),
        new Map(),
      );

      onSelectionChange(final);
    },
    [onSelectionChange],
  );

  return (
    <ComboSelect
      initialItems={initialItems}
      activeCategoryKey="app"
      objects={selector.objects}
      placeholder={intl('Labels.Select')}
      categories={selector.categories}
      onSelectionChange={handleScopeChange}
      filterLabelsByScope={false}
      tid="labels"
    />
  );
}
