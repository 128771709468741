/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import styles from './LoadingCircles.css';

export default function LoadingCircles() {
  return (
    <div className={styles.ellipsis}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
