/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {connect} from 'react-redux';
import {getId} from 'utils/href';
import {Component} from 'react';
import {object, string} from 'yup';
import {AppContext} from 'containers/App/AppUtils';
import {HeaderProps} from 'containers';
import {Form, AttributeList, Button, ToolBar, ToolGroup} from 'components';
import SubmitButton from 'containers/SubmitButton/SubmitButton';
import {submitAction} from 'containers/SubmitButton/SubmitSaga';
import ContainerClusterReducers from '../ContainerClusterState';
import {getContainerClusterDetailPage} from '../Detail/ContainerClusterDetailState';
import {fetchContainerClusterInstance} from '../Detail/ContainerClusterDetailSaga';

const getEmptyState = () => ({
  actionError: null,
});

@connect(getContainerClusterDetailPage)
export default class ContainerClusterConfig extends Component {
  static prefetch = fetchContainerClusterInstance;
  static contextType = AppContext;
  static reducers = ContainerClusterReducers;

  constructor(props) {
    super(props);

    this.state = getEmptyState();

    const {name, description} = props;

    this.schemas = object({
      name: string().max(255, intl('Common.NameIsTooLong')).required(intl('Users.NameRequired')),
    });
    this.handleAlertClose = this.handleAlertClose.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);

    this.initialValues = {
      name: name || '',
      description: description || '',
    };
  }

  handleAlertClose() {
    this.setState({actionError: null});
  }

  async handleOnSubmit(values) {
    const {
      context: {fetcher, navigate},
      props: {id},
    } = this;
    const {name, description} = values;
    const obj = {
      data: {
        name,
        description,
      },
    };

    if (id) {
      // edit mode
      obj.params = {container_cluster_id: id};
    }

    const {data, error: actionError} = await fetcher.spawn(submitAction, {
      target: id ? 'editContainerCluster' : 'addContainerCluster',
      obj,
    });

    if (!fetcher.unmounted && !actionError) {
      await fetcher.fork(fetchContainerClusterInstance.refetch);

      if (id) {
        navigate({to: 'containerClusters.item', params: {id}});
      } else {
        navigate({to: 'containerClusters.item', params: {id: getId(data.href), token: data.container_cluster_token}});
      }
    } else {
      this.setState({actionError});
      this.setSubmitting(false);
    }
  }

  renderForm(options) {
    const {isValid, isSubmitting, setSubmitting} = options;
    const {
      props: {id},
      state: {actionError},
    } = this;

    this.setSubmitting = setSubmitting;
    this.options = options;

    return (
      <>
        <ToolBar>
          <ToolGroup>
            <SubmitButton
              icon="save"
              type="submit"
              text={intl('Common.Save')}
              isSubmitting={isSubmitting}
              error={actionError}
              disabled={isValid === false}
              onCloseAlert={this.handleAlertClose}
            />
            <Button.Link
              icon="cancel"
              text={intl('Common.Cancel')}
              color="standard"
              tid="cancel"
              link={id ? {to: 'containerClusters.item', params: {id}} : 'containerClusters'}
            />
          </ToolGroup>
        </ToolBar>
        <AttributeList>
          {[
            {divider: true},
            {title: intl('Common.General')},
            {
              key: <Form.Label name="name" title={intl('Common.Name')} />,
              value: <Form.Input tid="name" placeholder={intl('Common.ProvideAName')} name="name" />,
            },
            {
              key: <Form.Label name="description" title={intl('Common.Description')} />,
              value: (
                <Form.Input tid="description" placeholder={intl('Common.OptionalDescription')} name="description" />
              ),
            },
          ]}
        </AttributeList>
      </>
    );
  }

  render() {
    const {id, name} = this.props;

    return (
      <>
        <HeaderProps
          title={intl('Menu.ContainerClusters', {multiple: true})}
          subtitle={name}
          up={id ? {to: 'containerClusters.item', params: {id}} : 'containerClusters'}
          label={`(${intl(id ? 'Common.Edit' : 'Common.Create')})`}
        />
        <Form schemas={this.schemas} initialValues={this.initialValues} onSubmit={this.handleOnSubmit}>
          {this.renderForm}
        </Form>
      </>
    );
  }
}
