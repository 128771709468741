/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {call, select} from 'redux-saga/effects';
import {getVenInstance} from '../VenItemState';
import {fetchContainerWorkloadList} from 'containers/ContainerWorkload/List/ContainerWorkloadListSaga';

export function* fetchVenContainerWorkloads(route, refetch = false) {
  const detail = yield select(getVenInstance);

  if (detail && detail.href) {
    yield call(fetchContainerWorkloadList, route, refetch, {ven: detail.href, ignoreScope: true});
  }
}
