/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {createElement, cloneElement, Fragment, type ReactElement} from 'react';
import Pill, {type PillProps} from './Pill';
import {getTid} from 'utils/tid';
import stylesUtils from 'utils.css';
import type {TruthFull} from 'utils/types';

const defaultTid = 'comp-pilldiff';
const sanitizeInput = <T,>(inputArr: T[] = []) => inputArr.filter(Boolean) as TruthFull<T>[];

type Value = {key: string; pill: ReactElement<PillProps, typeof Pill>};

export type PillDiffProps = {
  tid?: string;
  noDiff?: boolean;

  value?: (Value | undefined | null)[];

  oldValue?: (Value | undefined | null)[];

  // By default diff is shown in one line. Set to true to show in separate lines
  vertical?: boolean;
  // set true if no need wrap in gap
  noWrap?: boolean;
} & PillProps;

export default function PillDiff(props: PillDiffProps): JSX.Element | null {
  const {
    noDiff = false,
    vertical = false,
    noWrap = false,
    tid,
    value: inputValue,
    oldValue: inputOldValue,
    ...pillProps
  } = props;

  const value = sanitizeInput(inputValue);
  const oldValue = sanitizeInput(inputOldValue);

  if (value.length === 0 && oldValue.length === 0) {
    return null;
  }

  const className = cx(stylesUtils.gapXSmall, {
    [stylesUtils.gapHorizontalWrap]: !vertical,
    [stylesUtils.gapAlignStart]: vertical,
  });

  if (noDiff) {
    const unchangedTid = getTid(`${defaultTid}-unchanged`, tid);

    return createElement(
      noWrap ? Fragment : 'div',
      noWrap ? {'data-tid': unchangedTid} : {className, 'data-tid': unchangedTid},
      ...value.map(({key, pill}) => cloneElement(pill, {key, tid: 'unchanged', ...pillProps})),
    );
  }

  const pillsMap = value.reduce((map, item) => map.set(item.key, item), new Map<string, Value>());

  const {unchanged, removed} = oldValue.reduce(
    (acc, pill) => {
      const valuePill = pillsMap.get(pill.key);

      if (valuePill) {
        pillsMap.delete(pill.key);
        acc.unchanged.push(valuePill);
      } else {
        acc.removed.push(pill);
      }

      return acc;
    },
    {unchanged: [], removed: []} as {unchanged: Value[]; removed: Value[]},
  );

  const added = Array.from(pillsMap.values());

  const content = (
    <>
      {added.length > 0 &&
        added.map(({key, pill}) => cloneElement(pill, {key, tid: 'added', created: true, ...pillProps}))}
      {unchanged.length > 0 &&
        unchanged.map(({key, pill}) => cloneElement(pill, {key, tid: 'unchanged', ...pillProps}))}
      {removed.length > 0 &&
        removed.map(({key, pill}) => cloneElement(pill, {key, tid: 'removed', deleted: true, ...pillProps}))}
    </>
  );

  if (noWrap) {
    return content;
  }

  return (
    <div className={className} data-tid={getTid(defaultTid, tid)}>
      {content}
    </div>
  );
}
