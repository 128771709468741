/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {mixThemeWithProps} from '@css-modules-theme/react';
import {Tooltip} from 'components';
import styles from './ModalFooter.css';
import * as PropTypes from 'prop-types';

ModalFooter.propTypes = {
  noSingleton: PropTypes.bool, // whether we should render a singleton or not
  singletonProps: PropTypes.object, // we can pass props to the singleton if we want
};

export default function ModalFooter(props) {
  const {
    actions,
    children,
    theme,
    noSingleton = false, // by default we render the ModalFooter inside of a singleton
    singletonProps,
    ...elementProps
  } = mixThemeWithProps(styles, props);

  elementProps.className = theme.footer;
  elementProps['data-tid'] = 'comp-dialog-footer';

  return (
    <div {...elementProps}>
      <div className={theme.actions} data-tid="comp-dialog-actions">
        {noSingleton ? children : <Tooltip.Singleton {...singletonProps}>{children}</Tooltip.Singleton>}
      </div>
    </div>
  );
}
