/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {Button} from 'components';
import Modal from '../Modal';

// Default cancel button looks like link with Cancel text
const defaultCancelProps = {
  noFill: true,
  tid: 'cancel',
  text: intl('Common.Cancel'),
};
// Default confirm button
const defaultConfirmPropsValue = {
  tid: 'ok',
  text: intl('Common.OK'),
  progressCompleteWithCheckmark: true,
};

export default class ConfirmationModal extends PureComponent {
  // List of props specific to Alert modal, all extra props will be passed down to rendered Modal as is
  static propTypes = {
    onCancel: PropTypes.func, // If you want to specify cancel callback only and take button props from defaultCancelProps
    cancelProps: PropTypes.object, // Object with custom props for a button, will be merged with defaultButtonProps and onClose (if passed)

    onConfirm: PropTypes.func, // If you want to specify confirm callback only and take button props from defaultConfirmProps
    confirmProps: PropTypes.object, // Or object with custom props for confirm button, will be merged with defaultConfirmProps

    // Shortcut to disable cancel button and set progress state to confirm one
    // If you need different or more button state, you can pass cancel and confirm objects manually
    confirmIsInProgress: PropTypes.bool,

    // Props for <Modal.Header>
    title: PropTypes.node,
    noCloseIcon: PropTypes.bool,

    // Props for <Modal.Content>
    insensitive: PropTypes.bool,
    notScrollable: PropTypes.bool,
    noPaddings: PropTypes.bool,
    gap: PropTypes.string,
  };

  render() {
    const {
      children,
      onCancel,
      cancelProps,
      onConfirm,
      confirmProps,
      confirmIsInProgress,
      defaultConfirmProps = defaultConfirmPropsValue,
      // Destructure <Modal.Header> props
      title = intl('Common.AreYouSure'),
      noCloseIcon,
      // Destructure <Modal.Content> props
      insensitive,
      notScrollable,
      noPaddings,
      gap,
      footerProps = {},
      ...modalProps
    } = this.props;
    const headerProps = {title, noCloseIcon};
    const contentProps = {insensitive, notScrollable, noPaddings, gap};

    if (!modalProps.onClose && onCancel) {
      modalProps.onClose = onCancel;
    }

    return (
      <Modal {...modalProps}>
        <Modal.Header {...headerProps} />
        <Modal.Content {...contentProps}>{children}</Modal.Content>
        <Modal.Footer {...footerProps}>
          <Button onClick={onCancel} {...defaultCancelProps} {...cancelProps} />
          <Button onClick={onConfirm} progress={confirmIsInProgress} {...defaultConfirmProps} {...confirmProps} />
        </Modal.Footer>
      </Modal>
    );
  }
}

// Statically assign to the Modal class, so consumer can use it as <Modal.Confirmation>
Modal.Confirmation = ConfirmationModal;
