/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
export default {
  runningcontainer(state = [], action) {
    switch (action.type) {
      case 'CONTAINERWORKLOAD_RUNNING_CONTAINERS':
        return action.data;
      default:
        return state;
    }
  },
};

export const getRunningContainers = state => state.containerWorkloads.runningcontainer;
