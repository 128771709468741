/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {StatusIcon} from 'components';
import {createSelector} from 'reselect';

const incompatibilityTypes = createSelector([], () => ({
  ipsec_service_enabled: intl('Workloads.IncompatibilityTypes.IPSec'),
  ipv4_forwarding_enabled: intl('Workloads.IncompatibilityTypes.IPv4Forwarding'),
  ipv4_forwarding_pkt_cnt: intl('Workloads.IncompatibilityTypes.IPv4ForwardingPacketCount'),
  ip_multicast_inpkt_cnt: intl('Workloads.IncompatibilityTypes.MulticastIncomingPacketCount'),
  ip_multicast_outpkt_cnt: intl('Workloads.IncompatibilityTypes.MulticastOutgoingPacketCount'),
  iptables_rule_cnt: intl('Workloads.IncompatibilityTypes.IpTablesRuleCount'),
  ipv4_global_scope: intl('Workloads.IncompatibilityTypes.IPv4GlobalScope'),
  ipv6_global_scope: intl('Workloads.IncompatibilityTypes.IPv6GlobalScope'),
  ipv6_active_conn_cnt: intl('Workloads.IncompatibilityTypes.IPv6ActiveConnectionCount'),
  iptables_enabled: intl('Workloads.IncompatibilityTypes.IpTablesEnabled'),
  ip6tables_enabled: intl('Workloads.IncompatibilityTypes.Ip6TablesEnabled'),
  ip6tables_rule_cnt: intl('Workloads.IncompatibilityTypes.Ip6TablesRuleCount'),
  IPv6_enabled: intl('Workloads.IncompatibilityTypes.IPv6Enabled'),
  ipv6_forwarding_enabled: intl('Workloads.IncompatibilityTypes.IPv6ForwardingEnabled'),
  routing_table_conflict: intl('Workloads.IncompatibilityTypes.RoutingTableConflict'),
  teredo_tunneling_enabled: intl('Workloads.IncompatibilityTypes.TeredoTunnelingEnabled'),
  IPv4_enabled: intl('Workloads.IncompatibilityTypes.IPv4Enabled'),
  Unwanted_nics: intl('Workloads.IncompatibilityTypes.UnwantedNics'),
  Unwanted_roles: intl('Workloads.IncompatibilityTypes.UnwantedRoles'),
  MTU_size: intl('Workloads.IncompatibilityTypes.MtuSize'),
  Unwanted_software: intl('Workloads.IncompatibilityTypes.UnwantedSoftware'),
  Group_policy: intl('Workloads.IncompatibilityTypes.GroupPolicy'),
  Unwanted_WFP_providers: intl('Workloads.IncompatibilityTypes.UnwantedWFProviders'),
}));

export const gridSettings = createSelector([], () => ({
  id: 'compatibilityReport',
  sort: 'type',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    type: {
      header: intl('Common.Type'),
      value: ({row}) => row.type,
      format: ({value}) => incompatibilityTypes()[value],
    },
    value: {
      header: intl('Common.Value'),
      value: ({row}) => row.value,
    },
    status: {
      header: intl('Workloads.Compatible'),
      value: ({row}) => row.status,
      format: ({value}) => <StatusIcon status={value} />,
    },
  },

  templates: [
    [
      {columns: ['type'], size: 'minmax(100px, auto)'},
      {columns: ['value'], size: 'minmax(100px, auto)'},
      {columns: ['status'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['type'], size: 'minmax(100px, auto)'},
            {columns: ['value'], size: 'minmax(100px, auto)'},
            {columns: ['status'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['type'], size: 'minmax(100px, auto)'},
          {columns: ['value'], size: 'minmax(100px, auto)'},
          {columns: ['status'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [{columns: ['type', 'value', 'status'], size: 'minmax(200px, auto)'}];
        }

        return [{columns: ['type', 'value', 'status'], size: 'minmax(200px, auto)'}];
      },
    },
  ],
}));
