/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {all, call, put, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {
  getContainerClusterContainerWorkloadProfiles,
  getContainerClusterContainerWorkloadProfileDefault,
} from './ContainerClusterContainerWorkloadProfileListState';
import {gridSettings, getSelectorSettings} from './ContainerClusterContainerWorkloadProfileListConfig';
import gridSaga from 'components/Grid/GridSaga';
import {fetchAllUsers} from 'containers/User/UserSagas';

export function* fetchContainerClusterContainerWorkloadProfiles({params, filter, force = false} = {}) {
  const query = {
    representation: 'container_workload_profile_labels',
    max_results: 500,
    skip_default: true,
    xxxkey: 'assign_labels',
  };
  const selectorSettingsObject = yield select(getSelectorSettings);

  if (filter) {
    for (const [name, [value]] of Object.entries(filter)) {
      if (value) {
        if (selectorSettingsObject.staticValues.hasOwnProperty(name)) {
          if (Array.isArray(selectorSettingsObject.staticValues[name][value])) {
            selectorSettingsObject.staticValues[name][value].forEach(filter => {
              query[filter.name] = filter.value;
            });
          } else {
            query[name] = selectorSettingsObject.staticValues[name][value];
          }
        } else if (selectorSettingsObject.scopeMap.hasOwnProperty(name)) {
          if (query.xxxlabels && Array.isArray(query.xxxlabels[0])) {
            query.xxxlabels[0].push(value.href);
          } else {
            query.xxxlabels = [[value.href]];
          }
        } else {
          query[name] = value;
        }
      }
    }
  }

  return yield call(apiSaga, 'container_workload_profiles.get_collection', {
    params: {container_cluster_id: params.id},
    query,
    cache: !force,
    *onDone({data: list}) {
      const len = list.length;
      const count = {
        matched: len,
        total: len,
      };

      if (force || list !== (yield select(getContainerClusterContainerWorkloadProfiles))) {
        yield put({
          type: 'CONTAINER_CLUSTER_GET_CONTAINER_WORKLOAD_PROFILES',
          data: {containerWorkloadProfileList: list, containerWorkloadProfileCount: count},
        });
      }

      return {list, count};
    },
  });
}

export function* fetchContainerClusterContainerWorkloadDefault({params, force = false} = {}) {
  return yield call(apiSaga, 'container_workload_profiles.get_collection', {
    params: {container_cluster_id: params.id},
    query: {
      representation: 'container_workload_profile_labels',
      max_results: 500,
      name: 'Default Profile',
      namespace: '',
    },
    cache: !force,
    *onDone({data: list}) {
      if (force || list !== (yield select(getContainerClusterContainerWorkloadProfileDefault))) {
        yield put({
          type: 'CONTAINER_CLUSTER_GET_CONTAINER_WORKLOAD_PROFILE_DEFAULT',
          data: {
            containerWorkloadProfileDefault: list,
          },
        });
      }

      return {list};
    },
  });
}

export function* fetchContainerClusterWorkloadProfilesPage(route, refetch = false, options = {}) {
  const selectorSettingsObject = yield select(getSelectorSettings);
  const {getFilters = selectorSettingsObject.filterMap} = options;

  yield call(gridSaga, {
    route,
    settings: gridSettings,
    filterMap: getFilters,
    *onSaga({filterParams}) {
      const [{list}] = yield all([
        call(fetchContainerClusterContainerWorkloadProfiles, {
          params: route.params,
          filter: filterParams.isEmpty ? undefined : filterParams.valid,
          force: refetch,
        }),
        call(fetchContainerClusterContainerWorkloadDefault, {params: route.params, force: refetch}),
        call(fetchAllUsers, {force: refetch}),
      ]);

      return list && list.length;
    },
  });
}
