/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {useCallback, useEffect, useState, type ReactChild, type RefObject} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {Card, type CardProps} from 'components';
import {isMotionReduced} from 'utils/dom';
import InfoCardIcon from './InfoCardIcon';
import styles from './InfoCard.css';
import stylesUtils from 'utils.css';
import type {ReactStrictNode} from 'utils/types';

const variants = {
  show: (instant: boolean) => ({
    height: 'auto',
    opacity: 1,
    transition: {duration: instant || isMotionReduced() ? 0 : 0.4},
  }),
  hide: (instant: boolean) => ({height: 0, opacity: 0, transition: {duration: instant || isMotionReduced() ? 0 : 0.3}}),
} as const;

type InfoCardProps = {
  // Ref which will contain a dom element of the trigger, or a selector which can be used to find a dom element
  trigger: string | RefObject<HTMLElement>;
  // Event that should trigger the card to open. Default is 'click'.
  triggerEvent?: string;

  // Show the card instantly, without the animation. Default is false.
  instant?: boolean;
  // Open the card initially. Default is false.
  initiallyOpened?: boolean;

  // Custom tid
  tid?: string;

  children: Children;
} & ThemeProps &
  CardProps;

// Any dom element can be a trigger, this is a shorthand for a StatusIcon with the 'info' icon
InfoCard.Icon = InfoCardIcon;

type Header = {title: ReactChild};
type Section = {header: ReactChild; message: ReactStrictNode};
type ContentObject = Header | Section;

type Content = ContentObject[] | ReactChild;
type Children = Content | (() => Content);

const renderChildren = (children: Children): ReactStrictNode => {
  const content: Content = typeof children === 'function' ? children() : children;

  if (!Array.isArray(content)) {
    return content;
  }

  return (
    <div className={stylesUtils.gapMedium}>
      {content.map((obj, index) => {
        if ('title' in obj) {
          return (
            <div className={styles.title} key={index}>
              {obj.title}
            </div>
          );
        }

        return (
          <div key={index}>
            <div className={styles.header}>{obj.header}</div>
            <div className={styles.message}>{obj.message}</div>
          </div>
        );
      })}
    </div>
  );
};

export default function InfoCard(props: InfoCardProps): JSX.Element {
  const {
    theme,
    children,

    tid = 'info',
    instant = false,
    initiallyOpened = false,

    trigger,
    triggerEvent = 'click',
    ...rest
  } = mixThemeWithProps(styles, props);
  const [opened, setOpened] = useState(initiallyOpened);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const cardProps = {
    ...rest,
    tid,
    withBorder: true,
    showCloseIcon: true,
    onClose: handleClose,
    theme,
  };

  // Attach an event to the trigger element after component is mounted
  useEffect(() => {
    let triggerDom: HTMLElement | null;
    const eventHandler = () => setOpened(opened => !opened);
    const timeout = setTimeout(() => {
      triggerDom = typeof trigger === 'string' ? document.querySelector(trigger) : trigger.current;
      triggerDom?.addEventListener(triggerEvent, eventHandler);
    }, 100);

    return () => {
      clearTimeout(timeout);
      triggerDom?.removeEventListener(triggerEvent, eventHandler);
    };
  }, [setOpened, trigger, triggerEvent]);

  return (
    <AnimatePresence initial={false} custom={instant}>
      {opened && (
        <motion.div
          key="card"
          initial="hide"
          animate="show"
          exit="hide"
          variants={variants}
          custom={instant}
          className={theme.infoCard}
        >
          <Card {...cardProps}>{renderChildren(children)}</Card>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
