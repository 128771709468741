/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {all, call, put, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {hrefUtils} from 'utils';
import {getWorkloadInstance} from '../WorkloadItemState';
import {isEdge} from 'containers/App/AppState';
import {aggregateVulnerabilityValues} from './WorkloadVulnerabilitiesUtils';
import {getId, collator} from 'utils/href';

function parseVulnerabilities(vulnerabilities, nodeHref) {
  let aggregatedValues;
  const nodeVulnerabilities = {};

  const instances = vulnerabilities.reduce((result, vulnerability) => {
    // Add vulnerabilities indexed by port/protocol
    const key = [vulnerability.port, vulnerability.proto].join(',');
    let node;
    const severity = vulnerability.vulnerability.score / 10;
    const vulnerablePortExposure = vulnerability.hasOwnProperty('vulnerable_port_exposure')
      ? vulnerability.vulnerable_port_exposure
      : vulnerability.port_exposure;
    const wideExposure = vulnerability.vulnerable_port_wide_exposure || vulnerability.port_wide_exposure;
    let vulnerabilityExposureScore = null;

    if (!vulnerability.hasOwnProperty('vulnerability_exposure_score')) {
      vulnerabilityExposureScore =
        vulnerablePortExposure === null
          ? null
          : (Math.floor(Math.sqrt(vulnerablePortExposure) * (Math.pow(severity, 3) / 10)) / 10) *
            (vulnerability.num_workloads || 1);
    } else if (vulnerability.vulnerability_exposure_score !== null) {
      vulnerabilityExposureScore = vulnerability.vulnerability_exposure_score / 10;
    }

    // Accumulate the Wide Exposures by Port for label based || workload based
    const instance = {
      port: vulnerability.port,
      protocol: vulnerability.proto,
      severity,
      wideExposure,
      numWorkloads: vulnerability.num_workloads,
      vulnerablePortExposure,
      vulnerabilityScore: severity * (vulnerability.num_workloads || 1),
      vulnerabilityExposureScore,
      details: vulnerability.vulnerability,
    };

    // Collect the vulnerabilities per node
    aggregatedValues = aggregateVulnerabilityValues(aggregatedValues, instance, key);

    result[key] = (result[key] || []).concat([instance]);

    // Update the existing traffic
    // Find all the existing consuming traffic for this node on this port/protocol
    const consumingTraffics = node && node.consumingTraffic && node.consumingTraffic.byPort[key];

    _.forEach(consumingTraffics, consumingTraffic => {
      let traffic;

      if (traffic) {
        // Aggregate the max severity at the traffic level
        traffic.maxVulnerabilitySeverity = Math.max(traffic.maxVulnerabilitySeverity, severity);

        if (vulnerablePortExposure) {
          traffic.maxExpVulnerabilitySeverity = Math.max(traffic.maxExpVulnerabilitySeverity, severity);
        }

        // For each process within this port/protocol update the traffic connection
        consumingTraffic.forEach(trafficConnection => {
          const connection = traffic.connections[trafficConnection];

          if (connection) {
            if (!connection.vulnerabilities) {
              connection.vulnerabilities = {instances: []};
            }

            // Collect Vulnerabilities per connection
            connection.vulnerabilities.aggregatedValues = aggregateVulnerabilityValues(
              connection.vulnerabilities.aggregatedValues,
              instance,
            );
            connection.vulnerabilities.instances = (connection.vulnerabilities.instances || []).concat([instance]);
          }
        });
      }
    });

    return result;
  }, {});

  if (aggregatedValues) {
    nodeVulnerabilities[nodeHref] = {aggregatedValues, instances};
  }

  return nodeVulnerabilities;
}

export function* fetchVulnerabilities(refetch = false) {
  const workload = yield select(getWorkloadInstance);

  return yield all([
    call(apiSaga, 'detected_vulnerabilities.get_collection', {
      params: {workload_id: hrefUtils.getId(workload.href)},
      node: workload.href,
      cache: !refetch,
      *onDone({data, options}) {
        const clusterLabels = workload?.labels?.filter(label => label.key && label.key !== 'role');
        const parentIds = clusterLabels.map(label => getId(label.href)).sort((a, b) => collator.compare(a, b));

        const clusterId = parentIds.join('x') || 'discovered';

        const edgeEnabled = yield select(isEdge);

        if (!edgeEnabled) {
          const capsResponse = yield call(apiSaga, 'network_traffic.caps', {
            query: {cluster_keys: JSON.stringify([clusterId])},
          });

          if (capsResponse.data) {
            const rulesetCaps = capsResponse.data[clusterId]?.caps.rule_sets?.includes('read');

            data.rulesetCaps = rulesetCaps;
          }
        }

        const vulnerabilitiesdata = parseVulnerabilities(data, options.node);

        yield put({type: 'WORKLOAD_VULNERABILITIES', data: vulnerabilitiesdata});

        return vulnerabilitiesdata;
      },
    }),
  ]);
}
