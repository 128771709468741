/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {ButtonRefresh, Grid, ToolBar, ToolGroup} from 'components';
import {fetchContainerServiceBackendsPage} from './ContainerClusterServiceBackendsSaga';
import {getContainerClusterServiceBackendsPage} from './ContainerClusterServiceBackendsState';

@connect(getContainerClusterServiceBackendsPage)
export default class ContainerClusterServiceBackends extends PureComponent {
  static prefetch = fetchContainerServiceBackendsPage;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh() {
    return this.context.fetcher.fork(fetchContainerServiceBackendsPage.refetch);
  }

  render() {
    const {grid, count} = this.props;

    return (
      <>
        <ToolBar justify="flex-end">
          <ToolGroup>
            <ButtonRefresh color="standard" onRefresh={this.handleRefresh} />
          </ToolGroup>
        </ToolBar>
        <Grid grid={grid} count={count} emptyMessage={intl('Common.NoDataServiceBackends')} />
      </>
    );
  }
}
