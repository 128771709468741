/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {useCallback, useState} from 'react';
import {Modal} from 'components';
import styles from './ExpandableList.css';

export interface ExpandableListProps {
  count?: number /** Number of values visible at first */;

  /** Array of all the values */
  values: unknown[];

  /** Title of the modal */
  title?: string;
}

export default function ExpandableList(props: ExpandableListProps): JSX.Element {
  const [showMore, setShowMore] = useState(false);
  const {title, values, count = 3} = props;

  const handleToggle = useCallback(() => setShowMore(!showMore), [showMore]);
  const handleKeyUp = useCallback(evt => {
    if (evt.key === ' ' || evt.key === 'Enter') {
      setShowMore(true); // Emulate click on Space and Enter
    }
  }, []);

  return values.length <= count ? (
    <div>{values}</div>
  ) : (
    <div>
      {values.slice(0, count)}
      <div key="more" onClick={handleToggle} onKeyUp={handleKeyUp} className={styles.more} tabIndex={0} data-tid="more">
        {intl('Common.CountMore', {count: values.length - count})}
      </div>
      {showMore && (
        <Modal.Alert key="modal" title={title} gap="gapXSmall" onClose={handleToggle}>
          {values}
        </Modal.Alert>
      )}
    </div>
  );
}
