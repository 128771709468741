/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import * as apiUtils from 'api/apiUtils';
import intl from 'intl';
import {createSelector} from 'reselect';

export const pluralMap = createSelector([], () => ({
  'IP Address': intl('IPLists.IPAddresses'),
}));

export const getPluralObjectName = name => {
  const pluralMapObj = pluralMap();

  if (pluralMapObj[name]) {
    return pluralMapObj[name];
  }

  return name.slice(-1) === 's' ? name : `${name}s`;
};

export const pillIconMap = createSelector([], () => ({
  app: 'app',
  env: 'env',
  loc: 'loc',
  role: 'role',
  all_applications: 'app',
  all_environments: 'env',
  all_locations: 'loc',
  all_roles: 'role',
  workloads: 'workload',
  all_workloads: 'all-workloads',
  ip_lists: 'allowlist',
  virtual_services: 'virtual-service',
  virtual_servers: 'virtual-server',
  user_groups: 'org',
  all: 'scope',
}));

export const getNoLabelHref = (orgId, key) =>
  apiUtils.getParameterizedPathLoosely({
    orgId,
    params: {key, exists: false},
    path: '/orgs/:xorg_id/labels?key=:key&exists=:exists',
  });

export const noScopeLabels = createSelector([], () => ({
  role: [{value: intl('Common.NoRoleLabel'), key: 'role'}],
  app: [{value: intl('Common.NoApplicationLabel'), key: 'app'}],
  env: [{value: intl('Common.NoEnvironmentLabel'), key: 'env'}],
  loc: [{value: intl('Common.NoLocationLabel'), key: 'loc'}],
}));

export const allScopeLabels = createSelector([], () => ({
  role: [{value: intl('Common.AllRoleLabels'), key: 'role'}],
  app: [{value: intl('Common.AllApplicationLabels'), key: 'app'}],
  env: [{value: intl('Common.AllEnvironmentLabels'), key: 'env'}],
  loc: [{value: intl('Common.AllLocationLabels'), key: 'loc'}],
}));

export const getScopeLabelByHref = (href, allLabels = false) => {
  if (!href || !href.includes('labels') || !href.includes('exists')) {
    return;
  }

  const hrefArr = href.split(/[&/=?]+/);
  const key = hrefArr.length && hrefArr[5];

  return {
    key,
    value: allLabels ? allScopeLabels()[key][0].value : noScopeLabels()[key][0].value,
  };
};
