/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {generateRules} from 'containers/Workload/WorkloadRulesUtils';
import {gridSettings, customIpgridSettings} from './ContainerWorkloadRulesConfig';

export default {
  rules(state = {}, action) {
    switch (action.type) {
      case 'CONTAINERWORKLOAD_GET_RULES':
        return action.data;
      default:
        return state;
    }
  },
};

export const getContainerWorkloadRules = state => state.containerWorkloads.rules;
export const getContainerWorkloadInstance = state => state.containerWorkloads.instance;

export const getContainerWorkloadAllRules = createSelector([getContainerWorkloadRules], ruleView => {
  const {inboundRules, outboundRules, customIpTableRules} = generateRules(ruleView);

  const inboundRulesData = inboundRules.map((item, idx) => ({
    key: idx,
    ...item,
  }));

  const outboundRulesData = outboundRules.map((item, idx) => ({
    key: idx,
    ...item,
  }));

  const customIpTableData = customIpTableRules.map((item, idx) => ({
    key: idx,
    ...item,
  }));

  return {
    inboundRulesData,
    outboundRulesData,
    customIpTableData,
  };
});

export const getContainerWorkloadInboundRulesRows = createSelector(
  [getContainerWorkloadAllRules],
  ({inboundRulesData}) => inboundRulesData,
);

export const getContainerWorkloadOutboundRulesRows = createSelector(
  [getContainerWorkloadAllRules],
  ({outboundRulesData}) => outboundRulesData,
);

export const getContainerWorkloadcustomIpTableRulesRows = createSelector(
  [getContainerWorkloadAllRules],
  ({customIpTableData}) => customIpTableData,
);

export const inboundGridSettings = createSelector([gridSettings], gridSettings => ({...gridSettings, id: 'inbound'}));
export const outboundGridSettings = createSelector([gridSettings], gridSettings => ({...gridSettings, id: 'outbound'}));
export const customIpGridSettings = createSelector([customIpgridSettings], gridSettings => ({
  ...gridSettings,
  id: 'customip',
}));

const getInboundGrid = state =>
  getGridSelector(state, {
    settings: inboundGridSettings,
    rows: getContainerWorkloadInboundRulesRows,
  });

const getOutboundGrid = state =>
  getGridSelector(state, {
    settings: outboundGridSettings,
    rows: getContainerWorkloadOutboundRulesRows,
  });

const getcustomIpGrid = state =>
  getGridSelector(state, {
    settings: customIpGridSettings,
    rows: getContainerWorkloadcustomIpTableRulesRows,
  });

export const getContainerWorkloadRulesPage = createSelector(
  [getInboundGrid, getOutboundGrid, getcustomIpGrid, getContainerWorkloadRules, getContainerWorkloadInstance],
  (inboundGrid, outboundGrid, customIpGrid, ruleView, containerWorkload) => ({
    inboundGrid,
    outboundGrid,
    customIpGrid,
    ruleView,
    containerWorkload,
  }),
);
