/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {ExpandableList, Link, Icon} from 'components';
import {hrefUtils} from 'utils';
import {portProtocolMap} from 'utils/port';
import styles from './Summary/VirtualServiceSummary.css';
import {lookupProtocol} from '../../Service/ServiceUtils';

export const getWorkloadStatus = row => {
  if (!row.workload.mode) {
    return 'unmanaged';
  }

  if (!row.workload.online) {
    return 'offline';
  }

  if (row.workload.online) {
    return 'online';
  }

  return null;
};

export const getPortOverride = row => {
  const portProtocolMapObj = portProtocolMap();

  if (Array.isArray(row.port_overrides) && row.port_overrides.length) {
    const overrides = row.port_overrides.map((value, idx) => {
      let portProtocolKey;
      let unidentified = false;
      const protocol = value.proto || value.protocol; // try both since it changes between 18.2 and 18.3.

      if (protocol) {
        portProtocolKey = row.servicePortsMap[`${value.port},${protocol}`];
      } else {
        const portProtocolKey1 = row.servicePortsMap[`${value.port},${portProtocolMapObj.tcp.value}`]; // TCP
        const portProtocolKey2 = row.servicePortsMap[`${value.port},${portProtocolMapObj.udp.value}`]; // UDP

        if (portProtocolKey1 && portProtocolKey2) {
          portProtocolKey = `${portProtocolKey1}/${portProtocolMapObj.udp.text}`;
        } else {
          portProtocolKey = portProtocolKey1 || portProtocolKey2;
        }
      }

      let activePortStr;

      if (portProtocolKey) {
        activePortStr = portProtocolKey;
      } else if (!('port' in value)) {
        activePortStr = intl('Common.AllPorts');
      } else {
        activePortStr = intl('Common.Unidentified');
        unidentified = true;
      }

      const newValue = `${value.new_port}${value.new_to_port ? ' - ' + value.new_to_port : ''}`;

      return {activePortStr, newValue, idx, unidentified};
    });

    return overrides;
  }

  return null;
};

export const getPortOverrideValue = value => {
  if (value) {
    const portsValue = value.map(value => (
      <div key={value.idx}>
        {value.unidentified ? (
          <span className={styles.iconItem}>
            <Icon name="warning" />{' '}
          </span>
        ) : null}
        {`${value.activePortStr}: `}
        {value.newValue.length > 0 && String(value.newValue)}
      </div>
    ));
    const showvalue = <ExpandableList values={portsValue} count={1} title={intl('VirtualServices.PortOverride')} />;

    return showvalue;
  }

  return null;
};

export const getWorkloadsValue = row => {
  if (row.data.workload.deleted) {
    return (
      <span>
        <span className={styles.iconItemRemove}>
          <Icon name="error" />{' '}
        </span>
        <span>{row.data.finalname}</span>
      </span>
    );
  }

  return row.data.workload.href ? ( // Workload may be out-of-scope of Virtual Service
    <Link to="workloads.item" params={{id: hrefUtils.getId(row.data.workload.href)}}>
      {row.data.finalname}
    </Link>
  ) : (
    <div>{row.data.finalname}</div>
  );
};

export const formatWorkloadStateValue = row => {
  if (row.workload.mode === 'idle') {
    return intl('Common.Idle');
  }

  if (row.workload.mode === 'illuminated') {
    return !row.workload.log_traffic ? intl('Common.Build') : intl('Common.Test');
  }

  if (row.workload.mode === 'enforced') {
    return intl('Common.Enforced');
  }

  return intl('Common.Unmanaged');
};

export const formatAdditionalVS = row => {
  let showadditionalVirtualServices;

  if (row.additional_virtual_services_for_workload && row.additional_virtual_services_for_workload.length) {
    const additionalVirtualServices = [];

    row.additional_virtual_services_for_workload.forEach((vs, index) => {
      if (vs.href) {
        additionalVirtualServices.push(
          <div key={index}>
            <Link to="virtualServices.item.view" params={{id: hrefUtils.getId(vs.href), pversion: 'draft'}}>
              {vs.name}
            </Link>
          </div>,
        );
      } else if (vs.name) {
        additionalVirtualServices.push(<div key={index}>{vs.name}</div>);
      }
    });
    showadditionalVirtualServices = row.additional_virtual_services_for_workload ? (
      <ExpandableList values={additionalVirtualServices} count={1} title={intl('VirtualServices.Additional')} />
    ) : (
      []
    );
  }

  return showadditionalVirtualServices;
};

export const getPortProtocol = sp => {
  return `${sp.port}${sp.to_port ? '-' + sp.to_port : ''} ${
    sp.protocol || sp.proto ? lookupProtocol(sp.protocol || sp.proto) : ''
  }`;
};
