/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import listReducers from './List/WorkloadListState';
import rulesReducers from './Item/Rules/WorkloadRulesState';
import workloadReducers from './Item/WorkloadItemState';
import reportReducers from './Item/Report/WorkloadCompatibilityReportState';
import virtualServiceReducers from './Item/VirtualServices/WorkloadVirtualServicesState';
import vulnerabilitiesReducers from './Item/Vulnerabilities/WorkloadVulnerabilitiesState';
import summaryReducers from './Item/Summary/WorkloadSummaryState';
import editReducers from './Item/Edit/WorkloadEditState';
import statusReducers from '../WorkloadManagement/WorkloadManagementSatate';

export default {
  workload: combineReducers({
    ...listReducers,
    ...rulesReducers,
    ...workloadReducers,
    ...virtualServiceReducers,
    ...vulnerabilitiesReducers,
    ...summaryReducers,
    ...reportReducers,
    ...editReducers,
    ...statusReducers,
    osFamilies(state = [], action) {
      switch (action.type) {
        case 'WORKLOAD_GET_LIST_OS_FAMILIES':
          return action.data;
        default:
          return state;
      }
    },
  }),
};

export const getOSFamilies = state => state.workload.osFamilies;
