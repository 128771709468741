/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import produce from 'immer';
import {isAPIAvailable} from 'api/apiUtils';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {getLabelsObject} from 'components/Pill/Label/LabelUtils';
import {findSelectedOption} from 'components/Form/FormUtils';
import {getRouteName, getRouteCurrentParams, isEdge, isCrowdstrike} from 'containers/App/AppState';
import {getWorkload, getWorkloadInstance} from '../WorkloadItemState';
import {getOptionalFeatures} from '../Summary/WorkloadSummaryState';
import {interfaceGridSettings, actionOptions} from './WorkloadEditConfig';

export default {
  dnslookup(state = [], action) {
    switch (action.type) {
      case 'DNS_REVERSE_LOOKUP':
        return action.data;
      default:
        return state;
    }
  },
};

export const getDnsReverseLookup = state => state.workload.dnslookup;

export const getScopeDetailRows = createSelector(getWorkloadInstance, workload => workload);

export const getInterfaceSettings = createSelector(
  [getWorkload, interfaceGridSettings],
  (workload, interfaceGridSettings) => {
    const columns = {...interfaceGridSettings.columns};

    if (!workload?.agent?.status) {
      columns.interfacename.showAsterisk = true;
      columns.subnets.showAsterisk = true;
    } else {
      columns.interfacename.showAsterisk = false;
      columns.subnets.showAsterisk = false;
    }

    return {...interfaceGridSettings, columns};
  },
);

const getInterfaceGrid = state =>
  getGridSelector(state, {
    settings: getInterfaceSettings,
  });

const getInterfaceSubnet = interfaceValue =>
  interfaceValue.cidr_block ? `${interfaceValue.address}/${interfaceValue.cidr_block}` : interfaceValue.address;

export const getWorkloadEdit = createSelector(
  [
    getWorkload,
    getRouteName,
    getRouteCurrentParams,
    getOptionalFeatures,
    getInterfaceGrid,
    getDnsReverseLookup,
    isEdge,
    isCrowdstrike,
  ],
  (
    workload,
    routeName,
    currentRouteParams,
    optionalFeatures,
    interfacegrid,
    dnsReverseLookup,
    edgeEnabled,
    crowdstrikeEnabled,
  ) => {
    const dnsReverseLookupMap = {};
    const isEdit = routeName.endsWith('edit');
    const managedWorkload = Boolean(!workload || workload.agent?.status);
    const labelObject = getLabelsObject(workload.labels || []);
    const labelValues = Object.keys(labelObject).reduce((label, cur) => {
      if (!cur) {
        return label;
      }

      label[cur] = [labelObject[cur]];

      return label;
    }, {});

    const hasWritePermission = isEdit
      ? Array.isArray(workload.caps) && workload.caps.includes('write')
      : isAPIAvailable('workloads.create');

    for (const {ip, names} of dnsReverseLookup) {
      dnsReverseLookupMap[ip] = (Array.isArray(names) && names[0]) || '';
    }

    const convertedWorkload = produce(workload, draft => {
      const actionOptionsObj = actionOptions();

      // group the ips array from API by interfaces
      if (draft?.interfaces) {
        draft.interfaces = Object.values(
          draft.interfaces.reduce(
            (acc, interfacevalue) => {
              const interfaceName = interfacevalue.name;
              // provide the interface name to pass the CIDR check
              const ip = getInterfaceSubnet(interfacevalue);
              const defaultGateway = interfacevalue.default_gateway_address;

              if (acc.hasOwnProperty(interfaceName)) {
                acc[interfaceName].data.subnets.push(ip);

                if (
                  interfacevalue.default_gateway_address &&
                  !acc[interfaceName].data.defaultGateways.includes(defaultGateway)
                ) {
                  acc[interfaceName].data.defaultGateways.push(defaultGateway);
                }
              } else {
                acc[interfaceName] = {
                  key: acc[Symbol.for('count')]++,
                  selectable: !managedWorkload,
                  data: {
                    isEdit,
                    managedWorkload,
                    interfacename: interfaceName,
                    action: workload.ignored_interface_names.includes(interfaceName)
                      ? findSelectedOption(actionOptionsObj, 'ignored')
                      : findSelectedOption(actionOptionsObj, 'managed'),
                    // subnets contain all the IPs of an interface
                    subnets: [ip],
                    defaultGateways: interfacevalue.default_gateway_address ? [defaultGateway] : [],
                  },
                };
              }

              return acc;
            },
            {[Symbol.for('count')]: 0},
          ),
        );
      }
    });

    return {
      workload: convertedWorkload,
      isEdit,
      managedWorkload,
      currentRouteParams,
      // Selector role, app, env, loc for labels
      role: labelValues.role || [],
      app: labelValues.app || [],
      env: labelValues.env || [],
      loc: labelValues.loc || [],
      hasWritePermission,
      optionalFeatures,
      interfacegrid,
      dnsReverseLookupMap,
      edgeEnabled,
      crowdstrikeEnabled,
    };
  },
);
