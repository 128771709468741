/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getVenLibraries} from 'containers/Ven/Library/VenLibraryState';
import {
  getRouteName,
  getRoutePreviousName,
  getRouteCurrentParams,
  isEnhancedDataCollectionEnabled,
  isEdge,
  isCrowdstrike,
} from 'containers/App/AppState';
import {
  formatReleaseValue,
  getTimeType,
  getTimeTypeValue,
  labelLocks,
} from 'containers/PairingProfile/PairingProfileUtils';
import {getPairingProfileInstance} from '../PairingProfileItemState';
import {getLabelsObject} from 'components/Pill/Label/LabelUtils';
import {isAPIAvailable} from 'api/apiUtils';

export const getInitialSelectedLabels = ({role, app, env, loc}) => {
  const labels = [...role, ...app, ...env, ...loc];
  const selectedLabels = {};

  labels.forEach(label => (selectedLabels[label.key] = label));

  return selectedLabels;
};

export const getPairingProfileEdit = createSelector(
  [
    createSelector([getRouteName, getPairingProfileInstance], (routeName, pairingProfile) =>
      routeName === 'app.pairingProfiles.create' ? {} : pairingProfile,
    ),
    getVenLibraries,
    getRouteName,
    getRoutePreviousName,
    getRouteCurrentParams,
    isEnhancedDataCollectionEnabled,
    isEdge,
    isCrowdstrike,
  ],
  (
    pairingProfile,
    venReleases,
    routeName,
    previousRouteName,
    currentRouteParams,
    hasEnhancedDataCollection,
    edgeEnabled,
    crowdstrikeEnabled,
  ) => {
    // Set up the label: [role, app, loc, env] values from User's pairing profile information
    const labelObject = getLabelsObject(pairingProfile.labels || []);
    const isEdit = routeName === 'app.pairingProfiles.item.edit';
    const hasWritePermission = isEdit
      ? Array.isArray(pairingProfile.caps) && pairingProfile.caps.includes('write')
      : isAPIAvailable('pairing_profiles.create');

    const labelValues = Object.keys(labelObject).reduce((label, cur) => {
      if (!cur) {
        return label;
      }

      label[cur] = [labelObject[cur]];

      return label;
    }, {});

    // Setting up label locks flag. When label lock is false for [app, env, loc, role]_label_loc
    // indicates from backend that the user's lock is checked,
    // e.g. app_label_lock === false - Application Label can be set (checked)
    //      app_label_lock === true - Application Label cannot be set (unchecked)
    const labelLocksValues = labelLocks.reduce((lock, cur) => {
      // Important to check for type boolean, a boolean false is considered a checked value or set value from the backend
      lock[cur] = typeof pairingProfile[cur] === 'boolean' ? pairingProfile[cur] : true;

      return lock;
    }, {});

    // Set pairingProfileMappings to initialize form toggling when creating and editing Pairing Profiles
    // Important to initialize specific Form Field properties to empty string e.g. '' to indicate default 'create' state
    const pairingProfileMappings = {
      // Form Field
      labelsLockValue: true,
      // Form Field
      lifespanTimeType: 'day',
      // Form Field - numeric as default value
      lifespanTimeValue: 1,
      // Form Field
      keyLifeSpanField: 'unlimited',
      // Form Field
      stateLockValue: true,
      // Selector role, app, env, loc for labels
      role: labelValues.role || [],
      app: labelValues.app || [],
      env: labelValues.env || [],
      loc: labelValues.loc || [],
      ...labelLocksValues,
    };

    pairingProfileMappings.labelsLockValue =
      !isEdit ||
      Boolean(
        pairingProfile.env_label_lock &&
          pairingProfile.loc_label_lock &&
          pairingProfile.app_label_lock &&
          pairingProfile.role_label_lock,
      );

    if (pairingProfile.agent_software_release) {
      pairingProfile = {
        ...pairingProfile,
        agent_software_release: formatReleaseValue(pairingProfile.agent_software_release),
      };
    }

    // Set stateLockValue to boolean value for any of these boolean [enforcement_mode_lock, visibility_level_lock]
    if (typeof pairingProfile.enforcement_mode_lock === 'boolean') {
      pairingProfileMappings.stateLockValue = pairingProfile.enforcement_mode_lock;
    }

    // Set Life Span Time and Type
    if (Number(pairingProfile.key_lifespan)) {
      pairingProfileMappings.lifespanTimeType = getTimeType(pairingProfile.key_lifespan);
      // lifespanTimeValue: convert to string for Form.Input value and formk isValid property to be set properly
      pairingProfileMappings.lifespanTimeValue = String(
        getTimeTypeValue(pairingProfileMappings.lifespanTimeType, pairingProfile.key_lifespan),
      );
      // Important: Need to change this to 'custom' to show proper selection
      pairingProfileMappings.keyLifeSpanField = 'custom';
    } else {
      pairingProfileMappings.keyLifeSpanField = pairingProfile.key_lifespan || pairingProfileMappings.keyLifeSpanField;
    }

    // Initialize name
    if (!pairingProfile.name) {
      pairingProfile = {...pairingProfile, name: ''};
    }

    const initialSelectedLabels = getInitialSelectedLabels(pairingProfileMappings);

    return {
      isEdit,
      venReleases,
      edgeEnabled,
      crowdstrikeEnabled,
      pairingProfile,
      previousRouteName,
      routeName,
      currentRouteParams,
      hasWritePermission,
      ...pairingProfileMappings,
      initialSelectedLabels,
      hasEnhancedDataCollection,
    };
  },
);
