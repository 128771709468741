/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {Icon} from 'components';
import styles from './Selectors.css';
import Item from './Item';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {tidUtils} from 'utils';

const iconStyle = {icon: styles.icon};

const defaultComboInputTid = 'comp-combobox-input';

export default class Input extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    clearAll: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    items: PropTypes.any,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    object: PropTypes.object,
    activeCategory: PropTypes.object,
    categories: PropTypes.array,
    showFacetName: PropTypes.bool,
    saveInputRef: PropTypes.func,
    onFocus: PropTypes.func,
    onToggle: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onClearItems: PropTypes.func,
    onItemDelete: PropTypes.func,
    noIcon: PropTypes.bool,
    tid: PropTypes.string,
  };

  static defaultProps = {
    tid: '',
    activeCategory: {},
    // Initialize to '' to prevent React from complaining about controlled vs uncontrolled
    value: '',
    disabled: false,
    error: false,
    noIcon: false,
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  getItemTextElem(item) {
    let name;

    if (item.value && item.value.value) {
      // if it is href object
      name = item.value.value;
    }

    name = name || item.value || item.name || item.hostname;

    const category = this.props.categories.find(({categoryKey}) => categoryKey === item.categoryKey);

    const showFacetName = category?.showFacetName ?? (this.props.showFacetName && item.categoryName && !item.key);

    if (showFacetName) {
      return (
        <span>
          {`${category?.facetName ?? item.categoryName}: `}
          <span className={styles.bold}>{name}</span>
        </span>
      );
    }

    return name;
  }

  getPlaceholderText(active, items, activeCategory, placeholder) {
    if (_.isEmpty(activeCategory)) {
      return;
    }

    if (activeCategory.placeholder) {
      return active ? activeCategory.placeholder : '';
    }

    if (active) {
      return `${activeCategory.value}:`;
    }

    return placeholder;
  }

  handleChange(evt) {
    const {
      onChange,
      object,
      activeCategory: {categoryKey},
    } = this.props;

    onChange(evt.target.value, object, categoryKey);
  }

  handleKeyDown(evt) {
    const {
      onKeyDown,
      object,
      activeCategory: {categoryKey},
    } = this.props;

    onKeyDown(evt, object, categoryKey);
  }

  render() {
    const {
      items,
      clearAll,
      value,
      active,
      disabled,
      error,
      placeholder,
      object,
      saveInputRef,
      onFocus,
      onToggle,
      onClearItems,
      onItemDelete,
      activeCategory,
      noIcon,
      tid,
    } = this.props;

    const classes = cx(styles.search, {
      [styles.active]: active,
      [styles.disabled]: disabled,
      [styles.error]: error,
    });

    const placeholderText = this.getPlaceholderText(active, items, activeCategory, placeholder);
    const inputDataTid = tidUtils.getTid(defaultComboInputTid, tid);

    return (
      <div className={classes} onClick={onFocus} data-tid="comp-combobox-selected-list">
        <div className={styles.items}>
          <div className={styles.itemContainer}>
            {items.map((item, i) => (
              <Item key={i} object={object} onClose={onItemDelete} value={item} noIcon={noIcon}>
                {this.getItemTextElem(item)}
              </Item>
            ))}
          </div>
          <input
            disabled={disabled}
            ref={saveInputRef}
            data-tid={inputDataTid}
            className={styles.input}
            type="text"
            value={value}
            placeholder={placeholderText}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
          />
        </div>
        {clearAll && items.length ? (
          <div className={styles.clearAll} data-tid="comp-icon comp-icon-clear-all">
            <Icon theme={iconStyle} name="clear" onClick={disabled ? undefined : onClearItems} />
          </div>
        ) : null}
        <div className={styles.close} data-tid="comp-icon comp-icon-caret-down">
          <Icon theme={iconStyle} name={active ? 'up' : 'down'} onClick={disabled ? undefined : onToggle} />
        </div>
      </div>
    );
  }
}
