/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {type OpaqueConfig, spring} from 'react-motion';

const verticalPosition = {
  close: {y: 25, opacity: 0},
  open: {y: 0, opacity: 1},
};

export const dropdownVertical = {
  positions: verticalPosition,
  enter: verticalPosition.close,
  open: {
    y: spring(verticalPosition.open.y, {stiffness: 1000, damping: 40, precision: 0.25}),
    opacity: spring(verticalPosition.open.opacity, {stiffness: 800, damping: 40, precision: 0.1}),
  },
  leave: {
    y: spring(verticalPosition.close.y, {stiffness: 800, damping: 40, precision: 0.5}),
    opacity: spring(verticalPosition.close.opacity, {stiffness: 700, damping: 40, precision: 0.1}),
  },
};

const horizontalPosition = {
  close: {x: 10, opacity: 0},
  open: {x: 0, opacity: 1},
};

export type DropdownTransitionPlainStyle = {
  x: number;
  y: number;
  opacity: number;
};

export type HorizontalStyle = {
  y: number | OpaqueConfig;
  x: number | OpaqueConfig;
  opacity: number | OpaqueConfig;
};

export const dropdownHorizontal = {
  positions: horizontalPosition,
  enter: (y?: number): {x: number; y?: number; opacity: number} => ({y, ...dropdownHorizontal.positions.close}),
  open: (y: number): HorizontalStyle => ({
    y: spring(y, {stiffness: 700, damping: 40, precision: 0.5}),
    x: spring(dropdownHorizontal.positions.open.x, {stiffness: 800, damping: 40, precision: 0.5}),
    opacity: spring(dropdownHorizontal.positions.open.opacity, {stiffness: 800, damping: 40, precision: 0.1}),
  }),
  leave: (y: number | OpaqueConfig): HorizontalStyle => ({
    y,
    x: spring(dropdownHorizontal.positions.close.x, {stiffness: 800, damping: 40, precision: 0.5}),
    opacity: spring(dropdownHorizontal.positions.close.opacity, {stiffness: 800, damping: 40, precision: 0.1}),
  }),
};

const itemsPosition = {
  close: {y: -30, opacity: 0},
  open: {y: 0, opacity: 1},
};

const itemsConfig = {
  y: {stiffness: 700, damping: 40, precision: 0.5},
  size: {stiffness: 700, damping: 40, precision: 0.5},
  opacity: {stiffness: 700, damping: 40, precision: 0.1},
};

export type MotionDirection = 0 | 1 | -1;

// Items container and items list
export const items = {
  positions: itemsPosition,
  config: itemsConfig,
  containerSizes: ({width, height}: {width: number; height: number}): {width: OpaqueConfig; height: OpaqueConfig} => ({
    width: spring(width, items.config.size),
    height: spring(height, items.config.size),
  }),
  listEnter: (dir: MotionDirection = 0): {y: number; opacity: number} => ({
    y: items.positions.close.y * dir,
    opacity: items.positions.close.opacity,
  }),
  listActive: {
    y: spring(itemsPosition.open.y, itemsConfig.y),
    opacity: spring(itemsPosition.open.opacity, itemsConfig.opacity),
  },
  listLeave: (dir: MotionDirection = 0): {y: OpaqueConfig; opacity: OpaqueConfig} => ({
    y: spring(items.positions.close.y * dir, items.config.y),
    opacity: spring(items.positions.close.opacity, items.config.opacity),
  }),
};
