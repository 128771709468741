/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {call, put, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {getWorkloadInstance} from '../WorkloadItemState';

export function* fetchWorkloadCompatibilityReport() {
  const workload = yield select(getWorkloadInstance);

  const {data} = yield call(apiSaga, 'ven.compatibility_report', {
    params: {
      ven_id: workload.ven.href.split('/').pop(),
    },
  });

  yield put({type: 'WORKLOAD_GET_REPORT', data});
}
