/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {portUtils} from 'utils';
import {ExpandableList, Pill} from 'components';
import utilStyles from 'utils.css';

export const formatRuleService = portProtoItems =>
  portProtoItems.map(portProtoItem => {
    const {port, proto} = portProtoItem;
    const allServices = Array.isArray(port) && port.length === 1 && port[0] === null && proto === null;
    const serviceName = [];

    if (portProtoItem.pp) {
      serviceName.push(portUtils.stringifyPortObjectReadonly(portProtoItem.pp[0]));
    }

    if (portProtoItem.p) {
      serviceName.push(portProtoItem.p);
    }

    if (portProtoItem.s) {
      serviceName.push(portProtoItem.s);
    }

    const name = allServices
      ? intl('Common.AllServices')
      : serviceName.length > 0
      ? serviceName.join(',')
      : portUtils.stringifyPortObjectReadonly(portProtoItem);

    return <Pill.Service key={name} value={{name}} />;
  });

export const formatRuleAddress = value => {
  if (value.length === 0) {
    return intl('Common.NoPeers');
  }

  const addresses = _.uniq(value)
    .sort()
    .map(address => <div key={address}>{address}</div>);

  return <ExpandableList values={addresses} count={3} title={intl('PolicyGenerator.Addresses')} />;
};

export const formatCustomRule = row => (
  <div>
    <div className={utilStyles.bold}>
      {intl('Common.Chain')} {row.chain}
    </div>
    {row.rules.ipv4.length ? (
      <div>
        <div>{intl('Protocol.IPv4')}:</div>
        {row.rules.ipv4.map(rule => (
          <div key={rule}>{rule}</div>
        ))}
      </div>
    ) : null}
    {row.rules.ipv6.length ? (
      <div>
        <div>{intl('Protocol.IPv6')}:</div>
        {row.rules.ipv6.map(rule => (
          <div key={rule}>{rule}</div>
        ))}
      </div>
    ) : null}
  </div>
);
