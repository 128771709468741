/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import {combineReducers} from 'redux';
import {edge} from 'api/apiUtils';
import {getHelpUrl, getHelpVersion, isLDAPEnabled} from 'containers/App/AppState';
import {isSuperclusterMember, isUserOwner} from 'containers/User/UserState';
import ldapConfigReducers from './LDAP/View/AuthenticationSettingsLDAPViewState';

export default {
  authenticationSettings: combineReducers({
    settings(state = {}, action) {
      switch (action.type) {
        case 'AUTHENTICATION_GET_SETTINGS':
          return action.data;
        default:
          return state;
      }
    },
    ldap: combineReducers({
      ...ldapConfigReducers,
      ldapConfigs(state = {}, action) {
        switch (action.type) {
          case 'GET_LDAP_CONFIGS':
            return action.data;
          default:
            return state;
        }
      },
      verifyConnection(state = {}, action) {
        switch (action.type) {
          case 'UPDATE_VERIFY_CONNECTION':
            return action.data;
          default:
            return state;
        }
      },
    }),
    saml: combineReducers({
      config(state = {}, action) {
        switch (action.type) {
          case 'SAML_CONFIGS_GET_CONFIG':
            return action.data;
          default:
            return state;
        }
      },
      samlConfigs(state = {}, action) {
        switch (action.type) {
          case 'GET_SAML_CONFIGS':
            return action.data;
          default:
            return state;
        }
      },
    }),
  }),
};

export const getSettings = state => state.authenticationSettings.settings;
export const getVerifyConnection = state => state.authenticationSettings.ldap.verifyConnection;

export const getHelpLinks = createSelector(getHelpUrl, getHelpVersion, (url, version) => {
  const helpLinkBase = edge
    ? `${url}/${version}/Guides/illumio-edge-usage/user-management/`
    : `${url}/${version}/Guides/pce-administration/access-configuration/`;

  return {
    onelogin: {
      link: `${helpLinkBase}onelogin-single-sign-on.htm`,
      displayName: intl('Users.SSOConfig.OneLogin'),
      tid: 'onelogin',
    },
    adfs: {
      link: `${helpLinkBase}active-directory-single-sign-on.htm`,
      displayName: intl('Users.SSOConfig.ActiveDirectory'),
      tid: 'adfs',
    },
    azureAD: {
      link: `${helpLinkBase}azure-single-sign-on.htm`,
      displayName: intl('Users.SSOConfig.AzureAD'),
      tid: 'azureAD',
    },
    okta: {
      link: `${helpLinkBase}okta-single-sign-on.htm`,
      displayName: intl('Users.SSOConfig.Okta'),
      tid: 'okta',
    },
    ping: {
      link: `${helpLinkBase}ping-identity-single-sign-on.htm`,
      displayName: intl('Users.SSOConfig.PingIdentity'),
      tid: 'ping',
    },
  };
});

export const getAuthenticationSettingsPage = createSelector(
  isLDAPEnabled,
  getSettings,
  getHelpLinks,
  isSuperclusterMember,
  (ldapIsEnabled, settings, helpLinks, userIsSuperclusterMember) => ({
    ldapIsEnabled,
    settings,
    helpLinks,
    userIsSuperclusterMember,
  }),
);

export const isAuthenticationSettingsEnabled = createSelector(isUserOwner, userIsOwner => userIsOwner);
