/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {connect} from 'react-redux';
import {call} from 'redux-saga/effects';
import {Component} from 'react';
import {apiCachedResponses} from 'api/apiSaga';
import {AppContext} from 'containers/App/AppUtils';
import {fetchSupportReportsListPage, fetchSupportReportsList, createSupportReport} from './SupportReportsListSaga';
import {getSupportReportsListPage} from './SupportReportsListState';
import SupportListReducers from 'containers/SupportBundles/VENSupportReports/SupportReportsState';
import {Grid, Banner, Tally, Notifications, Modal} from 'components';
import {reactUtils, sagasUtils} from 'utils';
import styles from './SupportReportsList.css';
import {getMaxPageNotificationList} from 'components/Grid/GridUtils';

@connect(getSupportReportsListPage, null, null, {forwardRef: true})
export default class SupportReportsList extends Component {
  static prefetch = fetchSupportReportsListPage;
  static contextType = AppContext;
  static reducers = SupportListReducers;

  constructor(props) {
    super(props);

    this.state = {
      PCEReportConfirmation: false,
      PCEReportCreationPending: false,
    };

    this.handleGenerateReport = this.handleGenerateReport.bind(this);
    this.handleStartReport = this.handleStartReport.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    this.startPolling();
  }

  componentDidUpdate(prevProps) {
    // Restart polling if ven has changed
    if (this.props.ven !== prevProps.ven) {
      this.startPolling();
    }
  }

  componentWillUnmount() {
    // Drop support report cache, in case user will return back on that page, it should refetch immediately
    apiCachedResponses.removeByMethodName('support_report_requests.get_collection');

    if (this.pollingTask) {
      this.context.fetcher.cancel(this.pollingTask);
    }
  }

  handleRefresh() {
    // Return promise, so Pagination component can publish event for QA
    return this.context.fetcher.fork(fetchSupportReportsListPage.refetch);
  }

  handleCancel() {
    this.setState({PCEReportConfirmation: false, PCEReportCreationPending: false});
  }

  async handleStartReport() {
    // First, cancel current polling
    this.context.fetcher.cancel(this.pollingTask);

    try {
      await reactUtils.setStateAsync({PCEReportCreationPending: true}, this);

      // Call api to create PCE report using spawn (let it finish even if we leave the page)
      await this.context.fetcher.spawn(createSupportReport, {ven: this.props.ven});

      if (!this.context.fetcher.unmounted) {
        await reactUtils.setStateAsync({PCEReportConfirmation: false, PCEReportCreationPending: false}, this);
      }
    } catch (error) {
      console.log(error);
    }

    // Start polling again immediately (if component is already unmounted, fetcher.fork will do nothing)
    this.startPolling(true);
  }

  handleGenerateReport() {
    if (this.props.pceReport && this.props.pceReport.status === 'finished') {
      this.setState({PCEReportConfirmation: true});
    } else {
      this.handleStartReport();
    }
  }

  startPolling(instantStart) {
    this.pollingTask = this.context.fetcher.fork(sagasUtils.startPolling, {
      saga: call(fetchSupportReportsList, {ven: this.props.ven, force: true}),
      interval: 10_000,
      instantStart,
    });
  }

  render() {
    const {
      state: {PCEReportConfirmation, PCEReportCreationPending},
      props: {grid, pceReport, count},
    } = this;

    const tallyPCEItems = [
      {
        children: intl('SupportReports.PageName'),
        count: count && count.total ? count.total : 0,
      },
    ];

    let pceReportBanner;

    if (pceReport) {
      if (pceReport.status === 'finished') {
        pceReportBanner = (
          <Banner
            type="success"
            subText={intl('SupportReports.RequestedByAt', {user: pceReport.username, when: pceReport.created_at})}
          >
            {intl('SupportReports.SupportReportWasGenerated')}
          </Banner>
        );
      } else if (pceReport.status === 'failed') {
        pceReportBanner = <Banner type="progress">{intl('SupportReports.SupportReportBeingGenerated')}</Banner>;
      } else {
        pceReportBanner = (
          <Banner
            type="progress"
            subText={intl('SupportReports.RequestedByAt', {user: pceReport.username, when: pceReport.created_at})}
          >
            {intl('SupportReports.SupportReportBeingGenerated')}
          </Banner>
        );
      }
    }

    const notifications = getMaxPageNotificationList({page: grid.page, capacity: grid.capacity, count});

    return (
      <>
        {notifications.length > 0 && <Notifications sidebar>{notifications}</Notifications>}
        <Tally tallyItems={tallyPCEItems} />
        {pceReportBanner}
        <Grid
          grid={grid}
          theme={styles}
          count={count}
          onSelect={this.handleSelect}
          emptyMessage={intl('SupportReports.NoData')}
        />
        {PCEReportConfirmation && (
          <Modal.Confirmation
            confirmIsInProgress={PCEReportCreationPending}
            onConfirm={this.handleStartReport}
            onCancel={this.handleCancel}
          >
            {intl('SupportReports.NewReportOverwritesExistingReport')}
          </Modal.Confirmation>
        )}
      </>
    );
  }
}
