/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {Pill, type PillProps} from 'components';
import {hrefUtils} from 'utils';
import {getUpdateTypeProps} from '../PillUtils';
import type {IPList as IPListData} from 'illumio';

export type IPListProps = {
  value: IPListData;

  // policy version
  pversion?: string | number;

  noIcon?: boolean;
} & PillProps;

export default class IPList extends PureComponent<IPListProps> {
  element: HTMLElement | null = null;
  constructor(props: IPListProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: Pill) {
    this.element = element?.element;
  }

  render() {
    const {value, pversion, updateTypeTooltip, noIcon, ...pillProps} = this.props;

    Object.assign(pillProps, {
      ref: this.saveRef,
      icon: noIcon ? null : value.fqdn ? 'domain' : 'allowlist',
      link:
        pillProps.onClick || (!value.id && !value.href)
          ? null
          : {to: 'iplists.item.view', params: {pversion, id: value.id ?? hrefUtils.getId(value.href)}},
      ...getUpdateTypeProps({
        object: 'iplist',
        updateType: value.update_type,
        updateTypeTooltip,
        deleted: pillProps.deleted,
        pversion,
      }),
    });

    return <Pill {...pillProps}>{value.name}</Pill>;
  }
}
