/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createContext} from 'react';

export const GatewayContext = createContext({});

/**
 * GatewayController is a dispatcher, instance of which is passed down the react tree by the GatewayProvider and
 * gets consumed by the Gateway and GatewayTarget components,
 * and is used by them to pass children from Gateway to GatewayTarget by the name.
 *
 * For example,
 * ####################################################################
 * Gateway into="T1"  ↘                                               #
 * Gateway into="T2" -> GatewayController -> GatewayTarget name="T1"  #
 * Gateway into="T1"  ↗                    ↘ GatewayTarget name="T2"  #
 * ####################################################################
 */
export default class GatewayController {
  constructor() {
    this.targets = new Map();
    this.children = new Map();
    this.nextChildId = 1;
  }

  checkInTarget(name, instance) {
    if (this.targets.has(name)) {
      if (__DEV__ && this.targets.get(name).instance) {
        throw new Error('There cannot be two GatewayTargets with the same name');
      }

      this.targets.get(name).instance = instance;
    } else {
      this.targets.set(name, {instance, children: new Map()});
    }

    this.renderTarget(name);
  }

  renderTarget(name) {
    const target = this.targets.get(name);

    if (target && target.instance) {
      target.instance.setChildren(target.children);
    }
  }

  checkOutTarget(name) {
    const target = this.targets.get(name);

    if (target) {
      target.instance = null;

      if (!target.children.size) {
        this.targets.delete(name);
      }
    }
  }

  checkInChild(props, appContext, id) {
    if (!id) {
      id = this.nextChildId++;
    }

    const {into: targetName} = props;
    const {targets, children} = this;
    let target = targets.get(targetName);

    if (!target) {
      target = {instance: null, children: new Map()};
      targets.set(targetName, target);
    }

    children.set(id, targetName);
    target.children.set(id, {props, appContext});

    return id;
  }

  renderChild(props, appContext, id) {
    if (id) {
      const newTargetName = props.into;
      const previousTargetName = this.children.get(id);

      if (newTargetName === previousTargetName) {
        this.targets.get(newTargetName).children.set(id, {props, appContext});
      } else {
        this.checkOutChild(id, false);
        this.checkInChild(props, appContext, id);
      }
    } else {
      id = this.checkInChild(props, appContext);
    }

    this.renderTarget(props.into, id);

    return id;
  }

  checkOutChild(id, rerender = true) {
    const targetName = this.children.get(id);

    if (targetName) {
      this.children.delete(id);

      const target = this.targets.get(targetName);

      if (target) {
        target.children.delete(id);

        if (target.instance) {
          if (rerender) {
            this.renderTarget(targetName);
          }
        } else if (!target.children.size) {
          this.targets.delete(targetName);
        }
      }
    }
  }
}
