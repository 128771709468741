/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call} from 'redux-saga/effects';
import {cachedResponses} from 'api/apiCache';

export function* createLoadBalancerFiles(payload) {
  return yield call(apiSaga, 'slbs.create', {
    data: payload,
    *onDone() {
      // After load balancer is created invalidate load balancer list cache
      cachedResponses.removeByMethodName('slbs.get_collection');
    },
  });
}
