/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import styles from './FilterPanel.css';

export default class FilterOption extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    category: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onHover: PropTypes.func.isRequired,
    saveRef: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    subcategory: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.saveRef = this.saveRef.bind(this);
  }

  saveRef(element) {
    this.option = element;
    this.props.saveRef(this);
  }

  handleClick() {
    const {category, onClick, subcategory} = this.props;

    onClick(category, subcategory);
  }

  handleHover() {
    this.props.onHover(this);
  }

  render() {
    const {active, children, subcategory, category, className, selected} = this.props;

    const classes = cx(styles[className], {
      [styles.selected]: selected,
      [styles.active]: active,
    });

    return (
      <div
        data-tid={subcategory ?? _.kebabCase(category)}
        className={classes}
        tabIndex={-1}
        ref={this.saveRef}
        onMouseMove={this.handleHover}
        onClick={this.handleClick}
      >
        {children}
      </div>
    );
  }
}
