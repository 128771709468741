/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {select} from 'redux-saga/effects';
import {RedirectError} from 'errors';
import {isUserOwner} from 'containers/User/UserState';

export const isAPIKeySettingsEnabled = createSelector(isUserOwner, userIsOwner => userIsOwner);

export function* verifyUserPermissions() {
  const apiKeySettingsIsEnabled = yield select(isAPIKeySettingsEnabled);

  if (!apiKeySettingsIsEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }
}
