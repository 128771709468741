/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import {getWorkloadInstance} from '../WorkloadItemState';

export function* fetchVirtualServiceWorkloadsBind(refetch = false) {
  const workload = yield select(getWorkloadInstance);

  return yield all([
    call(apiSaga, 'service_bindings.get_collection', {
      query: {workload: workload.href, representation: 'expanded_virtual_services_resolved_service_ports'},
      cache: !refetch,
      *onDone({data: list, count}) {
        yield put({type: 'WORKLOADS_VIRTUAL_SERVICE_BIND', data: {list, count}});

        return {list, count};
      },
    }),
  ]);
}
