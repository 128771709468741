/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {Pill} from 'components/index';
import {hrefUtils} from 'utils';
import {getFriendlyName} from './WorkloadUtils';
import type {Workload as WorkloadData} from 'illumio';

interface WorkloadProps {
  value: WorkloadData;
}

export default class Workload extends PureComponent<WorkloadProps> {
  element: HTMLElement | null = null;

  constructor(props: WorkloadProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: Pill) {
    this.element = element?.element;
  }

  render() {
    const {value, ...pillProps} = this.props;

    Object.assign(pillProps, {
      icon: 'workload',
      link: {to: 'workloads.item.view', params: {id: hrefUtils.getId(value.href)}},
    });

    return (
      <Pill {...pillProps} ref={this.saveRef}>
        {getFriendlyName(value)}
      </Pill>
    );
  }
}
