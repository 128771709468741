/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect/lib/index';
import {getRouteName, isCrowdstrike, isEdge} from '../App/AppState';

export default {
  status(state = {}, action) {
    switch (action.type) {
      case 'WORKLOAD_VEN_STATUS':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getWorkloadVenStatus = state => state.workload.status;

export const getWorkloadManagementStatus = createSelector(
  [getRouteName, getWorkloadVenStatus, isCrowdstrike, isEdge],
  (routeName, status, isCrowdstrike, isEdge) => ({
    routeName,
    status,
    isCrowdstrike,
    isEdge,
  }),
);
