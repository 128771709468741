/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {useEffect, useState, useCallback} from 'react';
import PubSub from 'pubsub';
import {Modal} from 'components';

const defaultModalProps = {
  tid: 'unsaved-warning',
  small: true,
  title: intl('RBAC.ConfirmLeaveTitle'),
  confirmProps: {text: intl('Common.Leave')},
};

const initialState = {onUnsavedPending: null};
export default function UnsavedPendingWarning() {
  const [popupState, setPopupState] = useState(initialState);
  const [modalProps, setModalProps] = useState({});
  const [content, setContent] = useState(null);

  const handleClick = useCallback(
    action => {
      popupState.onUnsavedPending(action);
      setPopupState(initialState);
    },
    [popupState],
  );

  useEffect(() => {
    const token = PubSub.subscribe(
      'UNSAVED.WARNING',
      ({resolve = null, modalProps, content} = {}) => {
        setPopupState({onUnsavedPending: resolve});
        setModalProps(modalProps);
        setContent(content);
      },
      {
        getLast: true,
      },
    );

    return () => PubSub.unsubscribe(token);
  }, []);

  if (!popupState.onUnsavedPending) {
    return null;
  }

  return (
    <Modal.Confirmation
      onCancel={_.partial(handleClick, 'cancel')}
      onConfirm={handleClick}
      {...defaultModalProps}
      {...modalProps}
    >
      {content ?? intl('Common.UnsavedPendingWarning')}
    </Modal.Confirmation>
  );
}
