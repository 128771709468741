/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {PureComponent} from 'react';
import {HeaderMenu} from 'containers';
import {connect} from 'react-redux';
import {Icon, CounterBadge, MenuItem} from 'components';
import {getTotalCount} from 'containers/Provisioning/Pending/List/PendingListState';
import styles from './ProvisionMenu.css';
import ProvisionProgressMenu from './ProvisionProgressMenu';

const menuTheme = _.pick(styles, ['menu', 'trigger', 'dropdown', 'dropdownWithArrow', 'item']);

@connect(state => ({count: getTotalCount(state)}))
export default class ProvisionMenu extends PureComponent {
  render() {
    const {count} = this.props;

    return (
      <div className={styles.provision} data-tid="comp-navbar-provision">
        <HeaderMenu theme={menuTheme} data-tid="provision" iconBefore={<Icon name="provision" />}>
          <MenuItem
            link="pending"
            text={intl('Provision.DraftChangeCount', {count})}
            data-tid="comp-navbar-provision"
          />
          <MenuItem
            link="versions.list"
            text={intl('Provision.Versions', {multiple: true})}
            data-tid="comp-navbar-versions"
          />
          <MenuItem link="provisioning" text={<ProvisionProgressMenu />} data-tid="comp-navbar-provision-bar" />
        </HeaderMenu>

        <CounterBadge color="orange" count={count} theme={styles} />
      </div>
    );
  }
}
