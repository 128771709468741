/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {put, select, call} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import Cache from 'utils/cache';
import fetcher from 'api/fetcher';
import {getSuggestedServices} from './List/ServiceListUtils';

export function* fetchServiceInstance({pversion, service_id}) {
  return yield call(apiSaga, 'services.get_instance', {
    params: {pversion: pversion || 'draft', service_id},
  });
}

export const serviceDefinitionsCache = new Cache({lru: false});

export function* fetchDefaultServices({force = false} = {}) {
  let serviceDefinitions;
  const cacheKey = 'service_definitions';

  try {
    const cachedServices = serviceDefinitionsCache.find(cacheKey);

    if (!force && cachedServices !== undefined) {
      serviceDefinitions = cachedServices;
    } else {
      const request = yield fetcher({
        url: '/static/service_definitions',
        method: 'GET',
        headers: {Accept: 'application/json'},
      });
      const {data} = yield request.promise;

      serviceDefinitions = data;
      serviceDefinitionsCache.add(cacheKey, serviceDefinitions);
    }
  } catch (error) {
    if (__DEV__) {
      console.error('Fetching service definitions failed', error);
    }
  }

  if (force || serviceDefinitions?.services !== (yield select(getSuggestedServices))) {
    yield put({type: 'SERVICE_DEFINITIONS_GET_LIST', data: serviceDefinitions?.services || []});
  }
}
