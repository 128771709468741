/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

/**
 * Returns stringified routeParams
 * Manually remove null params, needed for omitting existing params on merge by setting them to null
 */
export function processParams(params?: Record<string, unknown>): Record<string, unknown> {
  const routeParams: Record<string, unknown> = {};

  if (!params) {
    return routeParams;
  }

  for (const paramName in params) {
    if (params.hasOwnProperty(paramName)) {
      const paramValue = params[paramName];
      const paramType = typeof paramValue;

      if (paramValue !== null) {
        routeParams[paramName] = paramValue;

        if (paramType === 'number') {
          routeParams[paramName] = String(paramValue);
        } else if (paramType === 'object' && !Array.isArray(paramValue)) {
          routeParams[paramName] = JSON.stringify(paramValue);
        }
      }
    }
  }

  return routeParams;
}
