/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component} from 'react';
import {select} from 'redux-saga/effects';
import {RedirectError} from 'errors';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {HeaderProps} from 'containers';
import {Button, Modal, TypedMessages, ToolGroup, ToolBar, Form, AttributeList} from 'components';
import {reactUtils} from 'utils';
import {object, string} from 'yup';
import {getVenItemPage, isVenEditEnabled} from '../VenItemState';
import SupportReportsReducers from 'containers/SupportBundles/VENSupportReports/SupportReportsState';
import {updateVen} from '../Summary/VenSummarySaga';
import {fetchVenItem} from '../../VenSaga';
import {isAPIAvailable} from 'api/apiUtils';

//Get formik's mandatory initialValues props for form setup
const getInitialValues = props => {
  const {ven} = props;
  const initialValues = {
    name: ven.name || ven.hostname || '',
    description: ven.description || '',
  };

  return initialValues;
};

// Initial State
const getInitialState = props => ({
  initialValues: getInitialValues(props),
  saving: false,
  error: null,
  href: props.ven.href,
});

@connect(getVenItemPage)
export default class VenEdit extends Component {
  static contextType = AppContext;
  static reducers = SupportReportsReducers;
  static prefetch = function* () {
    const venEditIsEnabled = yield select(isVenEditEnabled);

    if (!venEditIsEnabled) {
      throw new RedirectError({
        to: 'workloads.vens.item',
        thisFetchIsDone: true,
      });
    }
  };

  constructor(props) {
    super(props);

    this.renderForm = this.renderForm.bind(this);
    this.handleSubmit = _.noop;
    this.handleSave = this.handleSave.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);

    const initialSchema = {
      name: string().max(255, intl('Common.NameIsTooLong')),
      description: string().notRequired(),
    };

    this.schemas = object(initialSchema);

    this.state = getInitialState(props);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // When a different load balancer name is needed to reinitialized the state. API
    // returns name as unique.
    if (prevState.href !== nextProps.ven.href) {
      return getInitialState(nextProps);
    }

    return null;
  }

  async handleSave() {
    const {ven, currentRouteParams} = this.props;
    const {fetcher, navigate} = this.context;
    const {values} = this.formik;

    const payload = {};

    if (values.name !== ven.name) {
      payload.name = values.name.toString().trim();
    }

    if (values.description !== ven.description) {
      payload.description = values.description.toString().trim();
    }

    try {
      const id = currentRouteParams.id;

      await reactUtils.setStateAsync({saving: true}, this);
      await fetcher.spawn(updateVen, {id, payload});
      await fetcher.fork(fetchVenItem, {params: {id}}, true);

      // Wait progress on save button to finish
      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false}));
      // Navigate to a view page
      navigate({to: 'workloads.vens.item', params: {id}});
    } catch (error) {
      this.setState({error, saving: false});
    }
  }

  // Handle Edit Error
  handleErrorClose() {
    this.setState({error: null});
  }

  // Render alert message when edit or create fails
  renderErrorAlert() {
    const {error} = this.state;
    const title = intl('VEN.EditError');
    const message = _.get(error, 'data[0].message', error.message);

    return (
      <Modal.Alert title={title} buttonProps={{tid: 'ok', text: intl('Common.OK'), onClick: this.handleErrorClose}}>
        <TypedMessages>{[{icon: 'success', content: message}]}</TypedMessages>
      </Modal.Alert>
    );
  }

  renderForm(options) {
    const {isValid} = options;

    this.formik = options;

    const {saving, onSaveDone, error} = this.state;
    const {
      currentRouteParams: {id},
    } = this.props;
    const viewLink = {to: 'workloads.vens.item.view', params: {id}};

    const deviceDetail = [
      {
        tid: 'slb-name',
        key: <Form.Label name="name" title={intl('Common.Name')} />,
        value: <Form.Input tid="name" placeholder={intl('LoadBalancers.Create.CreateName')} name="name" />,
      },
      {
        tid: 'slb-description',
        key: <Form.Label name="description" title={intl('Common.Description')} />,
        value: (
          <Form.Textarea
            tid="description"
            placeholder={intl('LoadBalancers.Create.CreateDescription')}
            name="description"
          />
        ),
      },
    ];

    return (
      <>
        <HeaderProps label={`(${intl('Common.Edit')})`} up={viewLink} />
        <ToolBar>
          <ToolGroup>
            <Button
              textIsHideable
              icon="save"
              type="submit"
              text={intl('Common.Save')}
              tid="save"
              disabled={isValid === false || !isAPIAvailable('ven.update')}
              onClick={this.handleSave}
              onProgressDone={onSaveDone}
              progressCompleteWithCheckmark
              progress={saving}
              progressError={error !== null}
            />
            <Button.Link
              textIsHideable
              color="standard"
              disabled={saving || Boolean(onSaveDone)}
              icon="cancel"
              tid="cancel"
              link={viewLink}
              text={intl('Common.Cancel')}
            />
          </ToolGroup>
        </ToolBar>
        <AttributeList>{deviceDetail}</AttributeList>
        {error && this.renderErrorAlert()}
      </>
    );
  }

  render() {
    return (
      <Form
        enableReinitialize
        schemas={this.schemas}
        initialValues={this.state.initialValues}
        onSubmit={this.handleSubmit}
      >
        {this.renderForm}
      </Form>
    );
  }
}
