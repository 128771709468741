/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Component} from 'react';
import {delay, select} from 'redux-saga/effects';
import {webStorageUtils} from 'utils';
import {getRouteCurrent, getRouteCurrentName, getRouteCurrentParams} from 'containers/App/AppState';

export default class JumpToOld extends Component {
  static prefetch = function* ({router, name, params, prefetcher}) {
    const href = `${window.location.origin}${router.buildUrl(name, params)}`;
    const route = yield select(getRouteCurrent);
    const routeName = yield select(getRouteCurrentName);

    // Router5 doesn't have separate object of query params,
    // but we can determine which parameter is query parameter by checking route meta
    const queryParamNamesSet = Object.values(route.meta.params).reduce((set, params) => {
      for (const [param, type] of Object.entries(params)) {
        if (type === 'query') {
          set.add(param);
        }
      }

      return set;
    }, new Set());

    webStorageUtils.setSessionItem(
      'PreviousRoute',
      // Split router5 params object into two objects for react-router: params and query params
      // {sort: '-name', pversion: 'draft'}
      Object.entries(yield select(getRouteCurrentParams)).reduce(
        (result, [param, value]) => {
          if (queryParamNamesSet.has(param)) {
            result.query[param] = value;
          } else {
            result.params[param] = value;
          }

          return result;
        },
        {
          route: routeName.replace('app.', ''),
          params: {},
          query: {},
        },
      ),
    );

    router.stop();

    // For sharing user between tessereact<->legacy page. #USERJUMP
    if (window['--JUMPS_USER--']) {
      webStorageUtils.setSessionItem('JUMPS_USER', window['--JUMPS_USER--']);
    }

    if (_.trim(location.href, '/') !== _.trim(href, '/')) {
      // Manually invoke scroll saving before changing location,
      // otherwise 'beforeunload' in Prefetcher would save position to the new location state
      prefetcher.saveScroll();
      // Reset formIsDirty in prefetcher to prevent 'beforeunload' displaying browser native leave page warning
      prefetcher.formIsDirty = false;
      // Change location for redirect
      window.location = href + '/';
    }

    window.location.reload(false);
    yield delay(1e4); // Because reload is not instant
  };

  render() {
    return null;
  }
}
