/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import styles from './Selectors.css';
import * as PropTypes from 'prop-types';
import {Pill} from 'components';
import {PureComponent} from 'react';
import {pillIconMap} from './SelectorUtils';
import {edge} from 'api/apiUtils';

export default class Item extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    value: PropTypes.any,
    object: PropTypes.object,
    noIcon: PropTypes.bool,
  };

  static defaultProps = {
    object: {},
    noIcon: false,
  };

  constructor(props) {
    super(props);

    this.handleItemDelete = this.handleItemDelete.bind(this);
  }

  handleItemDelete(evt) {
    const {value, object, onClose} = this.props;

    onClose(evt, value, object);
  }

  render() {
    const {
      value,
      children,
      object: {admin: isAdmin},
      noIcon,
    } = this.props;
    const labelType = value.type || value.key || value.categoryKey;
    const isGroupPill = edge && (value?.key === 'role' || value?.type === 'role');

    const props = {
      icon: noIcon
        ? null
        : isGroupPill
        ? isAdmin
          ? 'admin'
          : null
        : pillIconMap()[labelType] === 'workload' && value.name
        ? 'unmanaged'
        : pillIconMap()[labelType],
      onClose: this.handleItemDelete,
      group: Boolean(value.name) && pillIconMap()[labelType] !== 'workload' && pillIconMap()[labelType] !== 'allowlist',
      pinned: value.scope,
      deleted: value.deleted,
    };

    return (
      <div className={styles.item} data-tid="comp-combobox-selected">
        <div className={styles.labelWrapper} data-tid="comp-combobox-selected-value">
          <Pill {...props}>{children}</Pill>
        </div>
      </div>
    );
  }
}
