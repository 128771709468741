/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {call, put, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {RedirectError} from 'errors';
import {getAppGroupLabelTypes, isIlluminationApiEnabled} from 'containers/App/AppState';
import {getContainerWorkloadInstance} from './Item/ContainerWokloadItemState';
import {getNodeAppGroupParent} from 'containers/Explorer/ExplorerUtils';

export function* fetchContainerWorkload({id, force = false}) {
  return yield call(apiSaga, 'container_workloads.get_instance', {
    params: {container_workload_id: id},
    cache: !force,
    *onDone({data}) {
      const appGroupType = yield select(getAppGroupLabelTypes);
      const appGroup = getNodeAppGroupParent(data, appGroupType);
      const appGroupParent = {app_group_keys: JSON.stringify([appGroup])};
      let capsResponse;

      if (appGroupParent && isIlluminationApiEnabled) {
        try {
          capsResponse = yield call(apiSaga, 'network_traffic.caps', {query: appGroupParent});
        } catch {
          console.error('Permission Error on Container Workload');
        }
      }

      const rulesetCaps = capsResponse?.data[appGroup]?.caps.rule_sets?.includes('read');

      if (force || data !== (yield select(getContainerWorkloadInstance))) {
        data.rulesetCaps = rulesetCaps;
        yield put({type: 'CONTAINERWORKLOAD_GET_INSTANCE', data});
      }
    },
  });
}

export function* fetchContainerWorkloadItem({params: {id}}, refetch = false) {
  try {
    yield call(fetchContainerWorkload, {id, force: refetch});
  } catch {
    throw new RedirectError({to: 'workloads.containers.list', proceedFetching: true, thisFetchIsDone: true});
  }
}
