/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {all, call, put, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {getContainerWorkloadProfileDetail} from './ContainerClusterContainerWorkloadProfileDetailState';
import {RedirectError} from 'errors';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {isUserReadOnlyClusterInsensitive} from 'containers/User/UserState';
import {fetchContainerClusterDetail} from '../../ContainerClusterDetailSaga';
import {isLocalContainerCluster} from '../../ContainerClusterDetailState';

export function* fetchContainerWorkloadProfileDetail({params, force = false} = {}) {
  let detail = null;

  if (params.profileId) {
    // for add, no profileId
    try {
      yield call(apiSaga, 'container_workload_profiles.get_instance', {
        params: {container_cluster_id: params.id, container_workload_profile_id: params.profileId},
        cache: !force,
        query: {representation: 'container_workload_profile_labels'},
        *onDone({data}) {
          detail = data;
        },
      });
    } catch {
      throw new RedirectError({
        to: 'containerClusters.item.profiles.list',
        params: {id: params.id},
        proceedFetching: true,
        thisFetchIsDone: true,
      });
    }
  }

  if (force || (yield select(getContainerWorkloadProfileDetail) !== detail)) {
    yield put({type: 'CONTAINER_WORKLOAD_PROFILE_GET_DETAIL', data: {containerWorkloadProfileDetail: detail}});
  }

  return {containerWorkloadProfileDetail: detail};
}

export function* fetchContainerWorkloadProfileInstance({params, route = {}}, refetch) {
  const readOnly = yield select(isUserReadOnlyClusterInsensitive);

  if (route.name === 'app.containerClusters.item.profiles.create') {
    if (readOnly || !(yield select(isLocalContainerCluster))) {
      throw new RedirectError({
        to: 'containerClusters.item.profiles.list',
        params,
        proceedFetching: true,
        thisFetchIsDone: true,
      });
    }
  }

  if (route.name === 'app.containerClusters.item.profiles.edit') {
    if (readOnly || !(yield select(isLocalContainerCluster))) {
      throw new RedirectError({
        to: 'containerClusters.item.profiles.item',
        params,
        proceedFetching: true,
        thisFetchIsDone: true,
      });
    }
  }

  yield all([
    call(fetchContainerClusterDetail, {params, force: refetch}),
    call(fetchContainerWorkloadProfileDetail, {params, force: refetch}),
    call(fetchAllUsers, {force: refetch}),
  ]);
}
