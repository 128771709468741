/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {lookupProtocol} from 'containers/Service/ServiceUtils';
import {Vulnerability} from 'components';
import {getPolicyState} from '../../WorkloadUtils';

export const formatProProtocol = row => {
  if (!row.protocol) {
    return null;
  }

  const protocol = lookupProtocol(row.protocol);

  return row.port ? `${row.port} ${protocol}` : row.protocol ? protocol : null;
};

export const formatVulnerability = row => {
  if (!row || (row.hasOwnProperty('num_vulnerabilities') && !row.num_vulnerabilities)) {
    return;
  }

  let exposureApplicable = row.exposureApplicable;

  if (row) {
    const config = row.agent && row.agent.config;
    const policyState = getPolicyState(config?.mode, config?.log_traffic);

    exposureApplicable = policyState && policyState !== 'idle';
  }

  let vulnerabilityExposureScore = row.vulnerabilityExposureScore;
  let maxSeverity = row.maxSeverity;
  const internetExposure = row.vulnerable_port_wide_exposure || row.wideExposure;

  if (row.hasOwnProperty('vulnerability_exposure_score')) {
    vulnerabilityExposureScore =
      row.vulnerability_exposure_score === null ? null : row.vulnerability_exposure_score / 10;
  }

  if (row.hasOwnProperty('max_vulnerability_score')) {
    maxSeverity = row.max_vulnerability_score / 10;
  } else if (row.hasOwnProperty('severity')) {
    maxSeverity = row.severity;
  }

  return (
    <Vulnerability
      internetExposure={internetExposure.any || internetExposure.ip_list}
      severityScore={maxSeverity}
      vulnerabilityExposureScore={vulnerabilityExposureScore}
      exposureApplicable={exposureApplicable}
    />
  );
};

export const aggregateVulnerabilityValues = (values, instance, key) => {
  const aggregatedValues = values || {
    maxSeverity: 0,
    maxExpSeverity: -1,
    wideExposure: {},
    vulnerablePortExposure: null,
    vulnerabilityScore: 0,
    vulnerabilityExposureScore: null,
    maxVulnerabilityExposure: -1,
    portValue: false,
  };

  let {
    wideExposure,
    maxSeverity,
    maxExpSeverity,
    vulnerablePortExposure,
    vulnerabilityScore,
    vulnerabilityExposureScore,
    maxVulnerabilityExposure,
    portValue,
  } = aggregatedValues;

  if (instance.wideExposure && (instance.wideExposure.any || instance.wideExposure.ip_list)) {
    maxExpSeverity = Math.max(
      maxExpSeverity,
      instance.hasOwnProperty('severity') ? instance.severity : instance.maxSeverity,
    );

    if (key) {
      wideExposure[key] = true;
    } else {
      wideExposure = true;
    }
  }

  // Calculate the maximum vulnerability severity
  maxSeverity = Math.max(maxSeverity, instance.hasOwnProperty('severity') ? instance.severity : instance.maxSeverity);
  maxVulnerabilityExposure = Math.max(vulnerabilityExposureScore, instance.vulnerabilityExposureScore);

  if (instance.vulnerablePortExposure) {
    maxExpSeverity = Math.max(
      maxExpSeverity,
      instance.hasOwnProperty('severity') ? instance.severity : instance.maxSeverity,
    );
  }

  // Aggregate the vulnerability and exposure scores
  if (instance.vulnerablePortExposure !== null) {
    if (vulnerablePortExposure === null) {
      vulnerablePortExposure = 0;
      vulnerabilityExposureScore = 0;
    }

    vulnerablePortExposure += instance.vulnerablePortExposure;
    vulnerabilityExposureScore += instance.vulnerabilityExposureScore;
  }

  vulnerabilityScore += instance.vulnerabilityScore;

  portValue |= instance.port !== null;

  return {
    wideExposure,
    maxSeverity,
    maxExpSeverity,
    vulnerablePortExposure,
    vulnerabilityScore,
    vulnerabilityExposureScore,
    maxVulnerabilityExposure,
    portValue,
  };
};
