/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {lookupProtocol} from 'containers/Service/ServiceUtils';
import {getOrg} from 'containers/App/AppState';
import {isUserScoped} from 'containers/User/UserState';
import {getWorkloadInstance} from '../WorkloadItemState';
import {gridSettings} from './WorkloadVirtualServicesConfig';

export default {
  vslist(state = {}, action) {
    switch (action.type) {
      case 'WORKLOADS_VIRTUAL_SERVICE_BIND':
        return action.data.list;
      default:
        return state;
    }
  },
};

export const getVirtualServiceWorkloadInstance = state => state.workload.vslist;

export const getVirtualServiceWorkload = createSelector(
  [getVirtualServiceWorkloadInstance, getOrg, isUserScoped],
  (virtualservices, orgs, userIsScoped) => {
    const servicePortsMap = {};
    let servicePorts = [];

    if (virtualservices) {
      servicePorts = _.reduce(
        virtualservices,
        (result, value) => {
          result = result.concat(value.resolved_service_ports).concat(value.port_overrides);

          return result;
        },
        [],
      );

      servicePorts.forEach(sp => {
        const protocol = sp.proto || sp.protocol; // try both since it changes between 18.2 and 18.3.

        if (!protocol) {
          return;
        }

        if (!servicePortsMap[`${sp.port},${protocol}`]) {
          servicePortsMap[`${sp.port},${protocol}`] = [
            `${sp.port}${sp.to_port ? '-' + sp.to_port : ''} ${lookupProtocol(protocol || '')}`,
          ];
        }
      });
    }

    const virtualservicesData = virtualservices.map((item, idx) => ({
      key: idx,
      servicePortsMap,
      userIsScoped,
      appGroupTypes: orgs.app_groups_default || [],
      ...item,
    }));

    return virtualservicesData;
  },
);

export const getVirtualServiceWorkloadRows = createSelector(
  [getVirtualServiceWorkload],
  virtualservicesData => virtualservicesData,
);

const getVirtualServiceGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getVirtualServiceWorkloadRows,
  });

export const getVirtualServiceWorkloadPage = createSelector(
  [getWorkloadInstance, getVirtualServiceGrid],
  (workload, virtualservicesgrid) => ({
    workload,
    virtualservicesgrid,
  }),
);
