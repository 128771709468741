/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import {getAllUsersMap, isUserScoped} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './VersionDetailConfig';
import {
  getTypeAndRoute,
  formatCountsForTally,
  getProvisionCounts,
  aggregateOutboundPolicy,
} from 'containers/Provisioning/ProvisioningUtils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {getProvisionVersions} from '../List/VersionListState';
import {isAPIAvailable} from 'api/apiUtils';
import {isKubernetesSupported, isEdge} from 'containers/App/AppState';
import {
  getOutboundAllowRulesetId,
  getOutboundAllowRulesetHref,
} from 'edge/containers/OutboundPolicy/OutboundPolicyState';
import {hrefUtils} from 'utils/index';

export default {
  version(state = {}, action) {
    switch (action.type) {
      case 'PROVISION_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },

  modifiedObjectList(state = [], action) {
    switch (action.type) {
      case 'PROVISION_MODIFIED_OBJECT_GET_LIST':
        return action.data;
      default:
        return state;
    }
  },
};

export const getVersion = state => state.provisioning.version;
export const getModifiedObjects = state => state.provisioning.modifiedObjectList;

export const getModifiedObjectsRows = createSelector(
  [getVersion, getModifiedObjects, getAllUsersMap, isEdge, getOutboundAllowRulesetId, getOutboundAllowRulesetHref],
  (version, modifiedObjects, usersMap, edgeEnabled, outboundAllowRulesetId, outboundAllowRulesetHref) => {
    let modifiedObjectRows = [];

    if (edgeEnabled) {
      const outboundPolicyRow = aggregateOutboundPolicy({
        ruleSets: modifiedObjects.filter(object => object.object_type === 'rule_sets'),
        outboundAllowRulesetHref,
        enforcementBoundaries: modifiedObjects.filter(object => object.object_type === 'enforcement_boundaries'),
        usersMap,
      });

      if (outboundPolicyRow) {
        modifiedObjectRows.push(outboundPolicyRow);
      }

      const validKeys = ['rule_sets', 'ip_lists', 'services'];

      modifiedObjects = modifiedObjects.filter(modifiedObject => validKeys.includes(modifiedObject.object_type));
    }

    modifiedObjectRows = modifiedObjectRows.concat([
      ...modifiedObjects
        .filter(({object_type: type, href}) => {
          if (
            (__ANTMAN__ && (type === 'virtual_servers' || type === 'enforcement_boundaries')) ||
            (!isKubernetesSupported && type === 'virtual_services')
          ) {
            return false;
          }

          return type !== 'rule_sets' || hrefUtils.getId(href) !== outboundAllowRulesetId;
        })
        .map(item => ({
          key: item.href,
          selectable: true,
          ...(edgeEnabled && {clickable: !item.groupDeleted}),
          // Fill each pending provisioning with user object
          data: {
            ...item,
            ...getTypeAndRoute(item.object_type),
            updated_at: item.updated_at,
            updated_by: fillUserInfo(usersMap, item.updated_by),
          },
        })),
    ]);

    return modifiedObjectRows;
  },
);

export const getCounts = createSelector(
  [getModifiedObjects, getOutboundAllowRulesetId],
  (items, outboundAllowRulesetId) => getProvisionCounts(items, outboundAllowRulesetId),
);

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getModifiedObjectsRows,
  });

export const getModifiedObjectsPage = createSelector(
  [getGrid, getCounts, getVersion, getProvisionVersions, isUserScoped, isEdge],
  (grid, counts, version, versions, userIsScoped, edgeEnabled) => {
    const hasWritePermission = isAPIAvailable('sec_policy.restore');

    const tallyItems = [
      //conditionally add workloads affected count in tally
      ...(version.workloads_affected > 0 && !edgeEnabled
        ? [
            {
              children: intl('Version.Detail.WorkloadsAffected', {count: version.workloads_affected}),
              count: version.workloads_affected,
            },
          ]
        : []),
      ...formatCountsForTally(counts, {versionLabel: true}),
    ];

    return {
      grid,
      tallyItems,
      version,
      hasWritePermission,
      userIsScoped,
      edgeEnabled,
      active: versions && versions[0] && version && versions[0].version === version.version,
    };
  },
);
