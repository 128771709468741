/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import {scopeColumn} from 'containers/RBAC/RBACUtils';

export const getSelectorSettings = createSelector([], () => {
  const objectMap = {
    org_permissions: {type: 'org_permissions'},
    labels: {type: 'labels'},
    label_groups: {type: 'label_groups', pversion: 'active'},
  };

  const staticMap = {
    roles: intl('Common.Roles'),
    noLabel: {value: intl('Common.NoLabel'), isStatic: true},
    all: {value: intl('Common.All'), isOption: true, showFacetName: false},
  };

  const staticValues = {
    roles: {
      [intl('Role.RulesetManager')]: 'ruleset_manager',
      [intl('Role.LimitedRulesetManager')]: 'limited_ruleset_manager',
      [intl('Role.RulesetProvisioner')]: 'ruleset_provisioner',
      [intl('Role.RulesetViewer')]: 'ruleset_viewer',
      [intl('Role.WorkloadManager')]: 'workload_manager',
    },
    noLabel: {
      [intl('Common.NoApplicationLabel')]: 'app',
      [intl('Common.NoEnvironmentLabel')]: 'env',
      [intl('Common.NoLocationLabel')]: 'loc',
    },
  };

  const scopeMap = {
    labels: {value: intl('Common.Labels'), object: objectMap.labels},
    label_groups: {value: intl('Labels.Groups'), object: objectMap.label_groups},
  };

  const facetMap = {
    auth_security_principal_name: {value: intl('RBAC.Principals'), object: objectMap.org_permissions},
  };

  const filterMap = {...staticMap, ...scopeMap, ...facetMap};

  return {objectMap, staticMap, staticValues, scopeMap, facetMap, filterMap};
});

export const gridSettings = createSelector([], () => ({
  id: 'scopelist',
  sort: 'scope',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    scope: {
      ...scopeColumn,
    },
    roles: {
      header: intl('Common.Roles'),
      value: 'num_roles',
    },
    principals: {
      header: intl('RBAC.Principals'),
      value: 'num_auth_security_principals',
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['scope'], size: 'minmax(135px, auto)'},
      {columns: ['roles'], size: 'minmax(135px, auto)'},
      {columns: ['principals'], size: 'minmax(135px, auto)'},
    ],
    {
      maxWidth: 640,
      template: [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['scope'], size: 'minmax(135px, auto)'},
        {columns: ['roles'], size: 'minmax(70px, auto)'},
        {columns: ['principals'], size: 'minmax(70px, auto)'},
      ],
    },
  ],
}));
