/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import SizeWatcher, {type BreakPoints} from 'react-size-watcher';
import {createRef, PureComponent} from 'react';
import {CSSTransition} from 'react-transition-group';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import styles from './ProgressBar.css';

const defaultBreakpoints = [
  {props: {className: styles.xLarge}},
  {maxWidth: 500, props: {className: styles.large}},
  {maxWidth: 360, props: {className: styles.medium}},
  {maxWidth: 220, props: {className: styles.small}},
  {maxWidth: 80, props: {className: styles.xSmall}},
];

interface ProgressBarProps extends ThemeProps {
  breakpoints?: BreakPoints; // Custom breakpoints
  fillPercent?: number;
  threshold?: number;
  tid?: string;
}

export default class ProgressBar extends PureComponent<ProgressBarProps> {
  progressRef = createRef<HTMLDivElement>();

  render() {
    const {
      tid = 'statusbar',
      theme,
      fillPercent = 100,
      threshold = 20,
      breakpoints = defaultBreakpoints,
      ...elementProps
    } = mixThemeWithProps(styles, this.props);
    const fillBar = fillPercent + threshold < 100 ? fillPercent + threshold : fillPercent;

    return (
      <SizeWatcher {...elementProps} renderContentOnInit breakpoints={breakpoints}>
        <CSSTransition timeout={{exit: 10_000}} nodeRef={this.progressRef}>
          <div className={theme.barFill} style={{width: `${fillBar}%`}} data-tid={tid} ref={this.progressRef} />
        </CSSTransition>
      </SizeWatcher>
    );
  }
}
