/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component} from 'react';
import * as PropTypes from 'prop-types';
import {object, string} from 'yup';
import {Form, AttributeList, Modal} from 'components';
import {SingleItemSelect} from '../../../Selectors';
import {getId} from 'utils/href';

const getEmptyState = () => ({
  actionError: null,
  isValid: false,
});

export default class SecureGatewayEditModal extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    initLabels: PropTypes.object.isRequired,
    initValues: PropTypes.object,
    isEdit: PropTypes.bool.isRequired,
    rowKeyOnEdit: PropTypes.string,
    gridRows: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = getEmptyState();
    this.options = {};
    this.schemas = object({
      ipList: string(),
      app: string(),
      role: string(),
      label: string(),
      env: string(),
      loc: string(),
    });

    this.handleSelect = this.handleSelect.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
    this.handleOnCancel = this.handleOnCancel.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.getDisabled = this.getDisabled.bind(this);

    const {
      initLabels: {role, app, env, loc, ip_lists},
    } = props;

    this.initialValues = {
      ip_lists,
      role,
      app,
      env,
      loc,
    };

    this.initialSelectedValues = {
      ip_lists: [{value: ip_lists.name || '', categoryKey: 'ip_lists'}],
      role: [{value: role.value || '', categoryKey: 'role'}],
      env: [{value: env.value || '', categoryKey: 'env'}],
      loc: [{value: loc.value || '', categoryKey: 'loc'}],
      app: [{value: app.value || '', categoryKey: 'app'}],
    };
  }

  getDisabled() {
    if (!this.formik) {
      return true;
    }

    const {gridRows} = this.props;
    const {values} = this.formik;
    const isEmpty = Object.keys(this.initialSelectedValues).some(
      item =>
        !(
          this.initialSelectedValues[item] &&
          this.initialSelectedValues[item][0] &&
          this.initialSelectedValues[item][0].value
        ),
    );

    const doesKeyAlreadyExist = gridRows.find(
      row =>
        row.key ===
        `${getId(values.ip_lists.href)}${getId(values.role.href)}${getId(values.app.href)}${getId(
          values.loc.href,
        )}${getId(values.env.href)}`,
    );

    const isValid = this.formik.isValid && !isEmpty && !doesKeyAlreadyExist;

    if (isValid !== this.state.isValid) {
      this.setState({isValid});
    }

    return isValid;
  }

  handleAlertClose() {
    this.setState({actionError: null});

    const {onClose} = this.props;

    onClose();
  }

  handleOnCancel() {
    const {onClose} = this.props;

    onClose();
  }

  handleOnSubmit() {
    const {onSubmit, onClose} = this.props;
    const {ip_lists, role, app, env, loc} = this.formik.values;

    onSubmit(
      {
        ip_lists,
        labels: {role, app, env, loc},
      },
      this.props.isEdit,
      this.props.rowKeyOnEdit,
    );

    onClose();
  }

  // Handle the <Selector> callback for: role, loc, app , env options
  handleSelect({key}) {
    const {setFieldValue} = this.formik;

    return selection => {
      if (selection.length) {
        const matches = selection[0];

        // Data structure needed to send to API backend
        if (key === 'ip_lists') {
          this.initialSelectedValues[key] = [
            {
              name: matches.name || matches.value,
              value: matches.name || matches.value,
              href: matches.href,
              categoryKey: key,
            },
          ];

          setFieldValue(key, {href: matches.href, key, name: matches.name});
        } else {
          this.initialSelectedValues[key] = [
            {
              value: matches.name || matches.value,
              href: matches.href,
              categoryKey: key,
            },
          ];

          setFieldValue(key, {href: matches.href, key, value: matches.value});
        }
      } else {
        // Reset when selection is empty
        this.initialSelectedValues[key] = [];
        setFieldValue(key, []);
        this.setState({isValid: false});
      }
    };
  }

  renderForm(options) {
    const {values, isValid} = options;

    this.formik = options;

    if (this.state.isValid !== isValid) {
      this.getDisabled();
    }

    return (
      <AttributeList>
        {[
          {content: <span data-tid="label-assignment">{intl('SecureGateway.AddDialog.TitleFieldset')}</span>},
          {
            tid: 'ipList',
            key: <Form.Label name="ipList" title={intl('Common.IPRange')} />,
            value: (
              <SingleItemSelect
                tid="ipList"
                showResultsFooter
                values={values.ip_lists}
                placeholder={intl('Common.SelectIPList')}
                onSelectionChange={this.handleSelect({key: 'ip_lists'})}
                objects={[{type: 'ip_lists', pversion: 'draft'}]}
                categories={[{value: 'IP Lists', categoryKey: 'ip_lists'}]}
                initialItems={
                  this.initialSelectedValues &&
                  !_.isEmpty(this.initialSelectedValues.ip_lists) &&
                  this.initialSelectedValues.ip_lists[0] &&
                  this.initialSelectedValues.ip_lists[0].value
                    ? this.initialSelectedValues.ip_lists
                    : []
                }
              />
            ),
          },
          {
            tid: 'role',
            key: <Form.Label name="role" title={intl('Common.Role')} />,
            value: (
              <SingleItemSelect
                tid="role"
                showResultsFooter
                allowCreateTypes={['labels']}
                values={values.role}
                placeholder={intl('Rulesets.Rules.SelectRole')}
                objects={[{type: 'labels', key: 'role'}]}
                onSelectionChange={this.handleSelect({key: 'role'})}
                categories={[
                  {value: intl('Labels.RoleLabels'), categoryKey: 'labels'},
                  {categoryKey: 'all', href: 'all', value: intl('Common.AllRoles')},
                ]}
                partials={['labels']}
                initialItems={
                  this.initialSelectedValues &&
                  !_.isEmpty(this.initialSelectedValues.role) &&
                  this.initialSelectedValues.role[0] &&
                  this.initialSelectedValues.role[0].value
                    ? this.initialSelectedValues.role
                    : []
                }
              />
            ),
          },
          {
            tid: 'app',
            key: <Form.Label name="app" title={intl('Common.Application')} />,
            value: (
              <SingleItemSelect
                tid="app"
                showResultsFooter
                allowCreateTypes={['labels']}
                values={values.app}
                placeholder={intl('Rulesets.Rules.SelectApplication')}
                onSelectionChange={this.handleSelect({key: 'app'})}
                objects={[{type: 'labels', key: 'app'}]}
                categories={[
                  {value: intl('Labels.Application'), categoryKey: 'labels'},
                  {categoryKey: 'all', href: 'all', value: intl('Common.AllApplications')},
                ]}
                partials={['labels']}
                initialItems={
                  this.initialSelectedValues &&
                  !_.isEmpty(this.initialSelectedValues.app) &&
                  this.initialSelectedValues.app[0] &&
                  this.initialSelectedValues.app[0].value
                    ? this.initialSelectedValues.app
                    : []
                }
              />
            ),
          },
          {
            tid: 'env',
            key: <Form.Label name="env" title={intl('Common.Environment')} />,
            value: (
              <SingleItemSelect
                tid="env"
                showResultsFooter
                allowCreateTypes={['labels']}
                values={values.env}
                placeholder={intl('Rulesets.Rules.SelectEnvironment')}
                objects={[{type: 'labels', key: 'env'}]}
                onSelectionChange={this.handleSelect({key: 'env'})}
                categories={[
                  {value: intl('Labels.Environment'), categoryKey: 'labels'},
                  {categoryKey: 'all', href: 'all', value: intl('Common.AllEnvironments')},
                ]}
                partials={['labels']}
                initialItems={
                  this.initialSelectedValues &&
                  !_.isEmpty(this.initialSelectedValues.env) &&
                  this.initialSelectedValues.env[0] &&
                  this.initialSelectedValues.env[0].value
                    ? this.initialSelectedValues.env
                    : []
                }
              />
            ),
          },
          {
            tid: 'loc',
            key: <Form.Label name="loc" title={intl('Common.Location')} />,
            value: (
              <SingleItemSelect
                tid="loc"
                showResultsFooter
                values={values.loc}
                allowCreateTypes={['labels']}
                placeholder={intl('Rulesets.Rules.SelectLocation')}
                objects={[{type: 'labels', key: 'loc'}]}
                onSelectionChange={this.handleSelect({key: 'loc'})}
                categories={[
                  {value: intl('Labels.Location'), categoryKey: 'labels'},
                  {categoryKey: 'all', href: 'all', value: intl('Common.AllLocations')},
                ]}
                partials={['labels']}
                initialItems={
                  !_.isEmpty(this.initialSelectedValues) &&
                  !_.isEmpty(this.initialSelectedValues.loc) &&
                  this.initialSelectedValues.loc[0] &&
                  this.initialSelectedValues.loc[0].value
                    ? this.initialSelectedValues.loc
                    : []
                }
              />
            ),
          },
        ]}
      </AttributeList>
    );
  }

  render() {
    const {isEdit} = this.props;

    return (
      <Modal.Confirmation
        large
        title={!isEdit ? intl('SecureGateway.AddDialog.TitleAdd') : intl('SecureGateway.AddDialog.TitleEdit')}
        footerProps={{tid: 'comp-dialog-actions'}}
        confirmProps={{
          disabled: !this.state.isValid,
          text: intl('Common.Add'),
          onClick: this.handleOnSubmit,
          tid: 'ok',
        }}
        cancelProps={{
          noFill: true,
          text: intl('Common.Cancel'),
          tid: 'cancel',
        }}
        onCancel={this.handleOnCancel}
        dontRestrainChildren
        notScrollable
      >
        <Form schemas={this.schemas} initialValues={this.initialValues} allowLeaveOnDirty>
          {this.renderForm}
        </Form>
      </Modal.Confirmation>
    );
  }
}
