/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {cloneElement, type ComponentPropsWithoutRef, createElement, type ReactElement} from 'react';
import {isReactElementOf, unwrapChildren} from 'utils/react';
import Button, {type ButtonProps} from './Button';
import styles from './Button.css';
import type {ReactStrictNode} from 'utils/types';

// List of props specific to ButtonGroup component, all extra props will be passed down to rendered div as is
type ButtonGroupPropsIn = Pick<ButtonProps, 'noStyle' | 'noFill' | 'size' | 'color'> & {
  children: ReactStrictNode; // List of group <Button>s
  /** Makes whole group not interactable (not clickable, not tabbable) */
  insensitive?: ButtonProps['insensitive'];

  /** Makes whole group not interactable and apply disabled styles */
  disabled?: ButtonProps['disabled'];
} & ComponentPropsWithoutRef<'div'> &
  ThemeProps;

export default function ButtonGroup(props: ButtonGroupPropsIn): JSX.Element {
  const {
    size,
    color,
    noStyle = false,
    noFill = false,
    insensitive,
    disabled,
    children,
    theme,
    ...elementProps
  } = mixThemeWithProps(styles, props);

  const buttons = (
    unwrapChildren(children).filter(
      child =>
        isReactElementOf(child, Button) || isReactElementOf(child, Button.Menu) || isReactElementOf(child, Button.Link),
    ) as ReactElement<ButtonProps>[]
  ).map((button, index, buttons) =>
    cloneElement(button, {
      size,
      color,
      noStyle,
      noFill,
      disabled: typeof button.props.disabled === 'boolean' ? button.props.disabled : disabled,
      insensitive: typeof button.props.insensitive === 'boolean' ? button.props.insensitive : insensitive,
      isGroupFirst: !index,
      isGroupLast: index === buttons.length - 1,
    }),
  );

  elementProps.className = theme.group;

  return createElement('div', elementProps, ...buttons);
}
