/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {Component} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {fetchLoadBalancerServersPage} from '../../LoadBalancerSaga';
import VirtualServerList from 'containers/VirtualServer/List/VirtualServerList';
import {getVirtualServersPage} from 'containers/VirtualServer/List/VirtualServerListState';

@connect(getVirtualServersPage)
export default class LoadBalancerServers extends Component {
  static prefetch = fetchLoadBalancerServersPage;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh() {
    return this.context.fetcher.fork(fetchLoadBalancerServersPage.refetch);
  }

  render() {
    return <VirtualServerList onRefresh={this.handleRefresh} showManage hideHeaderProps hideReports showGlobalLink />;
  }
}
