/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {Pill, type PillProps} from 'components';
import {hrefUtils} from 'utils';
import {getUpdateTypeProps} from '../PillUtils';
import type {VirtualService as VirtualServiceData} from 'illumio';

export interface VirtualServiceProps extends PillProps {
  // Virtual Server object
  value: VirtualServiceData;

  // policy version
  pversion?: string | number;
}

export default class VirtualService extends PureComponent<VirtualServiceProps> {
  element: HTMLElement | null = null;

  static defaultProps = {
    pversion: 'draft',
  };

  constructor(props: VirtualServiceProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: Pill) {
    this.element = element?.element;
  }

  render() {
    const {value, pversion, updateTypeTooltip, ...pillProps} = this.props;

    Object.assign(pillProps, {
      ref: this.saveRef,
      icon: 'virtual-service',
      link: pillProps.onClick
        ? null
        : {to: 'virtualServices.item.view', params: {id: hrefUtils.getId(value.href), pversion}},
      ...getUpdateTypeProps({
        object: 'virtualService',
        updateType: value.update_type,
        updateTypeTooltip,
        deleted: pillProps.deleted,
        pversion,
      }),
    });

    return <Pill {...pillProps}>{value.name}</Pill>;
  }
}
