/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Icon} from 'components';
import styles from './ServiceView.css';

export const getGridSettings = (os = 'linux') => ({
  id: 'serviceDefinition',
  showColumns: false,
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showManager: false,
  columns: {
    status: {
      format: ({row}) => (row.status ? <Icon name={row.status} theme={styles} themePrefix={`${row.status}-`} /> : null),
    },
    portProto: {
      header: intl('Common.PortAndOrProtocol'),
      value: 'portProto',
      sortable: false, // each column is not sortable; We are not sorting this list.
      ...(__ANTMAN__ && {disabled: os === 'windows_egress'}),
    },
    processName: {
      header: intl('Common.Process'),
      value: 'process_name',
      sortable: false,
      disabled: os === 'linux',
    },
    serviceName: {
      header: __ANTMAN__ ? intl('Antman.Services.ServiceName') : intl('Services.WindowsService'),
      value: 'service_name',
      sortable: false,
      disabled: os === 'linux',
    },
  },
  templates: [
    [
      {columns: ['status'], size: 'max-content'},
      {columns: ['portProto'], size: 'minmax(150px, auto)'},
      {columns: ['processName'], size: 'minmax(150px, auto)'},
      {columns: ['serviceName'], size: 'minmax(150px, auto)'},
    ],
    {
      maxWidth: 640,
      template: [
        {columns: ['status'], size: 'max-content'},
        {columns: ['portProto', 'processName', 'serviceName'], size: 'minmax(150px, auto)'},
      ],
    },
  ],
});
