/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {getContainerClusterDetailPage} from './ContainerClusterDetailState';
import {fetchContainerClusterInstance} from './ContainerClusterDetailSaga';
import {HeaderProps} from 'containers';
import {TabPanel} from 'components';
import ContainerClusterReducer from 'containers/ContainerCluster/ContainerClusterState';

@connect(getContainerClusterDetailPage)
export default class ContainerClusterDetail extends Component {
  static prefetch = fetchContainerClusterInstance;
  static reducers = ContainerClusterReducer;

  render() {
    const {id, name, routeName} = this.props;
    const isContainerClusterEdit = routeName === 'app.containerClusters.item.edit';
    const isContainerClusterProfileConfig =
      routeName === 'app.containerClusters.item.profiles.create' ||
      routeName === 'app.containerClusters.item.profiles.edit';

    return (
      <>
        <HeaderProps title={intl('Menu.ContainerClusters', {multiple: true})} subtitle={name} up="containerClusters" />
        <TabPanel>
          {[
            {
              link: {to: 'containerClusters.item.summary', params: {id}},
              text: intl('Common.Summary'),
              tid: 'summary',
              disabled: isContainerClusterProfileConfig,
            },
            {
              link: {to: 'containerClusters.item.vens', params: {id}},
              text: intl('Common.VENS'),
              tid: 'vens',
              disabled: isContainerClusterEdit || isContainerClusterProfileConfig,
            },
            {
              link: {to: 'containerClusters.item.profiles', params: {id}},
              text: intl('ContainerClusters.ContainerWorkloadProfiles'),
              tid: 'profiles',
              disabled: isContainerClusterEdit,
            },
            {
              link: {to: 'containerClusters.item.workloads', params: {id}},
              text: intl('Common.Workloads'),
              tid: 'workloads',
              disabled: isContainerClusterEdit || isContainerClusterProfileConfig,
            },
            {
              link: {to: 'containerClusters.item.containerWorkloads', params: {id}},
              text: intl('Common.ContainerWorkloads'),
              tid: 'containerworkloads',
              disabled: isContainerClusterEdit || isContainerClusterProfileConfig,
            },
            {
              link: {to: 'containerClusters.item.serviceBackends', params: {id}},
              text: intl('Common.ServiceBackends'),
              tid: 'servicebackends',
              disabled: isContainerClusterEdit || isContainerClusterProfileConfig,
            },
          ]}
        </TabPanel>
        {this.props.children}
      </>
    );
  }
}
