/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {AttributeList} from 'components';
import {formatDate} from 'components/Grid/GridUtils';
import {getRunningContainers} from './ContainerWorkloadContainerState';
import {fetchContainerWorkloadContainer} from './ContainerWorkloadContainerSaga';

@connect(state => ({
  runningContainers: getRunningContainers(state),
}))
export default class ContainerWorkloadContainers extends Component {
  static prefetch = fetchContainerWorkloadContainer;

  render() {
    const {runningContainers} = this.props;

    return (
      <AttributeList keyColumnWidth="fit-content(40%)" valueColumnWidth="auto" sizeXS={{maxWidth: 800}}>
        {runningContainers.reduce((result, container, idx) => {
          if (idx !== 0) {
            result.push({divider: true});
          }

          result.push(
            {title: intl('Common.Container')},
            {key: intl('Common.Name'), value: container.name},
            {key: intl('ContainerWorkloads.ContainerID'), value: container.container_id},
            {key: intl('ContainerWorkloads.StartedAt'), value: formatDate(container.started_at)},
            {key: intl('ContainerWorkloads.StoppedAt'), value: formatDate(container.finished_at)},
          );

          const labels = Object.entries(container.labels).map(([key, value]) => ({key, value}));

          result.push(..._.sortBy(labels, ['key']));

          return result;
        }, [])}
      </AttributeList>
    );
  }
}
