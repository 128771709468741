/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {edge} from 'api/apiUtils';

const getDestructedRule = rule => {
  const {
    peer_sets: peerSets, // Set names for remote peers
    local_sets: localSets, // Local set names these are configured as
    // the local or source peers for forwarding
    // rules
    pp, // Port and protocol
    id_set: idSet, // ID set names
    win_svcs: windowsServices, // Windows process and service
    stateless, // Stateless rule
    sec_type: secConnectType, // Secure connection type, [1,2,3]
    network_type,
    essential_service_rule: essentialServiceRuleKey,
  } = rule;

  return {
    peerSets,
    localSets,
    pp,
    idSet,
    network_type,
    windowsServices,
    stateless,
    secConnectType,
    essentialServiceRuleKey,
  };
};

const getInboundOutboundRules = (rules, networkSetsMap) => {
  const result = [];

  for (const rule of rules) {
    const destructedRule = getDestructedRule(rule);
    // Both Inbound Rules and Outbound Rules grids have the following columns:
    //   Service, Addresses, SecureConnect, Machine Authentication, Stateless

    if (Array.isArray(destructedRule?.pp) && destructedRule.pp.length === 2) {
      const icmpOnly = destructedRule.pp.some(
        pp =>
          (pp.proto === 58 || pp.proto === 1) && Array.isArray(pp.port) && pp.port.length === 1 && pp.port[0] === null,
      );

      if (icmpOnly) {
        destructedRule.pp = destructedRule.pp.reverse();
      }
    }

    // Both Inbound Rules and Outbound Rules grids have the following columns:
    //   Service, Addresses, SecureConnect, Machine Authentication, Stateless
    const constructedRule = {
      stateless: destructedRule.stateless,
      service: destructedRule.pp,
      network_type: destructedRule.network_type,
    };

    if (destructedRule.essentialServiceRuleKey) {
      constructedRule.essentialServiceRuleKey = destructedRule.essentialServiceRuleKey;
    }

    if (destructedRule.windowsServices) {
      constructedRule.service = destructedRule.pp
        ? destructedRule.pp.concat(destructedRule.windowsServices)
        : destructedRule.windowsServices;
    }

    if (destructedRule.peerSets) {
      // Replace the set IDs with their containing IP Addresses for display
      constructedRule.addresses = destructedRule.peerSets.flatMap(peerSet => networkSetsMap[peerSet]);
    }

    if (destructedRule.secConnectType) {
      // 1 => Machine Auth
      // 2 => Secure Connect
      // 3 => Machine Auth and Secure Connect
      switch (destructedRule.secConnectType) {
        case 1:
          constructedRule.machineAuth = true;
          break;
        case 2:
          constructedRule.secConnect = true;
          break;
        case 3:
          constructedRule.machineAuth = true;
          constructedRule.secConnect = true;
          break;
      }
    }

    result.push(constructedRule);
  }

  return result;
};

const getInbounOutboundPipRules = rules => {
  const result = [];

  for (const rule of rules) {
    if (Array.isArray(rule.pip) && rule.pip.length > 0) {
      rule.pip.forEach(
        ({port, ips, names}) =>
          (ips || names) &&
          result.push({
            service: [{proto: rule.proto, port}],
            addresses: [...(ips || []), ...(names || [])],
          }),
      );
    }
  }

  return result;
};

const getForwardRules = (rules, networkSetsMap) => {
  const result = [];

  for (const rule of rules) {
    const {pp, stateless, localSets, peerSets} = getDestructedRule(rule);
    // Forward Rules grid has the following columns:
    //   Service, Local, Remote Peers
    const constructedRule = {service: pp, stateless};

    // Replace the set IDs with their containing IP Addresses for display
    constructedRule.local = localSets.flatMap(localSet => networkSetsMap[localSet]);

    constructedRule.remotePeers = peerSets.flatMap(peerSet => networkSetsMap[peerSet]);

    result.push(constructedRule);
  }

  return result;
};

export const generateRules = body => {
  const networkAccessPath = 'workload_instructions[0].network_access';
  const networkAccess = _.get(body, networkAccessPath);

  const networkDenyPath = 'workload_instructions[0].network_deny';
  const networkDeny = _.get(body, networkDenyPath);

  const networkTypeMap = _.get(body, 'workload_instructions[0].brn_map', []).reduce((map, {net, brn}) => {
    map[net] = brn; // guid : boolean

    return map;
  }, {});

  const setNetworkType = (net, rules = []) => {
    // infer three possible types, using boolean and absence of parent `networks`
    const network_type = !net.networks ? 'all' : !networkTypeMap[net.networks[0]] ? 'non_brn' : 'brn';

    return rules.map(rule => ({...rule, network_type}));
  };

  const inboundRules = _.flatMap(networkAccess, net => setNetworkType(net, net.sb?.ingress));
  const outboundRules = _.flatMap(networkAccess, net => setNetworkType(net, net.sb?.egress));
  const inboundIppRules = _.flatMap(networkAccess, net => setNetworkType(net, net.ipp?.ingress));
  const outboundIppRules = _.flatMap(networkAccess, net => setNetworkType(net, net.ipp?.egress));
  const forwardRules = _.flatMap(networkAccess, net => setNetworkType(net, net.forward?.ingress));
  const customIpTableRules = _.flatMap(networkAccess, net => setNetworkType(net, net.custom));

  const inboundEnforcement = _.flatMap(networkDeny, net => setNetworkType(net, net.sb?.ingress));
  const outboundEnforcement = _.flatMap(networkDeny, net => setNetworkType(net, net.sb?.egress));

  // Map for "sets", which are IDed array of IP Addresses typically
  const networkSetsMap = {}; // {IPL__1_4: [0.0.0.0/0]}

  for (const network of _.get(body, 'sets.networks', [])) {
    // Create an ID => names/IP Addresses map for easier lookup
    for (const networkSet of network.sets || []) {
      if (network.type === 'fqdn') {
        networkSetsMap[networkSet.id] = networkSet.names || [];
      } else if (networkSet.sec_ips) {
        //old version
        networkSetsMap[networkSet.id] = (networkSet.sec_ips.ikev1 || [])
          .concat(networkSet.sec_ips.ikev2 || [])
          .concat(networkSet.ips || []);
      } else if (networkSet.sec_peers) {
        networkSetsMap[networkSet.id] = (networkSet.sec_peers.ips || []).concat(networkSet.ips || []);
      } else {
        networkSetsMap[networkSet.id] = networkSet.ips || [];
      }
    }
  }

  const adminRules = [];
  let finalInboundRules;

  if (edge) {
    finalInboundRules = [];

    inboundRules.forEach(rules => {
      if (rules.sec_type === 1 || rules.sec_type === 3) {
        adminRules.push(rules);
      } else {
        finalInboundRules.push(rules);
      }
    });
  } else {
    finalInboundRules = inboundRules;
  }

  // The UI Workload Grid has four sections:
  // Inbound Rules, Outbound Rules, Forward Rules, Custom iptables Rules
  return {
    inboundRules: _.uniqWith(
      [...getInboundOutboundRules(finalInboundRules, networkSetsMap), ...getInbounOutboundPipRules(inboundIppRules)],
      _.isEqual,
    ),
    outboundRules: _.uniqWith(
      [...getInboundOutboundRules(outboundRules, networkSetsMap), ...getInbounOutboundPipRules(outboundIppRules)],
      _.isEqual,
    ),
    forwardRules: getForwardRules(forwardRules, networkSetsMap),
    customIpTableRules, // No parsing needed as these are mostly flat texts
    ...(edge ? {adminRules: getInboundOutboundRules(adminRules, networkSetsMap)} : {}),
    inboundEnforcement: getInboundOutboundRules(inboundEnforcement, networkSetsMap),
    outboundEnforcement: getInboundOutboundRules(outboundEnforcement, networkSetsMap),
  };
};
