/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {reactUtils, hrefUtils} from 'utils';
import {
  Button,
  ButtonRefresh,
  Grid,
  Modal,
  ToolBar,
  ToolGroup,
  TypedMessages,
  Notifications,
  ModalMachineAuto,
} from 'components';
import {HeaderProps, ReportButtons} from 'containers';
import {ComboSelect} from 'containers/Selectors';
import ProvisionButtons from 'containers/Provisioning/Provision/ProvisionButtons';
import {labelFields} from 'components/Pill/Label/LabelUtils';
import {getMaxPageNotificationList} from 'components/Grid/GridUtils';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import {fetchServiceInstance} from 'containers/Service/ServiceSaga';
import {createVirtualServer} from 'containers/LoadBalancer/LoadBalancerSaga';
import {fetchPending} from 'containers/Provisioning/ProvisioningSaga';
import {fetchVirtualServerList, removeVirtualServer, updateEnforceState} from './VirtualServerListSaga';
import {getVirtualServersPage} from './VirtualServerListState';
import VirtualServerReducers from 'containers/VirtualServer/VirtualServerState';
import LabelReducers from '../../Label/LabelState';
import ReportsReducers from '../../Reports/ReportsState';
import {resourceType} from './VirtualServerListConfig';
import styles from './VirtualServerList.css';

const autocompleteFilterKeys = [...(labelFields || []), 'slbs'];

const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};
const revocableRowHighLight = {className: stylesGridUtils.rowToRevert};
const enforceRowHighLight = {className: styles.rowToEnforce};
const provisionRowHighLight = {className: stylesGridUtils.rowToProvision};
const revertRowHighLight = {className: stylesGridUtils.rowToRevert};
const unmanageRowHighLight = {className: stylesGridUtils.rowToRemove};

const getEmptyState = () => ({
  runningEnforce: false,
  runningUnenforce: false,
  extraPropsKeyMap: new Map(),
  selectedKeySet: new Set(),
  selectedKeySetToUnmanage: new Set(),
  selectedObjectsToProvision: {virtual_servers: []},
  selectedKeySetEnforceable: new Set(),
  selectedKeySetRevocable: new Set(),
  unmanagedVirtualServers: new Set(),
  unmanage: false,
  manage: null,
});

@connect(getVirtualServersPage)
export default class VirtualServerList extends Component {
  static prefetch = fetchVirtualServerList;
  static contextType = AppContext;
  static reducers = [ReportsReducers, LabelReducers, VirtualServerReducers];

  static propTypes = {
    showManage: PropTypes.bool, // whether to reveal Manage Button
    hideHeaderProps: PropTypes.bool, // whether to show page header "Virtual Servers". true for Virtual Servers page
    hideReports: PropTypes.bool, // whether to hide reports button
    showGlobalLink: PropTypes.bool, // whether to show All Virtual Server button (and not limited by one SLB)
  };

  constructor(props) {
    super(props);

    this.state = getEmptyState();

    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleEnforce = this.handleEnforce.bind(this);
    this.handleEnforceEnter = this.handleEnforceEnter.bind(this);
    this.handleEnforceLeave = this.handleEnforceLeave.bind(this);
    this.handleStopEnforcement = this.handleStopEnforcement.bind(this);
    this.handleStopEnforcementEnter = this.handleStopEnforcementEnter.bind(this);
    this.handleStopEnforcementLeave = this.handleStopEnforcementLeave.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleRemoveEnter = this.handleRemoveEnter.bind(this);
    this.handleRemoveLeave = this.handleRemoveLeave.bind(this);
    this.handleProvisionButtonsHover = this.handleProvisionButtonsHover.bind(this);
    this.handleProvisionDone = this.handleProvisionDone.bind(this);
    this.handleManage = this.handleManage.bind(this);
    this.handleUnmanage = this.handleUnmanage.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.grid.rows !== prevState.rows) {
      const nextState = {rows: nextProps.grid.rows};

      if (prevState.selectedKeySet.size) {
        // If page or sorting have changed, find selected items that remain on current page
        nextState.selectedKeySet = new Set();
        nextState.selectedKeySetToUnmanage = new Set();
        nextState.selectedObjectsToProvision = {virtual_servers: []};
        nextState.selectedKeySetEnforceable = new Set();
        nextState.selectedKeySetRevocable = new Set();
        nextState.unmanagedVirtualServers = new Set();

        for (const row of nextProps.grid.rows) {
          if (row.selectable && prevState.selectedKeySet.has(row.key)) {
            nextState.selectedKeySet.add(row.key);

            if (row.data.managed === intl('Common.Unmanaged')) {
              nextState.unmanagedVirtualServers.add(row.data);
            } else if (row.writable) {
              nextState.selectedKeySetToUnmanage.add(row.key);

              if (row.data.slb) {
                if (
                  row.data.mode === 'enforced' ||
                  row.data.managed === intl('Common.Managed') + ': ' + intl('Common.Enforced')
                ) {
                  nextState.selectedKeySetRevocable.add(row.key);
                } else if (
                  row.data.mode === 'unmanaged' ||
                  row.data.managed === intl('Common.Managed') + ': ' + intl('Common.NotEnforced')
                ) {
                  nextState.selectedKeySetEnforceable.add(row.key);
                }
              }
            }

            if (row.draft) {
              nextState.selectedObjectsToProvision = {
                virtual_servers: nextState.selectedObjectsToProvision.virtual_servers.concat({href: row.key}),
              };
            }
          }
        }
      }

      if (prevState.runningEnforce) {
        nextState.runningEnforce = false;
      }

      if (prevState.runningUnenforce) {
        nextState.runningUnenforce = false;
      }

      return nextState;
    }

    return null;
  }

  handleClick(evt, row) {
    if (row.data.managed !== intl('Common.Unmanaged')) {
      this.context.navigate({evt, to: 'virtualServers.item', params: {id: hrefUtils.getId(row.key)}});
    }
  }

  handleFilterChange(selection) {
    const {
      context: {navigate},
    } = this;
    const scopeItems = selection.filter(item => labelFields.includes(item.categoryKey)); // only labels for scope
    const filterItems = selection.filter(item => !labelFields.includes(item.categoryKey)); // no labels for filter
    const scope = scopeItems.map(({key, href}) => ({href, key}));

    const filter = filterItems.reduce((result, item) => {
      const {categoryKey, href, value, name} = item;

      if (autocompleteFilterKeys.includes(categoryKey)) {
        result[categoryKey] = [{value: name || value, href}];
      } else {
        result[categoryKey] = [value];
      }

      return result;
    }, {});

    navigate({
      params: {
        scope: scope.length ? {scope} : undefined,
        filter,
        [this.props.grid.settings.id]: {...this.props.grid.params, filter, page: null},
      },
    });
  }

  handleSelect({affectedRows, selecting}) {
    this.setState(state => {
      const selectedKeySet = new Set(state.selectedKeySet);
      const selectedKeySetToUnmanage = new Set(state.selectedKeySetToUnmanage);
      let selectedObjectsToProvision = {...state.selectedObjectsToProvision};
      const selectedKeySetEnforceable = new Set(state.selectedKeySetEnforceable);
      const selectedKeySetRevocable = new Set(state.selectedKeySetRevocable);
      const unmanagedVirtualServers = new Set(state.unmanagedVirtualServers);

      for (const row of affectedRows) {
        selectedKeySet[selecting ? 'add' : 'delete'](row.key);

        if (selecting) {
          if (!row.data.mode) {
            unmanagedVirtualServers.add(row.data);
          } else if (row.writable) {
            selectedKeySetToUnmanage.add(row.key);

            if (row.data.slb) {
              if (
                row.data.mode === 'enforced' ||
                row.data.managed === intl('Common.Managed') + ': ' + intl('Common.Enforced')
              ) {
                selectedKeySetRevocable.add(row.key);
              } else if (
                row.data.mode === 'unmanaged' ||
                row.data.managed === intl('Common.Managed') + ': ' + intl('Common.NotEnforced')
              ) {
                selectedKeySetEnforceable.add(row.key);
              }
            }
          }

          if (row.draft) {
            selectedObjectsToProvision = {
              virtual_servers: selectedObjectsToProvision.virtual_servers.concat({href: row.key}),
            };
          }
        } else if (row.data.managed === intl('Common.Unmanaged')) {
          unmanagedVirtualServers.delete(row.data);
        } else {
          selectedKeySetToUnmanage.delete(row.key);
          selectedKeySetEnforceable.delete(row.key);
          selectedKeySetRevocable.delete(row.key);
          selectedObjectsToProvision = {
            virtual_servers: selectedObjectsToProvision.virtual_servers.filter(object => object.href !== row.key),
          };
        }
      }

      return {
        selectedKeySet,
        selectedKeySetToUnmanage,
        unmanagedVirtualServers,
        selectedObjectsToProvision,
        selectedKeySetEnforceable,
        selectedKeySetRevocable,
      };
    });
  }

  handleRefresh() {
    if (this.props.onRefresh) {
      return this.props.onRefresh();
    }

    // Return promise that will wait for all, so Pagination component can publish event for QA
    return Promise.all([
      // Refetch the list, cancelable on page leave
      this.context.fetcher.fork(fetchVirtualServerList.refetch),
      // Refetch provision counter, doesn't depend on page leave
      this.context.fetcher.spawn(fetchPending),
    ]);
  }

  handleProvisionButtonsHover(evt, type, action) {
    this.setState(state => {
      if (type === 'enter' && state.selectedObjectsToProvision.virtual_servers.length) {
        if (action === 'provision') {
          return {
            extraPropsKeyMap: new Map(
              state.selectedObjectsToProvision.virtual_servers.map(object => [object.href, provisionRowHighLight]),
            ),
          };
        }

        if (action === 'revert') {
          return {
            extraPropsKeyMap: new Map(
              state.selectedObjectsToProvision.virtual_servers.map(object => [object.href, revertRowHighLight]),
            ),
          };
        }
      }

      if (type === 'leave' && state.extraPropsKeyMap.size) {
        return {extraPropsKeyMap: new Map()};
      }

      // Return null to prevent updating state
      return null;
    });
  }

  handleProvisionDone() {
    // Reset selection
    this.setState(getEmptyState());
    this.handleRefresh();
  }

  async handleEnforce() {
    try {
      await reactUtils.setStateAsync({runningEnforce: true, extraPropsKeyMap: new Map()}, this);
      await this.context.fetcher.spawn(updateEnforceState, {
        policyState: 'enforced',
        hrefs: [...this.state.selectedKeySetEnforceable],
      });
    } catch (error) {
      this.setState({enforce: {error}, runningEnforceError: true});
    } finally {
      this.setState(getEmptyState());
      this.handleRefresh();
    }
  }

  handleEnforceEnter() {
    if (this.state.selectedKeySetEnforceable.size) {
      this.setState(state => ({
        extraPropsKeyMap: new Map(Array.from(state.selectedKeySetEnforceable, key => [key, enforceRowHighLight])),
      }));
    }
  }

  handleEnforceLeave() {
    if (this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  async handleStopEnforcement() {
    try {
      await reactUtils.setStateAsync({runningUnenforce: true, extraPropsKeyMap: new Map()}, this);
      await this.context.fetcher.spawn(updateEnforceState, {
        policyState: 'unmanaged',
        hrefs: [...this.state.selectedKeySetRevocable],
      });
    } catch (error) {
      this.setState({stopenforce: {error}, runningUnenforceError: true});
    } finally {
      this.setState(getEmptyState());
      this.handleRefresh();
    }
  }

  handleStopEnforcementEnter() {
    if (this.state.selectedKeySetRevocable.size) {
      this.setState(state => ({
        extraPropsKeyMap: new Map(Array.from(state.selectedKeySetRevocable, key => [key, revocableRowHighLight])),
      }));
    }
  }

  handleStopEnforcementLeave() {
    if (this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  handleUnmanage() {
    this.setState(({unmanage}) => ({unmanage: !unmanage}));
  }

  async handleManage() {
    const {fetcher} = this.context;

    while (true) {
      // Note: handleMange is the resolve method in a Promise
      const {cancel} = await new Promise(handleOnManage =>
        this.setState(({manage}) => ({
          manage: {
            ...manage, // Destructor manage properties
            handleOnManage, // Promise's resolve handle to listen to the onClick event
          },
        })),
      );

      // Close modal if user clicked cancel by resetting remove to null
      if (cancel) {
        // Return to end the while loop and close the modal
        return this.setState({manage: null});
      }

      // runningError: boolean flag to toggle from red -> green or green -> red
      const {unmanagedVirtualServers} = await reactUtils.setStateAsync(
        ({manage}) => ({manage: {...manage, running: true, runningError: false}}),
        this,
      );

      let allService;

      try {
        allService = await fetcher.spawn(fetchServiceInstance, {pversion: 'draft', service_id: 'all_services'});

        for (const data of unmanagedVirtualServers) {
          let payload = {};

          payload = {
            name: data.name,
            discovered_virtual_server: {href: data.vshref},
            labels: [],
            service: {href: allService.data.href},
            providers: [],
            mode: 'unmanaged',
          };

          await fetcher.spawn(createVirtualServer, payload);
        }

        await reactUtils.setStateAsync({manage: null}, this);

        return this.handleRefresh();
      } catch (error) {
        await reactUtils.setStateAsync(
          ({manage}) => ({
            manage: {
              ...manage, // Destructor remove properties
              running: false, // Set running to false
              error, // Set error property,
              runningError: true, // Set runningError to show the 'Red' error
              // Ensure remove state status is null during render() phase
              handleOnManageClose: () => {
                // the 'this' pointer is binded by the arrow method syntax () => {} which is the current method's this pointer
                this.setState(() => ({manage: null}));
              },
            },
          }),
          this,
        );
      }
    }
  }

  handleRemoveEnter() {
    if (this.state.selectedKeySetToUnmanage.size) {
      this.setState(state => ({
        extraPropsKeyMap: new Map(Array.from(state.selectedKeySetToUnmanage, key => [key, unmanageRowHighLight])),
      }));
    }
  }

  handleRemoveLeave() {
    if (this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  handleError() {
    this.setState({enforce: null, stopenforce: null, runningEnforceError: false, runningUnenforceError: false});
  }

  renderUnmanage() {
    const {
      props: {
        grid: {rowsMap},
      },
      state: {selectedKeySetToUnmanage},
    } = this;

    return (
      <ModalMachineAuto
        settled
        onClose={this.handleUnmanage}
        onDone={this.handleRefresh}
        rowsMap={rowsMap}
        saga={removeVirtualServer}
        hrefs={[...selectedKeySetToUnmanage]}
        listItem={['name']}
      >
        {{
          title: ({selectedHrefs}) =>
            intl('VirtualServers.Detail.UnmanageVirtualServer', {count: selectedHrefs.length}),
          confirmMessage: ({selectedHrefs}) =>
            intl('VirtualServers.ConfirmUnmanageServer', {count: selectedHrefs.length}),
          submitProps: {text: intl('Common.Unmanage'), tid: 'ok'},
          error: {
            title: ({selectedHrefs}) =>
              intl('VirtualServers.Detail.UnmanageVirtualServer', {count: selectedHrefs.length}),
            itemSuccessMessage: ({successHrefs}) =>
              intl('VirtualServers.List.UnmanagedSuccess', {count: successHrefs.length}),
            customErrorMessage: {
              virtual_server_referenced: ({errorsHrefs}) =>
                intl('VirtualServers.List.BoundToRules', {count: errorsHrefs.length}),
            },
          },
        }}
      </ModalMachineAuto>
    );
  }

  renderManage() {
    const {
      unmanagedVirtualServers,
      manage: {handleOnManageClose, error, handleOnManage, running, runningError},
    } = this.state;

    const renderItem = data => <li key={data.href}>{data.name}</li>;

    let notifications;

    if (error) {
      const message = _.get(error, 'data[0].message', error.message);

      notifications = (
        <Notifications title={intl('Common.Error')}>
          {[{type: 'error', title: message, handleClose: handleOnManageClose}]}
        </Notifications>
      );
    }

    return (
      <Modal.Confirmation
        title={intl('VirtualServers.Detail.ManageVirtualServer', {count: unmanagedVirtualServers.size})}
        confirmIsInProgress={running}
        onCancel={_.partial(handleOnManage, {cancel: true})}
        confirmProps={{
          text: intl('LoadBalancers.Detail.Manage'),
          onClick: handleOnManage,
          progress: running,
          progressError: runningError,
        }}
      >
        {notifications}
        {intl('VirtualServers.ConfirmManageServer', {count: unmanagedVirtualServers.size})}
        <ol>{Array.from(unmanagedVirtualServers, renderItem)}</ol>
      </Modal.Confirmation>
    );
  }

  // Render alert message when edit or create fails
  renderAlert() {
    const {enforce, stopenforce} = this.state;

    if (enforce || stopenforce) {
      const showerror = enforce || stopenforce;
      const message = _.get(showerror.error, 'data[0].message', showerror.error.message);

      return (
        <Modal.Alert
          title={intl('Common.Error')}
          onClose={this.handleError}
          buttonProps={{tid: 'ok', text: intl('Common.OK')}}
        >
          <TypedMessages>{[{icon: 'error', content: message}]}</TypedMessages>
        </Modal.Alert>
      );
    }
  }

  render() {
    const {
      props: {showManage, hideReports, hideHeaderProps, showGlobalLink, grid, selector, count},
      state: {
        rows,
        runningEnforce,
        runningUnenforce,
        extraPropsKeyMap,
        selectedKeySet,
        selectedKeySetToUnmanage,
        selectedObjectsToProvision,
        selectedKeySetEnforceable,
        selectedKeySetRevocable,
        unmanage,
        unmanagedVirtualServers,
        manage,
        enforce,
        runningEnforceError,
        stopenforce,
        runningUnenforceError,
      },
    } = this;

    const notifications = getMaxPageNotificationList({page: grid.page, capacity: grid.capacity, count});

    return (
      <>
        {!hideHeaderProps && <HeaderProps title={intl('Common.VirtualServers')} />}
        {notifications.length > 0 && <Notifications sidebar>{notifications}</Notifications>}
        <ToolBar>
          <ToolGroup>
            {showManage && (
              <Button
                text={intl('LoadBalancers.Detail.Manage')}
                icon="manage"
                onClick={this.handleManage}
                disabled={unmanagedVirtualServers.size === 0}
                counter={unmanagedVirtualServers.size}
                tid="manage"
              />
            )}
            <Button
              color="standard"
              icon="remove"
              text={intl('Common.Unmanage')}
              textIsHideable
              tid="remove"
              theme={buttonsTheme}
              counter={selectedKeySetToUnmanage.size}
              counterColor="red"
              disabled={runningEnforce || runningUnenforce || !selectedKeySetToUnmanage.size}
              onClick={this.handleUnmanage}
              onMouseEnter={this.handleRemoveEnter}
              onMouseLeave={this.handleRemoveLeave}
            />
            <Button
              color="standard"
              icon="enforce"
              text={intl('Common.Enforce')}
              textIsHideable
              tid="enforce"
              theme={buttonsTheme}
              counter={selectedKeySetEnforceable.size}
              progressCompleteWithCheckmark
              progress={runningEnforce}
              progressError={runningEnforceError}
              disabled={!selectedKeySetEnforceable.size}
              onClick={this.handleEnforce}
              onMouseEnter={this.handleEnforceEnter}
              onMouseLeave={this.handleEnforceLeave}
            />
            <Button
              color="standard"
              icon="cancel"
              text={intl('Common.EnforcementStop')}
              textIsHideable
              tid="unenforce"
              theme={buttonsTheme}
              counter={selectedKeySetRevocable.size}
              counterColor="yellow"
              progressCompleteWithCheckmark
              progress={runningUnenforce}
              progressError={runningUnenforceError}
              disabled={!selectedKeySetRevocable.size}
              onClick={this.handleStopEnforcement}
              onMouseEnter={this.handleStopEnforcementEnter}
              onMouseLeave={this.handleStopEnforcementLeave}
            />
            <ProvisionButtons
              counter={selectedObjectsToProvision.virtual_servers.length}
              theme={buttonsTheme}
              onButtonHover={this.handleProvisionButtonsHover}
              onDone={this.handleProvisionDone}
              objectsToProvision={selectedObjectsToProvision}
            />
          </ToolGroup>
          <ToolGroup>
            <ButtonRefresh color="standard" textIsHideable onRefresh={this.handleRefresh} theme={buttonsTheme} />
            {!hideReports && <ReportButtons type="virtualServers" disabledGen={!rows.length} theme={buttonsTheme} />}
            {showGlobalLink && (
              <Button.Link
                color="standard"
                textIsHideable
                icon="external-link"
                theme={buttonsTheme}
                text={intl('VirtualServers.All')}
                link="virtualServers"
                tid="virtualserverall"
              />
            )}
          </ToolGroup>
        </ToolBar>
        <ToolBar>
          <ToolGroup expand tid="page-filter">
            <ComboSelect
              scrollable
              objects={selector.objects}
              placeholder={intl('Common.FilterView')}
              initialItems={selector.initialItems}
              categories={selector.categories}
              activeCategoryKey="name"
              facets={selector.facets}
              statics={selector.statics}
              partials={selector.partials}
              filterMap={selector.filterMap}
              onSelectionChange={this.handleFilterChange}
              resourceType={resourceType}
            />
          </ToolGroup>
        </ToolBar>
        <Grid
          grid={grid}
          theme={styles}
          count={count}
          selectedKeySet={selectedKeySet}
          dontHighlightSelected={extraPropsKeyMap.size > 0}
          extraPropsKeyMap={extraPropsKeyMap}
          onClick={this.handleClick}
          onSelect={this.handleSelect}
          emptyMessage={intl('VirtualServers.NoData')}
        />

        {unmanage && this.renderUnmanage()}
        {manage && this.renderManage()}
        {(enforce || stopenforce) && this.renderAlert()}
      </>
    );
  }
}
