/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {type ComponentPropsWithoutRef, PureComponent} from 'react';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import styles from './Badge.css';
import type {ReactStrictNode} from 'utils/types';

const badgeTextMap: Record<string, string> = {
  new: intl('Common.New'),
  preview: intl('Common.Preview'),
  created: intl('Common.Created'),
  updated: intl('Common.Updated'),
  deleted: intl('Common.Deleted'),
  disabled: intl('Common.Disabled'),
  info: intl('Common.Info'),
  low: intl('Common.Low'),
  medium: intl('Common.Medium'),
  high: intl('Common.High'),
  critical: intl('Common.Critical'),
} as const;

export const badges = Object.keys(badgeTextMap);

type Badges = keyof typeof badgeTextMap;

export type BadgeProps = {
  type: typeof badgeTextMap[Badges];
  children?: ReactStrictNode;
  className?: string;
} & ThemeProps &
  ComponentPropsWithoutRef<'div'>;

export default class Badge extends PureComponent<BadgeProps> {
  render(): JSX.Element | null {
    const {type, children, theme, ...props} = mixThemeWithProps(styles, this.props);

    props.className = `${theme.badge} ${theme[type]}`;
    props['data-tid'] = `comp-badge ${type}`;

    return <div {...props}>{children || badgeTextMap[type]}</div>;
  }
}
