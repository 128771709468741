/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {HeaderProps} from 'containers';
import {hrefUtils} from 'utils';
import {fetchContainerWorkloadList} from './ContainerWorkloadListSaga';
import {getContainerWorkloadsPage} from './ContainerWorkloadListState';
import ContainerWorkloadReducers from 'containers/ContainerWorkload/ContainerWorkloadState';
import LabelReducers from '../../Label/LabelState';
import {ButtonRefresh, Grid, Notifications, ToolBar, ToolGroup} from 'components';
import styles from './ContainerWorkloadList.css';
import {ComboSelect} from 'containers/Selectors';
import {resourceType} from './ContainerWorkloadListConfig';
import {getMaxPageNotificationList} from 'components/Grid/GridUtils';

const scopeFilterKeys = ['role', 'app', 'env', 'loc'];
const autocompleteFilterKeys = [...scopeFilterKeys, 'container_clusters'];

@connect(getContainerWorkloadsPage)
export default class ContainerWorkloadList extends Component {
  static prefetch = fetchContainerWorkloadList;
  static contextType = AppContext;
  static reducers = [LabelReducers, ContainerWorkloadReducers];

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleClick(evt, row) {
    this.context.navigate({evt, to: 'workloads.containers.item.view', params: {id: hrefUtils.getId(row.key)}});
  }

  handleFilterChange(selection) {
    const scope = selection
      .filter(item => scopeFilterKeys.includes(item.categoryKey))
      .map(({key, href}) => ({key, href}));

    const filter = selection.reduce((result, {categoryKey, href, value, name}) => {
      if (this.props.isInDetailPage || !scopeFilterKeys.includes(categoryKey)) {
        if (autocompleteFilterKeys.includes(categoryKey)) {
          result[categoryKey] = [{value: name || value, href}];
        } else {
          result[categoryKey] = [value];
        }
      }

      return result;
    }, {});

    this.context.navigate({
      params: {
        scope: !this.props.isInDetailPage && scope.length ? {scope} : undefined,
        [this.props.grid.settings.id]: {...this.props.grid.params, filter, page: null},
      },
    });
  }

  handleRefresh() {
    // Refetch the list, cancelable on page leave
    // Return promise, so Pagination component can publish event for QA
    if (this.props.onRefresh) {
      return this.props.onRefresh();
    }

    return this.context.fetcher.fork(fetchContainerWorkloadList.refetch);
  }

  render() {
    const {
      props: {isInDetailPage, grid, count, selector, hideHeaderProps},
    } = this;

    const initialItems = isInDetailPage ? selector.filterItems : selector.scopeItems.concat(selector.filterItems);

    const notifications = getMaxPageNotificationList({page: grid.page, capacity: grid.capacity, count});

    return (
      <>
        {!hideHeaderProps && <HeaderProps title={intl('Common.ContainerWorkloads')} />}
        {notifications.length > 0 && <Notifications sidebar>{notifications}</Notifications>}
        <ToolBar justify="flex-end">
          <ToolGroup>
            <ButtonRefresh color="standard" onRefresh={this.handleRefresh} />
          </ToolGroup>
          {/* Temporary fix to add spacing. Needs to be done correctly once Button dropdown is fixed to be aware of screen */}
          <div style={{margin: '0 4px'}} />
        </ToolBar>
        <ToolBar>
          <ToolGroup expand tid="page-filter">
            <ComboSelect
              scrollable
              objects={selector.objects}
              placeholder={intl('Common.FilterView')}
              initialItems={initialItems}
              categories={selector.categories}
              activeCategoryKey="name"
              partials={selector.partials}
              facets={selector.facets}
              statics={selector.statics}
              customPickers={selector.customPickers}
              onSelectionChange={this.handleFilterChange}
              resourceType={resourceType}
            />
          </ToolGroup>
        </ToolBar>

        <Grid
          grid={grid}
          count={count}
          theme={styles}
          onClick={this.handleClick}
          emptyMessage={
            initialItems.length > 0
              ? intl('Common.NoMatchDataContainerWorkloads')
              : intl('Common.NoDataContainerWorkloads')
          }
        />
      </>
    );
  }
}
