/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent, createRef} from 'react';
import {AttributeList, Button, Notifications, ToolBar, ToolGroup} from 'components';
import {UserName, HeaderProps} from 'containers';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {getContainerWorkloadProfileDetailPage} from './ContainerClusterContainerWorkloadProfileDetailState';
import {fetchContainerWorkloadProfileInstance} from './ContainerClusterContainerWorkloadProfileDetailSaga';
import RemoveButton from 'containers/Remove/RemoveButton';
import {getLabelTypeName} from 'components/Pill/Label/LabelUtils';
import styles from './ContainerClusterContainerWorkloadProfileDetail.css';
import {enforcementModeView, visibilityLevelView} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {managementView, labelOrAllowList} from '../../ContainerClusterDetailUtils';

const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};

@connect(getContainerWorkloadProfileDetailPage)
export default class ContainerClusterWorkloadProfileDetail extends PureComponent {
  static prefetch = fetchContainerWorkloadProfileInstance;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.keyRef = createRef();

    this.handleFieldFocus = this.handleFieldFocus.bind(this);
    this.handleRemoveDone = this.handleRemoveDone.bind(this);
  }

  getAttributes() {
    const {
      namespaceTerm,
      name,
      namespace,
      description,
      created_at: createdAt,
      created_by_user: createdBy,
      updated_at: updatedAt,
      updated_by_user: updatedBy,
      enforcement_mode: enforcementMode,
      visibility_level: visibilityLevel,
      role,
      app,
      env,
      loc,
      managed,
    } = this.props;
    const classNameContent = styles.content;
    const enforcementModeViewTexts = enforcementModeView();

    let attributes = [
      {divider: true},
      {title: intl('Common.General')},
      {
        tid: 'name',
        key: intl('Common.Name'),
        value: name,
      },
      {
        tid: 'namespace',
        key: namespaceTerm,
        value: namespace,
      },
      {
        tid: 'desc',
        key: intl('Common.Description'),
        value: description,
      },
      {
        tid: 'created',
        key: intl('Common.Created'),
        value: UserName.dateAtTimeBy(createdAt, createdBy, 'full_name'),
      },
      updatedAt && updatedAt !== createdAt
        ? {
            tid: 'last-modified',
            key: intl('Common.LastModified'),
            value: UserName.dateAtTimeBy(updatedAt, updatedBy, 'full_name'),
          }
        : null,
    ];

    // Managed
    const managementDetail = managementView(managed);

    attributes.push({
      tid: 'managed',
      key: intl('Common.Management'),
      value: (
        <>
          {managementDetail.name}
          <div className={classNameContent}>{managementDetail.desc}</div>
        </>
      ),
    });

    if (managed) {
      // Enforcement Mode
      attributes.push({
        tid: 'enforcement-mode',
        key: intl('Common.Enforcement'),
        value: (
          <>
            {enforcementModeViewTexts[enforcementMode]?.name}
            <div className={classNameContent}>{enforcementModeViewTexts[enforcementMode]?.desc}</div>
          </>
        ),
      });

      // Visibility View
      let visibilityValue;

      if (enforcementMode === 'idle') {
        visibilityValue = <div>{visibilityLevelView()[enforcementMode].name}</div>;
      } else {
        visibilityValue = (
          <>
            {visibilityLevelView()[visibilityLevel]?.name}
            <div className={classNameContent}>{visibilityLevelView()[visibilityLevel]?.desc}</div>
          </>
        );
      }

      attributes.push({
        tid: 'visibility-level',
        key: intl('Common.Visibility'),
        value: visibilityValue,
      });

      attributes = attributes.concat([
        {divider: true},
        {title: intl('Common.Labels')},
        {
          tid: 'labelrole',
          key: getLabelTypeName('role'),
          value: labelOrAllowList(role, 'role', managed),
        },
        {
          tid: 'labelapp',
          key: getLabelTypeName('app'),
          value: labelOrAllowList(app, 'app', managed),
        },
        {
          tid: 'labelenv',
          key: getLabelTypeName('env'),
          value: labelOrAllowList(env, 'env', managed),
        },
        {
          tid: 'labelloc',
          key: getLabelTypeName('loc'),
          value: labelOrAllowList(loc, 'loc', managed),
        },
      ]);
    }

    this.renderPairingKeyInfo(attributes);

    return attributes;
  }

  handleFieldFocus() {
    this.keyRef.current.select();
    document.execCommand('copy');
  }

  handleRemoveDone() {
    this.context.navigate({to: 'containerClusters.item.profiles.list', params: {id: this.props.id}});
  }

  renderPairingKeyInfo(attributes) {
    const {pairingKey} = this.props;

    if (!pairingKey) {
      return;
    }

    const notifications = [
      {
        type: 'instruction',
        title: intl('ContainerClusters.CopyPairingKeyMessage'),
      },
    ];

    attributes.push(
      {divider: Boolean(pairingKey)},
      {content: <Notifications>{notifications}</Notifications>},
      {title: intl('Common.PairingKey')},
      {
        tid: 'pairingkey',
        key: intl('PairingProfiles.Key'),
        value: (
          <>
            <input
              data-tid="container-workloads-profile-pairing-key"
              ref={this.keyRef}
              className={styles.keyValue}
              readOnly
              value={pairingKey}
              onClick={this.handleFieldFocus}
            />
            <Button
              color="standard"
              text={intl('ContainerClusters.CopyKey')}
              tid="copykey"
              theme={buttonsTheme}
              onClick={this.handleFieldFocus}
            />
          </>
        ),
      },
    );
  }

  render() {
    const {clusterName, name, namespace, id, profileId, href, linked, userIsReadOnlyClusterInsensitive, isLocalPce} =
      this.props;

    return (
      <>
        <HeaderProps
          title={intl('Menu.ContainerClusters', {multiple: true})}
          up={{to: 'containerClusters.item.profiles.list', params: {id}}}
          subtitle={profileId ? `${clusterName} - ${name || namespace}` : clusterName}
        />
        <ToolBar>
          <ToolGroup>
            <Button.Link
              icon="edit"
              text={intl('Common.Edit')}
              textIsHideable
              link={{to: 'containerClusters.item.profiles.edit', params: {id, profileId}}}
              tid="edit"
              theme={buttonsTheme}
              disabled={userIsReadOnlyClusterInsensitive || !isLocalPce}
            />
            {!linked && (
              <RemoveButton
                theme={buttonsTheme}
                name={name}
                href={href}
                target="containerWorkloadProfile"
                onRemoveDone={this.handleRemoveDone}
                disabled={userIsReadOnlyClusterInsensitive || !isLocalPce}
              />
            )}
          </ToolGroup>
        </ToolBar>
        <AttributeList>{this.getAttributes()}</AttributeList>
        <div className={styles.bottomSpacer} />
      </>
    );
  }
}
