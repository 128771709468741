/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Field} from 'formik';
import {forwardRefFactory, forwardRefSymbol} from 'react-forwardref-utils';
import * as PropTypes from 'prop-types';
import {whenPropTypes, useWhen} from '../FormUtils';
import Input from './Input';
import Textarea from '../Textarea/Textarea';

FormInput.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  ...whenPropTypes,
};

/**
 * This form component handles the creation of input and textarea form components.
 * By default an input is created. Pass in type='textarea' to create a form textarea component.
 *
 * @param ref
 * @param when
 * @param form
 * @param field
 * @param onBlur
 * @param onChange
 * @param inputProps
 * @returns {*}
 * @constructor
 */
function FormInput(props) {
  const {
    [forwardRefSymbol]: ref,
    form,
    field,
    onBlur,
    onChange,
    ...inputProps
  } = useWhen(props.form, props.field, props);

  const {name, value} = field;
  const {errors, touched, handleChange, handleBlur} = form;
  // An error border will be displayed by default if the name for this input appears as a key in the errors object.
  const error = _.get(touched, name) === true && _.has(errors, name);
  // An error message will be displayed if the value for this inputs key is not undefined.
  const errorMessage = error && _.get(errors, name);

  inputProps.name = name;
  inputProps.value = value;
  inputProps.ref = ref;
  inputProps.onChange = onChange || handleChange;
  inputProps.onBlur = onBlur || handleBlur;
  inputProps.error = error;

  if (errorMessage) {
    inputProps.errorMessage = errorMessage;
  }

  if (inputProps.validatedMessage && (!touched[name] || inputProps.value.length === 0)) {
    inputProps.validatedMessage = null;
  }

  return inputProps.type === 'textarea' ? <Textarea {...inputProps} /> : <Input {...inputProps} />;
}

export default forwardRefFactory(props => <Field {...props} component={FormInput} />, {hoistSource: FormInput});
