/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {Button} from 'components';
import styles from './SwitchList.css';
import {composeDownloadUrl} from '../../Reports/ReportsUtils';
import {createSelector} from 'reselect';

export const getSelectorSettings = createSelector([], () => ({
  staticMap: {
    model: 'Model',
    manufacturer: 'Manufacturer',
  },
  filterMap: {
    model: 'Model',
    manufacturer: 'Manufacturer',
  },
}));

export const gridSettings = createSelector([], () => ({
  id: 'switchlist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    name: {
      highlightValue: true,
      header: intl('Common.Name'),
      value: ({row}) => row.name,
    },
    deviceType: {
      highlightValue: true,
      header: intl('Common.DeviceType'),
      value: ({row}) => row.deviceType,
    },
    ipaddress: {
      highlightValue: true,
      header: intl('Common.IPAddress'),
      value: ({row}) => row.ipaddress,
    },
    manufacturer: {
      highlightValue: true,
      header: intl('Switches.Manufacturer'),
      value: ({row}) => row.manufacturer,
    },
    model: {
      highlightValue: true,
      header: intl('Switches.Model'),
      value: ({row}) => row.model,
    },
    interfaces: {
      highlightValue: true,
      header: intl('Workloads.Interfaces'),
      value: ({row}) => row.interfaces,
    },
    download: GridUtils.clickableColumn({
      header: intl('Switches.DownloadACLs'),
      format: ({row, clickableRef}) => {
        const {dataHref, dataTimeStamp, inProgress} = row;

        if (inProgress) {
          return intl('Switches.AclGenerationPending');
        }

        if (dataTimeStamp) {
          return (
            <div className={styles.displayFlex}>
              {intl('Common.GeneratedAtTime', {time: intl.date(dataTimeStamp, intl.formats.date.L_HH_mm_ss)})}
              <div className={styles.generateButton}>
                <Button.Link
                  color="primary"
                  tid="download-acls"
                  text={intl('Switches.DownloadACLs')}
                  ref={clickableRef}
                  link={{href: composeDownloadUrl(dataHref)}}
                />
              </div>
            </div>
          );
        }

        return null;
      },
    }),
    applied: {
      isDate: true,
      highlightValue: true,
      header: intl('Switches.AppliedAt'),
      value: ({row}) => row.applied,
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['name'], size: 'minmax(120px, auto)'},
      {columns: ['deviceType'], size: 'minmax(120px, auto)'},
      {columns: ['ipaddress'], size: 'minmax(120px, auto)'},
      {columns: ['interfaces'], size: 'minmax(120px, auto)'},
      {columns: ['manufacturer'], size: 'minmax(120px, auto)'},
      {columns: ['model'], size: 'minmax(120px, auto)'},
      {columns: ['download'], size: 'minmax(120px, auto)'},
      {columns: ['applied'], size: 'minmax(120px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['deviceType'], size: 'minmax(100px, auto)'},
            {columns: ['ipaddress', 'interfaces'], size: 'minmax(120px, auto)'},
            {columns: ['manufacturer', 'model'], size: 'minmax(100px, auto)'},
            {columns: ['download', 'applied'], size: 'minmax(120px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['deviceType'], size: 'minmax(100px, auto)'},
          {columns: ['ipaddress', 'interfaces'], size: 'minmax(120px, auto)'},
          {columns: ['manufacturer', 'model'], size: 'minmax(100px, auto)'},
          {columns: ['download', 'applied'], size: 'minmax(120px, auto)'},
        ];
      },
    },
  ],
}));
