/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {getScrollParentAndOffsetElements, isCursorWithinElement} from 'utils/dom';

import type {Instance, Plugin} from 'tippy.js';
import type {TooltipProps, TooltipPlacement, ComputedPlacement} from './Tooltip';

// key is shorthand placement, value is actual placement
export const placementMap = new Map<TooltipPlacement, ComputedPlacement>([
  ['top', 'top'],
  ['topStart', 'top-start'],
  ['topEnd', 'top-end'],

  ['right', 'right'],
  ['rightStart', 'right-start'],
  ['rightEnd', 'right-end'],

  ['bottom', 'bottom'],
  ['bottomStart', 'bottom-start'],
  ['bottomEnd', 'bottom-end'],

  ['left', 'left'],
  ['leftStart', 'left-start'],
  ['leftEnd', 'left-end'],
]);

// converts the shorthand placement prop to the actual placement value - returns undefined if no shorthand is in props
export const getPlacement = (tooltipProps: Partial<TooltipProps>): ComputedPlacement | undefined => {
  if (!tooltipProps) {
    return;
  }

  for (const [shorthand, value] of placementMap) {
    if (tooltipProps[shorthand]) {
      return value;
    }
  }
};

interface XY {
  clientX: number;
  clientY: number;
}

// Plugin for hide on scroll - IIFE that returns an object
export const hideOnScroll: Plugin = (() => {
  // cache mouse position across different tooltips instances, since mouse events aren't triggered on scroll
  // we only get mouse position from mouse events - see MouseEvent interface
  const mousePosition: XY = {clientX: 0, clientY: 0};

  const handleMouseMove = _.debounce(({clientX, clientY}: XY) => {
    // sometimes first mouse event returns 0 for all properties
    if (clientX !== 0 && clientY !== 0) {
      mousePosition.clientX = clientX;
      mousePosition.clientY = clientY;
    }
  }, 10);

  return {
    name: 'hideOnScroll',
    fn(instance: Instance) {
      let scrollParent: Element | Document;
      let handleScroll: (() => void) | undefined;
      let hasMouseTrigger: boolean;

      return {
        onTrigger() {
          hasMouseTrigger = instance.props.trigger.includes('mouse');

          // do not setup scroll on hide for tooltips that are triggered by focus and are currently focused.
          // The tooltip should stay anchored to the reference element until blur event occurs, regardless of any scrolling
          const wasTriggeredByFocus =
            instance.props.trigger.includes('focus') && document.activeElement === instance.reference;

          // if this is a standard tooltip, setup listeners and handlers for hide on scroll functionality
          if (hasMouseTrigger && !wasTriggeredByFocus) {
            const {
              reference,
              hide,
              popper,
              props: {interactive, triggerTarget},
            } = instance;

            document.addEventListener('mousemove', handleMouseMove);

            const {parent} = getScrollParentAndOffsetElements(reference);

            if (parent) {
              if (parent.nodeName.toLowerCase() === 'html') {
                scrollParent = document;
              } else {
                scrollParent = parent;
              }

              handleScroll = _.throttle(() => {
                if (
                  // singleton: triggerTarget is an array of DOM elements
                  (Array.isArray(triggerTarget) &&
                    triggerTarget.every(element => !isCursorWithinElement(element, mousePosition))) ||
                  // standard tooltips: if the the tooltip is interactive, we need to check if the cursor is within the tooltip itself
                  // set margins to avoid hiding a tooltip on scroll when the cursor is between trigger and a tooltip
                  (!triggerTarget &&
                    !isCursorWithinElement(reference, mousePosition) &&
                    (!interactive ||
                      !isCursorWithinElement(popper, mousePosition, {top: 10, right: 10, bottom: 10, left: 10})))
                ) {
                  hide();
                }
              }, 40);

              scrollParent.addEventListener('scroll', handleScroll, {passive: true});
            }
          }
        },

        onUntrigger() {
          // cleanup listeners for "hide on scroll" logic
          if (hasMouseTrigger && handleScroll) {
            scrollParent?.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousemove', handleMouseMove);
          }
        },
      };
    },
  };
})();
