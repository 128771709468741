/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {reactUtils} from 'utils';
import {AppContext} from 'containers/App/AppUtils';
import {namesByType} from 'components/Pill/Label/LabelUtils';
import {getPolicyStateContent, groupErrorsByTokens} from '../WorkloadUtils';
import {
  enforcementModeView,
  enforcementModeViewEdge,
  visibilityLevelViewEdge,
} from '../../EnforcementBoundaries/EnforcementBoundariesUtils';
import {fetchPairingProfileByLabel} from '../../../edge/containers/Group/GroupSaga';
import {editLabelsWorkloads, editLabelsWorkloadsInEdge} from '../WorkloadSaga';
import {Button, Pill, Notifications, ModalMachineAuto, Modal, TypedMessages, Spinner} from 'components';
import {SingleItemSelect} from 'containers/Selectors';
import styleUtils from 'utils.css';
import styles from './EditLabels.css';

const getEmptyState = edgeEnabled => ({
  showModal: false,
  ...(edgeEnabled && {
    running: false,
    edited: [],
    error: null,
    enforcementContent: null,
  }),
});

const labelTypes = ['role', 'app', 'env', 'loc'];
// Used in Edge
const spinnerTheme = {spinner: styles['icon-spinner']};

export default class EditLabels extends PureComponent {
  static contextType = AppContext;
  static propTypes = {
    rowsMap: PropTypes.object.isRequired,
    hrefsToEdit: PropTypes.instanceOf(Set).isRequired,
    // Edge property
    unmanagedWorkloads: PropTypes.instanceOf(Set),
    showCounter: PropTypes.bool,

    onButtonHover: PropTypes.func,
    onDone: PropTypes.func,
    disabled: PropTypes.bool,
    resourceType: PropTypes.string,
    edgeEnabled: PropTypes.bool, // Displays edit messaging for Edge
    crowdstrikeEnabled: PropTypes.bool,
  };

  static defaultProps = {
    showCounter: false,
    onButtonHover: _.noop,
    onDone: _.noop,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = getEmptyState(props.edgeEnabled);

    this.handleButtonEnter = this.handleButtonEnter.bind(this);
    this.handleButtonLeave = this.handleButtonLeave.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleLabelSelectorChange = this.handleLabelSelectorChange.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);

    // Edge handlers
    this.handleAlertClose = this.handleAlertClose.bind(this);
    this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.showModal && nextProps.hrefsToEdit !== prevState.hrefsToEdit) {
      const initialLabels = {role: new Set(), app: new Set(), env: new Set(), loc: new Set()};
      const finalLabels = {role: new Set(), app: new Set(), env: new Set(), loc: new Set()};
      const labelValueMap = {};

      const workloadsData = Array.from(nextProps.hrefsToEdit, key => {
        const {data} = nextProps.rowsMap.get(key);
        const labels = data.labels;

        labelTypes.forEach(labelKey => {
          if (labelKey in labels) {
            labelValueMap[labels[labelKey].href] = labels[labelKey].value;
            initialLabels[labelKey].add(labels[labelKey].href);
            finalLabels[labelKey].add(labels[labelKey].href);
          } else {
            initialLabels[labelKey].add(null);
            finalLabels[labelKey].add(null);
          }
        });

        return {
          href: key,
          labels: data.labels,
        };
      });

      const nextState = {
        hrefsToEdit: nextProps.hrefsToEdit,
        workloadsData,
        initialLabels,
        finalLabels,
        labelValueMap,
      };

      return nextState;
    }

    return null;
  }

  getSelectedLabels() {
    const result = [];
    const {finalLabels} = this.state;

    if (finalLabels) {
      for (const [key, value] of Object.entries(finalLabels)) {
        if (key && value) {
          const [href] = Array.from(value);

          if (href && !href.includes('exists')) {
            result.push({
              key,
              href,
            });
          }
        }
      }
    }

    return result;
  }

  handleButtonEnter(evt) {
    this.props.onButtonHover(evt, 'enter');
  }

  handleButtonLeave(evt) {
    // Notify parent on leave only when modal is not shown,
    // so parent can drop highlight if user moved out cursor and haven't opened modal
    if (!this.state.showModal) {
      this.props.onButtonHover(evt, 'leave');
    }
  }

  async handleLabelChange(type, value) {
    // Policy Content details to appear under group selection
    let enforcementContent = null;

    if (value === 'clear') {
      // Only show if initial selection exists e.g. going from group to no group via 'clear' action.
      if (
        this.props.crowdstrikeEnabled &&
        this.state.initialLabels.role.size === 1 &&
        !this.state.initialLabels.role.has(null)
      ) {
        enforcementContent = getPolicyStateContent('idle', 'flow_summary', false);
      }

      this.setState(prevState => ({
        finalLabels: {
          ...prevState.finalLabels,
          [type]: new Set(),
        },
        ...(this.props.edgeEnabled && {
          enforcementContent,
        }),
      }));
    } else if (value === 'undo') {
      this.setState(prevState => ({
        finalLabels: {
          ...prevState.finalLabels,
          [type]: prevState.initialLabels[type],
        },
        ...(this.props.edgeEnabled && {
          enforcementContent,
        }),
      }));
    } else {
      if (this.props.edgeEnabled) {
        await reactUtils.setStateAsync({fetchingPairingProfile: true}, this);

        // Fetch policy state for group
        const pairingProfile = await this.context.fetcher.spawn(fetchPairingProfileByLabel, {
          labelHref: value,
          force: true,
          dispatch: false,
        });

        const {enforcement_mode: enforcementMode, visibility_level: visibilityLevel} = pairingProfile;

        // Set policy state content for group selection
        enforcementContent = {enforcement_mode: enforcementMode, visibility_level: visibilityLevel};
        enforcementContent.group = this.state.labelValueMap[value];
      }

      this.setState(prevState => ({
        finalLabels: {
          ...prevState.finalLabels,
          [type]: new Set([value]),
        },
        ...(this.props.edgeEnabled && {
          fetchingPairingProfile: false,
          enforcementContent,
        }),
      }));
    }
  }

  async handleLabelSelectorChange(type, selection) {
    const selectionLabelValueMap = selection.reduce((result, label) => {
      result[label.href] = label.value;

      return result;
    }, {});

    // selectionLabelValueMap => An object that is always the selection made in the selector (Only one item at max)
    // labelValueMap => All Labels that were initially selected.
    await reactUtils.setStateAsync(
      ({labelValueMap}) => ({
        labelValueMap: {
          ...labelValueMap,
          ...selectionLabelValueMap,
        },
      }),
      this,
    );

    if (selection.length > 0) {
      await this.handleLabelChange(type, selection[0].href);
    } else {
      await this.handleLabelChange(type, 'clear');
    }
  }

  handleButtonClick() {
    this.setState({showModal: true});
  }

  async handleClose(context) {
    if (this.props.edgeEnabled) {
      this.setState(getEmptyState(this.props.edgeEnabled));

      return;
    }

    // closing the error alert
    if (context.errors) {
      // Notify parent about changes, so parent will clear selection which will automatically close this modal in getDerivedStateFromProps
      // In the case of errors, we need to trigger handleDone manually because modal machine does not call onDone in error state
      await this.handleDone(context);
      this.setState({
        showModal: false,
      });
    } else {
      const {initialLabels} = this.state;

      this.props.onButtonHover(null, 'leave');
      this.setState({
        showModal: false,
        finalLabels: initialLabels,
      });
    }
  }

  async handleConfirm() {
    const {hrefsToEdit, initialLabels, finalLabels} = await reactUtils.setStateAsync({running: true}, this);
    const edited = [...hrefsToEdit];

    // Prevent update when no change was made
    if (finalLabels.role.size === 1 && [...finalLabels.role].join(',') === [...initialLabels.role].join(',')) {
      await this.handleDone({processResult: {data: {edited}}});
      await this.handleClose();

      return;
    }

    // list of label types which will be removed
    const labelTypesForRemoveLabels = labelTypes.filter(labelType => finalLabels[labelType].size === 0);

    const labelsForSetLabels = labelTypes.reduce((result, labelType) => {
      const finalLabelsCurrentType = finalLabels[labelType];
      const initialLabelsCurrentType = initialLabels[labelType];

      if (
        finalLabelsCurrentType.size === 1 &&
        [...initialLabelsCurrentType].join(',') !== [...finalLabelsCurrentType].join(',')
      ) {
        result.push({href: [...finalLabelsCurrentType].join(',')});
      }

      return result;
    }, []);

    const data = {
      workloadsForSetLabels: edited,
      labelsForSetLabels,
      labelTypesForRemoveLabels,
    };

    const {
      context: {fetcher},
      props: {edgeEnabled},
    } = this;
    const error = await fetcher.spawn(edgeEnabled ? editLabelsWorkloadsInEdge : editLabelsWorkloads, data);

    const errorMessage =
      Array.isArray(error) && error.length > 0 ? this.formatErrorMessage(edited.length, error) : error;

    if (error) {
      this.setState({running: false, edited, error: errorMessage});
    } else {
      await this.handleDone({processResult: {data: {edited}}});
      await this.handleClose();
    }
  }

  handleConfirmationClose(evt) {
    const {initialLabels} = this.state;

    this.props.onButtonHover(evt, 'leave');
    this.setState({
      showModal: false,
      finalLabels: initialLabels,
      enforcementContent: null,
    });
  }

  async handleAlertClose() {
    if (this.state.edited.length) {
      // When removing is done we set running state to true to show loading in modal, because parent will refresh list in that case
      await reactUtils.setStateAsync({running: true}, this);
    }

    // Notify parent about changes, so parent will clear selection wich will automatically close this modal in getDerivedStateFromProps
    await this.handleDone({processResult: {data: {edited: this.state.edited}}, errors: this.state.error});

    await this.handleClose();
  }

  handleDone({
    processResult: {
      data: {edited},
    },
    errors,
  }) {
    return this.props.onDone(edited, errors);
  }

  async onSave() {
    const {hrefsToEdit, initialLabels, finalLabels} = this.state;
    const edited = [...hrefsToEdit];

    // Edge: Prevent update when no change was made
    if (this.props.edgeEnabled) {
      if (finalLabels.role.size === 1 && [...finalLabels.role].join(',') === [...initialLabels.role].join(',')) {
        await this.handleDone({processResult: {data: {edited}}});
        await this.handleClose();

        return;
      }
    }

    // list of label types which will be removed
    const labelTypesForRemoveLabels = labelTypes.filter(labelType => finalLabels[labelType].size === 0);

    const labelsForSetLabels = labelTypes.reduce((result, labelType) => {
      const finalLabelsCurrentType = finalLabels[labelType];
      const initialLabelsCurrentType = initialLabels[labelType];

      if (
        finalLabelsCurrentType.size === 1 &&
        [...initialLabelsCurrentType].join(',') !== [...finalLabelsCurrentType].join(',')
      ) {
        result.push({href: [...finalLabelsCurrentType].join(',')});
      }

      return result;
    }, []);

    const data = {
      workloadsForSetLabels: edited,
      labelsForSetLabels,
      labelTypesForRemoveLabels,
    };

    const {
      context: {fetcher},
      props: {edgeEnabled},
    } = this;
    const error = await fetcher.spawn(edgeEnabled ? editLabelsWorkloadsInEdge : editLabelsWorkloads, data);

    const errorMessage =
      Array.isArray(error) && error.length > 0 ? this.formatErrorMessage(edited.length, error) : error;

    if (error) {
      throw new Error(errorMessage);
    }

    return {edited};
  }

  formatErrorMessage(totalWorkloads, error) {
    const messages = [];
    const errorArray = groupErrorsByTokens(error);
    const count = totalWorkloads - error.length;

    // Show total number of Workloads successfully modified.
    if (count) {
      messages.push({
        type: 'success',
        message: intl('Workloads.EditLabels.WorkloadsModified', {count}),
      });
    }

    // Show total number of Workloads failed modified.
    if (errorArray.length > 0) {
      messages.push({
        type: 'error',
        message: errorArray.map(({token, count, message}) => {
          let errorMessage = message;

          if (token) {
            const str = intl(`ErrorsAPI.err:${token}`);

            if (!str.includes(token)) {
              errorMessage = str;
            }
          }

          return intl('Workloads.EditLabels.WorkloadsCannotModified', {count, message: errorMessage});
        }),
      });
    }

    return messages;
  }

  renderLabelSelectors() {
    const {
      state: {initialLabels, finalLabels, labelValueMap},
      props: {resourceType},
    } = this;

    const selectedLabels = this.getSelectedLabels();

    return labelTypes.map(labelType => {
      const labelTypeName = namesByType[labelType];
      const initialLabelsCurrentType = initialLabels[labelType];
      const finalLabelsCurrentType = finalLabels[labelType];
      const initialLabelsString = [...initialLabelsCurrentType].sort().join(',');
      const finalLabelsString = [...finalLabelsCurrentType].sort().join(',');

      let initialItems = [];

      if (finalLabelsCurrentType.size === 1) {
        const finalHref = [...finalLabelsCurrentType][0];

        if (finalHref !== null) {
          const labelValue = labelValueMap[finalHref];

          initialItems = [
            {
              categoryName: `${labelTypeName} Label`,
              categoryKey: 'labels',
              href: finalHref,
              key: labelType,
              value: labelValue,
            },
          ];
        }
      }

      return (
        <div key={labelType} className={styles.editLabelsRow} data-tid={`edit-label-row-${labelType}label`}>
          <div className={styles.labelType}>{labelTypeName}</div>
          <div className={styles.labelSelector} data-tid={`comp-labelselect comp-labelselect-${labelType}label`}>
            {finalLabelsCurrentType.size > 1 ? (
              <div className={styles.pseudoSelector} onClick={_.partial(this.handleLabelChange, labelType, 'clear')}>
                <div className={styles.item} data-tid="comp-combobox-selected-value">
                  <Pill.Label onClose={_.partial(this.handleLabelChange, labelType, 'clear')}>
                    {intl('Workloads.EditLabels.MultipleClickRemove', {type: labelTypeName})}
                  </Pill.Label>
                </div>
              </div>
            ) : (
              <SingleItemSelect
                initialItems={initialItems}
                showResultsFooter
                allowCreateTypes={['labels']}
                placeholder={intl('Labels.SelectType', {type: labelTypeName})}
                objects={[{type: 'labels', key: labelType}]}
                onSelectionChange={_.partial(this.handleLabelSelectorChange, labelType)}
                categories={[{value: `${labelTypeName} Label`, categoryKey: 'labels'}]}
                selectedLabels={selectedLabels}
                resourceType={resourceType}
              />
            )}
          </div>
          <div className={styles.undoButton}>
            {initialLabelsString !== finalLabelsString && (
              <Button
                noFill
                text={intl('Common.Undo')}
                tid={`undo-${labelType}`}
                onClick={_.partial(this.handleLabelChange, labelType, 'undo')}
              />
            )}
          </div>
        </div>
      );
    });
  }

  renderModal() {
    const {
      state: {
        hrefsToEdit,
        running,
        error,
        initialLabels,
        finalLabels,
        labelValueMap,
        enforcementContent: groupPolicyContent,
        fetchingPairingProfile,
      },
      props: {resourceType, edgeEnabled, crowdstrikeEnabled, unmanagedWorkloads},
    } = this;

    const title = edgeEnabled ? intl('Edge.MoveToGroup') : intl('Labels.Edit');

    if (!this.props.edgeEnabled) {
      const isOkDisabled = labelTypes.every(type => {
        const initialLabel = initialLabels[type].values().next().value;
        const finalLabel = finalLabels[type].values().next().value;

        // labels can be null or undefined and when we check equality undefined === null
        return initialLabel === finalLabel;
      });

      const clearedLabels = labelTypes.filter(labelType => finalLabels[labelType].size === 0);

      return (
        <ModalMachineAuto
          saga={this.onSave}
          modalProps={{
            large: true,
            notResizable: true,
          }}
          contentProps={{
            notScrollable: true,
          }}
          onDone={this.handleDone}
          onClose={this.handleClose}
        >
          {{
            title,
            confirmMessage: (
              <>
                <div>{intl('Labels.ModifyForWorkloads', {count: hrefsToEdit.size}, {html: true})}</div>
                {/* notifications render here instead of using the `notifications` prop because they need to be under the div above */}
                {clearedLabels.length > 0 && (
                  <Notifications>
                    {[
                      {
                        type: 'warning',
                        message: intl(
                          'Labels.ClearMessage',
                          {
                            labelNames: intl.list(clearedLabels.map(clearedLabel => namesByType[clearedLabel])),
                            count: hrefsToEdit.size,
                          },
                          {html: true},
                        ),
                      },
                    ]}
                  </Notifications>
                )}
                {this.renderLabelSelectors()}
              </>
            ),
            submitProps: {tid: 'ok', disabled: isOkDisabled, text: intl('Common.OK')},
          }}
        </ModalMachineAuto>
      );
    }

    // Edge modal rendering
    let initialItems = [];

    const isOkDisabled = labelTypes.every(type => {
      const initialLabelString = [...initialLabels[type]].sort().join(',');
      const finalLabelString = [...finalLabels[type]].sort().join(',');

      return initialLabelString === finalLabelString;
    });

    if (error) {
      return (
        <Modal large notResizable onClose={this.handleAlertClose}>
          <Modal.Header title={title} />
          <Modal.Content notScrollable>
            {Array.isArray(error) ? (
              <Notifications>{error}</Notifications>
            ) : (
              <TypedMessages>{[{icon: 'error', content: error.message || error}]}</TypedMessages>
            )}
          </Modal.Content>
          <Modal.Footer>
            <Button
              key="primary"
              text={intl('Common.OK')}
              tid="ok"
              onClick={this.handleAlertClose}
              progressCompleteWithCheckmark
              progressError
            />
          </Modal.Footer>
        </Modal>
      );
    }

    const selectedLabels = this.getSelectedLabels();

    let enforcementContent = groupPolicyContent;

    if (selectedLabels.length === 0 && crowdstrikeEnabled) {
      enforcementContent = {
        enforcement_mode: 'idle',
        visibility_level: 'idle',
      };
    }

    let visibilityValue;

    if (!crowdstrikeEnabled && edgeEnabled) {
      if (enforcementContent?.enforcement_mode === 'idle') {
        visibilityValue = (
          <>
            {visibilityLevelViewEdge[enforcementContent?.enforcement_mode]?.name}
            <div className={styles.content}>{visibilityLevelViewEdge[enforcementContent?.enforcement_mode]?.desc}</div>
          </>
        );
      } else if (enforcementContent) {
        visibilityValue = (
          <>
            {visibilityLevelViewEdge[enforcementContent.visibility_level]?.name}
            <div className={styles.content}>
              {enforcementContent.enforcement_mode === 'full' && enforcementContent.visibility_level === 'flow_summary'
                ? visibilityLevelViewEdge.flow_summary_enforce?.desc
                : visibilityLevelViewEdge[enforcementContent.visibility_level]?.desc}
            </div>
          </>
        );
      }
    }

    const showUnmanagedWarning = crowdstrikeEnabled && unmanagedWorkloads?.size > 0;

    /** _.xor() is used to make sure both [...unmanagedWorkloads], [...hrefsToEdit] do not include each other regardless of order */

    const disableEnforcementView = crowdstrikeEnabled && _.xor([...unmanagedWorkloads], [...hrefsToEdit]).length === 0;

    let moveGroupMessage;

    if (crowdstrikeEnabled) {
      moveGroupMessage = intl('Common.Managed');
    }

    return (
      <Modal large notResizable onClose={this.handleConfirmationClose}>
        <Modal.Header title={title} />
        <Modal.Content notScrollable>
          {showUnmanagedWarning && (
            <Notifications>
              {[
                {
                  type: 'warning',
                  message: intl('Labels.ModifyForWorkloadsEdgeUnmanaged'),
                },
              ]}
            </Notifications>
          )}
          <div>
            {edgeEnabled
              ? intl('Labels.ModifyForWorkloadsEdge', {count: hrefsToEdit.size}, {html: true})
              : intl('Labels.ModifyForWorkloads', {count: hrefsToEdit.size}, {html: true})}
          </div>
          {labelTypes.map(labelType => {
            if (edgeEnabled && labelType !== 'role') {
              return;
            }

            const labelTypeName = namesByType[labelType];
            const initialLabelsCurrentType = initialLabels[labelType];
            const finalLabelsCurrentType = finalLabels[labelType];
            const initialLabelsString = [...initialLabelsCurrentType].sort().join(',');
            const finalLabelsString = [...finalLabelsCurrentType].sort().join(',');

            if (finalLabelsCurrentType.size === 1) {
              const finalHref = [...finalLabelsCurrentType][0];

              if (finalHref !== null) {
                const labelValue = labelValueMap[finalHref];

                initialItems = [
                  {
                    categoryName: `${labelTypeName} Label`,
                    categoryKey: 'labels',
                    href: finalHref,
                    key: labelType,
                    value: labelValue,
                  },
                ];
              }
            }

            return (
              <div key={labelType} className={styles.editLabelsRow} data-tid={`edit-label-row-${labelType}label`}>
                <div className={styles.labelType}>{labelTypeName}</div>
                <div className={styles.labelSelector} data-tid={`comp-labelselect comp-labelselect-${labelType}label`}>
                  {finalLabelsCurrentType.size > 1 ? (
                    <div
                      className={styles.pseudoSelector}
                      onClick={_.partial(this.handleLabelChange, labelType, 'clear')}
                    >
                      <div className={styles.item} data-tid="comp-combobox-selected-value">
                        <Pill.Label onClose={_.partial(this.handleLabelChange, labelType, 'clear')}>
                          {intl('Workloads.EditLabels.MultipleClickRemove', {type: labelTypeName})}
                        </Pill.Label>
                      </div>
                    </div>
                  ) : (
                    <SingleItemSelect
                      initialItems={initialItems}
                      showResultsFooter
                      placeholder={intl('Labels.SelectType', {type: labelTypeName})}
                      objects={[{type: 'labels', key: labelType}]}
                      onSelectionChange={_.partial(this.handleLabelSelectorChange, labelType)}
                      categories={[{value: `${labelTypeName} Label`, categoryKey: 'labels'}]}
                      selectedLabels={selectedLabels}
                      resourceType={resourceType}
                    />
                  )}
                </div>
                <div className={styles.undoButton}>
                  {initialLabelsString !== finalLabelsString && (
                    <Button
                      noFill
                      text={intl('Common.Undo')}
                      tid={`undo-${labelType}`}
                      onClick={_.partial(this.handleLabelChange, labelType, 'undo')}
                    />
                  )}
                </div>
              </div>
            );
          })}
          {!disableEnforcementView && edgeEnabled && !fetchingPairingProfile && enforcementContent && !isOkDisabled && (
            <div className={styleUtils.gap}>
              <div>
                {intl(
                  selectedLabels.length > 0
                    ? 'Workloads.EditLabels.MovingWorkloadIntoGroup'
                    : 'Workloads.EditLabels.MovingWorkloadGroupGeneric',
                  {count: hrefsToEdit.size, managed: moveGroupMessage},
                )}
              </div>
              {enforcementContent?.enforcement_mode && (
                <div className={styles.policyContent}>
                  {
                    enforcementModeViewEdge[
                      edgeEnabled && enforcementContent.enforcement_mode === 'full'
                        ? 'enforced'
                        : enforcementContent.enforcement_mode
                    ]?.name
                  }
                  <div className={styles.content}>
                    {edgeEnabled
                      ? enforcementModeViewEdge[enforcementContent.enforcement_mode]?.desc
                      : enforcementModeView[enforcementContent.enforcement_mode]?.desc}
                  </div>
                  {visibilityValue}
                </div>
              )}
            </div>
          )}
          {!disableEnforcementView && edgeEnabled && fetchingPairingProfile && (
            <div className={styles.spinnerContainer}>
              <Spinner color="dark" theme={spinnerTheme} />
            </div>
          )}
        </Modal.Content>
        <Modal.Footer>
          <Button noFill text={intl('Common.Cancel')} tid="cancel" onClick={this.handleConfirmationClose} />
          <Button
            key="primary"
            disabled={isOkDisabled}
            text={intl('Common.OK')}
            tid="ok"
            onClick={this.handleConfirm}
            progressCompleteWithCheckmark
            progress={running}
            progressError={false}
          />
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const {
      props: {showCounter, disabled, edgeEnabled},
      state: {hrefsToEdit, showModal},
    } = this;
    const theme = composeThemeFromProps(styles, this.props);

    return (
      <>
        <Button
          color="standard"
          icon="edit"
          textIsHideable
          text={edgeEnabled ? intl('Edge.MoveToGroup') : intl('Labels.Edit')}
          tid="edit"
          theme={theme}
          counter={showCounter ? hrefsToEdit.size : undefined}
          disabled={!hrefsToEdit.size || disabled}
          onMouseEnter={this.handleButtonEnter}
          onMouseLeave={this.handleButtonLeave}
          onClick={this.handleButtonClick}
        />
        {showModal && this.renderModal()}
      </>
    );
  }
}
