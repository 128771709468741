/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {hrefUtils} from 'utils';
import {isAPIAvailable} from 'api/apiUtils';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, getSelectorSettings} from './NetworkListConfig';
import {getAllUsersMap} from 'containers/User/UserState';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'NETWORK_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'NETWORK_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getNetworks = state => state.networks.list;
export const getNetworksCount = state => state.networks.count;

const getNetworkRows = createSelector([getNetworks, getAllUsersMap], (networks, usersMap) => {
  // API doesn't support caps thus use is isAPIAvailable() for both selectable
  const apiAvailable = isAPIAvailable('network.update');

  return networks.map(item => {
    const {user_managed} = item;

    return {
      key: item.href,
      network_id: hrefUtils.getId(item.href),
      selectable: user_managed && apiAvailable,
      removable: user_managed && apiAvailable,
      data: {
        ...item,
        user_managed,
        updated_by: fillUserInfo(usersMap, item.updated_by),
      },
    };
  });
});

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getNetworkRows,
    filterMap: getSelectorSettings().filterMap,
  });

export const getNetworksPage = createSelector(
  [getGrid, getNetworksCount, getSelectorSettings],
  (grid, count, selectorSettingsObject) => {
    // Selector parameters based on filter and config
    const selector = {
      initialItems: Object.entries(grid.filter).map(([categoryKey, [value]]) => ({
        categoryKey,
        value,
        categoryName: selectorSettingsObject.filterMap[categoryKey],
      })),
      categories: Object.entries(selectorSettingsObject.filterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value,
      })),
      facets: Object.keys(selectorSettingsObject.facetMap),
      partials: Object.keys(selectorSettingsObject.facetMap),
    };

    return {grid, count, selector, apAvailable: isAPIAvailable('network.update')};
  },
);
