/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import listReducers from './List/VirtualServerListState';
import summaryReducers from './Item/Summary/VirtualServerSummaryState';
import rulesReducers from './Item/Rules/VirtualServerRulesState';
import detailReducers from './Item/VirtualServerItemState';

export default {
  virtualservers: combineReducers({
    ...listReducers,
    ...summaryReducers,
    ...rulesReducers,
    ...detailReducers,
  }),
};
