/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {reactUtils, hrefUtils} from 'utils';
import {fetchPairingProfileList, removePairingProfiles} from './PairingProfileListSaga';
import {getPairingProfilesPage} from './PairingProfileListState';
import PairingProfileReducers from '../PairingProfileState';
import LabelState from '../../Label/LabelState';
import ReportsState from '../../Reports/ReportsState';
import {Button, ButtonRefresh, Link, Grid, ToolBar, ToolGroup, Notifications, ModalMachineAuto} from 'components';
import {HeaderProps, ReportButtons} from 'containers';
import {ComboSelect} from 'containers/Selectors';
import styles from './PairingProfileList.css';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import {getMaxPageNotificationList} from 'components/Grid/GridUtils';
import {isAPIAvailable} from 'api/apiUtils';
import {resourceType} from './PairingProfileListConfig';

const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};
const removeRowHighLight = {className: stylesGridUtils.rowToRemove};
const scopeFilterKeys = ['role', 'app', 'env', 'loc'];

// Statically create add item link for grid to avoid its rerender on each page render (Grid is PureComponent)
const addPairingProfileLink = <Link to="pairingProfiles.create">{intl('PairingProfiles.AddNew')}</Link>;

const getEmptyState = () => ({
  extraPropsKeyMap: new Map(),
  selectedKeySet: new Set(),
  selectedKeySetToRemove: new Set(),
  remove: false,
});

@connect(getPairingProfilesPage)
export default class PairingProfileList extends Component {
  static prefetch = fetchPairingProfileList;
  static contextType = AppContext;
  static reducers = [ReportsState, LabelState, PairingProfileReducers];

  constructor(props) {
    super(props);

    this.state = getEmptyState();

    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleRemoveClose = this.handleRemoveClose.bind(this);
    this.handleRemoveCloseAlert = this.handleRemoveCloseAlert.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
    this.handleRemoveEnter = this.handleRemoveEnter.bind(this);
    this.handleRemoveLeave = this.handleRemoveLeave.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.grid.rows !== prevState.rows) {
      const nextState = {rows: nextProps.grid.rows};

      if (prevState.selectedKeySet.size) {
        // If page or sorting have changed, find selected items that remain on current page
        nextState.selectedKeySet = new Set();
        nextState.selectedKeySetToRemove = new Set();

        for (const row of nextProps.grid.rows) {
          if (row.selectable && prevState.selectedKeySet.has(row.key)) {
            nextState.selectedKeySet.add(row.key);

            if (row.removable) {
              nextState.selectedKeySetToRemove.add(row.key);
            }
          }
        }
      }

      return nextState;
    }

    return null;
  }

  handleClick(evt, row) {
    this.context.navigate({evt, to: 'pairingProfiles.item.view', params: {id: hrefUtils.getId(row.key)}});
  }

  handleSelect({affectedRows, selecting}) {
    this.setState(state => {
      const selectedKeySet = new Set(state.selectedKeySet);
      const selectedKeySetToRemove = new Set(state.selectedKeySetToRemove);

      for (const row of affectedRows) {
        selectedKeySet[selecting ? 'add' : 'delete'](row.key);

        if (selecting) {
          if (row.removable) {
            selectedKeySetToRemove.add(row.key);
          }
        } else {
          selectedKeySetToRemove.delete(row.key);
        }
      }

      return {selectedKeySet, selectedKeySetToRemove};
    });
  }

  handleRefresh() {
    // Refetch list, cancelable on page leave
    // Return promise, so Pagination component can publish event for QA
    return this.context.fetcher.fork(fetchPairingProfileList.refetch);
  }

  handleFilterChange(selection) {
    const scopeItems = selection.filter(item => scopeFilterKeys.includes(item.categoryKey));
    const filterItems = selection.filter(item => !scopeFilterKeys.includes(item.categoryKey));

    const scope = scopeItems.map(({key, href}) => ({href, key}));

    const filter = filterItems.reduce((result, {categoryKey, value}) => {
      result[categoryKey] = [value];

      return result;
    }, {});

    this.context.navigate({
      params: {
        scope: scope.length ? {scope} : undefined,
        [this.props.grid.settings.id]: {...this.props.grid.params, filter, page: null},
      },
    });
  }

  handleRemove() {
    this.setState({remove: {}});
  }

  handleRemoveClose() {
    this.setState({remove: null, extraPropsKeyMap: new Map()});
  }

  async handleRemoveCloseAlert() {
    // When removing is done we need to refetch the list, which will cause modal close in getDerivedStateFromProps
    await reactUtils.setStateAsync(({remove}) => ({remove: {...remove, running: true}}), this);
    this.handleRefresh();
  }

  handleRemoveEnter() {
    if (this.state.selectedKeySetToRemove.size) {
      this.setState(state => ({
        extraPropsKeyMap: new Map(Array.from(state.selectedKeySetToRemove, key => [key, removeRowHighLight])),
      }));
    }
  }

  handleRemoveLeave() {
    // Drop highlight if user moved out cursor and haven't opened remove dialog
    if (!this.state.remove && this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  handleRemoveConfirm() {
    this.setState(({remove}) => ({remove: !remove}));
  }

  renderRemove() {
    const {
      props: {
        grid: {rowsMap},
      },
      state: {selectedKeySetToRemove},
    } = this;

    return (
      <ModalMachineAuto
        batch
        rowsMap={rowsMap}
        onClose={this.handleRemoveConfirm}
        onDone={this.handleRefresh}
        saga={removePairingProfiles}
        hrefs={[...selectedKeySetToRemove]}
        listItem={['name']}
      >
        {{
          title: intl('PairingProfiles.Delete', {count: selectedKeySetToRemove.size}),
          confirmMessage: intl('PairingProfiles.List.Message.DeleteConfirm', {count: selectedKeySetToRemove.size}),
          submitProps: {tid: 'ok'},
          error: {
            title: intl('PairingProfiles.Delete', {count: selectedKeySetToRemove.size}),
          },
        }}
      </ModalMachineAuto>
    );
  }

  render() {
    const {
      props: {grid, count, selector, userIsReadOnly},
      state: {extraPropsKeyMap, selectedKeySet, selectedKeySetToRemove, remove},
    } = this;

    const notifications = getMaxPageNotificationList({page: grid.page, capacity: grid.capacity, count});

    return (
      <>
        <HeaderProps title={intl('PairingProfiles.Profiles')} />
        {notifications.length > 0 && <Notifications sidebar>{notifications}</Notifications>}

        <ToolBar>
          <ToolGroup>
            <Button.Link
              textIsHideable
              icon="add"
              text={intl('Common.Add')}
              link="pairingProfiles.create"
              tid="add"
              theme={buttonsTheme}
              disabled={!isAPIAvailable('pairing_profiles.create')}
            />
            <Button
              color="standard"
              textIsHideable
              icon="remove"
              text={intl('Common.Remove')}
              tid="remove"
              counter={selectedKeySetToRemove.size}
              counterColor="red"
              disabled={!selectedKeySetToRemove.size || !isAPIAvailable('pairing_profiles.delete')}
              onMouseEnter={this.handleRemoveEnter}
              onMouseLeave={this.handleRemoveLeave}
              theme={buttonsTheme}
              onClick={this.handleRemove}
            />
          </ToolGroup>
          <ToolGroup>
            <ButtonRefresh color="standard" textIsHideable onRefresh={this.handleRefresh} theme={buttonsTheme} />
            <ReportButtons type="pairingProfiles" disabledGen={!grid.rows.length} theme={buttonsTheme} />
          </ToolGroup>
        </ToolBar>
        <ToolBar>
          <ToolGroup expand tid="page-filter">
            <ComboSelect
              objects={selector.objects}
              placeholder={intl('Common.FilterView')}
              initialItems={selector.initialItems}
              activeCategoryKey="name"
              categories={selector.categories}
              partials={selector.partials}
              facets={selector.facets}
              statics={{}}
              onSelectionChange={this.handleFilterChange}
              resourceType={resourceType}
            />
          </ToolGroup>
        </ToolBar>

        <Grid
          grid={grid}
          theme={styles}
          count={count}
          selectedKeySet={selectedKeySet}
          dontHighlightSelected={extraPropsKeyMap.size > 0}
          extraPropsKeyMap={extraPropsKeyMap}
          onClick={this.handleClick}
          onSelect={this.handleSelect}
          emptyMessage={
            selector.initialItems.length ? intl('PairingProfiles.NoMatchData') : intl('PairingProfiles.NoData')
          }
          addItemLink={!userIsReadOnly && !selector.initialItems.length && addPairingProfileLink}
        />
        {remove && this.renderRemove()}
      </>
    );
  }
}
