/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import styles from './IPDecorator.css';
import cx from 'classnames';
import {Tooltip} from 'components';
import intl from 'intl';

const defaultTooltipProps = {
  delay: [200, 200],
  maxWidth: 600,
  interactive: true,
  right: true,
};

const findBlockStatus = (contentBlock, callback) => {
  const text = contentBlock.getText();
  const start = 0;
  const data = contentBlock.getData();
  const ip = data.get('ip');

  // New items needs to determine if they are checked before showing error
  // Original item will show error check by default without relying on touch
  if (data.size && ip && (data.get('touched') || ip.get('original'))) {
    if (
      ip.get('warning') ||
      ip.get('error') ||
      ip.get('exclusion') ||
      ip.get('toolTipError') ||
      (ip.get('duplicate') && !ip.get('toolTipErrorLineToReference'))
    ) {
      callback(start, start + text.length);
    }
  }
};

const handleIPStrategy = (contentBlock, callback) => {
  findBlockStatus(contentBlock, callback);
};

const IPDecorator = (props = {}) => {
  const handleIp = props => {
    const {contentState, blockKey, tooltipProps} = props;
    const contentBlock = contentState.getBlockForKey(blockKey);
    const data = contentBlock.getData();
    const ip = data.get('ip');

    let className = '';
    let errorMessage;

    if (ip.get('warning') && (!ip.get('duplicate') || ip.get('toolTipErrorLineToReference'))) {
      // Warning only
      className = cx(styles.warning);
      errorMessage = intl('IPLists.List.ZeroValidity');
    } else if (ip.get('error') || ip.get('toolTipError') || ip.get('duplicate')) {
      if (ip.get('error') && ip.get('exclusion')) {
        className = cx(styles.exclusion, styles.error);
      } else {
        className = cx(styles.error);
      }

      errorMessage = ip.get('toolTipError') || ip.get('error') || ip.get('duplicate');
    } else if (ip.get('exclusion')) {
      // Exclusion only
      className = cx(styles.exclusion);
      errorMessage = intl('Common.Exclude');
    }

    const toolTips = {...defaultTooltipProps, ...tooltipProps, content: errorMessage};

    return (
      <Tooltip {...toolTips}>
        <span data-tid="comp-error" className={className}>
          {props.children}
        </span>
      </Tooltip>
    );
  };

  return {
    strategy: handleIPStrategy,
    component: handleIp,
    props: {...props},
  };
};

export default IPDecorator;
