/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {call, put, all, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {fetchSelectiveUsers} from 'containers/User/UserSagas';
import {cachedResponses} from 'api/apiCache';
import {getRouteParams, isEdge} from 'containers/App/AppState';
import {isUserReadOnly} from 'containers/User/UserState';
import {RedirectError} from 'errors';
import {fetchNetworks} from 'containers/Network/List/NetworkListSaga';
import {getSecureGatewayInstance, getSecureGatewayVersions} from './SecureGatewayItemState';
import {isSecureGatewayEnabled} from '../SecureGatewayState';

export function* fetchSecureGatewayItem({params}, refetch = false) {
  const pversion = Number(params.pversion);
  const isOldVersion = !_.isNaN(pversion) && pversion > 0;
  const secureGatewayIsEnabled = yield select(isSecureGatewayEnabled);

  if (!secureGatewayIsEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  const [{data: active}, {data: draft}, oldPversionObj, oldPrevPversionObj] = yield all([
    call(apiSaga, 'secure_connect_gateways.get_instance', {
      cache: !refetch,
      ignoreCodes: [404],
      params: {secure_connect_gateway_id: params.id, pversion: 'active'},
    }),
    call(apiSaga, 'secure_connect_gateways.get_instance', {
      cache: !refetch,
      ignoreCodes: [404],
      params: {secure_connect_gateway_id: params.id, pversion: 'draft'},
    }),
    ...(isOldVersion
      ? [
          call(apiSaga, 'secure_connect_gateways.get_instance', {
            cache: !refetch,
            ignoreCodes: [404],
            params: {secure_connect_gateway_id: params.id, pversion},
          }),
        ]
      : []),
    ...(isOldVersion && pversion > 1
      ? [
          call(apiSaga, 'secure_connect_gateways.get_instance', {
            cache: !refetch,
            ignoreCodes: [404],
            params: {secure_connect_gateway_id: params.id, pversion: pversion - 1},
          }),
        ]
      : []),
  ]);

  // If both draft and active are not valid, redirect to the list page
  // draft and active is undefined for deleted policy object
  // Redirect to list page if pversion is invalid
  const validItem = oldPversionObj || oldPrevPversionObj || draft || active;
  const validPversion = isOldVersion || ['draft', 'active'].includes(params.pversion);

  if (!validItem || !validPversion || (isOldVersion && !oldPversionObj && !oldPrevPversionObj)) {
    throw new RedirectError({to: 'secureGateways.list', proceedFetching: true, thisFetchIsDone: true});
  }

  const data = {
    active,
    draft,
    ...(oldPversionObj ? {oldPversionObj: oldPversionObj?.data} : {}),
    ...(oldPrevPversionObj ? {oldPrevPversionObj: oldPrevPversionObj?.data} : {}),
  };

  const detail = yield select(getSecureGatewayInstance);

  if (
    refetch ||
    active !== detail.active ||
    draft !== detail.draft ||
    oldPversionObj?.data !== detail.oldPversion ||
    oldPrevPversionObj?.data !== detail.oldPrevPversion
  ) {
    yield put({type: 'SECURE_GATEWAYS_GET_INSTANCE', data});
  }

  return data;
}

export function* fetchSecureGatewaySummary({name, params: {id, pversion}}, refetch = false) {
  const isEditPage = name.endsWith('.edit');
  const isDraftPage = pversion === 'draft';
  const {versions, isOldVersion} = yield select(getSecureGatewayVersions);
  const {active, draft, pversionObj} = versions;
  const edgeEnabled = yield select(isEdge);

  if (edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  // If there is no active (new item), redirect to the draft version
  if (!isOldVersion && !isDraftPage && !active) {
    throw new RedirectError({params: {id, pversion: 'draft'}, proceedFetching: true});
  }

  // If there is no real draft (no pending changes), redirect to active version
  if (isDraftPage && !isEditPage && !draft.update_type) {
    throw new RedirectError({params: {id, pversion: 'active'}, proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(fetchSelectiveUsers, [pversionObj.created_by, pversionObj.updated_by], refetch);
}

export function* fetchSecureGatewayEdit(route, refetch = false) {
  const userIsReadOnly = yield select(isUserReadOnly);
  const {
    versions: {draft},
    isOldVersion,
  } = yield select(getSecureGatewayVersions);
  const {
    params: {id, pversion},
  } = route;
  const secureGatewayIsEnabled = yield select(isSecureGatewayEnabled);
  const edgeEnabled = yield select(isEdge);

  if (!secureGatewayIsEnabled || edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  if (userIsReadOnly || (draft && draft.update_type === 'delete') || isOldVersion) {
    throw new RedirectError({
      to: 'secureGateways.item',
      params: {id, pversion},
      thisFetchIsDone: true,
    });
  }

  // Editing should always happen on draft page
  if (pversion === 'active') {
    throw new RedirectError({params: {id, pversion: 'draft'}, proceedFetching: true});
  }

  yield call(fetchNetworks, {force: refetch});
}

export function* createSecureGateway(gateway, pversion) {
  return yield call(apiSaga, 'secure_connect_gateways.create', {
    data: gateway,
    params: {pversion: pversion || 'draft'},
    *onDone() {
      // After secure gateway is created invalidate secure gateway list cache
      cachedResponses.removeByMethodName('secure_connect_gateways.get_collection');
    },
  });
}

export function* updateSecureGateway(payload, pversion) {
  const {id} = yield select(getRouteParams);

  yield call(apiSaga, 'secure_connect_gateway.update', {
    params: {pversion: pversion || 'draft', secure_connect_gateway_id: id},
    data: payload,
    *onDone() {
      // Invalidate instance
      cachedResponses.removeByMethodName('secure_connect_gateways.get_instance');
      // Invalidate list
      cachedResponses.removeByMethodName('secure_connect_gateways.get_collection');
    },
  });
}

export function* deleteSecureGateway() {
  const {id} = yield select(getRouteParams);

  yield call(apiSaga, 'secure_connect_gateway.delete', {
    params: {secure_connect_gateway_id: id, pversion: 'draft'},
    *onDone() {
      // Invalidate instance
      cachedResponses.removeByMethodName('secure_connect_gateways.get_instance');
      // Invalidate cache for list
      cachedResponses.removeByMethodName('secure_connect_gateways.get_collection');
    },
  });
}
