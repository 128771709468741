/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {Component} from 'react';
import {Button, ButtonRefresh, Grid, Link, ModalMachineAuto, Notifications, ToolBar, ToolGroup} from 'components';
import {hrefUtils} from 'utils';
import SecureGatewayReducers from 'containers/SecureGateway/SecureGatewayState';
import {getSecureGatewaysPage} from './SecureGatewayListState';
import {fetchSecureGatewayList, removeSecureGateway} from './SecureGatewayListSaga';
import {fetchPending} from 'containers/Provisioning/ProvisioningSaga';
import {HeaderProps} from 'containers';
import styles from './SecureGatewayList.css';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import ProvisionButtons from 'containers/Provisioning/Provision/ProvisionButtons';
import {getMaxPageNotificationList} from 'components/Grid/GridUtils';

const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};
const removeRowHighLight = {className: stylesGridUtils.rowToRemove};
const revertRowHighLight = {className: stylesGridUtils.rowToRevert};
const provisionRowHighLight = {className: stylesGridUtils.rowToProvision};
const createLink = <Link to="secureGateways.create">{intl('SecureGateway.AddNew')}</Link>;

const getEmptyState = () => ({
  extraPropsKeyMap: new Map(),
  selectedKeySet: new Set(),
  selectedKeySetToRemove: new Set(),
  selectedObjectsToProvision: {secure_connect_gateways: []},
});

@connect(getSecureGatewaysPage)
export default class SecureGatewayList extends Component {
  static prefetch = fetchSecureGatewayList;
  static contextType = AppContext;
  static reducers = SecureGatewayReducers;

  constructor(props) {
    super(props);

    this.state = getEmptyState();

    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleRemoveEnter = this.handleRemoveEnter.bind(this);
    this.handleRemoveLeave = this.handleRemoveLeave.bind(this);
    this.handleProvisionButtonsHover = this.handleProvisionButtonsHover.bind(this);
    this.handleProvisionDone = this.handleProvisionDone.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.grid.rows !== prevState.rows) {
      const nextState = {rows: nextProps.grid.rows};

      if (prevState.remove) {
        Object.assign(nextState, getEmptyState());
      } else if (prevState.selectedKeySet.size > 0) {
        // If page or sorting have changed, find selected items that remain on current page
        nextState.selectedKeySet = new Set();
        nextState.selectedKeySetToRemove = new Set();
        nextState.selectedObjectsToProvision = {secure_connect_gateways: []};

        for (const row of nextProps.grid.rows) {
          if (row.selectable && prevState.selectedKeySet.has(row.key)) {
            nextState.selectedKeySet.add(row.key);

            if (row.removable) {
              nextState.selectedKeySetToRemove.add(row.key);
            }

            if (row.draft) {
              nextState.selectedObjectsToProvision = {
                secure_connect_gateways: nextState.selectedObjectsToProvision.secure_connect_gateways.concat({
                  href: row.key,
                }),
              };
            }
          }
        }
      }

      return nextState;
    }

    return null;
  }

  handleSelect({affectedRows, selecting}) {
    this.setState(state => {
      const selectedKeySet = new Set(state.selectedKeySet);
      const selectedKeySetToRemove = new Set(state.selectedKeySetToRemove);
      let selectedObjectsToProvision = {...state.selectedObjectsToProvision};

      for (const row of affectedRows) {
        selectedKeySet[selecting ? 'add' : 'delete'](row.key);

        if (selecting) {
          if (row.removable) {
            selectedKeySetToRemove.add(row.key);
          }

          if (row.draft) {
            selectedObjectsToProvision = {
              secure_connect_gateways: selectedObjectsToProvision.secure_connect_gateways.concat({href: row.key}),
            };
          }
        } else {
          selectedKeySetToRemove.delete(row.key);
          selectedObjectsToProvision = {
            secure_connect_gateways: selectedObjectsToProvision.secure_connect_gateways.filter(
              object => object.href !== row.key,
            ),
          };
        }
      }

      return {selectedKeySet, selectedKeySetToRemove, selectedObjectsToProvision};
    });
  }

  handleRefresh() {
    // Return promise that will wait for all, so Pagination component can publish event for QA
    return Promise.all([
      // Refetch list, cancelable on page leave
      this.context.fetcher.fork(fetchSecureGatewayList.refetch),
      // Refetch provision counter, doesn't depend on page leave
      this.context.fetcher.spawn(fetchPending),
    ]);
  }

  handleRowClick(evt, row) {
    this.context.navigate({evt, to: 'secureGateways.item', params: {id: hrefUtils.getId(row.key)}});
  }

  handleProvisionButtonsHover(evt, type, action) {
    this.setState(state => {
      if (type === 'enter' && state.selectedObjectsToProvision.secure_connect_gateways.length) {
        if (action === 'provision') {
          return {
            extraPropsKeyMap: new Map(
              state.selectedObjectsToProvision.secure_connect_gateways.map(object => [
                object.href,
                provisionRowHighLight,
              ]),
            ),
          };
        }

        if (action === 'revert') {
          return {
            extraPropsKeyMap: new Map(
              state.selectedObjectsToProvision.secure_connect_gateways.map(object => [object.href, revertRowHighLight]),
            ),
          };
        }
      }

      if (type === 'leave' && state.extraPropsKeyMap.size) {
        return {extraPropsKeyMap: new Map()};
      }

      // Return null to prevent updating state
      return null;
    });
  }

  handleProvisionDone() {
    // Reset selection
    this.setState(getEmptyState());
    this.handleRefresh();
  }

  handleRemoveEnter() {
    if (this.state.selectedKeySetToRemove.size) {
      this.setState(state => ({
        extraPropsKeyMap: new Map(Array.from(state.selectedKeySetToRemove, key => [key, removeRowHighLight])),
      }));
    }
  }

  handleRemoveLeave() {
    // Drop highlight if user moved out cursor and haven't opened remove dialog
    if (!this.state.remove && this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  handleRemove() {
    this.setState(({remove}) => ({remove: !remove}));
  }

  renderRemove() {
    const {
      props: {rowsMap},
      state: {selectedKeySetToRemove},
    } = this;

    return (
      <ModalMachineAuto
        settled
        saga={removeSecureGateway}
        onDone={this.handleRefresh}
        hrefs={[...selectedKeySetToRemove]}
        rowsMap={rowsMap}
        onClose={this.handleRemove}
        listItem={['name', ['ipAddress', value => `[${value}]`]]}
      >
        {{
          title: ({selectedHrefs}) => intl('SecureGateway.SecureConnectDelete', {count: selectedHrefs.length}),
          confirmMessage: ({selectedHrefs}) =>
            intl('SecureGateway.SecureConnectDeleteConfirm', {count: selectedHrefs.length}),
          error: {
            title: ({selectedHrefs}) =>
              intl('SecureGateway.List.Message.RemoveResultTitle', {count: selectedHrefs.length}),
            itemSuccessMessage: ({successHrefs}) =>
              intl('SecureGateway.List.Message.RemoveSuccess', {count: successHrefs.length}),
          },
        }}
      </ModalMachineAuto>
    );
  }

  render() {
    const {
      props: {grid, count, userIsReadOnly},
      state: {rows, extraPropsKeyMap, selectedKeySet, selectedObjectsToProvision, selectedKeySetToRemove, remove},
    } = this;
    const modifiedGrid = {...grid, rows};
    const notifications = getMaxPageNotificationList({page: modifiedGrid.page, capacity: modifiedGrid.capacity, count});

    return (
      <>
        <HeaderProps title={intl('SecureGateway.SecureConnect')} />
        {notifications.length > 0 && <Notifications sidebar>{notifications}</Notifications>}
        <ToolBar>
          <ToolGroup>
            <Button.Link
              icon="add"
              text={intl('Common.Add')}
              textIsHideable
              link="secureGateways.create"
              tid="add"
              theme={buttonsTheme}
              disabled={userIsReadOnly}
            />
            <ProvisionButtons
              counter={selectedObjectsToProvision.secure_connect_gateways.length}
              theme={buttonsTheme}
              onButtonHover={this.handleProvisionButtonsHover}
              onDone={this.handleProvisionDone}
              objectsToProvision={selectedObjectsToProvision}
            />
            <Button
              color="standard"
              icon="remove"
              text={intl('Common.Remove')}
              textIsHideable
              tid="remove"
              theme={buttonsTheme}
              counter={selectedKeySetToRemove.size}
              counterColor="red"
              disabled={userIsReadOnly || !selectedKeySetToRemove.size}
              onClick={this.handleRemove}
              onMouseEnter={this.handleRemoveEnter}
              onMouseLeave={this.handleRemoveLeave}
            />
          </ToolGroup>
          <ToolGroup>
            <ButtonRefresh color="standard" textIsHideable onRefresh={this.handleRefresh} theme={buttonsTheme} />
          </ToolGroup>
        </ToolBar>
        <Grid
          grid={modifiedGrid}
          theme={styles}
          count={count}
          selectedKeySet={selectedKeySet}
          dontHighlightSelected={extraPropsKeyMap.size > 0}
          extraPropsKeyMap={extraPropsKeyMap}
          onClick={this.handleRowClick}
          onSelect={this.handleSelect}
          emptyMessage={intl('SecureConnect.NoData')}
          addItemLink={userIsReadOnly ? null : createLink}
        />

        {remove && this.renderRemove()}
      </>
    );
  }
}
