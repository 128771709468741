/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteCurrentParams} from 'containers/App/AppState';
import {isContainerClustersEnabled} from 'containers/ContainerCluster/ContainerClusterState';
import {isUserWithReducedScope} from 'containers/User/UserState';

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'CONTAINERWORKLOAD_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getContainerWorkloadInstance = state => state.containerWorkloads.instance;

export const getContainerWorkloadItemPage = createSelector(
  [getContainerWorkloadInstance, getRouteCurrentParams, isUserWithReducedScope, isContainerClustersEnabled],
  (containerWorkload, currentRouteParams, userIsWithReducedScope, containerClustersEnabled) => {
    const isUserReadOnly = containerWorkload.isUserReadOnly;

    return {
      containerWorkload,
      isUserReadOnly,
      containerClustersEnabled,
      currentRouteParams,
      userIsWithReducedScope,
    };
  },
);
