/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {Icon} from 'components';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import styles from './IconSort.css';
import stylesUtils from 'utils.css';

export type IconSortProps = {
  //
  /** Sorting direction, 1 - up(ascending), -1 - down(descending) */
  sorted?: 1 | -1;

  /** Make it invisible */
  invisible?: boolean;
} & ThemeProps;

export default function IconSort(props: IconSortProps): JSX.Element {
  const {sorted, invisible} = props;
  const theme = composeThemeFromProps(styles, props);

  return (
    <div className={cx(theme.iconsSort, {[stylesUtils.invisible]: invisible})}>
      <div className={cx(theme.iconSort, {[theme.iconSortSelected]: sorted === 1})} data-tid="comp-grid-icon-sort-up">
        <Icon name="sort-up" />
      </div>
      <div
        className={cx(theme.iconSort, {[theme.iconSortSelected]: sorted === -1})}
        data-tid="comp-grid-icon-sort-down"
      >
        <Icon name="sort-down" />
      </div>
    </div>
  );
}
