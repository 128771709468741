/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call} from 'redux-saga/effects';
import {hrefUtils} from 'utils/index';

export function* removeOneItem({href, actionId, params, idKey}) {
  try {
    yield call(apiSaga, actionId, {params: {...params, [idKey]: hrefUtils.getId(href)}});
  } catch (err) {
    err.href = href;
    throw err;
  }
}
