/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';

export const getDiscardChangesModalProps = object => ({
  modalProps: {title: intl('Common.DiscardUnsavedChanges'), confirmProps: {text: intl('Common.Discard')}},
  content: object
    ? intl('Common.DiscardUnsavedChangesToObjectMessage', {object})
    : intl('Common.DiscardUnsavedChangesMessage'),
});
