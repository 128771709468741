/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {Pill, type PillProps} from 'components/index';
import {hrefUtils, portUtils} from 'utils';
import {getServiceDefinitionString, getUpdateTypeProps} from '../PillUtils';
import styleUtils from 'utils.css';
import type {Service as ServiceData} from 'illumio';

export interface ServiceProps extends PillProps {
  // services or ports object
  value: ServiceData;

  // policy version
  pversion?: string | number;

  // number: show number of ports or port ranges
  // 'all': show all the ports or port ranges
  showPorts?: number | 'all';
  noIcon?: boolean;
}

export default class Service extends PureComponent<ServiceProps> {
  element: HTMLElement | null = null;

  constructor(props: ServiceProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: Pill) {
    this.element = element?.element;
  }

  render() {
    const {value, pversion = 'draft', showPorts = false, noIcon = false, updateTypeTooltip, ...pillProps} = this.props;

    if (!noIcon) {
      Object.assign(pillProps, {icon: 'service'});
    }

    const id = value.id ?? hrefUtils.getId(value.href);
    const name = value.name ?? portUtils.stringifyPortObjectReadonly(value);

    // Named service, i.e. service is provisionable
    let finalPorts;
    const servicePorts = value.service_ports ?? value.windows_services ?? [];

    if (showPorts) {
      finalPorts = getServiceDefinitionString(servicePorts, showPorts);
    }

    if (id) {
      Object.assign(pillProps, {
        icon: noIcon ? undefined : 'service',
        link: pillProps.onClick
          ? null
          : {
              to: 'services.item',
              params: {pversion, id},
            },
        ...getUpdateTypeProps({
          object: 'service',
          updateType: value.update_type,
          updateTypeTooltip,
          deleted: pillProps.deleted,
          pversion,
        }),
      });
    }

    return (
      <Pill ref={this.saveRef} {...pillProps}>
        {showPorts && servicePorts.length > 0 ? ( // If both showPorts flag is set and ports exists then add ports div
          <>
            <strong>{name}</strong>
            <div className={styleUtils.preWrap}>{finalPorts}</div>
          </>
        ) : (
          name
        )}
      </Pill>
    );
  }
}
