/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Form, StatusIcon} from 'components';
import styles from './WorkloadEdit.css';
import {createSelector} from 'reselect';
import {getWorkload} from '../WorkloadItemState';
import {getRouteName} from 'containers/App/AppState';
import {convertIPTextToObject} from 'components/Form/DraftJS/EditorTypes/IPListEditor/IPListEditorUtils';

export const actionOptions = createSelector([], () => [
  {value: 'managed', label: intl('Common.Managed')},
  {value: 'ignored', label: intl('Workloads.Ignored')},
]);

export const interfaceGridSettings = createSelector([getWorkload, getRouteName], (workload, routeName) => ({
  id: 'interfaces',
  showManager: false,
  getInitialRow: key => ({
    key,
    selectable: true,
    data: {
      isEdit: false,
      managedWorkload: false,
      interfacename: '',
      subnet: '',
      action: Form.Utils.findSelectedOption(actionOptions(), 'managed'),
    },
  }), //call getDefaultValues from editableGrid when adding new row to get inital values and set form value
  columns: {
    checkboxes: {},
    modified: {
      format: ({row}) => (row.modified ? <StatusIcon status="modified" theme={styles} /> : null),
      sortable: false,
    },
    interfacename: {
      header: intl('SystemSettings.InterfaceName'),
      value: 'interfacename',
      format: ({row}) =>
        row.data.isEdit && row.data.managedWorkload ? (
          <div>{row.data.interfacename}</div>
        ) : (
          <Form.Input
            name={`interfaces[${row.idx}].data.interfacename`}
            tid={`interfaces[${row.idx}].interfacename`}
            placeholder={intl('Workloads.InterfacePlaceholder')}
          />
        ),
      sortable: false,
    },
    action: {
      header: intl('Workloads.PCEAction'),
      value: 'action',
      format: ({row}) => (
        <Form.Selector
          name={`interfaces[${row.idx}].data.action`}
          tid={`interfaces[${row.idx}].data.action`}
          options={actionOptions()}
        />
      ),
      sortable: false,
      disabled: !workload || !workload.agent?.status || routeName === 'app.workloads.create',
    },
    subnets: {
      header: intl('Workloads.IPCIDR'),
      value: 'subnets',
      format: ({row, component}) =>
        row.data.isEdit && row.data.managedWorkload ? (
          row.data.subnets.map((subnet, i) => <div key={i}>{subnet}</div>)
        ) : (
          <Form.IPListEditor
            name={`interfaces[${row.idx}].data.subnets`}
            tid={`interfaces[${row.idx}].subnets`}
            placeholder={intl('Workloads.IPCIDRPlaceholder')}
            initialValue={row.data.subnets?.map(subnet => convertIPTextToObject(subnet, true))}
            onChange={component.handleOnChange(row.data.subnets)}
            isInterface
            noFQDN
            noExperimental
            noComments
            noInterfaces
            noRanges
          />
        ),
      sortable: false,
    },
    defaultGateways: {
      header: intl('Workloads.DefaultGateway'),
      value: 'defaultGateways',
      format: ({row}) =>
        row.data.isEdit && row.data.managedWorkload
          ? row.data.defaultGateways.map((gateway, i) => <div key={i}>{gateway}</div>)
          : null,
      sortable: false,
      disabled: !workload?.agent?.status && (routeName.endsWith('edit') || routeName.endsWith('create')),
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['modified'], size: 'min-content'},
      {columns: ['interfacename'], size: 'minmax(var(--75px), 1fr)'},
      {columns: ['subnets'], size: 'minmax(var(--100px), 1fr)'},
      {columns: ['defaultGateways'], size: 'minmax(var(--100px), 1fr)'},
      {columns: ['action'], size: 'minmax(calc(var(--15px) * 10), auto)'},
    ],
  ],
}));
