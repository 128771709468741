/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {all, call, select, put} from 'redux-saga/effects';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {getPending, getGridSettings} from './PendingListState';
import apiSaga from 'api/apiSaga';
import {getSelectorSettings} from './PendingListConfig';
import {
  getProvisionCountsTotal,
  getProvisionMenuCounts,
  getInternalType,
} from 'containers/Provisioning/ProvisioningUtils';
import gridSaga from 'components/Grid/GridSaga';
import {isUserWithReducedScope} from 'containers/User/UserState';
import {RedirectError} from 'errors';

// Fetch list of all pending provisioning objects
export function* fetchPendingObjectList({force = false} = {}) {
  if (!(yield select(isUserWithReducedScope))) {
    return yield call(apiSaga, 'sec_policy_pending.get', {
      cache: !force,
      *afterFetch({data}) {
        if (force || data !== (yield select(getPending))) {
          yield put({type: 'PROVISION_GET_PENDING', data});
        }

        return data;
      },
    });
  }
}

export function* fetchPendingList(route, refetch = false) {
  const userIsWithReducedScope = yield select(isUserWithReducedScope);
  const selectorSettingsObject = yield select(getSelectorSettings);

  // Users with limited scoped read are not allowed to access this component.
  if (userIsWithReducedScope) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: selectorSettingsObject.filterMap,
    *onSaga({filterParams}) {
      const filter = filterParams.isEmpty ? undefined : filterParams.valid;
      let [data] = yield all([
        call(fetchPendingObjectList, {filter, force: refetch}),
        call(fetchAllUsers, {force: refetch}),
      ]);

      if (filter) {
        const filterEntry = Object.entries(filter).map(([categoryKey, [value]]) => ({
          categoryKey,
          value,
          categoryName: selectorSettingsObject.filterMap[categoryKey],
        }));

        if (Array.isArray(filterEntry) && filterEntry.length && filterEntry[0].categoryKey === 'type') {
          // currently we only support the type filter
          const internalType = getInternalType(filterEntry[0].value);

          data = internalType && data[internalType] ? {[internalType]: data[internalType]} : {};
        }
      }

      return getProvisionCountsTotal(getProvisionMenuCounts(data));
    },
  });
}
