/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {memo} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Modal, Pill} from 'components';
import ServiceEdit from 'containers/Service/Item/Edit/ServiceEdit';
import ServiceView from 'containers/Service/Item/View/ServiceView';
import styles from './ServiceCard.css';
import cx from 'classnames';

// if service has href then it exists in the system, so it could be 'active' or 'draft'
// if href is synthetically generated then service comes from template ~ context
export const SERVICE_TYPE = {
  TEMPLATE: 'template',
  CONTEXT: 'context',
  DRAFT: 'draft',
};

const ServiceCard = memo(
  ({edit, service, checked, onCheckboxChange, onServiceSave}) => {
    const {type, name, description} = service;
    let serviceCard;

    if (type === SERVICE_TYPE.TEMPLATE) {
      serviceCard = (
        <div className={styles.serviceCardContent} tid="service-name">
          {name}
          <div className={styles.serviceCardDescription} tid="service-description">
            {description}
          </div>
        </div>
      );
    }

    if (type === SERVICE_TYPE.CONTEXT || type === SERVICE_TYPE.DRAFT) {
      const isAllServices = service.name === intl('Common.AllServices');

      const pageInvokerProps = {
        edit,
        container: edit && !isAllServices ? ServiceEdit : ServiceView,
        title:
          edit && !isAllServices
            ? intl('Edge.ServiceEditModalTitle', {serviceName: service.name})
            : intl('Edge.ServiceViewModalTitle', {serviceName: service.name}),
        onSave: onServiceSave,
      };

      serviceCard = (
        <Modal.PageInvoker
          {...pageInvokerProps}
          containerProps={{
            controlled: true,
            versions: {pversionObj: service},
          }}
        >
          {({handleOpen}) => <Pill.Service showPorts value={service} onClick={handleOpen} noIcon {...service.props} />}
        </Modal.PageInvoker>
      );
    }

    return (
      <div className={cx(styles.serviceCard)}>
        <Checkbox theme={styles} checked={checked} onChange={onCheckboxChange} />
        {serviceCard}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.checked === nextProps.checked && _.isEqual(prevProps.service, nextProps.service),
);

ServiceCard.types = SERVICE_TYPE;

ServiceCard.propTypes = {
  service: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(SERVICE_TYPE)).isRequired,
  }),
  checked: PropTypes.bool.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
};

export default ServiceCard;
