/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import _ from 'lodash';
import intl from 'intl';
import {Component, createRef} from 'react';
import * as PropTypes from 'prop-types';
import {Icon, ToolBar, ToolGroup, StatusIcon} from 'components';
import FilterOption from './FilterOption';
import styles from './FilterPanel.css';
import Button from 'components/Button/Button';

const iconStyle = {icon: styles.icon};
export default class FilterPanel extends Component {
  static propTypes = {
    menuItems: PropTypes.object.isRequired,
    itemCounts: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onToggle: PropTypes.func,
    selected: PropTypes.string,
  };

  static defaultProps = {
    onSelect: _.noop,
    onToggle: _.noop,
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected,
    };

    this.focusedIndex = -1;
    this.optionList = [];
    this.refMap = new Map();
    this.filter = createRef();

    this.optionsRef = this.optionsRef.bind(this);
    this.handleOptionHover = this.handleOptionHover.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleCategoryToggle = this.handleCategoryToggle.bind(this);
    this.handleSubcategoryClick = this.handleSubcategoryClick.bind(this);
    this.handleClearFilterClick = this.handleClearFilterClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentMouseDown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown);
  }

  handleKeyDown(evt) {
    const {show} = this.props;

    if (show) {
      evt.preventDefault();
    }

    const {key, shiftKey} = evt;

    if (key === 'Enter' || key === 'ArrowRight') {
      const option = _.get(this.optionList[this.focusedIndex], 'props', {});

      if (option.subcategory) {
        this.handleSubcategoryClick(option.category, option.subcategory);
      } else if (option.category) {
        this.handleCategoryToggle(option.category);
      }

      if (!show) {
        this.props.onToggle();
      }
    } else if ((key === 'Tab' && !shiftKey) || key === 'ArrowDown') {
      if (this.focusedIndex + 1 >= this.optionList.length) {
        return;
      }

      this.focusedIndex++;
      this.optionList[this.focusedIndex].option.focus();
    } else if ((key === 'Tab' && shiftKey) || key === 'ArrowUp') {
      if (this.focusedIndex - 1 < 0) {
        return;
      }

      this.focusedIndex--;
      this.optionList[this.focusedIndex].option.focus();
    } else if (key === 'Escape' || key === 'ArrowLeft') {
      this.focusedIndex = -1;

      if (show) {
        this.props.onToggle();
      }
    }
  }

  handleCategoryToggle(category) {
    this.setState(state => ({[category]: !state[category]}));
  }

  handleSubcategoryClick(category, subcategory) {
    const data = this.props.itemCounts[subcategory];

    this.props.onSelect({...data, category, subcategory});

    const selected = category + subcategory;

    this.setState({selected});

    if (this.focusedIndex !== -1 && this.optionList[this.focusedIndex]) {
      this.optionList[this.focusedIndex].option.blur();
      this.focusedIndex = -1;
    }

    this.optionList = this.generateOptionList(selected);
  }

  handleClearFilterClick() {
    this.props.onSelect({category: undefined, subcategory: undefined});
    this.setState({selected: null});
  }

  handleOptionHover(element) {
    this.focusedIndex = this.optionList.indexOf(element);
    element.option.focus();
  }

  generateCategories(category) {
    const {menuItems, itemCounts} = this.props;
    const {selected} = this.state;
    const isOpen = this.state[category];

    const subcategoryProps = {
      className: 'subcategory',
      category,
      onClick: this.handleSubcategoryClick,
      onHover: this.handleOptionHover,
      saveRef: this.optionsRef,
    };
    const subcategories = menuItems[category].map((item, idx) => {
      const count = itemCounts[item].count;

      return (
        <FilterOption key={item + idx} subcategory={item} selected={selected === category + item} {...subcategoryProps}>
          <div data-tid="filter-name" className={styles.itemText} title={item}>
            {item}
          </div>
          <div data-tid="filter-count" className={styles.number}>
            {count}
          </div>
        </FilterOption>
      );
    });

    return (
      <div key={category} className={styles.container}>
        <FilterOption
          className="category"
          active={selected?.startsWith(category)}
          category={category}
          saveRef={this.optionsRef}
          onHover={this.handleOptionHover}
          onClick={this.handleCategoryToggle}
        >
          {category}
          <Icon name={isOpen ? 'up' : 'down'} theme={iconStyle} onClick={this.handleClick} />
        </FilterOption>
        {isOpen ? <div>{subcategories}</div> : null}
      </div>
    );
  }

  generateOptionList(selected) {
    return Object.keys(this.props.menuItems).reduce((list, category) => {
      if (!list) {
        list = [];
      }

      list.push(this.refMap.get(category));

      if (this.state[category]) {
        this.props.menuItems[category].forEach(subcategory => {
          const key = category + subcategory;

          if (selected !== key) {
            list.push(this.refMap.get(key));
          }
        });
      }

      return list;
    }, []);
  }

  optionsRef(item) {
    if (!item) {
      return;
    }

    const {category, subcategory} = item.props;
    const itemKey = subcategory ? category + subcategory : category;

    this.refMap.set(itemKey, item);

    this.optionList = this.generateOptionList(this.state.selected);
  }

  render() {
    const {show} = this.props;
    const menuClasses = cx(styles.menu, {
      [styles.collapsed]: !show,
    });

    return (
      <div className={menuClasses} ref={this.filter} tabIndex={0} onKeyDown={this.handleKeyDown}>
        <div className={styles.stickyMenu}>
          <ToolBar theme={styles} themePrefix="header-">
            {show && (
              <ToolGroup>
                {intl('Events.QuickFilter')}
                <StatusIcon
                  tid="quickfilterinfo"
                  status="info"
                  tooltip={intl('Events.QuickFilterInfo')}
                  tooltipProps={{
                    fast: true,
                    bottom: true,
                  }}
                />
              </ToolGroup>
            )}
            <ToolGroup expand={!show}>
              {show && (
                <Button onClick={this.handleClearFilterClick} size="small" noFill tid="clearquickfilter">
                  {intl('Events.QuickFilterClear')}
                </Button>
              )}
              <div className={show ? styles.openCaret : styles.closedCaret}>
                <Icon name="expand" theme={iconStyle} onClick={this.props.onToggle} />
              </div>
            </ToolGroup>
          </ToolBar>

          <div className={styles.menuScroller}>
            <div className={!show ? styles.hideContent : null}>
              {Object.keys(this.props.menuItems).map(category => this.generateCategories(category))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
