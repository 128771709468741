/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call, select} from 'redux-saga/effects';
import {cachedResponses} from 'api/apiCache';
import {getVenInstance} from '../VenItemState';
import {fetchSupportReportsListPage} from 'containers/SupportBundles/VENSupportReports/List/SupportReportsListSaga';
import {isSupportReportsEnabled} from 'containers/SupportBundles/VENSupportReports/SupportReportsState';

export function* fetchVenSummaryPage(route, refetch = false) {
  const supportReportsIsEnabled = yield select(isSupportReportsEnabled);

  if (supportReportsIsEnabled) {
    const detail = yield select(getVenInstance);

    if (detail && detail.href) {
      yield call(fetchSupportReportsListPage, route, refetch, {ven: detail.href, ignoreScope: true});
    }
  }
}

export function* updateVen({id, payload}) {
  yield call(apiSaga, 'ven.update', {
    params: {ven_id: id},
    data: payload,
    *onDone() {
      cachedResponses.removeByMethodName('vens.get_instance');
      cachedResponses.removeByMethodName('vens.get_collection');
    },
  });
}
