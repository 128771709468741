/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {Component} from 'react';
import {Button, Modal, TypedMessages} from 'components';
import {submitAction} from './SubmitSaga';

export const targets = {
  addVirtualService: {
    action: submitAction,
    actionId: 'virtual_services.create',
    cleanCaches: ['virtual_services.get_collection', 'sec_policy_pending.get'],
  },
  editVirtualService: {
    action: submitAction,
    actionId: 'virtual_service.update',
    cleanCaches: ['virtual_services.get_collection', 'virtual_services.get_instance'],
  },
  addContainerCluster: {
    action: submitAction,
    actionId: 'container_clusters.create',
    cleanCaches: ['container_clusters.get_collection', 'container_clusters.get_instance'],
  },
  editContainerCluster: {
    action: submitAction,
    actionId: 'container_cluster.update',
    cleanCaches: ['container_clusters.get_collection', 'container_clusters.get_instance'],
  },
  addContainerClusterProfile: {
    action: submitAction,
    actionId: 'container_workload_profiles.create',
    cleanCaches: ['container_workload_profiles.get_collection', 'container_workload_profiles.get_instance'],
  },
  editContainerClusterProfile: {
    action: submitAction,
    actionId: 'container_workload_profile.update',
    cleanCaches: [
      'container_workload_profiles.get_collection',
      'container_workload_profiles.get_instance',
      'container_workloads.get_collection',
    ],
  },
};

export default class SubmitButton extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    error: PropTypes.string,
    onCloseAlert: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    isSubmitting: false,
    error: false,
  };

  renderAlert() {
    return (
      <Modal.Alert
        stretch
        title={intl('Common.Alert')}
        onClose={this.props.onCloseAlert}
        buttonProps={{text: intl('Common.OK')}}
      >
        <TypedMessages>{[{icon: 'error', content: this.props.error}]}</TypedMessages>
      </Modal.Alert>
    );
  }

  render() {
    const {disabled, isSubmitting, error} = this.props;

    return (
      <>
        <Button
          color="primary"
          icon="save"
          type="submit"
          text={intl('Common.Save')}
          tid="save"
          progress={isSubmitting}
          progressCompleteWithCheckmark
          progressError={Boolean(error)}
          disabled={disabled}
        />
        {error && this.renderAlert()}
      </>
    );
  }
}
