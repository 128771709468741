/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {generateRulesGridData} from '../../VirtualServerUtils';
import {gridSettings, getSelectorSettings} from './VirtualServerRulesConfig';
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';

const defaultRules = {providing: [], using: []};

export default {
  rules(state = defaultRules, action) {
    switch (action.type) {
      case 'VIRTUAL_SERVER_GET_RULES':
        return action.data.rules;
      case 'VIRTUAL_SERVER_RESET_RULES':
        return defaultRules;
      default:
        return state;
    }
  },
};

export const getRules = state => state.virtualservers.rules;

export const getVirtualServerInboundRulesRows = createSelector([getRules], rules => {
  const inboundData = [];

  if (rules) {
    rules.providing.forEach(service => {
      generateRulesGridData(inboundData, service, false);
    });
  }

  return inboundData;
});

export const getVirtualServerOutboundRulesRows = createSelector([getRules], rules => {
  const outboundData = [];

  if (rules) {
    rules.using.forEach(service => {
      generateRulesGridData(outboundData, service, false);
    });
  }

  return outboundData;
});

export const inboundGridSettings = createSelector(gridSettings, gridSettings => ({...gridSettings, id: 'inbound'}));
export const outboundGridSettings = createSelector(gridSettings, gridSettings => ({...gridSettings, id: 'outbound'}));

const getInboundGrid = state =>
  getGridSelector(state, {
    settings: inboundGridSettings,
    rows: getVirtualServerInboundRulesRows,
    filterMap: getSelectorSettings().filterMap,
  });

const getOutboundGrid = state =>
  getGridSelector(state, {
    settings: outboundGridSettings,
    rows: getVirtualServerOutboundRulesRows,
    filterMap: getSelectorSettings().filterMap,
  });

export const getVirtualServerRulesPage = createSelector(
  [getInboundGrid, getOutboundGrid],
  (inboundGrid, outboundGrid) => ({inboundGrid, outboundGrid}),
);
