/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {StatusIcon, Tooltip, Form} from 'components';
import {validatePortAndOrProtocol} from 'containers/Service/ServiceUtils';
import styles from './ServiceEdit.css';

export const getGridSettings = ({os = 'linux', isEdit = true}) => ({
  id: 'serviceDefinitionEdit',
  showColumns: false,
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showManager: false,
  //call getInitialRow from editableGrid when adding new row to get inital values and set form value
  getInitialRow: key => ({key, selectable: true, data: {portProto: [], process_name: '', service_name: ''}}),
  columns: {
    checkboxes: {},
    modified: {
      format: ({row}) => (row.modified ? <StatusIcon status="modified" theme={styles} /> : null),
      sortable: false,
      disabled: !isEdit,
    },
    error: {
      format: ({row}) =>
        row.errorMsg ? (
          <Tooltip content={row.errorMsg}>
            <StatusIcon status="error" theme={styles} />
          </Tooltip>
        ) : null,
      sortable: false,
    },
    portProto: {
      header: intl('Common.PortAndOrProtocol'),
      ...(__ANTMAN__ && {disabled: os === 'windows_egress'}),
      format: ({row}) => (
        <Form.ObjectSelectorOld
          name={`rows[${row.idx}].data.portProto`}
          tid={`rows[${row.idx}].data.portProto`}
          type="SingleItemSelect"
          placeholder={intl('Port.PortProtocolPlaceHolder')}
          objects={[]}
          categories={[
            {
              value: intl('Common.PortAndOrProtocol'),
              categoryKey: 'portProto',
              freeSearch: true,
              validate: validatePortAndOrProtocol,
            },
          ]}
        />
      ),
      sortable: false, // each column is not sortable; We are not sorting this list.
      showAsterisk: os === 'linux',
    },
    processName: {
      header: intl('Port.ProcessPath'),
      format: ({row}) => (
        <Form.Input
          name={`rows[${row.idx}].data.process_name`}
          tid={`rows[${row.idx}].data.process_name`}
          placeholder={intl('Port.ProcessNamePlaceHolder')}
        />
      ),
      sortable: false,
      value: 'process_name',
      disabled: os === 'linux',
    },
    serviceName: {
      header: __ANTMAN__ ? intl('Antman.Services.ServiceName') : intl('Workloads.ProcessName'),
      format: ({row}) => (
        <Form.Input
          name={`rows[${row.idx}].data.service_name`}
          tid={`rows[${row.idx}].data.service_name`}
          placeholder={intl('Port.WindowsServicePlaceHolder')}
        />
      ),
      sortable: false,
      value: 'service_name',
      disabled: os === 'linux',
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['modified'], size: 'min-content'},
      {columns: ['error'], size: 'min-content'},
      {columns: ['portProto'], size: 'minmax(150px, auto)'},
      {columns: ['processName'], size: 'minmax(150px, auto)'},
      {columns: ['serviceName'], size: 'minmax(150px, auto)'},
    ],
    {
      maxWidth: 640,
      template: [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['modified'], size: 'min-content'},
        {columns: ['error'], size: 'min-content'},
        {columns: ['portProto', 'processName', 'serviceName'], size: 'minmax(150px, auto)'},
      ],
    },
  ],
});
