/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import listReducers from './List/FlowCollectionFiltersListState';
import editReducers from './Edit/FlowCollectionFiltersEditState';
import {isUserOwner} from 'containers/User/UserState';
import {createSelector} from 'reselect';

export default {
  flowCollectionFilters: combineReducers({
    ...listReducers,
    ...editReducers,
  }),
};

export const isFlowCollectionEnabled = createSelector(isUserOwner, userIsOwner => userIsOwner);
