/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {Icon, StatusIcon} from 'components';
import {
  formatRuleService,
  formatRuleAddress,
  formatCustomRule,
} from 'containers/ContainerWorkload/Item/Rules/ContainerWorkloadRulesUtils';
import stylesUtils from 'utils.css';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import {createSelector} from 'reselect';
import {networkTypeMap} from 'containers/Network/NetworkUtils';
import {requiredForTextMap} from 'containers/EssentialServiceRules/EssentialServiceRulesUtils';
import {collator} from 'utils/href';

export const gridSettings = createSelector([], () => ({
  id: 'workloadRules',
  sort: '-info',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    service: {
      header: intl('Common.Service'),
      value: ({row}) => row.service,
      format: ({value}) => (
        <div className={`${stylesUtils.gapXSmall} ${stylesUtils.gapAlignStart}`}>{formatRuleService(value)}</div>
      ),
    },
    addresses: {
      header: intl('PolicyGenerator.Addresses'),
      value: ({row}) => row.addresses,
      format: ({value}) => formatRuleAddress(value),
      sortFunction: sortvalue =>
        collator.compare(sortvalue.a.addresses.sort().join(','), sortvalue.b.addresses.sort().join(',')),
    },
    networkType: {
      header: intl('Edge.NetworkProfile'),
      value: ({row}) => row.network_type,
      sortable: false,
      format: ({value}) => networkTypeMap.get(value),
    },
    secConnect: {
      header: intl('Common.SecureConnect'),
      value: ({row}) => row.secConnect,
      format: ({value}) => (value ? <Icon name="lock" /> : null),
    },
    machineAuth: {
      header: intl('Common.MachineAuthentication'),
      value: ({row}) => row.machineAuth,
      format: ({value}) => (value ? intl('Common.Enabled') : ''),
    },
    stateless: {
      header: intl('Common.Stateless'),
      value: ({row}) => row.stateless,
      format: ({value}) => (value ? intl('Common.Enabled') : ''),
    },
    info: {
      headerManager: intl('Common.Information'),
      value: ({row}) => row.essentialServiceRuleKey,
      format: ({value}) => (value ? <StatusIcon status="info" tooltip={requiredForTextMap.get(value)} /> : null),
      sortable: false,
    },
  },

  templates: [
    [
      {columns: ['service'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
      {columns: ['addresses'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
      {columns: ['secConnect'], size: 'minmax(100px, auto)'},
      {columns: ['machineAuth'], size: 'minmax(100px, auto)'},
      {columns: ['stateless'], size: 'minmax(75px, auto)'},
      {columns: ['networkType'], size: 'minmax(120px, auto)'},
      {columns: ['info'], size: 'max-content'},
    ],
    {
      maxWidth: 800,
      template: [
        {columns: ['service'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
        {columns: ['addresses'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
        {columns: ['secConnect'], size: 'minmax(100px, auto)'},
        {columns: ['machineAuth'], size: 'minmax(100px, auto)'},
        {columns: ['stateless'], size: 'minmax(75px, auto)'},
        {columns: ['networkType'], size: 'minmax(80px, auto)'},
        {columns: ['info'], size: 'max-content'},
      ],
    },
    {
      maxWidth: 640,
      template: [
        {columns: ['service', 'addresses', 'secConnect', 'networkType'], size: 'minmax(200px, auto)'},
        {columns: ['machineAuth', 'stateless'], size: 'minmax(200px, auto)'},
        {columns: ['info'], size: 'max-content'},
      ],
    },
  ],
}));

export const admingridSettings = createSelector([], () => ({
  id: 'workloadRules',
  sort: 'service',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    service: {
      header: intl('Common.Service'),
      value: ({row}) => row.service,
      format: ({value}) => (
        <div className={`${stylesUtils.gapXSmall} ${stylesUtils.gapAlignStart}`}>{formatRuleService(value)}</div>
      ),
    },
    addresses: {
      header: intl('PolicyGenerator.Addresses'),
      value: ({row}) => row.addresses,
      format: ({value}) => formatRuleAddress(value),
      sortFunction: sortvalue =>
        collator.compare(sortvalue.a.addresses.sort().join(','), sortvalue.b.addresses.sort().join(',')),
    },
    encrypt: {
      header: intl('Edge.AdminGroup.AllowedAdminAccess.EncryptTraffic'),
      value: ({row}) => (row.secConnect ? intl('Common.Yes') : intl('Common.No')),
    },
  },

  templates: [
    [
      {columns: ['service'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
      {columns: ['addresses'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
      {columns: ['encrypt'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['service'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
            {columns: ['addresses'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
            {columns: ['encrypt'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['service'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
          {columns: ['addresses'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
          {columns: ['encrypt'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [{columns: ['service', 'addresses', 'encrypt'], size: 'minmax(200px, auto)'}];
        }

        return [{columns: ['service', 'addresses', 'encrypt'], size: 'minmax(200px, auto)'}];
      },
    },
  ],
}));

export const forwardgridSettings = createSelector([], () => ({
  id: 'workloadRules',
  sort: 'service',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    service: {
      header: intl('Common.Service'),
      value: ({row}) => row.service,
      format: ({value}) => formatRuleService(value),
    },
    local: {
      header: intl('Common.Local'),
      value: ({row}) => row.local,
    },
    remotePeers: {
      header: intl('Workloads.Summary.RemotePeers'),
      value: ({row}) => row.remotePeers,
    },
  },

  templates: [
    [
      {columns: ['service'], size: 'minmax(100px, auto)'},
      {columns: ['local'], size: 'minmax(100px, auto)'},
      {columns: ['remotePeers'], size: 'minmax(100px, auto)'},
    ],
  ],
}));

export const customIpgridSettings = createSelector([], () => ({
  id: 'workloadRules',
  sort: 'rules',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    rules: {
      header: intl('Common.Rules'),
      format: ({row}) => formatCustomRule(row),
    },
  },

  templates: [[{columns: ['rules'], size: 'minmax(100px, auto)'}]],
}));
