/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getLabelGroupDetail} from '../Item/LabelGroupItemState';
import {getRouteCurrentName, getRouteCurrentParams} from 'containers/App/AppState';

export const getLabelGroup = createSelector([getRouteCurrentName, getLabelGroupDetail], (routeName, labelGroup) =>
  routeName === 'app.labelGroups.create' ? {} : labelGroup,
);

export const getLabelGroupEdit = createSelector(
  [getRouteCurrentName, getRouteCurrentParams, getLabelGroup],
  (routeName, routeParams, labelGroup) => {
    return {routeName, routeParams, labelGroup};
  },
);
