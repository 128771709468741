/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';

// Set Existing Label name
export function setExistingName(formik) {
  const {setFieldTouched, validateForm, touched} = formik;

  // Set touched immediately to show name clash error without awaiting for onBlur
  if (!touched.name) {
    setFieldTouched('name', true);
  } else {
    validateForm();
  }
}

// Determine if name is exclude name
export function isExcludeName(excludeNames = [], formikName, stateInitialName) {
  const value = formikName.trim();

  return (
    value.localeCompare(stateInitialName, intl.locale, {sensitivity: 'base'}) !== 0 &&
    excludeNames.some(name => !name.localeCompare(value, intl.locale, {sensitivity: 'base'}))
  );
}

// Returns duplicated object {href, key, value}. Where object.href and object.value are used to render link in errorMessage of Input
export function getDuplicateObject(existingNames = [], formikName, val = 'name') {
  const value = formikName.trim();

  return existingNames.find(name => !name[val].localeCompare(value, intl.locale, {sensitivity: 'base'}));
}
