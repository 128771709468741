/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import styles from './PageTitle.css';

export default ({text}) => (
  <div className={styles.pageTitle}>
    <div tid="group-setup-message">{text}</div>
  </div>
);
