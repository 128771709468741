/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {Component} from 'react';
import intl from 'intl';
import {Notifications} from 'components';
import {connect} from 'react-redux';
import {getVenUpgradeStatus} from './VenUpgradeState';

const allottedTime = 66 * 60 * 1000; // server expiry of upgrade process is 60 minutes

/*
 * Where present, VenUpgradeRequestedNotice displays immediate, transient feedback to the user who launched a VEN Upgrade.
 * Banner displays until 60 minute timeout, it is manually closed, or the user session ends.
 */
@connect(getVenUpgradeStatus)
export default class VenUpgradeRequestedNotice extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.started && prevProps.started !== this.props.started) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = setTimeout(this.setDataEmpty, allottedTime);
    }
  }

  setDataEmpty = () => {
    this.props.dispatch({type: 'VENS_UPGRADE_REQUESTED', data: {}});
  };

  // data examples
  // set status: {type: 'VENS_UPGRADE_REQUESTED', data: {errors: [], started: Date.now(), count: 5}}
  // clear status: {type: 'VENS_UPGRADE_REQUESTED', data: {}}

  render() {
    const {started, count} = this.props;

    if (!started && !count) {
      return null;
    }

    return (
      <Notifications>
        {[
          {
            type: 'instruction',
            title: intl('VEN.Upgrade.UpgradeInProgress'),
            message: intl('VEN.Upgrade.UpgradeStartedOnDate', {
              count,
              datetime: intl.date(started, intl.formats.date.L_HH_mm_ss),
            }),
            handleClose: this.setDataEmpty,
          },
        ]}
      </Notifications>
    );
  }
}
