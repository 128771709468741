/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {call, put, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {getContainerClusterServiceBackends} from './ContainerClusterServiceBackendsState';
import {gridSettings} from './ContainerClusterServiceBackendsConfig';
import gridSaga from 'components/Grid/GridSaga';

export function* fetchContainerClusterServiceBackends({params, force = false} = {}) {
  return yield call(apiSaga, 'container_cluster.service_backends', {
    params: {container_cluster_id: params.id},
    cache: !force,
    *onDone({data: list}) {
      const len = list.length;
      const count = {
        matched: len,
        total: len,
      };

      if (force || list !== (yield select(getContainerClusterServiceBackends))) {
        yield put({
          type: 'CONTAINER_CLUSTER_GET_SERVICE_BACKENDS',
          data: {serviceBackendList: list, serviceBackendCount: count},
        });
      }

      return {list, count};
    },
  });
}

export function* fetchContainerServiceBackendsPage(route, refetch = false) {
  yield call(gridSaga, {
    route,
    settings: gridSettings,
    *onSaga() {
      const {list} = yield call(fetchContainerClusterServiceBackends, {params: route.params, force: refetch});

      return list && list.length;
    },
  });
}
