/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {
  formatRuleService,
  formatRuleAddress,
} from 'containers/ContainerWorkload/Item/Rules/ContainerWorkloadRulesUtils';
import stylesUtils from 'utils.css';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import {createSelector} from 'reselect';

const collator = new Intl.Collator(intl.lang, {sensitivity: 'base'});

export const enforcementgridSettings = createSelector([], () => ({
  id: 'workloadRules',
  sort: 'service',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    service: {
      header: intl('Common.Service'),
      value: ({row}) => row.service,
      format: ({value}) => (
        <div className={`${stylesUtils.gapXSmall} ${stylesUtils.gapAlignStart}`}>{formatRuleService(value)}</div>
      ),
    },
    addresses: {
      header: intl('PolicyGenerator.Addresses'),
      value: ({row}) => row.addresses,
      format: ({value}) => formatRuleAddress(value),
      sortFunction: sortvalue =>
        collator.compare(sortvalue.a.addresses.sort().join(','), sortvalue.b.addresses.sort().join(',')),
    },
  },

  templates: [
    [
      {columns: ['service'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
      {columns: ['addresses'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
    ],
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['service'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
            {columns: ['addresses'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
          ];
        }

        return [
          {columns: ['service'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
          {columns: ['addresses'], size: 'minmax(100px, auto)', extraClass: stylesGridUtils.vCenter},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [{columns: ['service', 'addresses'], size: 'minmax(200px, auto)'}];
        }

        return [{columns: ['service', 'addresses'], size: 'minmax(200px, auto)'}];
      },
    },
  ],
}));
