/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, getSelectorSettings} from './ContainerClusterListConfig';
import {getClusters, getLocalFQDN} from 'containers/Health/HealthState';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getContainerClusters = state => state.containerClusters.list;
export const getContainerClustersCount = state => state.containerClusters.count;

const getContainerClusterRows = createSelector(getContainerClusters, containerClusters =>
  containerClusters.map(item => ({
    key: item.href,
    removable: Array.isArray(item.caps) && item.caps.includes('write'),
    data: {
      ...item,
    },
  })),
);

export const getCalculatedStaticMap = createSelector(getClusters, clusters =>
  clusters.length > 1
    ? {
        pce_fqdn: intl('Common.PCE'),
      }
    : {},
);

export const getCalculatedFilterMap = createSelector(
  [getCalculatedStaticMap, getSelectorSettings],
  (calculatedStaticMap, selectorSettings) => ({
    ...selectorSettings.filterMap,
    ...calculatedStaticMap,
  }),
);

export const getCalculatedRouteFilters = createSelector(getCalculatedFilterMap, calculatedFilterMap => ({
  ...calculatedFilterMap,
}));

export const getCalculatedStaticValues = createSelector([getClusters, getLocalFQDN], (clusters, localFQDN) =>
  clusters.length > 1
    ? {
        pce_fqdn: clusters.reduce((result, cluster) => {
          if (cluster) {
            const label = cluster.fqdn === localFQDN ? intl('Workloads.ThisPCE', {fqdn: cluster.fqdn}) : cluster.fqdn;

            result[label] = cluster.fqdn;
          }

          return result;
        }, {}),
      }
    : {},
);

export const getGridSettings = createSelector([getClusters, gridSettings], (clusters, gridSettings) => {
  const columns = {...gridSettings.columns};

  columns.pce.disabled = clusters.length <= 1;

  return {...gridSettings, columns};
});

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getContainerClusterRows,
    filterMap: getCalculatedRouteFilters,
  });

export const getContainerClustersPage = createSelector(
  [getGrid, getContainerClustersCount, getCalculatedFilterMap, getCalculatedStaticValues, getSelectorSettings],
  (grid, count, calculatedFilterMap, calculatedStaticValues, selectorSettings) => {
    const filterItems = Object.keys(grid.filter).reduce((result, categoryKey) => {
      result.push({
        categoryKey,
        value: grid.filter[categoryKey][0],
        categoryName: calculatedFilterMap[categoryKey].value || calculatedFilterMap[categoryKey],
      });

      return result;
    }, []);

    const selector = {
      filterItems,
      objects: Object.values(selectorSettings.objectMap),
      categories: Object.entries(calculatedFilterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value: value.value || value,
        object: value.object,
        scope: value.scope,
        statics: value.statics,
      })),
      facets: Object.keys(calculatedFilterMap),
      partials: Object.keys(calculatedFilterMap),
      statics: Object.entries(calculatedStaticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
    };

    return {grid, count, selector};
  },
);
