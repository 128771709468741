/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {all, call, put} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';

function* fetchWorkloadHealth({status = 'error', maxResult = 0, force = false} = {}) {
  const query = {
    policy_health: status,
    max_results: maxResult,
  };

  return yield call(apiSaga, 'workloads.get_collection', {
    query,
    cache: !force,
    *onDone({count}) {
      return count?.matched ?? 0;
    },
  });
}

function* fetchVensHealth({condition, value = 'error', maxResult = 0, force = false} = {}) {
  const query = {
    [condition]: value,
    max_results: maxResult,
  };

  return yield call(apiSaga, 'vens.get_collection', {
    query,
    cache: !force,
    *onDone({count}) {
      return count?.matched ?? 0;
    },
  });
}

export function* fetchStatuses({force = false} = {}) {
  const [workloadError, workloadSuspended, workloadWarning, venError, venWarning, venSuspended] = yield all([
    call(fetchWorkloadHealth, {status: 'error', force}),
    call(fetchWorkloadHealth, {status: 'suspended', force}),
    call(fetchWorkloadHealth, {status: 'warning', force}),
    call(fetchVensHealth, {force, condition: 'health', value: 'error'}),
    call(fetchVensHealth, {force, condition: 'health', value: 'warning'}),
    call(fetchVensHealth, {force, condition: 'status', value: 'suspended'}),
  ]);

  yield put({
    type: 'WORKLOAD_VEN_STATUS',
    data: {
      count: {
        workloadError,
        workloadWarning: workloadSuspended + workloadWarning,
        venError,
        venWarning: venSuspended + venWarning,
      },
    },
  });
}
