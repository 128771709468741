/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {edge} from 'api/apiUtils';
import {apiPrefix} from 'api/api';
import {createSelector} from 'reselect';

export const types = createSelector([], () => ({
  ipLists: {
    method: 'ip_lists.get_collection',
    prefix: 'IP_Lists',
    label: edge ? intl('IPLists.Mixin.Ranges') : intl('Common.IPLists'),
    params: {pversion: 'draft'},
    link: {to: 'iplists.list'},
  },
  ...(!edge && {
    enforcementBoundary: {
      method: 'enforcement_boundaries.get_collection',
      prefix: 'EnforcementBoundaries_Lists',
      label: intl('Workloads.EnforcementBoundaries'),
      params: {pversion: 'draft'},
      link: {to: 'boundaries.list'},
    },
    serviceAccounts: {
      method: 'service_accounts.get_collection',
      prefix: 'service_accounts',
      label: intl('Common.ServiceAccounts'),
      link: {to: 'rbac.serviceAccounts.list'},
      formats: ['json'],
    },
  }),
  services: {
    method: 'services.get_collection',
    prefix: 'Services',
    label: intl('Common.Services'),
    params: {pversion: 'draft'},
    link: {to: 'services.list'},
  },
  rulesets: {
    method: 'rule_sets.get_collection',
    prefix: 'Rulesets',
    label: edge ? intl('Common.Groups') : intl('Common.Rulesets'),
    params: {pversion: 'draft'},
    link: {to: 'rulesets.list'},
    formats: ['json'], // Rulesets support only json format for now
  },
  labels: {
    method: 'labels.get_collection',
    prefix: 'Labels',
    label: intl('Common.Labels'),
    link: {to: 'labels'},
    usage: true,
  },
  labelGroups: {
    method: 'label_groups.get_collection',
    prefix: 'Label_Groups',
    label: intl('Labels.Groups'),
    params: {pversion: 'draft'},
    link: {to: 'labelGroups.list'},
    usage: true,
  },
  pairingProfiles: {
    method: 'pairing_profiles.get_collection',
    prefix: 'Pairing_Profiles',
    label: intl('PairingProfiles.Profiles'),
    link: {to: 'pairingProfiles.list'},
  },
  virtualServers: {
    method: 'virtual_servers.get_collection',
    prefix: 'Virtual_Servers',
    label: intl('Common.VirtualServers'),
    link: {to: 'virtualServers'},
    params: {pversion: 'draft'},
    formats: ['json'], // Virtual services support only json format for now
  },
  virtualServices: {
    method: 'virtual_services.get_collection',
    prefix: 'Virtual_Services',
    label: intl('Common.VirtualServices'),
    params: {pversion: 'draft'},
    link: {to: 'virtualServices.list'},
  },
  workloads: {
    method: 'workloads.get_collection',
    prefix: intl('Common.Workloads'),
    label: intl('Common.Workloads'),
    link: {to: 'workloads.list'},
  },
  vens: {
    method: 'vens.get_collection',
    prefix: 'Vens',
    label: intl('Common.VEN'),
    link: {to: 'workloads.vens.list'},
  },
}));

/**
 * Format date for Report name
 *
 * @example
 * 2019-01-15_13-52-18
 */
export const formatReportDate = (date = new Date(), utc = false) =>
  utc
    ? date
    : new Date(date.getTime() - date.getTimezoneOffset() * 60_000)
        .toISOString()
        .replaceAll(':', '-')
        .replace('T', '_')
        .replace(/\..*/, '');

/**
 * Returns default report name
 *
 * @example
 * IP_Lists_CSV_2019-01-15_13-52-18
 */
export const generateDefaultReportName = (prefix, format) => {
  const finalPrefix =
    prefix === 'Rulesets' && edge ? 'Groups' : prefix === 'IP_Lists' ? (edge ? 'IP_Ranges' : 'IP_Lists') : prefix;

  return `${finalPrefix}_${format.toUpperCase()}_${formatReportDate()}`;
};

/**
 * Compose download url
 *
 * @example
 * /api/v2/orgs/1/datafiles/5d42cbc0?filename=IP_Lists_CSV_2019-01-16_03-11-06.csv
 */
export const composeDownloadUrl = (href, filename) =>
  `${apiPrefix}${href}${filename ? `?filename=${encodeURIComponent(filename)}` : ''}`;

/**
 * Filter given reports array with a given type and sort by requested time
 *
 * @param {Array} reports
 * @param {String} type
 * @returns {*}
 */
export const filterReportsByTypeAndSortByRequested = (reports, type) =>
  reports
    .filter(report => report.job_type === type)
    .sort((a, b) => new Date(b.requested_at).getTime() - new Date(a.requested_at).getTime());
