/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {ProgressBar, Icon} from 'components';
import styles from './ProvisionProgress.css';
import {getProvisioningPage} from '../ProvisioningState';
import {webStorageUtils} from 'utils';
import {AppContext} from 'containers/App/AppUtils';

const getInitialState = () => {
  //Initialize hide and show with session entry from legacy page pop-up
  const provisionProgress = webStorageUtils.getSessionItem('ProvisionProgress', {remove: true}) || {
    hide: false,
    show: false,
  };

  let redAgentTimeout = provisionProgress.timeout;

  //Check timeout captured in old page session
  if (provisionProgress.timeoutFromSession) {
    redAgentTimeout = provisionProgress.timeoutFromSession;
  }

  return {
    hide: provisionProgress.hide,
    show: provisionProgress.show,
    total: provisionProgress.total,
    timeout: redAgentTimeout,
  };
};

@connect(getProvisioningPage)
export default class ProvisionProgress extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = getInitialState();

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handlePathChange = this.handlePathChange.bind(this);
    this.popupTimeout = null;
    this.AUTO_DISMISS_DELAY = 10_000;
  }

  componentDidMount() {
    this.handlePathChange();
    webStorageUtils.setSessionItem('ProvisionProgress', this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    //To enable state sharing between new and old provision popup
    //Need to compare show, hide and complete state values to handle 0 workloads affected status in legacy when provisioned from modal
    if (this.state !== prevState) {
      webStorageUtils.setSessionItem('ProvisionProgress', this.state);
    }

    const {
      props: {red},
      state: {show, hide, timeout},
    } = this;

    const complete = !timeout && !red && !hide && show;

    if (complete) {
      this.popupTimeout = setTimeout(() => {
        this.closePopup();
      }, this.AUTO_DISMISS_DELAY);
    }
  }

  componentWillUnmount() {
    if (this.popupTimeout) {
      clearTimeout(this.popupTimeout);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.calcInProgress !== prevState.calcInProgress ||
      nextProps.complete.showProvisionProgressPopup !== prevState.complete.showProvisionProgressPopup
    ) {
      const nextState = {
        calcInProgress: nextProps.calcInProgress,
        complete: nextProps.complete,
        show: prevState.show,
        hide: prevState.hide,
        total: prevState.total || nextProps.total,
      };

      //Check if it previously timeout but now is in progress
      if (prevState.timeout && !nextProps.red) {
        nextState.timeout = prevState.timeout;
      } else {
        nextState.timeout = nextProps.timeout;
      }

      //Do not show pop-up in provision route when no provisioning is in progress
      if (nextProps.routeName !== 'app.provision') {
        if (nextProps.calcInProgress === false && prevState.calcInProgress) {
          if (nextProps.listenOnlyMode) {
            nextState.show = false;
          } else {
            nextState.show = !prevState.hide;
          }
        }

        if (
          (nextProps.calcInProgress && prevState.calcInProgress === false) ||
          nextProps.complete.showProvisionProgressPopup
        ) {
          nextState.show = true;
          nextState.hide = false;
        }
      }

      return nextState;
    }

    return null;
  }

  handlePathChange() {
    if (this.props.routeName === 'app.provisioning') {
      this.setState({show: false});
    } else if (this.props.calcInProgress) {
      this.setState(state => ({show: !state.hide}));
    }
  }

  handleCloseClick(evt) {
    evt.stopPropagation(); // To prevent row click event

    // prevent auto close if manually closed
    if (this.popupTimeout) {
      clearTimeout(this.popupTimeout);
      this.popupTimeout = null;
    }

    this.closePopup();
  }

  handleClick(evt) {
    if (this.props.complete.workloadsAffected === 0) {
      const complete = this.props.complete;

      this.context.navigate({evt, to: 'provisioning', params: {complete}});
    } else {
      this.context.navigate({evt, to: 'provisioning', params: {complete: {isComplete: true}}});
    }
  }

  closePopup() {
    this.setState({hide: true});

    if (this.props.complete.showProvisionProgressPopup) {
      this.props.dispatch({
        type: 'PROVISION_COMPLETED',
        data: {...this.props.complete, showProvisionProgressPopup: false},
      });
    }
  }

  render() {
    const {
      props: {red, listenOnlyMode},
      state: {show, hide, timeout},
    } = this;

    if (hide || !show) {
      return null;
    }

    const complete = !timeout && !red;
    //TODO const fillPercent = total ? synced / total * 100 : 0;

    return (
      <div
        className={`${styles.provisionProgress} ${complete ? styles.provisionProgressComplete : ''}`}
        data-tid="provisionprogress"
        onClick={this.handleClick}
      >
        <div onClick={this.handleCloseClick}>
          <Icon name="close" theme={styles} themePrefix="close-" />
        </div>

        <div
          className={`${styles.provisionProgressContent} ${
            listenOnlyMode || timeout ? styles.provisionProgressContentNoBar : ''
          }`}
        >
          {!listenOnlyMode &&
            !timeout &&
            (complete ? (
              <Icon name="check" theme={styles} themePrefix="complete-" />
            ) : (
              <div className={styles.progressBar}>
                <ProgressBar tid="statusbar" />
              </div>
            ))}
          {listenOnlyMode
            ? intl('Provision.ListenOnlyModeSynchronizeCount', {count: red})
            : complete
            ? intl('Provision.ProvisioningComplete')
            : timeout
            ? intl('Provision.ProvisioningInProgress', {ellipsis: true})
            : intl('Provision.SynchronizingCount', {count: red})}
        </div>
      </div>
    );
  }
}
