/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {edge} from 'api/apiUtils';

export const networkTypesList = [
  [edge ? intl('Common.Corporate') : intl('Rulesets.Rules.CorporateNetwork'), 'brn'],
  [edge ? intl('Common.External') : intl('Rulesets.Rules.NonCorporateNetworks'), 'non_brn'],
  [edge ? intl('Common.All') : intl('Rulesets.Rules.AllNetworks'), 'all'],
];

// array of UI display values for network types
export const networkTypes = networkTypesList.map(([type]) => type);

// JS Map with KV pairs for: display value to api values, and api values to display values
export const networkTypeMap = new Map([...networkTypesList, ...networkTypesList.map(type => [...type].reverse())]);

// used in OptionSelector component
export const networkTypesOptionObject = networkTypesList.map(([label, value]) => ({label, value}));

// object for selectors
export const networkTypeMenuOptions = {
  network_type: networkTypes,
};
