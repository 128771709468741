/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Badge} from 'components';
import {UserName} from 'containers';
import {formatUtils} from 'utils';
import * as GridUtils from 'components/Grid/GridUtils';
import {getServicePortsString, getServicePortsSortString} from '../ServiceUtils';
import styles from './ServiceList.css';
import {createSelector} from 'reselect';

export const getSelectorSettings = createSelector([], () => ({
  facetMap: {
    name: intl('Common.Name'),
    port: intl('Port.Port'),
    protocol: intl('Common.Protocol'),
    description: intl('Common.Description'),
  },
  staticMap: {
    provision: intl('Provision.Status'),
  },
  filterMap: {
    name: intl('Common.Name'),
    port: intl('Port.Port'),
    protocol: intl('Common.Protocol'),
    description: intl('Common.Description'),
    provision: intl('Provision.Status'),
  },
  staticValues: {
    provision: {
      [intl('Provision.PendingAddition')]: 'create',
      [intl('Provision.PendingDeletion')]: 'delete',
      [intl('Provision.PendingModification')]: 'update',
    },
  },
}));

export const gridSettings = createSelector([], () => ({
  id: 'servicelist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: {
      header: intl('Provision.Status'),
      value: ({row}) => formatUtils.getBadgeLabelByUpdateType(row.data.update_type),
      format: ({row, value}) =>
        value ? (
          <Badge type={formatUtils.getBadgeTypeByUpdateType(row.data.update_type)} theme={styles} themePrefix="status-">
            {value}
          </Badge>
        ) : null,
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'name',
    },
    portProtos: {
      header: intl('Port.Protocol'),
      value: ({row}) => {
        if (row.data.name === intl('Common.AllServices') || !(row.data.windows_services || row.data.service_ports)) {
          return intl('Common.ALL');
        }

        return getServicePortsString(row.data.windows_services || row.data.service_ports);
      },
      format: ({value}) => <span className={styles.serviceListPorts}>{value}</span>,
      sort: ({row}) => {
        if (!row.data.service_ports) {
          return getServicePortsSortString(row.data.windows_services).toLowerCase();
        }

        return getServicePortsSortString(row.data.service_ports).toLowerCase();
      },
    },
    updatedAt: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.LastModifiedOn'),
      value: 'updated_at',
    },
    updatedBy: GridUtils.clickableColumn({
      header: intl('Common.LastModifiedBy'),
      value: ({row}) => row.data.updated_by.username,
      format: ({row, clickableRef}) =>
        row.data.updated_by && <UserName user={row.data.updated_by} ref={clickableRef} />,
    }),
    desc: {
      header: intl('Common.Description'),
      value: 'description',
    },
  },

  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'min-content'},
      {columns: ['name'], size: 'minmax(120px, auto)'},
      {columns: ['portProtos'], size: 'minmax(120px, auto)'},
      {columns: ['updatedAt'], size: 'minmax(170px, auto)'},
      {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
      {columns: ['desc'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['portProtos'], size: 'minmax(100px, auto)'},
            {columns: ['updatedAt'], size: 'minmax(170px, auto)'},
            {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
            {columns: ['desc'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['portProtos'], size: 'minmax(100px, auto)'},
          {columns: ['updatedAt'], size: 'minmax(170px, auto)'},
          {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
          {columns: ['desc'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['portProtos'], size: 'minmax(100px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(230px, auto)'},
            {columns: ['desc'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['portProtos'], size: 'minmax(100px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(230px, auto)'},
          {columns: ['desc'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name', 'portProtos'], size: 'minmax(100px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(230px, auto)'},
            {columns: ['desc'], size: 'minmax(80px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name', 'portProtos'], size: 'minmax(100px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(230px, auto)'},
          {columns: ['desc'], size: 'minmax(80px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'portProtos', 'status'], size: 'minmax(100px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(230px, auto)'},
            {columns: ['desc'], size: 'minmax(80px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'portProtos', 'status'], size: 'minmax(100px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(230px, auto)'},
          {columns: ['desc'], size: 'minmax(80px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'portProtos', 'status'], size: 'minmax(150px, auto)'},
            {columns: ['updatedAt', 'updatedBy', 'desc'], size: 'minmax(170px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'portProtos', 'status'], size: 'minmax(150px, auto)'},
          {columns: ['updatedAt', 'updatedBy', 'desc'], size: 'minmax(170px, auto)'},
        ];
      },
    },
  ],
}));
