/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {Component} from 'react';
import {select} from 'redux-saga/effects';
import {isCrowdstrike} from 'containers/App/AppState';
import {RedirectError} from 'errors';
import VenReducers from './VenState';
import SecuritySettingsReducers from '../SecuritySettings/SecuritySettingsState';

export default class Ven extends Component {
  static reducers = [VenReducers, SecuritySettingsReducers];
  static prefetch = function* () {
    const crowdstrike = yield select(isCrowdstrike);

    if (crowdstrike) {
      throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
    }
  };

  render() {
    return this.props.children;
  }
}
