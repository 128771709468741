/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import Cache from 'utils/cache';
import stringify from 'safe-stable-stringify';
// TODO: Task interface from redux-saga is too generic, use it when they fix it
// import type {Task} from 'redux-saga';
import type {FixedTask as Task} from 'typed-redux-saga';
import type {APICallResponse} from './api';
import type {APICallerResult} from './apiSaga';

export class ApiCache<T> extends Cache<T> {
  /**
   * Standard cache has method 'remove' that takes key as an argument.
   * Key consist of method name and stringified options, like 'workloads.get_collection/{"query":{"max":500},"params":{"xorg_id":1}}'.
   * If you want to remove all cached entries for method, call this method, that extends Cache.
   *
   * @example
   * cachedResponses.removeByMethodName('workloads.get_collection')
   *
   * @param {string} name - Method name
   * @returns {number} - number of removed items
   */
  removeByMethodName(name: string): number {
    let numberOfRemoved = 0;

    if (this.stack.length > 0) {
      this.stack = this.stack.filter(item => {
        if (item.key.startsWith(name)) {
          this.remove(item.key, false);
          numberOfRemoved++;

          return false;
        }

        return true;
      });
    }

    return numberOfRemoved;
  }
}

// Cache for api responses.
// Make it non-lru to expire response in default 1m even if response is taken several times during its lifetime
export const cachedResponses = new ApiCache<{
  cacheKey: string;
  cacheSalt?: string;
  response: APICallResponse;
  afterFetchResult: unknown;
}>({lru: false, maxValues: 100, defaultMaxValueAge: 60_000});

// Cache for pending api sagas.
// Make it non-lru and not expirable, since pending fetch will be canceled by optionally set or default fetch timeout
export const pendingCalls = new ApiCache<{count: number; apiTask: Task<APICallerResult>}>({
  lru: false,
  maxValues: Infinity,
  defaultMaxValueAge: Infinity,
});

// Generate cache key using safe-stable-stringify
// If method is 'workloads.get_collection' and 'options' is {query: {max:500}, params: {xorg_id: 1}}
// key will be 'workloads.get_collection/{"query":{"max":500},"params":{"xorg_id":1}}'
export const generateKey = (method: string, options: unknown, salt?: string): string =>
  `${method}/${salt ? `${salt}/` : ''}${stringify(options)}`;

// Expose cache to global in dev
if (__DEV__) {
  (function () {
    globalThis.pendingCalls = pendingCalls;
    globalThis.cachedResponses = cachedResponses;
  })();
}
