/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {call, select} from 'redux-saga/effects';
import {getContainerClusterDetail} from '../ContainerClusterDetailState';
import {fetchVenList} from 'containers/Ven/List/VenListSaga';
import {gridSettings} from 'containers/Ven/List/VenListConfig';

export function* fetchContainerClusterVens(route, refetch = false) {
  const detail = yield select(getContainerClusterDetail);

  const columns = {...gridSettings().columns};

  columns.checkboxes.disabled = true;

  const modifiedGridSettings = {...gridSettings(), columns}; // by design, this is not an editing list for anyone.

  if (detail?.href) {
    yield call(fetchVenList, route, refetch, {
      gridSettings: modifiedGridSettings,
      containerCluster: detail.href,
      ignoreScope: true,
    });
  }
}
