/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteCurrentParams, getRouteName} from 'containers/App/AppState';
import {isUserReadOnly} from 'containers/User/UserState';
import {mapSecureAPIDataToUIFriendlyVersion} from '../../SecureGatewayUtils';
import {getNetworks} from 'containers/Network/List/NetworkListState';
import {getSecureGatewayInstance} from 'containers/SecureGateway/Item/SecureGatewayItemState';
import {getId} from 'utils/href';

export const getSecureGatewayEdit = createSelector(
  [
    getRouteName,
    getRouteCurrentParams,
    isUserReadOnly,
    createSelector([getRouteName, getSecureGatewayInstance], (routeName, securegateways) =>
      routeName === 'app.secureGateways.create' ? {} : securegateways,
    ),
  ],
  (routeName, currentRouteParams, userReadOnly, securegateway) => {
    const originalSecureGateway = mapSecureAPIDataToUIFriendlyVersion(securegateway.draft) || {};
    const authMode = securegateway.draft && securegateway.draft.ca_id ? 'certificate' : 'psk';

    return {
      routeName,
      currentRouteParams,
      originalSecureGateway,
      authMode,
      userReadOnly,
    };
  },
);

const getSecureGatewaysEditRows = createSelector([getSecureGatewayEdit], ({originalSecureGateway}) =>
  (originalSecureGateway.secure_networks || [])
    .filter(subnet => subnet.update_type !== 'delete')
    .map(item => ({
      key: `${getId(item.ipList.href)}${getId(item.labels.role.href)}${getId(item.labels.app.href)}${getId(
        item.labels.loc.href,
      )}${getId(item.labels.env.href)}`,
      selectable: item.update_type === 'create' || !item.update_type,
      removable: item.update_type === 'create' || !item.update_type,
      data: {...item},
      action: item.update_type === 'create' || !item.update_type,
    })),
);

export const getSecureGatewaysEditPage = createSelector(
  [getSecureGatewaysEditRows, getSecureGatewayEdit, getNetworks],
  (rows, securegatewaysedit, networkList) => ({rows, networkList, ...securegatewaysedit}),
);
