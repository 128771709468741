/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {Button} from 'components';
import Modal from '../Modal';

const defaultButtonProps = {
  tid: 'done',
  text: intl('Common.Done'),
};

export default class AlertModal extends PureComponent {
  // List of props specific to Alert modal, all extra props will be passed down to rendered Modal as is
  static propTypes = {
    buttonProps: PropTypes.object, // Object with custom props for a button, will be merged with defaultButtonProps and onClose (if passed)
    isInProgress: PropTypes.bool, // Shortcut to set progress state to the button if buttonProps is not specified

    // Props for <Modal.Header>
    title: PropTypes.node,
    noCloseIcon: PropTypes.bool,

    // Props for <Modal.Content>
    insensitive: PropTypes.bool,
    notScrollable: PropTypes.bool,
    noPaddings: PropTypes.bool,
    gap: PropTypes.string,
  };

  render() {
    const {
      children,
      buttonProps,
      isInProgress,
      // Destructure <Modal.Header> props
      title = intl('Common.Alert'),
      noCloseIcon,
      // Destructure <Modal.Content> props
      insensitive,
      notScrollable,
      noPaddings,
      gap,
      ...modalProps
    } = this.props;
    const headerProps = {title, noCloseIcon};
    const contentProps = {insensitive, notScrollable, noPaddings, gap};
    const button = {onClick: modalProps.onClose, progress: isInProgress, ...defaultButtonProps, ...buttonProps};

    return (
      <Modal {...modalProps}>
        <Modal.Header {...headerProps} />
        <Modal.Content {...contentProps}>{children}</Modal.Content>
        <Modal.Footer>
          <Button {...button} />
        </Modal.Footer>
      </Modal>
    );
  }
}
