/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

/**
 * Process value and prepares input for diff function
 */
export const sanitizeDiffValue = (() => {
  const tagsRegex = /<[^>]*>/g;

  return <T extends string | string[]>(value: T): T extends string ? string : string[] => {
    if (Array.isArray(value)) {
      // If value is an array of strings and tags exist, return a new array without tags
      if (value.some(v => tagsRegex.test(v))) {
        return value.map(v => v.replace(tagsRegex, '')) as T extends string ? string : string[];
      }
    } else if (typeof value === 'string' && tagsRegex.test(value)) {
      // If it's a string with tags, return a new string without tags
      return value.replace(tagsRegex, '') as T extends string ? string : string[];
    }

    // Otherwise return value as is
    return value as unknown as T extends string ? string : string[];
  };
})();
