/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Icon, Button, ButtonGroup, MenuItem, MenuDelimiter} from 'components';
import {getRolesString} from 'containers/RBAC/RBACUtils';
import _ from 'lodash';
import {hrefUtils} from 'utils';
import * as GridUtils from 'components/Grid/GridUtils';
import {isAPIAvailable} from 'api/apiUtils';
import {createSelector} from 'reselect';

function handleAction(type, props) {
  const {row, component} = props;
  const {
    props: {routeParams},
  } = component;

  switch (type) {
    case 'edit':
      component.context.navigate({
        to: 'rbac.accesswizard',
        params: {
          type: 'scoped',
          prevRoute: JSON.stringify({params: {id: routeParams.id}, to: 'rbac.roles.scope.detail'}),
          scopeId: routeParams.id,
          roles: JSON.stringify([...row.data.roles]),
          authSecPrincipals: JSON.stringify([hrefUtils.getId(row.data.authSecPrincipal.href)]),
        },
      });
      break;

    case 'remove':
      component.handleRemoveRow(row);
      break;
  }
}

export const gridSettings = createSelector(() => ({
  id: 'scopedroledetail',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showPagination: true,
  columns: {
    checkboxes: {},
    type: {
      header: intl('Common.Type'),
      value: ({row}) => row.data.authSecPrincipal.type,
      format: value => <Icon size="medium" autoTooltip name={value.value === 'user' ? 'user' : 'group'} />,
    },
    name: {
      header: intl('Common.Name'),
      value: ({row}) => row.data.authSecPrincipal.display_name,
    },
    username: {
      header: intl('Common.EmailUsernameGroup'),
      value: ({row}) => row.data.authSecPrincipal.name,
    },
    roles: {
      header: intl('Common.Roles'),
      value: ({row}) => getRolesString([...row.data.roles]),
    },
    action: {
      value: ({row}) => row.data,
      format(props) {
        // in config format, if you want to call functions / use this.props in the actual component, you need to:
        //    1. in actual component, pass down a prop "compoent" with value of "this" in Grid. e.g.: <Grid component={this}>...</Grid>
        //    2. in config columns.format, (format becomes a function) you can either use props.component, or "this" keyword directly
        //       e.g.: this.context.navigate({...}) is equivalent to props.component.context.navigate({...})

        return (
          <ButtonGroup color="standard" noFill>
            <Button
              icon="edit"
              disabled={!isAPIAvailable('org_permission.delete', 'org_permissions.create')}
              tid="edit"
              onClick={_.partial(handleAction, 'edit', props)}
            />
            <Button.Menu
              menu={[
                <MenuItem
                  text="Edit"
                  disabled={!isAPIAvailable('org_permission.delete', 'org_permissions.create')}
                  tid="edit"
                  onSelect={_.partial(handleAction, 'edit', props)}
                />,
                <MenuDelimiter />,
                <MenuItem
                  disabled={!isAPIAvailable('org_permission.delete')}
                  text="Remove"
                  tid="remove"
                  onSelect={_.partial(handleAction, 'remove', props)}
                />,
              ]}
            />
          </ButtonGroup>
        );
      },
      sortable: false,
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['type'], size: 'minmax(50px, auto)'},
      {columns: ['name'], size: 'minmax(150px, auto)'},
      {columns: ['username'], size: 'minmax(150px, auto)'},
      {columns: ['roles'], size: 'minmax(150px, auto)'},
      {columns: ['action'], size: 'max-content'},
    ],
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['type'], size: 'minmax(100px, auto)'},
            {columns: ['name', 'username'], size: 'minmax(200px, auto)'},
            {columns: ['roles'], size: 'minmax(200px, auto)'},
            {columns: ['action'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['type'], size: 'minmax(100px, auto)'},
          {columns: ['name', 'username'], size: 'minmax(200px, auto)'},
          {columns: ['roles'], size: 'minmax(200px, auto)'},
          {columns: ['action'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['type', 'name', 'username'], size: 'minmax(120px, auto)'},
            {columns: ['roles'], size: 'minmax(120px, auto)'},
            {columns: ['action'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['type', 'name', 'username'], size: 'minmax(120px, auto)'},
          {columns: ['roles'], size: 'minmax(120px, auto)'},
          {columns: ['action'], size: 'max-content'},
        ];
      },
    },
  ],
}));
