/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import {Badge, Link} from 'components';
import {hrefUtils} from 'utils';
import {getBadgeTypeByUpdateType, getBadgeLabelByUpdateType} from 'utils/formats';
import {lookupProtocol} from 'containers/Service/ServiceUtils';
import * as GridUtils from 'components/Grid/GridUtils';
import {getLoadBalancerInstance} from 'containers/LoadBalancer/Item/LoadBalancerItemState';
import {getFriendlyMode, getPort, isOnLoadBalancerPage} from '../VirtualServerUtils';
import styles from './VirtualServerList.css';

// This config is used context /virtualservers page and /loadbalancers/{id}/virtualservers tab.
// Virtual Servers are a subset of Discovered Virtual Servers. discovered_virtual_servers endpoint is used, not virtual_servers.
//
// VirtualServer Page:
// filters: slb, name, mode, vip, vip_port, vip_proto, labels
// Mode is a hard-coded set of (not enforced, enforced)
//
// LoadBalancer Page:
// SLB filters:  name, mode, vip, vip_port, vip_proto, labels (slb is only 1, set by page context).
// Note that there is no explicit SLB filter on the UI, since the entire tab is already filtered by the "parent" SLB.
// Facets on discovered_virtual_server for: name, vip, vip_port, vip_proto
// New autocomplete API for SLB name -> href is used.

export const resourceType = 'discovered_virtual_servers';

export const getSelectorSettings = createSelector(
  [isOnLoadBalancerPage, getLoadBalancerInstance],
  (isSLBPage, {href: slbHref}) => {
    const objectMap = {
      discovered_virtual_servers: {
        type: 'discovered_virtual_servers',
        queryArgs: {
          ...(!isSLBPage && {has_virtual_server: true}),
          ...(isSLBPage && {slb: slbHref}),
        }, // facets: do not suggest unmanaged VS except on SLB.servers tab
      },
      roleLabels: {type: 'labels', key: 'role'},
      appLabels: {type: 'labels', key: 'app'},
      envLabels: {type: 'labels', key: 'env'},
      locLabels: {type: 'labels', key: 'loc'},
      ...(!isSLBPage && {slbs: {type: 'slbs', queryArgs: {has_virtual_server: true}}}), // filter is implicitly true on SLB page; it has-an SLB}
    };

    const staticMap = {
      virtual_server_mode: {value: intl('VirtualServers.VirtualServerMode')},
    };

    const facetMap = {
      name: {value: intl('Common.Name'), object: objectMap.discovered_virtual_servers, isPartial: true},
      vip: {
        value: intl('VirtualServers.VIP'),
        object: objectMap.discovered_virtual_servers,
        wildcardQueryParam: 'advanced_search_vip',
      },
      vip_port: {value: intl('VirtualServers.VIPPort'), object: objectMap.discovered_virtual_servers},
      vip_proto: {value: intl('VirtualServers.VIPProto'), object: objectMap.discovered_virtual_servers},
    };
    const scopeMap = {
      role: {value: intl('Labels.RoleLabels'), object: objectMap.roleLabels, scope: true},
      app: {value: intl('Labels.ApplicationLabels'), object: objectMap.appLabels, scope: true},
      env: {value: intl('Labels.EnvironmentLabels'), object: objectMap.envLabels, scope: true},
      loc: {value: intl('Labels.LocationLabels'), object: objectMap.locLabels, scope: true},
    };
    const staticValues = {
      virtual_server_mode: {
        [intl('VirtualServers.ModeManagedEnforced')]: 'enforced',
        [intl('VirtualServers.ModeManagedNotEnforced')]: 'unmanaged',
        [intl('Common.Unmanaged')]: null, // translates to has_virtual_server=false, no virtual_server_mode declared
      },
    };

    const autocompleteMap = {
      ...(!isSLBPage && {slbs: {value: intl('VirtualServers.Detail.ServerLoadBalancer'), object: objectMap.slbs}}),
    };
    const filterMap = {...scopeMap, ...staticMap, ...facetMap, ...autocompleteMap};

    return {
      objectMap,
      facetMap,
      scopeMap,
      filterMap,
      staticMap,
      staticValues,
      autocompleteMap,
    };
  },
);

/**
[{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: node | Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
*/

export const gridSettings = createSelector([], () => ({
  id: 'virtualserverlist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500, 1000],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: {
      header: intl('Provision.Status'),
      value: ({row}) => getBadgeLabelByUpdateType(row.data.update_type),
      format: ({row, value}) =>
        value ? (
          <Badge type={getBadgeTypeByUpdateType(row.data.update_type)} theme={styles} themePrefix="status-">
            {value}
          </Badge>
        ) : null,
    },
    name: {
      linky: ({row}) => row.data.managed !== intl('Common.Unmanaged'),
      header: intl('Common.Name'),
      value: ({row}) => row.data.name,
    },
    mode: {
      header: intl('Common.Mode'),
      value: 'dvsMode', // e.g. SNAT
      format: ({value}) => value?.toUpperCase(),
    },
    vipPort: {
      header: intl('Common.VIPPort'),
      value: 'vipPort',
      format: ({value}) => (value ? `${value.vip} ${getPort(value.port)} ${lookupProtocol(value.protocol)}` : null),
    },
    manageState: {
      header: intl('LoadBalancers.Detail.ManagementState'),
      value: 'mode',
      format: ({row, value}) => (!row.data.slb ? intl('VirtualServers.Unassociated') : getFriendlyMode(value)),
    },
    slb: GridUtils.clickableColumn({
      header: intl('VirtualServers.List.SLB'),
      value: 'slb',
      // Create link with custom ref and style that takes up all cell's vertical space
      format: ({value, clickableRef}) => {
        if (!value) {
          return null;
        }

        return (
          <Link
            to="loadBalancers.item"
            params={{id: hrefUtils.getId(value.href), tab: 'summary'}}
            ref={clickableRef}
            theme={styles}
            themePrefix="slb-"
            themeCompose="merge"
          >
            {value.name}
          </Link>
        );
      },
    }),
    role: {
      header: intl('Common.Role'),
      ...GridUtils.clickableLabelColumn,
    },
    app: {
      header: intl('Common.Application'),
      ...GridUtils.clickableLabelColumn,
    },
    env: {
      header: intl('Common.Environment'),
      ...GridUtils.clickableLabelColumn,
    },
    loc: {
      header: intl('Common.Location'),
      ...GridUtils.clickableLabelColumn,
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to format function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to format function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to format function
    data: ?object,
  }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'min-content'},
      {columns: ['name'], size: 'minmax(120px, auto)'},
      {columns: ['mode'], size: 'minmax(100px, auto)'},
      {columns: ['vipPort'], size: 'minmax(150px, auto)'},
      {columns: ['manageState'], size: 'minmax(150px, auto)'},
      {columns: ['slb'], size: 'minmax(130px, auto)'},
      {columns: ['role'], size: 'minmax(150px, auto)'},
      {columns: ['app'], size: 'minmax(150px, auto)'},
      {columns: ['env'], size: 'minmax(150px, auto)'},
      {columns: ['loc'], size: 'minmax(150px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(120px, auto)'},
            {columns: ['mode'], size: 'minmax(90px, auto)'},
            {columns: ['vipPort'], size: 'minmax(150px, auto)'},
            {columns: ['manageState'], size: 'minmax(150px, auto)'},
            {columns: ['slb'], size: 'minmax(110px, auto)'},
            {columns: ['role'], size: 'minmax(130px, auto)'},
            {columns: ['app'], size: 'minmax(130px, auto)'},
            {columns: ['env'], size: 'minmax(130px, auto)'},
            {columns: ['loc'], size: 'minmax(130px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(120px, auto)'},
          {columns: ['mode'], size: 'minmax(90px, auto)'},
          {columns: ['vipPort'], size: 'minmax(150px, auto)'},
          {columns: ['manageState'], size: 'minmax(150px, auto)'},
          {columns: ['slb'], size: 'minmax(110px, auto)'},
          {columns: ['role'], size: 'minmax(130px, auto)'},
          {columns: ['app'], size: 'minmax(130px, auto)'},
          {columns: ['env'], size: 'minmax(130px, auto)'},
          {columns: ['loc'], size: 'minmax(130px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name', 'mode'], size: 'minmax(150px, auto)'},
            {columns: ['vipPort', 'slb'], size: 'minmax(150px, auto)'},
            {columns: ['role', 'app'], size: 'minmax(150px, auto)'},
            {columns: ['env', 'loc'], size: 'minmax(150px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name', 'mode'], size: 'minmax(150px, auto)'},
          {columns: ['vipPort', 'slb', 'manageState'], size: 'minmax(150px, auto)'},
          {columns: ['role', 'app'], size: 'minmax(150px, auto)'},
          {columns: ['env', 'loc'], size: 'minmax(150px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'mode', 'status'], size: 'minmax(100px, auto)'},
            {columns: ['vipPort', 'slb', 'manageState'], size: 'minmax(100px, auto)'},
            {columns: ['role', 'app'], size: 'minmax(120px, auto)'},
            {columns: ['env', 'loc'], size: 'minmax(120px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'mode', 'status'], size: 'minmax(100px, auto)'},
          {columns: ['vipPort', 'slb', 'manageState'], size: 'minmax(100px, auto)'},
          {columns: ['role', 'app'], size: 'minmax(120px, auto)'},
          {columns: ['env', 'loc'], size: 'minmax(120px, auto)'},
        ];
      },
    },
    {
      maxWidth: 660,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'mode', 'status'], size: 'minmax(150px, auto)'},
            {columns: ['vipPort', 'slb', 'manageState'], size: 'minmax(100px, auto)'},
            {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(150px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'mode', 'status'], size: 'minmax(150px, auto)'},
          {columns: ['vipPort', 'slb', 'manageState'], size: 'minmax(100px, auto)'},
          {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(150px, auto)'},
        ];
      },
    },
    {
      maxWidth: 500,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'mode', 'vipPort', 'slb', 'manageState', 'status'], size: 'minmax(150px, auto)'},
            {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(150px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'mode', 'vipPort', 'slb', 'manageState', 'status'], size: 'minmax(150px, auto)'},
          {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(150px, auto)'},
        ];
      },
    },
  ],
}));
