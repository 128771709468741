/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {AttributeList, Button, Notifications, ToolBar, ToolGroup, Grid} from 'components';
import fileSaver from 'file-saver';
import {getContainerWorkloadRulesPage} from './ContainerWorkloadRulesState';
import {fetchContainerWorkloadRules} from './ContainerWorkloadRulesSaga';

@connect(getContainerWorkloadRulesPage)
export default class ContainerWorkloadRules extends Component {
  static prefetch = fetchContainerWorkloadRules;

  constructor(props) {
    super(props);

    this.handleDownload = this.handleDownload.bind(this);
  }

  handleDownload() {
    const {ruleView, containerWorkload} = this.props;

    const fileName = `${containerWorkload.name}Rules.txt`;
    const blob = new Blob([JSON.stringify(ruleView)], {type: 'text/plain;charset=utf-8'});

    fileSaver.saveAs(blob, fileName, true);
  }

  render() {
    const {inboundGrid, outboundGrid, customIpGrid} = this.props;

    return (
      <>
        <Notifications sidebar>
          {[
            {
              type: 'instruction',
              title: intl('Policy.ActiveVersion'),
              message: intl('Policy.ViewingActiveVersion'),
            },
          ]}
        </Notifications>
        <ToolBar>
          <ToolGroup>
            <Button
              textIsHideable
              icon="export"
              text={intl('Common.ExportJSON')}
              autoFocus
              onClick={this.handleDownload}
              tid="export"
            />
          </ToolGroup>
        </ToolBar>
        <AttributeList>
          {[
            {title: intl('Rulesets.Rules.Inbound')},
            {content: <Grid grid={inboundGrid} tid="inbound" emptyMessage={intl('Rulesets.Rules.NoInboundRules')} />},

            {title: intl('Rulesets.Rules.Outbound')},
            {
              content: (
                <Grid grid={outboundGrid} tid="outbound" emptyMessage={intl('Rulesets.Rules.NoOutboundRules')} />
              ),
            },

            {title: intl('Help.Title.CustomiptablesRules')},
            {content: <Grid grid={customIpGrid} tid="custom" emptyMessage={intl('Common.NoData')} />},
          ]}
        </AttributeList>
      </>
    );
  }
}
