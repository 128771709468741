/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Address4} from 'ip-address';
import {isValidNumber} from 'utils/general';
import {portProtocolRegex, portProtocolMap} from 'utils/port';
import {isValidIPv6} from 'utils/ip';
import {portUtils} from '../../utils';

const regex = {
  workloads: /\/workloads\//,
  containerWorkloads: /\/container_workloads\//,
};

// Virtual Service list presentation
/**
 * Combine the IP/DNS values to a object.
 * Pure IP addresses are in:
 *   "ip_overrides": ["192.168.2.3"]
 * IP with BRN and FQDN are in:
 *   "service_addresses": [
 *     {"ip": "10.0.0.1", "network":
 *       {"name": "feqa1-openshift-cluster1 container network", "href": "/orgs/1/networks/5b393d46-c661-4902-a194-7d8f2028489e"}},
 *     {"ip": "10.0.2.0/16", "network":
 *       {"name": "Private", "href": "/orgs/1/networks/5b393d46-c661-4902-a194-7d8f2028489f"}},
 *     {"fqdn": "fqdn1.mycompany.com"},
 *     {"fqdn": "fqdn2.mycompany.com"}
 *   ]
 * The output will be:
 *   ["192.168.2.3", "10.0.0.1 "feqa1-openshift-cluster1 container network"", "10.0.2.0/16 "Private"",
 *    "fqdn1.mycompany.com", "fqdn2.mycompany.com"],
 * @param data
 * @returns {ipOverrides, serviceIps, serviceFqdns}
 */
export const getVirtualServiceAddressObj = data => {
  if (!data) {
    return {
      ipOverrides: [],
      serviceIps: [],
      serviceFqdns: [],
    };
  }

  const ipOverrides = new Set(Array.isArray(data.ip_overrides) ? [...data.ip_overrides].sort() : []); // Sort and remove duplicates

  if (!Array.isArray(data.service_addresses)) {
    return {
      ipOverrides: [...ipOverrides],
      serviceIps: [],
      serviceFqdns: [],
    };
  }

  const serviceIps = new Map(); // Use map to remove the duplicate display values for "ip + BRN href" as key
  const serviceFqdns = new Set(); // Use set to remove duplicates, values are unsorted

  data.service_addresses.forEach(serviceAddressObj => {
    if (serviceAddressObj.ip) {
      let key = serviceAddressObj.ip;
      let value = serviceAddressObj.ip;

      if (serviceAddressObj.port) {
        if (isValidIPv6(serviceAddressObj.ip)) {
          key = `[${key}]:${serviceAddressObj.port}`;
          value = `[${value}]:${serviceAddressObj.port}`;
        } else {
          key += `:${serviceAddressObj.port}`;
          value += `:${serviceAddressObj.port}`;
        }
      }

      if (serviceAddressObj.network && serviceAddressObj.network.name && serviceAddressObj.network.href) {
        key += `"${serviceAddressObj.network.href}"`; // ip + BRN href is the key
        value += ` "${serviceAddressObj.network.name}"`; // ip + BRN name is the value
      }

      if (!serviceIps.has(key)) {
        serviceIps.set(key, value);
      }
    }

    if (serviceAddressObj.fqdn) {
      if (serviceAddressObj.port) {
        serviceFqdns.add(`${serviceAddressObj.fqdn}:${serviceAddressObj.port}`);
      } else {
        serviceFqdns.add(serviceAddressObj.fqdn);
      }
    }
  });

  return {
    ipOverrides: [...ipOverrides],
    serviceIps: [...serviceIps.values()].sort(),
    serviceFqdns: [...serviceFqdns].sort(),
  };
};

export const getVirtualServiceAddresses = data => {
  const {ipOverrides, serviceIps, serviceFqdns} = getVirtualServiceAddressObj(data);

  return [...ipOverrides, ...serviceIps, ...serviceFqdns];
};

/** Use static method isValid() to check if ip4 is valid. Don't need instance of Address4 */
const getIpType = ip => (Address4.isValid(ip) ? 'ip4' : 'ip6');

// Populates Virtual Services item grid from API data
export const getVirtualServiceAddressAsRows = (data = {}) => {
  const {ip_overrides: ipOverrides = [], service_addresses: serviceAddresses = []} = data;
  const existKey = [];
  const result = [...new Set(ipOverrides)].map(item => {
    existKey.push(item);

    return {address: item, type: getIpType(item)};
  });

  serviceAddresses.forEach(({ip, network, fqdn, description, port}) => {
    const portString = port ? `:${port}` : '';

    if (ip) {
      if (network) {
        if (!existKey.includes(ip + network.href + portString)) {
          existKey.push(ip + network.href + portString);
          result.push({address: ip, network, port, type: getIpType(ip)});
        }
      } else if (!existKey.includes(ip + portString)) {
        existKey.push(ip + portString);
        result.push({address: ip, port, type: getIpType(ip)});
      }
    } else if (!existKey.includes(fqdn + portString)) {
      existKey.push(fqdn + portString);
      result.push({address: fqdn, port, description, type: 'domain'});
    }
  });

  return result;
};

export const getObjectCount = (hrefArray, id) => {
  let count = 0;

  if (Array.isArray(hrefArray)) {
    hrefArray.forEach(href => {
      if (href && href.href && regex[id].test(href.href)) {
        count++;
      }
    });
  }

  return count;
};

export const validatePortSearch = (key, query, items = []) => {
  const result = [];
  const portProtocolMapObj = portProtocolMap();

  if (portUtils.isValidPort(query)) {
    const queryNum = Number(query);

    if (!items.some(item => item.categoryKey === key && item.value === `${query} ${portProtocolMapObj.tcp.text}`)) {
      result.push({
        value: `${queryNum} ${portProtocolMapObj.tcp.text}`,
        detail: {proto: portProtocolMapObj.tcp.value, port: queryNum},
      });
    }

    if (!items.some(item => item.categoryKey === key && item.value === `${query} ${portProtocolMapObj.udp.text}`)) {
      result.push({
        value: `${queryNum} ${portProtocolMapObj.udp.text}`,
        detail: {proto: portProtocolMapObj.udp.value, port: queryNum},
      });
    }
  } else {
    const values = query.split(/\s+/).map(item => item.trim());

    if (
      portProtocolRegex.tcpPortProtocol.test(query) &&
      portUtils.isValidPort(values[0]) &&
      !items.some(item => item.categoryKey === key && item.value === `${values[0]} ${portProtocolMapObj.tcp.text}`)
    ) {
      result.push({
        value: `${values[0]} ${portProtocolMapObj.tcp.text}`,
        detail: {proto: portProtocolMapObj.tcp.value, port: Number(values[0])},
      });
    } else if (
      portProtocolRegex.udpPortProtocol.test(query) &&
      portUtils.isValidPort(values[0]) &&
      !items.some(item => item.categoryKey === key && item.value === `${values[0]} ${portProtocolMapObj.udp.text}`)
    ) {
      result.push({
        value: `${values[0]} ${portProtocolMapObj.udp.text}`,
        detail: {proto: portProtocolMapObj.udp.value, port: Number(values[0])},
      });
    }
  }

  return result;
};

export const validatePortRangeSearch = (key, query, items = []) => {
  const result = [];
  const portProtocolMapObj = portProtocolMap();
  const ranges = query.split('-').map(item => item.trim());

  if (ranges.length === 2 && isValidNumber(ranges[0])) {
    const valueStart = Number(ranges[0]);

    if (isValidNumber(ranges[1])) {
      const valueEnd = Number(ranges[1]);

      if (valueStart < valueEnd) {
        const range = `${valueStart}-${valueEnd}`;

        if (!items.some(item => item.categoryKey === key && item.value === `${range} ${portProtocolMapObj.tcp.text}`)) {
          result.push({
            value: `${range} ${portProtocolMapObj.tcp.text}`,
            detail: {proto: portProtocolMapObj.tcp.value, port: valueStart, to_port: valueEnd},
          });
        }

        if (!items.some(item => item.categoryKey === key && item.value === `${range} ${portProtocolMapObj.udp.text}`)) {
          result.push({
            value: `${range} ${portProtocolMapObj.udp.text}`,
            detail: {proto: portProtocolMapObj.udp.value, port: valueStart, to_port: valueEnd},
          });
        }
      }
    } else {
      const values = ranges[1].split(/\s+/).map(item => item.trim());

      if (isValidNumber(values[0])) {
        const valueEnd = Number(values[0]);
        const range = `${valueStart}-${valueEnd}`;

        if (valueStart < valueEnd) {
          if (
            portProtocolRegex.tcpPortProtocol.test(ranges[1]) &&
            !items.some(item => item.categoryKey === key && item.value === `${range} ${portProtocolMapObj.tcp.text}`)
          ) {
            result.push({
              value: `${range} ${portProtocolMapObj.tcp.text}`,
              detail: {proto: portProtocolMapObj.tcp.value, port: valueStart, to_port: valueEnd},
            });
          } else if (
            portProtocolRegex.udpPortProtocol.test(ranges[1]) &&
            !items.some(item => item.categoryKey === key && item.value === `${range} ${portProtocolMapObj.udp.text}`)
          ) {
            result.push({
              value: `${range} ${portProtocolMapObj.udp.text}`,
              detail: {proto: portProtocolMapObj.udp.value, port: Number(values[0])},
            });
          }
        }
      }
    }
  }

  return result;
};

export const applyToOptions = [
  {value: 'host_only', label: intl('VirtualServices.HostOnly')},
  {value: 'internal_bridge_network', label: intl('VirtualServices.InternalBridgeNetwork')},
];

export const createAddressRow = ({address, network, description, type, updateType, key, port}) => ({
  key:
    key ||
    (type === 'ip6' && port ? `[${address}]` : address) + (port ? `:${port}` : '') + (network ? network.href : ''),
  selectable: updateType !== 'delete',
  removable: updateType !== 'delete',
  data: {
    address,
    network,
    description,
    type,
    updateType,
    port,
  },
});

export const generateRowHash = ({address, network, port, description, type}) => {
  if (type === 'ip6' && port) {
    address = `[${address}]`;
  }

  return address + (port ? `:${port}` : '') + (network ? network.href : '') + (description || '') + type;
};

export const getDuplicatedValues = list => [...new Set(list.filter((item, index) => index > list.indexOf(item)))];

export const createdByPceSystem = item => item && item.created_by?.href === '/users/0';
