/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {RedirectError} from 'errors';
import {hrefUtils} from 'utils';
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import {getSecureGateways, getSecureGatewaysCount, getGridSettings} from './SecureGatewayListState';
import {isEdge} from 'containers/App/AppState';
import gridSaga from 'components/Grid/GridSaga';
import {isSecureGatewayEnabled} from '../SecureGatewayState';

export function* fetchSecureGateways({pversion = 'draft', force = false} = {}) {
  return yield call(apiSaga, 'secure_connect_gateways.get_collection', {
    query: {max_results: 500},
    params: {pversion},
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getSecureGateways)) || count !== (yield select(getSecureGatewaysCount))) {
        yield put({type: 'SECURE_CONNECT_GATEWAYS_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* removeSecureGateway({href, pversion = 'draft'}) {
  yield call(apiSaga, 'secure_connect_gateway.delete', {
    params: {pversion, secure_connect_gateway_id: hrefUtils.getId(href)},
    hrefs: [href],
  });
}

export function* fetchSecureGatewayList(route, refetch = false) {
  const secureGatewayIsEnabled = yield select(isSecureGatewayEnabled);
  const edgeEnabled = yield select(isEdge);

  if (!secureGatewayIsEnabled || edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    *onSaga() {
      const [{list}] = yield all([call(fetchSecureGateways, {force: refetch})]);

      return list.length;
    },
  });
}
