/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import intl from 'intl';
import {Icon, StatusIcon} from 'components';
import {UserName} from 'containers';
import * as GridUtils from 'components/Grid/GridUtils';
import {noScopeLabels} from 'containers/Selectors/SelectorUtils';
import {enforcementModeView, visibilityLevelView} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {formatAllowLabels, managementView} from '../../ContainerClusterDetailUtils';
import {hrefUtils} from 'utils';

export const objectMap = {
  container_workload_profiles: {type: 'container_workload_profiles'},
  roleLabels: {type: 'labels', key: 'role'},
  appLabels: {type: 'labels', key: 'app'},
  envLabels: {type: 'labels', key: 'env'},
  locLabels: {type: 'labels', key: 'loc'},
};

export const getSelectorSettings = createSelector([], () => ({
  objectMap,
  facetMap: {
    name: {value: intl('Common.Name'), object: objectMap.container_workload_profiles},
    namespace: {value: intl('Common.Namespace'), object: objectMap.container_workload_profiles},
  },
  staticMap: {
    enforcement_mode: intl('Common.Enforcement'),
  },
  scopeMap: {
    role: {value: intl('Labels.RoleLabels'), object: objectMap.roleLabels, scope: true, statics: noScopeLabels().role},
    app: {
      value: intl('Labels.ApplicationLabels'),
      object: objectMap.appLabels,
      scope: true,
      statics: noScopeLabels().app,
    },
    env: {
      value: intl('Labels.EnvironmentLabels'),
      object: objectMap.envLabels,
      scope: true,
      statics: noScopeLabels().env,
    },
    loc: {value: intl('Labels.LocationLabels'), object: objectMap.locLabels, scope: true, statics: noScopeLabels().loc},
  },
  filterMap: {
    name: {value: intl('Common.Name'), object: objectMap.container_workload_profiles},
    namespace: {value: intl('Common.Namespace'), object: objectMap.container_workload_profiles},
    enforcement_mode: intl('Common.Enforcement'),
    role: {value: intl('Labels.RoleLabels'), object: objectMap.roleLabels, scope: true, statics: noScopeLabels().role},
    app: {
      value: intl('Labels.ApplicationLabels'),
      object: objectMap.appLabels,
      scope: true,
      statics: noScopeLabels().app,
    },
    env: {
      value: intl('Labels.EnvironmentLabels'),
      object: objectMap.envLabels,
      scope: true,
      statics: noScopeLabels().env,
    },
    loc: {value: intl('Labels.LocationLabels'), object: objectMap.locLabels, scope: true, statics: noScopeLabels().loc},
  },
  staticValues: {
    enforcement_mode: {
      [intl('Workloads.Full')]: 'full',
      [intl('Workloads.EnforcementBoundaries')]: 'selective',
      [intl('Common.VisibilityOnly')]: 'visibility_only',
      [intl('Common.Idle')]: 'idle',
    },
  },
}));

const labelOrAllowListColumn = {
  value: ({row, column}) => {
    if (row.data.labels?.length) {
      return GridUtils.clickableLabelColumn.value({row, column});
    }

    return GridUtils.clickableLabelColumn.value({row, column});
  },
  refs: GridUtils.clickableLabelColumn.refs,
  onMouseOver: GridUtils.clickableLabelColumn.onMouseOver,
  format: ({row, value, refs, column}) => {
    // allow is-an array, and may be empty.
    if (!value?.hasOwnProperty('assignment')) {
      return formatAllowLabels(value, column.id, row.data.managed);
    }

    // assign is-a plain object
    const assignLabel = {key: value.key, id: hrefUtils.getId(value.assignment.href), ...value.assignment};

    return GridUtils.clickableLabelColumn.format({value: assignLabel, refs});
  },
  sort: ({column, row}) => {
    const {labels, managed} = row.data;
    // fn causes very unlike-items to sort in a predictable order.
    // the variety of data types cannot be sorted alphabetically or numerically:
    // multiple text labels, empty [], undefined and "cannot have labels, because it's unmanaged".

    if (managed === false) {
      return -1; // causes unmanaged float to the top. they need attention most
    }

    const key = column.id;

    if (_.isEmpty(labels) || !labels[key]) {
      return 0; // empty sorts after unmanaged, and before alpha
    }

    const labelByType = labels[key];

    // label has text, so it sorts alphabetically, `after the exceptional types` above
    return labelByType?.assignment ? labelByType.assignment.value : String(labelByType.restriction.length);
  },
};

/**
[{
  header: string | Function,
  key: string | Function,
  value: string | Function,
  format: node | Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
*/
export const gridSettings = createSelector([], () => ({
  id: 'cclusterprofilelist',
  sort: 'namespace',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    linked: {
      headerManager: intl('ContainerClusters.ProfileLink'),
      value: ({row}) => (row.data.linked ? intl('Common.Linked') : intl('Common.Pending')),
      format: ({row, value}) =>
        row.data.linked ? <Icon name="pair" title={value} /> : <StatusIcon status="pending" title={value} />,
    },
    name: {
      header: intl('Common.Name'),
      value: 'name',
      linky: true,
      required: true,
    },
    namespace: {
      // header is dynamic generated based on container cluster type, which is defined in ContainerClusterContainerWorkloadProfileListState
      value: 'namespace',
      required: true,
    },
    enforcementMode: {
      header: intl('Common.Enforcement'),
      value: ({row}) => enforcementModeView()[row.data.enforcement_mode].name,
    },
    visibilityLevel: {
      header: intl('Common.Visibility'),
      value: ({row}) =>
        row.data.enforcement_mode === 'idle'
          ? visibilityLevelView()[row.data.enforcement_mode].name
          : visibilityLevelView()[row.data.visibility_level].name,
    },
    management: {
      header: intl('Common.Management'),
      value: ({row}) => managementView(row.data.managed).name,
    },
    role: {
      header: intl('Common.Role'),
      ...labelOrAllowListColumn,
    },
    app: {
      header: intl('Common.Application'),
      ...labelOrAllowListColumn,
    },
    env: {
      header: intl('Common.Environment'),
      ...labelOrAllowListColumn,
    },
    loc: {
      header: intl('Common.Location'),
      ...labelOrAllowListColumn,
    },
    updatedAt: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.LastModifiedOn'),
      value: ({row}) => row.data.updated_at || row.data.created_at || null,
    },
    updatedBy: GridUtils.clickableColumn({
      header: intl('Common.LastModifiedBy'),
      value: ({row}) => row.data.updated_by.username,
      format: ({row, clickableRef}) => <UserName user={row.data.updated_by} ref={clickableRef} />,
    }),
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to format function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to format function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to format function
    data: ?object,
  }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['linked'], size: 'max-content'},
      {columns: ['name'], size: 'minmax(120px, auto)'},
      {columns: ['namespace'], size: 'minmax(120px, auto)'},
      {columns: ['enforcementMode'], size: 'minmax(120px, auto)'},
      {columns: ['visibilityLevel'], size: 'minmax(120px, auto)'},
      {columns: ['management'], size: 'minmax(120px, auto)'},
      {columns: ['role'], size: 'minmax(80px, auto)'},
      {columns: ['app'], size: 'minmax(100px, auto)'},
      {columns: ['env'], size: 'minmax(120px, auto)'},
      {columns: ['loc'], size: 'minmax(90px, auto)'},
      {columns: ['updatedAt'], size: 'minmax(100px, auto)'},
      {columns: ['updatedBy'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 1400,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['linked'], size: 'max-content'},
          {columns: ['name'], size: 'minmax(120px, auto)'},
          {columns: ['namespace'], size: 'minmax(120px, auto)'},
          {columns: ['enforcementMode'], size: 'minmax(120px, auto)'},
          {columns: ['visibilityLevel'], size: 'minmax(120px, auto)'},
          {columns: ['management'], size: 'minmax(120px, auto)'},
          {columns: ['role'], size: 'minmax(80px, auto)'},
          {columns: ['app'], size: 'minmax(100px, auto)'},
          {columns: ['env'], size: 'minmax(120px, auto)'},
          {columns: ['loc'], size: 'minmax(90px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1200,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['linked'], size: 'max-content'},
          {columns: ['name'], size: 'minmax(120px, auto)'},
          {columns: ['namespace'], size: 'minmax(120px, auto)'},
          {columns: ['enforcementMode'], size: 'minmax(120px, auto)'},
          {columns: ['visibilityLevel'], size: 'minmax(120px, auto)'},
          {columns: ['management'], size: 'minmax(120px, auto)'},
          {columns: ['role'], size: 'minmax(80px, auto)'},
          {columns: ['app'], size: 'minmax(100px, auto)'},
          {columns: ['env'], size: 'minmax(120px, auto)'},
          {columns: ['loc'], size: 'minmax(90px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['linked'], size: 'max-content'},
          {columns: ['name'], size: 'minmax(120px, auto)'},
          {columns: ['namespace'], size: 'minmax(120px, auto)'},
          {columns: ['enforcementMode', 'visibilityLevel', 'management'], size: 'minmax(120px, auto)'},
          {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(120px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['linked'], size: 'max-content'},
          {columns: ['name', 'role', 'app', 'env', 'loc'], size: 'minmax(120px, auto)'},
          {columns: ['enforcementMode', 'visibilityLevel', 'management'], size: 'minmax(120px, auto)'},
          {columns: ['namespace', 'updatedAt', 'updatedBy'], size: 'minmax(120px, auto)'},
        ];
      },
    },
  ],
}));
