/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Link, TypedMessages, Pill} from 'components';
import {reverseLookupProtocol} from 'containers/Service/ServiceUtils';
import {getNoLabelHref} from 'containers/Selectors/SelectorUtils';
import {networkTypeMap} from 'containers/Network/NetworkUtils';
import stylesUtils from 'utils.css';

export const extractGroupRoleLabel = group => group.scopes[0]?.find(({label}) => label?.key === 'role')?.label;

// Try parsing the external_data_set into an object
export const parseGroupExternalDataSet = group => {
  if (group?.external_data_set?.length) {
    try {
      return JSON.parse(group.external_data_set);
    } catch (error) {
      if (__DEV__) {
        console.warn(`Could not parse the ruleset.external_data_set with value: ${group.external_data_set}`);
        console.error(error);
      }
    }
  }

  return {};
};

export const noLabelsExistsScope = orgId => ({
  scope: [
    {key: 'role', href: getNoLabelHref(orgId, 'role')},
    {key: 'app', href: getNoLabelHref(orgId, 'app')},
    {key: 'env', href: getNoLabelHref(orgId, 'env')},
    {key: 'loc', href: getNoLabelHref(orgId, 'loc')},
  ],
});

export const DISCOVERED_GROUP = 'discovered';

export const lookupProtocol = protocol => {
  const protocols = {};
  const protocolNumber = reverseLookupProtocol(protocol);

  // This will need to change whenever we start allowing all protocols for just one process name
  if (protocolNumber !== -1) {
    protocols.proto = protocolNumber;
  }

  return protocols;
};

export const getGroupFilter = (rows, filter) => {
  if (filter) {
    return rows.filter(({status, data: {service, ipRanges, oldIpRanges, network_type, oldNetworkType}}) => {
      let filterServices;
      let filterIpRanges;
      let filterNetwork;
      const filterNetworkLength = filter?.networkType?.length;
      const filterServicesLength = filter?.service?.length;
      const filterIpRangesLength = filter?.ip_list?.length;

      if (filterNetworkLength > 0) {
        if (!status || status === 'add' || status === 'remove') {
          filterNetwork = networkTypeMap.get(filter.networkType[0]) === network_type;
        } else {
          filterNetwork =
            networkTypeMap.get(filter.networkType[0]) === network_type ||
            networkTypeMap.get(filter.networkType[0]) === oldNetworkType;
        }
      }

      if (filter?.service?.length > 0) {
        filterServices = filter.service.includes(service?.name) ?? true;
      }

      if (filter?.ip_list?.length > 0) {
        filterIpRanges =
          filter.ip_list.every(
            item =>
              ipRanges?.some(ipRangeItem => item === ipRangeItem?.name) ||
              oldIpRanges?.some(oldIpRangeItem => item === oldIpRangeItem?.name),
          ) ?? true;
      }

      if (!filterIpRangesLength && !filterNetworkLength) {
        return filterServices;
      }

      if (!filterServicesLength && !filterNetworkLength) {
        return filterIpRanges;
      }

      if (!filterIpRangesLength && !filterServicesLength) {
        return filterNetwork;
      }

      if (filterIpRangesLength && filterNetworkLength && !filterServicesLength) {
        return filterIpRanges && filterNetwork;
      }

      if (filterIpRangesLength && filterServicesLength && !filterNetworkLength) {
        return filterIpRanges && filterServices;
      }

      if (filterNetworkLength && filterServicesLength && !filterIpRangesLength) {
        return filterNetwork && filterServices;
      }

      return filterServices && filterIpRanges && filterNetwork;
    });
  }

  return rows;
};

export const getAdminGroupFilter = (rows, filter) => {
  //TODO: Fix Brute force algorithm
  if (filter) {
    rows = rows.filter(({status, data: {encrypt, oldEncrypt, service, adminGroups, oldAdminGroups}}) => {
      let filterServices;
      let filterAdminGroups;
      let filterEncrypt;
      const filterAdminGroupsLength = filter?.adminGroups?.length;
      const filterServicesLength = filter?.service?.length;
      const filterEncryptLength = filter?.encryption?.length;

      if (filterEncryptLength > 0) {
        if (!status || status === 'add' || status === 'remove') {
          filterEncrypt = filter.encryption[0] === encrypt;
        } else {
          filterEncrypt = filter.encryption[0] === encrypt || filter.encryption[0] === oldEncrypt;
        }
      }

      if (filterServicesLength > 0) {
        filterServices = filter.service.includes(service?.name) ?? true;
      }

      if (filterAdminGroupsLength > 0) {
        filterAdminGroups =
          filter.adminGroups.every(
            item =>
              adminGroups?.some(adminGroupItem => item === adminGroupItem?.value) ||
              oldAdminGroups?.some(oldAdminGroupItem => item === oldAdminGroupItem?.value),
          ) ?? true;
      }

      if (!filterAdminGroupsLength && !filterEncryptLength) {
        return filterServices;
      }

      if (!filterServicesLength && !filterEncryptLength) {
        return filterAdminGroups;
      }

      if (!filterAdminGroupsLength && !filterServicesLength) {
        return filterEncrypt;
      }

      if (filterAdminGroupsLength && filterEncryptLength && !filterServicesLength) {
        return filterAdminGroups && filterEncrypt;
      }

      if (filterAdminGroupsLength && filterServicesLength && !filterEncryptLength) {
        return filterAdminGroups && filterServices;
      }

      if (filterEncryptLength && filterServicesLength && !filterAdminGroupsLength) {
        return filterEncrypt && filterServices;
      }

      return filterServices && filterAdminGroups && filterEncrypt;
    });
  }

  return rows;
};

const groupRemovalErrorsMap = {
  InUseByPolicy: ['label_still_has_associated_rule_set', 'label_still_has_associated_enforcement_boundary'],
  InUseByEndpoint: ['label_still_has_associated_agent_info'],
};

const groupRemovalErrors = {
  InUseByPolicy: ({id: groupId, name, administeredGroups, adminsteredGroupsCount}) => {
    const errors = [
      {content: intl('Edge.Group.InUseByPolicy'), alignWithIcon: true},
      {
        content: (
          <Link target="_blank" to="groups.view" params={{group: groupId, tab: 'inboundpolicy'}}>
            {intl('Policy.Inbound')}
          </Link>
        ),
        alignWithIcon: true,
      },
      {
        content: (
          <Link target="_blank" to="groups.view" params={{group: groupId, tab: 'outboundpolicy'}}>
            {intl('Policy.Outbound')}
          </Link>
        ),
        alignWithIcon: true,
      },
      {
        content: (
          <Link target="_blank" to="groups.view" params={{group: groupId, tab: 'admin'}}>
            {intl('Edge.AdminGroup.AdminAccess')}
          </Link>
        ),
        alignWithIcon: true,
      },
    ];

    if (adminsteredGroupsCount > 0) {
      errors.push({
        content: (
          <>
            {intl('Edge.AdminGroup.GroupsAdministered', {groupName: name}, {html: true})}
            <div className={`${stylesUtils.gapSmall} ${stylesUtils.gapAlignStart}`}>
              {administeredGroups.map(item => (
                <Pill.Group queryParams={{tab: 'admin'}} link={{target: '_blank'}} value={item} key={item.href} />
              ))}
            </div>
          </>
        ),
        alignWithIcon: true,
      });
    }

    return errors;
  },
  InUseByEndpoint: ({id: groupId}) => [
    {
      content: (
        <>
          {`${intl('Edge.Group.InUseByEndpoint')} `}
          <Link target="_blank" to="groups.view" params={{group: groupId, tab: 'workloadlist'}}>
            {intl('Edge.ViewEndpoints')}
          </Link>
        </>
      ),
      alignWithIcon: true,
    },
  ],
  UnknownError: () => [
    {
      content: intl('Edge.Group.UnknownError'),
      alignWithIcon: true,
    },
  ],
};

export const getGroupError = (group, type) => groupRemovalErrors[type](group);

export const removeGroupModalConfig = targetGroup => ({
  title: intl('Edge.Group.RemoveGroupTitle', {groupName: targetGroup.name}, {html: true}),
  confirmMessage: intl('Edge.Group.RemoveGroupContent', {groupName: targetGroup.name}, {html: true}),
  submitProps: {tid: 'confirm', text: intl('Common.Confirm')},
  error: {
    customErrorMessage: ({errors}) => {
      const errorMessages = new Map();

      if (Array.isArray(errors)) {
        errors.forEach(error => {
          if (groupRemovalErrorsMap.InUseByPolicy.includes(error.token)) {
            if (!errorMessages.get('InUseByPolicy')) {
              errorMessages.set('InUseByPolicy', getGroupError(targetGroup, 'InUseByPolicy'));
            }
          } else if (groupRemovalErrorsMap.InUseByEndpoint.includes(error.token)) {
            if (!errorMessages.get('InUseByEndpoint')) {
              errorMessages.set('InUseByEndpoint', getGroupError(targetGroup, 'InUseByEndpoint'));
            }
          } else if (!errorMessages.get('UnknownError')) {
            errorMessages.set('UnknownError', getGroupError(targetGroup, 'UnknownError'));
          }
        });
      }

      return (
        <>
          <TypedMessages>{[{icon: 'error', content: intl('Edge.Group.UnableToRemove')}]}</TypedMessages>
          <TypedMessages gap="gapXSmall">{Array.from(errorMessages.values()).flat()}</TypedMessages>
        </>
      );
    },
  },
});
