/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Badge, Button, Link} from 'components';
import {UserName} from 'containers';
import styles from './VersionList.css';
import stylesGrid from 'components/Grid/Grid.css';
import {hrefUtils} from 'utils';
import * as GridUtils from 'components/Grid/GridUtils';
import {getTypeAndRoute, outboundOrgPolicyObjType} from 'containers/Provisioning/ProvisioningUtils';
import {edge} from 'api/apiUtils';
import {createSelector} from 'reselect';

const pendingGridId = 'pendinglist';
const clickableNumColumn = GridUtils.clickableColumn({
  format: ({row, column, value, breakpoint, clickableRef}) => {
    if ((typeof value === 'number' && value > 0) || value === outboundOrgPolicyObjType) {
      let text = value;

      if (breakpoint.maxWidth <= 2100 && typeof column.displayValue === 'function') {
        text = column.displayValue(value);
      }

      if (row.data.special === 'draft' && clickableRef) {
        return (
          <Link
            to="pending"
            params={{[pendingGridId]: {filter: {type: [getTypeAndRoute(column.filter).typeLabel]}}}}
            ref={clickableRef}
          >
            {text}
          </Link>
        );
      }

      return text;
    }

    return null;
  },
});

export const gridSettings = createSelector([], () => ({
  id: 'versionlist',
  sort: '-version',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    version: {
      header: intl('Common.Version'),
      value: 'version',
      format: ({row, value, selected}) => {
        if (row.data.special === 'active') {
          return (
            <>
              <Badge type="created" theme={styles} themePrefix="status-">
                {intl('Common.Active')}
              </Badge>
              <div className={stylesGrid.linky}>{value}</div>
            </>
          );
        }

        if (row.data.special === 'draft') {
          return (
            <Badge type="preview" theme={styles} themePrefix="status-">
              {intl('Common.Draft')}
            </Badge>
          );
        }

        if (selected) {
          return (
            <>
              <Badge type="info" theme={styles} themePrefix="status-">
                {intl('Common.Selected')}
              </Badge>
              <div className={stylesGrid.linky}>{value}</div>
            </>
          );
        }

        return <div className={stylesGrid.linky}>{value}</div>;
      },
    },
    outbound: {
      header: intl('Policy.Organizational'),
      value: ({row}) => (row.data.object_counts ? row.data.object_counts.outbound : null),
      filter: outboundOrgPolicyObjType,
      displayValue: () => intl('Common.Outbound'),
      ...clickableNumColumn,
    },
    workloads: {
      header: intl('Version.WorkloadsAffected'),
      value: 'workloads_affected',
      displayValue: count => intl('Version.WorkloadsAffectedCount', {count}),
      ...clickableNumColumn,
    },
    rulesets: {
      header: edge ? intl('Common.Group') : intl('Common.Rulesets'),
      value: ({row}) => (row.data.object_counts ? row.data.object_counts.rule_sets : null),
      filter: 'rule_sets',
      displayValue: count => (edge ? intl('Common.GroupsCountNumber', {count}) : intl('Common.RulesetsCount', {count})),
      ...clickableNumColumn,
    },
    enforcementBoundaryRules: {
      header: intl('Workloads.EnforcementBoundaries'),
      value: ({row}) => (row.data.object_counts ? row.data.object_counts.enforcement_boundaries : null),
      filter: 'enforcement_boundaries',
      displayValue: count => intl('EnforcementBoundaries.RulesCount', {count}),
      ...clickableNumColumn,
    },
    ipLists: {
      header: edge ? intl('IPLists.Mixin.Ranges') : intl('Common.IPLists'),
      value: ({row}) => (row.data.object_counts ? row.data.object_counts.ip_lists : null),
      filter: 'ip_lists',
      displayValue: count => (edge ? intl('Common.IPRangesCount', {count}) : intl('Common.IPListsCount', {count})),
      ...clickableNumColumn,
    },
    services: {
      header: intl('Common.Services'),
      value: ({row}) => (row.data.object_counts ? row.data.object_counts.services : null),
      filter: 'services',
      displayValue: count => intl('Common.ServicesCount', {count}),
      ...clickableNumColumn,
    },
    labelGroups: {
      header: intl('Labels.Groups'),
      value: ({row}) => (row.data.object_counts ? row.data.object_counts.label_groups : null),
      filter: 'label_groups',
      displayValue: count => intl('Labels.GroupsCount', {count}),
      ...clickableNumColumn,
    },
    settings: {
      header: intl('Common.Settings'),
      filter: 'settings',
      value: ({row}) => (row.data.object_counts ? row.data.object_counts.settings : null),
      displayValue: count => intl('Common.SettingsCount', {count}),
      ...clickableNumColumn,
    },
    virtualServers: {
      header: intl('Common.VirtualServers'),
      value: ({row}) => (row.data.object_counts ? row.data.object_counts.virtual_servers : null),
      filter: 'virtual_servers',
      displayValue: count => intl('Common.VirtualServersCount', {count}),
      ...clickableNumColumn,
    },
    secureConnectGateways: {
      header: intl('Common.SecureConnectGateways'),
      value: ({row}) => (row.data.object_counts ? row.data.object_counts.secure_connect_gateways : null),
      filter: 'secure_connect_gateways',
      displayValue: count => intl('Common.SecureConnectGatewaysCount', {count}),
      ...clickableNumColumn,
    },
    virtualServices: {
      header: intl('Common.VirtualServices'),
      value: ({row}) => (row.data.object_counts ? row.data.object_counts.virtual_services : null),
      filter: 'virtual_services',
      displayValue: count => intl('Common.VirtualServicesCount', {count}),
      ...clickableNumColumn,
    },
    provisionedBy: GridUtils.clickableColumn({
      header: intl('Version.ProvisionedBy'),
      value: ({row}) => (row.data.special === 'draft' ? null : row.data.created_by?.username),
      format: ({row, clickableRef}) =>
        row.data.special === 'draft'
          ? null
          : row.data.created_by && <UserName user={row.data.created_by} ref={clickableRef} />,
    }),
    provisionedOn: {
      isDate: 'L_HH_mm_ss',
      header: intl('Version.ProvisionedOn'),
      value: 'created_at',
      format: ({row, value}) => {
        if (row.data.special === 'draft') {
          return <div className={stylesGrid.linky}>{intl('Provision.Pending')}</div>;
        }

        return value;
      },
    },
    note: {
      header: intl('Common.Note'),
      value: ({row}) => (row.data.special === 'draft' ? intl('Provision.PendingNote') : row.data.commit_message),
    },
    restore: GridUtils.clickableColumn({
      header: intl('Common.Restore'),
      value: ({row}) => (!row.data.special && !row.data.userIsReadOnly ? intl('Common.Restore') : null),
      format: ({value, row, clickableRef}) => {
        if (value) {
          return (
            <Button.Link
              color="primary"
              text={value}
              disabled={row.data.isUserReadOnlyClusterInsensitive}
              ref={clickableRef}
              link={{to: 'versions.item.restore', params: {pversion: hrefUtils.getId(row.key)}}}
            />
          );
        }

        return null;
      },
    }),
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to cellFormat function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to cellFormat function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to cellFormat function
    data: ?object,
  }];
   */
  templates: [
    [
      {columns: ['version'], size: 'max-content'},
      {columns: ['workloads', 'outbound'], size: 'minmax(100px, auto)'},
      {columns: ['rulesets'], size: 'minmax(100px, auto)'},
      {columns: ['ipLists'], size: 'minmax(100px, auto)'},
      {columns: ['services'], size: 'minmax(100px, auto)'},
      {columns: ['labelGroups'], size: 'minmax(100px, auto)'},
      {columns: ['settings'], size: 'minmax(100px, auto)'},
      {columns: ['virtualServers'], size: 'minmax(100px, auto)'},
      {columns: ['secureConnectGateways'], size: 'minmax(100px, auto)'},
      {columns: ['virtualServices'], size: 'minmax(100px, auto)'},
      {columns: ['enforcementBoundaryRules'], size: 'minmax(100px, auto)'},
      {columns: ['provisionedBy'], size: 'minmax(150px, auto)'},
      {columns: ['provisionedOn'], size: 'minmax(150px, auto)'},
      {columns: ['note'], size: 'minmax(150px, 300px)'},
      {columns: ['restore'], size: 'min-content'},
    ],
    {
      maxWidth: 2100,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['version'], size: 'max-content'},
            {columns: ['workloads', 'outbound'], size: 'minmax(100px, auto)'},
            {columns: ['rulesets', 'ipLists'], size: 'minmax(100px, auto)'},
            {columns: ['services', 'labelGroups'], size: 'minmax(100px, auto)'},
            {columns: ['settings', 'virtualServers'], size: 'minmax(100px, auto)'},
            {
              columns: ['secureConnectGateways', 'virtualServices', 'enforcementBoundaryRules'],
              size: 'minmax(100px, auto)',
            },
            {columns: ['provisionedBy', 'provisionedOn'], size: 'minmax(150px, auto)'},
            {columns: ['note'], size: 'minmax(150px, 300px)'},
            {columns: ['restore'], size: 'min-content'},
          ];
        }

        return [
          {columns: ['version'], size: 'max-content'},
          {columns: ['workloads', 'outbound'], size: 'minmax(100px, auto)'},
          {columns: ['rulesets', 'ipLists'], size: 'minmax(100px, auto)'},
          {columns: ['services', 'labelGroups'], size: 'minmax(100px, auto)'},
          {columns: ['settings', 'virtualServers'], size: 'minmax(100px, auto)'},
          {
            columns: ['secureConnectGateways', 'virtualServices', 'enforcementBoundaryRules'],
            size: 'minmax(100px, auto)',
          },
          {columns: ['provisionedBy', 'provisionedOn'], size: 'minmax(150px, auto)'},
          {columns: ['note'], size: 'minmax(150px, 300px)'},
          {columns: ['restore'], size: 'min-content'},
        ];
      },
    },
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['version', 'workloads', 'outbound', 'rulesets', 'ipLists'], size: 'minmax(130px, auto)'},
            {columns: ['services', 'labelGroups', 'settings'], size: 'minmax(120px, auto)'},
            {columns: ['virtualServers', 'secureConnectGateways', 'virtualServices'], size: 'minmax(120px, auto)'},
            {
              columns: ['enforcementBoundaryRules', 'provisionedBy', 'provisionedOn', 'note'],
              size: 'minmax(150px, auto)',
            },
            {columns: ['restore'], size: 'min-content'},
          ];
        }

        return [
          {columns: ['version', 'workloads', 'outbound', 'rulesets', 'ipLists'], size: 'minmax(130px, auto)'},
          {columns: ['services', 'labelGroups', 'settings'], size: 'minmax(120px, auto)'},
          {columns: ['virtualServers', 'secureConnectGateways', 'virtualServices'], size: 'minmax(120px, auto)'},
          {
            columns: ['enforcementBoundaryRules', 'provisionedBy', 'provisionedOn', 'note'],
            size: 'minmax(150px, auto)',
          },
          {columns: ['restore'], size: 'min-content'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['version', 'provisionedBy', 'provisionedOn', 'restore'], size: 'minmax(130px, auto)'},
            {
              columns: ['outbound', 'workloads', 'rulesets', 'ipLists', 'services', 'labelGroups'],
              size: 'minmax(150px, auto)',
            },
            {
              columns: [
                'settings',
                'virtualServers',
                'secureConnectGateways',
                'enforcementBoundaryRules',
                'virtualServices',
                'note',
              ],
              size: 'minmax(150px, auto)',
            },
          ];
        }

        return [
          {columns: ['version', 'provisionedBy', 'provisionedOn', 'restore'], size: 'minmax(130px, auto)'},
          {
            columns: ['outbound', 'workloads', 'rulesets', 'ipLists', 'services', 'labelGroups'],
            size: 'minmax(150px, auto)',
          },
          {
            columns: [
              'settings',
              'virtualServers',
              'secureConnectGateways',
              'enforcementBoundaryRules',
              'virtualServices',
              'note',
            ],
            size: 'minmax(150px, auto)',
          },
        ];
      },
    },
    {
      maxWidth: 500,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {
              columns: [
                'outbound',
                'version',
                'workloads',
                'rulesets',
                'ipLists',
                'provisionedBy',
                'provisionedOn',
                'restore',
              ],
              size: 'minmax(100px, auto)',
            },
            {
              columns: [
                'outbound',
                'services',
                'labelGroups',
                'settings',
                'virtualServers',
                'secureConnectGateways',
                'enforcementBoundaryRules',
                'virtualServices',
                'note',
              ],
              size: 'minmax(100px, auto)',
            },
          ];
        }

        return [
          {
            columns: [
              'outbound',
              'version',
              'workloads',
              'rulesets',
              'ipLists',
              'provisionedBy',
              'provisionedOn',
              'restore',
            ],
            size: 'minmax(100px, auto)',
          },
          {
            columns: [
              'outbound',
              'services',
              'labelGroups',
              'settings',
              'virtualServers',
              'secureConnectGateways',
              'enforcementBoundaryRules',
              'virtualServices',
              'note',
            ],
            size: 'minmax(100px, auto)',
          },
        ];
      },
    },
  ],
}));
