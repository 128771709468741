/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {call, select} from 'redux-saga/effects';
import {getContainerClusterDetail} from '../ContainerClusterDetailState';
import {fetchContainerWorkloadList} from 'containers/ContainerWorkload/List/ContainerWorkloadListSaga';

export function* fetchContainerClusterContainerWorkloads(route, refetch = false) {
  const detail = yield select(getContainerClusterDetail);

  if (detail && detail.href) {
    yield call(fetchContainerWorkloadList, route, refetch, {containerCluster: detail.href, ignoreScope: true});
  }
}
