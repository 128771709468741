/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {fetchContainerClusterVens} from './ContainerClusterVensSaga';
import VenList from 'containers/Ven/List/VenList';
import VenListStateReducers from 'containers/Ven/List/VenListState';
import SecuritySettingReducers from 'containers/SecuritySettings/SecuritySettingsState';
import WorkloadReducers from 'containers/Workload/WorkloadState';
import LabelReducers from 'containers/Label/LabelState';

export default class ContainerClusterVens extends PureComponent {
  static prefetch = fetchContainerClusterVens;
  static reducers = [VenListStateReducers, SecuritySettingReducers, WorkloadReducers, LabelReducers];

  render() {
    return <VenList gridOnly />;
  }
}
