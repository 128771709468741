/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {Component} from 'react';
import {select} from 'redux-saga/effects';
import {isCrowdstrike} from 'containers/App/AppState';
import {RedirectError} from 'errors';
import ReportsState from '../Reports/ReportsState';
import LabelState from '../Label/LabelState';
import PairingProfileReducers from './PairingProfileState';
import WorkloadReducers from '../Workload/WorkloadState';

export default class PairingProfile extends Component {
  static reducers = [ReportsState, LabelState, PairingProfileReducers, WorkloadReducers];
  static prefetch = function* () {
    const crowdstrike = yield select(isCrowdstrike);

    if (crowdstrike) {
      throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
    }
  };

  render() {
    return this.props.children;
  }
}
