/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import ContainerWorkloadList from 'containers/ContainerWorkload/List/ContainerWorkloadList';
import {fetchVenContainerWorkloads} from './VenContainerWorkloadsSaga';

export default class VenContainerWorkloads extends PureComponent {
  static prefetch = fetchVenContainerWorkloads;
  static contextType = AppContext;

  handleRefresh() {
    return this.context.fetcher.fork(fetchVenContainerWorkloads.refetch);
  }

  render() {
    return <ContainerWorkloadList onRefresh={this.handleRefresh} hideHeaderProps isInDetailPage />;
  }
}
