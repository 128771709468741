/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import PubSub from 'pubsub';
import {PureComponent} from 'react';
import {reactUtils, tidUtils} from 'utils';
import {Button, type ButtonProps} from 'components';
import type {MouseEventLike} from 'utils/dom';

export type ButtonRefreshProps = {
  //
  /**
   * Function to call on button press, can return promise
   */
  onRefresh: (evt: MouseEventLike) => void;
  tid?: string;
} & ButtonProps;

interface ButtonRefreshState {
  isRefreshing: boolean;
  error: null;
}

export default class ButtonRefresh extends PureComponent<ButtonRefreshProps, ButtonRefreshState> {
  unmounted?: boolean;

  constructor(props: ButtonRefreshProps) {
    super(props);

    this.state = {error: null, isRefreshing: false};
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  static getDerivedStateFromProps(_: Readonly<ButtonRefreshProps>, prevState: ButtonRefreshState) {
    if (prevState.isRefreshing && prevState.error) {
      // Reset error on refreshing
      return {error: null};
    }

    return null;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  private async handleRefresh(evt: MouseEventLike) {
    let error = null;

    try {
      // Publish refresh start event for QA
      PubSub.publish('PAGE.REFRESH_START');

      // Set flag to add progress style to button
      await reactUtils.setStateAsync({isRefreshing: true}, this);

      // onRefresh can return promise if it's async or any other value if it's sync (in that case it will not be waited)
      await this.props.onRefresh(evt);
    } catch (err) {
      error = err;
    }

    if (!this.unmounted) {
      await reactUtils.setStateAsync({error, isRefreshing: false}, this);
    }

    if (!this.unmounted) {
      // Publish refresh done event for QA
      PubSub.publish('PAGE.REFRESH_DONE');
    }
  }

  render() {
    const {tid, onRefresh, text = intl('Common.Refresh'), children, ...props} = this.props;
    const {error, isRefreshing} = this.state;

    return (
      <Button
        {...props}
        icon="refresh"
        text={text}
        title={text}
        onClick={this.handleRefresh}
        tid={tidUtils.getTid('refresh', tid)}
        progress={!error && isRefreshing}
        progressError={Boolean(error)}
      />
    );
  }
}
