/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {Pill, type PillProps} from 'components/index';
import {hrefUtils} from 'utils';
import {getUpdateTypeProps} from '../PillUtils';
import type {VirtualServer as VirtualServerData} from 'illumio';

interface VirtualServerProps extends PillProps {
  // Virtual Server object
  value: VirtualServerData;

  // policy version
  pversion?: string | number;
}

export default class VirtualServer extends PureComponent<VirtualServerProps> {
  static propTypes = {};

  element: HTMLElement | null = null;

  constructor(props: VirtualServerProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: Pill) {
    this.element = element?.element;
  }

  render() {
    const {value, pversion = 'draft', updateTypeTooltip, ...pillProps} = this.props;

    Object.assign(pillProps, {
      ref: this.saveRef,
      icon: 'virtual-server',
      link: pillProps.onClick
        ? null
        : {to: 'virtualServers.item.summary', params: {id: hrefUtils.getId(value.href), pversion}},
      ...getUpdateTypeProps({
        object: 'virtualServer',
        updateType: value.update_type,
        updateTypeTooltip,
        deleted: pillProps.deleted,
        pversion,
      }),
    });

    return <Pill {...pillProps}>{value.name}</Pill>;
  }
}
