/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {Grid, Button, ToolBar, ToolGroup} from 'components';
import {getGridData, prepareColumns} from 'components/Grid/GridUtils';
import {randomString} from 'utils/general';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import styleUtils from 'utils.css';
import styles from './EditorGrid.css';
import selectorStyles from 'containers/Selectors/Selectors.css';
import optionSelectorStyles from '../OptionSelector/OptionSelector.css';
import inputStyles from '../Input/Input.css';

const defaultAddButtonProps = {
  icon: 'add',
  tid: 'add',
  text: intl('Common.Add'),
  textIsHideable: true,
};
const defaultRemoveButtonProos = {
  icon: 'remove',
  color: 'standard',
  tid: 'remove',
  text: intl('Common.Remove'),
  textIsHideable: true,
  counterColor: 'red',
};
const removeRowHighLight = {className: stylesGridUtils.rowToRemove};
export default class EditorGrid extends PureComponent {
  static propTypes = {
    settings: PropTypes.object.isRequired, //Grid config
    rows: PropTypes.array.isRequired,
    errors: PropTypes.array,

    noAdd: PropTypes.bool, //hide Add Button
    noRemove: PropTypes.bool, //hide Remove Button

    onAddOrRemoveRows: PropTypes.func,

    gridProps: PropTypes.object, // object with custom props for grid
    addButtonProps: PropTypes.object, // object with custom props for add button, will be merged with defaultAddButtonProps
    removeButtonProps: PropTypes.object, // object with custom props for remove button, will be merged with defaultRemoveButtonProps
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedKeySet: new Set(),
      extraPropsKeyMap: new Map(),
      settings: {},
      rows: [],
      initialRows: props.rows,
      initialSettings: props.settings,
    };

    this.saveRef = this.saveRef.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleAddOrRemove = this.handleAddOrRemove.bind(this);
    this.handleRemoveRowEnter = this.handleRemoveRowEnter.bind(this);
    this.handleRemoveRowLeave = this.handleRemoveRowLeave.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.settings !== prevState.initialSettings ||
      nextProps.rows !== prevState.rows ||
      nextProps.errors !== prevState.errors
    ) {
      const nextState = {...nextProps};

      nextState.grid = getGridData({
        settings: nextState.settings,
        rows: nextState.rows.map((row, idx) => ({
          ...row,
          idx,
          errorMsg: nextState.errors?.[idx]?.rowError,
          modified: !_.isEqual(prevState.initialRows?.find(item => item.key === row.key)?.data, row.data),
        })),
        columns: prepareColumns(nextState.settings.columns),
        sort: nextState.settings.sort,
        capacity: nextState.settings.capacity,
      });

      if (prevState.selectedKeySet.size > 0) {
        // If page or sorting have changed, find selected items that remain on current page
        nextState.selectedKeySet = new Set();

        for (const row of nextState.rows) {
          if (row.selectable && prevState.selectedKeySet.has(row.key)) {
            nextState.selectedKeySet.add(row.key);
          }
        }
      }

      nextState.extraPropsKeyMap = new Map();

      return nextState;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows.length !== prevProps.rows.length) {
      // Keep the focus to the Grid when rows are added/removed
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (!this.grid?.contains(document.activeElement)) {
          this.grid
            ?.querySelector(`.${inputStyles.input}, .${optionSelectorStyles.selectorMain}, .${selectorStyles.selector}`)
            ?.focus();
        }
      });
    }
  }

  saveRef(grid) {
    this.grid = grid?.ref.current;
  }

  handleSelect({affectedRows, selecting}) {
    this.setState(state => {
      const selectedKeySet = new Set(state.selectedKeySet);

      for (const row of affectedRows) {
        selectedKeySet[selecting ? 'add' : 'delete'](row.key);
      }

      return {selectedKeySet};
    });
  }

  handleAddOrRemove(action) {
    const {rows, settings, onAddOrRemoveRows, selectedKeySet} = this.state;

    if (onAddOrRemoveRows) {
      return onAddOrRemoveRows({action, settings, selectedKeySetToRemove: selectedKeySet});
    }

    let newRows;

    if (action === 'add') {
      newRows = [settings.getInitialRow(randomString(5, true)), ...rows];
    } else {
      newRows = rows.filter(row => !selectedKeySet.has(row.key));
    }

    this.setState({rows: newRows});
  }

  handleRemoveRowEnter() {
    if (this.state.selectedKeySet.size) {
      this.setState(state => ({
        extraPropsKeyMap: new Map(Array.from(state.selectedKeySet, key => [key, removeRowHighLight])),
      }));
    }
  }

  handleRemoveRowLeave() {
    // Drop highlight if user moved out cursor and haven't opened remove dialog
    if (!this.state.remove && this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  render() {
    const {
      state: {selectedKeySet, extraPropsKeyMap, grid},
      props: {addButtonProps, removeButtonProps, gridProps, errors, noAdd, noRemove},
    } = this;

    return (
      <div className={styleUtils.gap}>
        {!noAdd && !noRemove && (
          <ToolBar>
            <ToolGroup>
              {!noAdd && (
                <Button
                  disabled={Boolean(errors)}
                  onClick={_.partial(this.handleAddOrRemove, 'add')}
                  {...defaultAddButtonProps}
                  {...addButtonProps}
                />
              )}
              {!noRemove && (
                <Button
                  counter={selectedKeySet.size}
                  disabled={!selectedKeySet.size}
                  onClick={_.partial(this.handleAddOrRemove, 'remove')}
                  onMouseEnter={this.handleRemoveRowEnter}
                  onMouseLeave={this.handleRemoveRowLeave}
                  {...defaultRemoveButtonProos}
                  {...removeButtonProps}
                />
              )}
            </ToolGroup>
          </ToolBar>
        )}
        <Grid
          ref={this.saveRef}
          localGrid
          inlineSize
          grid={grid}
          theme={styles}
          dontHighlightSelected={extraPropsKeyMap.size > 0}
          extraPropsKeyMap={extraPropsKeyMap}
          selectedKeySet={selectedKeySet}
          onSelect={this.handleSelect}
          {...gridProps}
        />
      </div>
    );
  }
}
