/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {useCallback, useState} from 'react';
import Icon from 'components/Icon/Icon';
import {EditorBlock, EditorState, SelectionState, ContentState} from 'draft-js';
import styles from './IPItem.css';

/**
 * Get the index (number) for each specific content block
 *
 * @param {object} props
 * @returns {number}
 */
export const useGetIndex = props => {
  let index = 0;
  const {blockMap, selectionKey} = props;

  // Find the index of each data key
  blockMap.forEach((data, dataKey) => {
    index++;

    if (selectionKey === dataKey) {
      // return false to break thus don't need to iterate the whole block when item is found
      // Note: This forEach is different from Array#forEach
      // refer: https://immutable-js.github.io/immutable-js/docs/#/OrderedMap/forEach
      return false;
    }

    return true;
  });

  return index;
};

function IPItem(props) {
  const {
    block,
    contentState,
    blockProps: {getEditorState, setReadOnly, showModified = false},
  } = props;
  const editorState = getEditorState();
  const selectionKey = block.getKey();
  const data = block.getData();
  const blockMap = contentState.getBlockMap();
  const [mouseHoverBlockKey, setMouseHoverBlockKey] = useState(null);
  const ip = data.get('ip');
  const text = block.getText();
  const textTrimmed = text.trim();

  const original = (ip && ip.get('original')) || null;

  /** originalText is used to compare the current User's input vs the original text
   *  When is editing mode, the modification icon will show if the original text has been modified or new row entry
   */
  let originalText = '';

  if (original) {
    if (original.has('text')) {
      originalText = original.get('text');
    } else {
      originalText = original.get('fqdn');
    }
  }

  // Delete block is used to delete each content block
  // Had to re-implement below example.
  // https://github.com/globocom/megadraft/blob/a16d4c2977d659387d79c85a5831d0d3813e8a70/src/components/Media.js
  const deleteBlock = () => {
    const {onChange} = props.blockProps;
    const selection = editorState.getSelection();
    const content = editorState.getCurrentContent();
    const keyAfter = content.getKeyAfter(block.key);

    // Delete a block by using current block key
    const blockMap = content.getBlockMap().delete(props.block.key);

    // New content to merge changed blockMap
    const newContent = content.merge({
      blockMap,
      selectionAfter: selection, // current deleted block
    });

    // Get the newEditorState by using EditorState.push() to save the current selectionState to the undo stack which
    // is used to undo.
    const newState = EditorState.push(editorState, newContent, 'remove-range');

    // Get blockMap size
    const blockMapSize = newState.getCurrentContent().getBlockMap().size;

    // When not deleting the last index block or last block in the blockMap
    if (keyAfter) {
      const afterBlock = content.getBlockForKey(keyAfter);
      const afterText = afterBlock.getText();
      // position the cursor starting and end point : anchorOffset and focusOffset
      const textLen = afterText.length;

      const newSelection = new SelectionState({
        anchorKey: keyAfter,
        anchorOffset: textLen,
        focusKey: keyAfter,
        focusOffset: textLen,
      });

      // Use this to force selection to focus the cursor
      const newEditorState = EditorState.forceSelection(newState, newSelection);

      onChange(newEditorState);
    } else {
      // Logic for deleting last block and last index block
      let newEditorState = newState;

      if (!blockMapSize) {
        // blockMapSize === 0 : blockMap is empty because last block was deleted
        // Create a new empty block to initialize a clean block
        const newCurrentContent = ContentState.createFromText('');
        // Can use getLastBlock() or getFirstBlock() to get the only block
        const block = newCurrentContent.getLastBlock();
        const blockKey = block.getKey();

        // Important to pass in editorState to preserve the undo stack with newCurrentContent.
        newEditorState = EditorState.push(editorState, newCurrentContent, 'change-block-data');

        // Create newSelection to initialize the position with the specific blockKey
        const newSelection = new SelectionState({
          anchorKey: blockKey,
          anchorOffset: 0,
          focusKey: blockKey,
          focusOffset: 0,
        });

        // Use this to force selection to focus the cursor with re-render
        newEditorState = EditorState.forceSelection(newEditorState, newSelection);
      } else {
        // Deleting the the last index in the block e.g. [1...N], we are deleting N
        const keyBefore = content.getKeyBefore(block.key);
        const beforeBlock = content.getBlockForKey(keyBefore);
        const beforeText = beforeBlock.getText();
        // position the cursor starting and end point : anchorOffset and focusOffset
        const textLen = beforeText.length;

        // Create newSelection to initialize the position
        const newSelection = new SelectionState({
          anchorKey: keyBefore,
          anchorOffset: textLen,
          focusKey: keyBefore,
          focusOffset: textLen,
        });

        // Use this to force selection to focus the cursor with re-render
        newEditorState = EditorState.forceSelection(newState, newSelection);
      }

      onChange(newEditorState);
    }
  };

  const disableDelete = useCallback(() => {
    // reset the current mouse block key
    setMouseHoverBlockKey(null);
  }, [setMouseHoverBlockKey]);

  const enableDelete = useCallback(() => {
    // Set the current mouse over block key
    setMouseHoverBlockKey(selectionKey);

    // Make sure the Editor is editable - sometime the editor is in readOnly
    setReadOnly(false);
  }, [selectionKey, setReadOnly]);

  const index = useGetIndex({blockMap, selectionKey});

  // Need to show the delete icon in this cases
  // 1) blockMap.size > 1: hover over lines with more than one block entry then show delete
  const showDeleteIcon = selectionKey === mouseHoverBlockKey && (blockMap.size > 1 || Boolean(textTrimmed));

  // When in create mode when showModified=false, don't show modified icon e.g. 'notmodified-'
  const showModifiedIcon =
    showModified && textTrimmed && ip && (!ip.get('original') || originalText !== text) ? 'modified-' : 'notmodified-';

  return (
    <div className={styles.ipMain} data-tid="comp-ip-item" onMouseOver={enableDelete} onMouseLeave={disableDelete}>
      <span>
        <span
          data-line-number={index}
          onClick={showDeleteIcon ? deleteBlock : _.noop}
          className={cx(styles.lineNumberColumn, {
            [styles.lineNumberHide]:
              showDeleteIcon || (blockMap.size <= 1 && !textTrimmed && !editorState.getSelection().hasFocus),
            // Insert invisible number to the first 9 lines to align with next lines 10 - 99.
            // It's ok to have line shifts when pass other big figures (100, 1000), since it's rare and hidden below the fold
            [styles.lineNumberExtend]: index < 10,
          })}
        >
          {showDeleteIcon && <Icon name="delete" theme={styles} themePrefix="delete-" />}
        </span>
      </span>
      <Icon name="online" theme={styles} themePrefix={showModifiedIcon} />
      <EditorBlock {...props} />
    </div>
  );
}

export default IPItem;
