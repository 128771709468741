/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

import intl from 'intl';
import {Badge, Pill} from 'components';
import {getBadgeTypeByUpdateType, getBadgeLabelByUpdateType} from 'utils/formats';
import * as GridUtils from 'components/Grid/GridUtils';
import styles from './LabelGroupMembers.css';
import {createSelector} from 'reselect';

export const memberFilterMap = createSelector([], () => ({
  label_groups: intl('Common.Name'),
  labels: intl('Common.Name'),
}));

export const memberGridSettings = createSelector([], () => ({
  id: 'memberlist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: {
      header: intl('Provision.Status'),
      value: ({row}) => getBadgeLabelByUpdateType(row.update_type, row.policyVersion),
      format: ({row, value}) =>
        value ? (
          <Badge type={getBadgeTypeByUpdateType(row.update_type)} theme={styles} themePrefix="status-">
            {value}
          </Badge>
        ) : null,
    },
    name: {
      header: intl('Common.Name'),
      value: ({row}) => row.name,
      format: ({row, value}) => (
        <>
          <Pill.Icon group={row.isGroup} position="before" name={row.type} />
          {value}
        </>
      ),
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'min-content'},
      {columns: ['name'], size: 'minmax(200px, auto)'},
    ],
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(200px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(200px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'status'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'status'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 500,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'status'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'status'], size: 'minmax(100px, auto)'},
        ];
      },
    },
  ],
}));
