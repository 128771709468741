/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component, createRef} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {AttributeList, Button, Modal, Form, Grid, ToolBar, ToolGroup, TypedMessages, InfoCard} from 'components';
import {getSecureGatewaysEditPage} from './SecureGatewayEditState';
import styles from './SecureGatewayEdit.css';
import {
  fetchSecureGatewayItem,
  fetchSecureGatewayEdit,
  createSecureGateway,
  updateSecureGateway,
} from '../SecureGatewayItemSaga';
import {object, string} from 'yup';
import SecureGatewayEditModal from './SecureGatewayEditModal';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from '../SecureGatewayRulesConfig';
import {formatNewRow, formatEditedRows, getWriteableData} from '../../SecureGatewayUtils';
import {reactUtils, ipUtils} from 'utils';
import {getId} from 'utils/href';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import {HeaderProps} from 'containers';

const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};
const removeRowHighLight = {className: stylesGridUtils.rowToRemove};

const getEmptyState = () => ({
  extraPropsKeyMap: new Map(),
  selectedKeySet: new Set(),
  selectedKeySetToRemove: new Set(),
  remove: false,
});

// Get formik's mandatory initialValues props for form setup
const getInitialValues = (props, isEdit) => {
  const {originalSecureGateway, authMode} = props;

  return {
    // A required input name use empty string
    name: originalSecureGateway.name || '',
    description: originalSecureGateway.description || '',
    addresses: originalSecureGateway.addresses || '',
    ca_id: originalSecureGateway.ca_id || '',
    psk: originalSecureGateway.psk || '',
    authMode: isEdit ? authMode : 'certificate',
    local_id: originalSecureGateway.local_id || '',
    remote_id: originalSecureGateway.remote_id || '',
    idle_timeout_min: originalSecureGateway.idle_timeout_min || '5',
  };
};

// Initial State
const getInitialState = (props, isEdit) => ({
  showAuthFields: true,
  initialValues: getInitialValues(props, isEdit),
  // isEdit - [true] : editing, [false] : create
  isEdit,
  showEditSubnetModal: false,
  editSubnet: false,
  formLabels: {role: {}, app: {}, loc: {}, env: {}, ip_lists: {}},
  extraPropsKeyMap: new Map(),
  selectedKeySet: new Set(),
  selectedKeySetToRemove: new Set(),
  rowAddedOrEditedOrDeleted: false,
  remove: false,
  rows: props.rows,
  editRowkey: null,
});

@connect(getSecureGatewaysEditPage)
export default class SecureGatewayEdit extends Component {
  static prefetch = fetchSecureGatewayEdit;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    const isEdit = props.routeName === 'app.secureGateways.item.edit';

    // Yup validation
    this.schemas = object({
      name: string().trim().max(255, intl('Common.NameIsTooLong')).required(intl('Common.AddValidName')),
      description: string(intl('Common.AddValidDescription')),
      addresses: string()
        .required(intl('Common.AddValidAddress'))
        .test('isValidAddress', intl('Common.AddValidAddress'), value => ipUtils.validateIPAddress(value)),
      authMode: string().required(intl('SecureGateway.AddValidAuthMode')),
      ca_id: string()
        .trim()
        .when('authMode', {
          is: 'certificate',
          then: string().trim().required(intl('SecureGateway.AddValidCertificateAuthID')),
        }),
      psk: string()
        .trim()
        .when('authMode', {
          is: 'psk',
          then: isEdit ? string().trim() : string().trim().required(intl('SecureGateway.AddValidPreSharedKey')),
        }),
      local_id: string(),
      remote_id: string(),
      idle_timeout_min: string()
        .required(intl('SecureGateway.AddIdleTimeoutMin'))
        .test('isIdleTimeoutMin', intl('SecureGateway.AddIdleTimeoutMin'), value => parseInt(value, 10)),
    });

    this.infoCardIconRef = createRef();
    this.infoCardIconRef2 = createRef();
    this.infoCardIconRef3 = createRef();

    this.handleSave = this.handleSave.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubnetRemove = this.handleSubnetRemove.bind(this);
    this.handleRemoveEnterSubnet = this.handleRemoveEnterSubnet.bind(this);
    this.handleRemoveLeaveSubnet = this.handleRemoveLeaveSubnet.bind(this);
    this.handleEditDefaultSubmit = this.handleEditDefaultSubmit.bind(this);
    this.handleEditDefaultClose = this.handleEditDefaultClose.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);
    this.handleAddSubnet = this.handleAddSubnet.bind(this);
    this.handleEditSubnet = this.handleEditSubnet.bind(this);
    this.handleRemoveSubnetConfirm = this.handleRemoveSubnetConfirm.bind(this);
    this.handleRemoveSubnetClose = this.handleRemoveSubnetClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = getInitialState(props, isEdit);
  }

  handleInputChange(evt) {
    const {name, value} = evt.target;
    const {setFieldValue} = this.formik;

    // Set fields to re invoke formik so it shows yup errors in case user enters empty string or whitspace.
    if (name === 'ca_id') {
      setFieldValue('ca_id', value);
    } else if (name === 'psk') {
      setFieldValue('psk', value);
    }
  }

  handleEditSubnet(row) {
    const labelsRow = _.get(row, 'data.labels');
    const ipListRow = _.get(row, 'data.ipList') || _.get(row, 'data.labels.ipList', {});
    const formLabels = {
      role: labelsRow.role,
      app: labelsRow.app,
      loc: labelsRow.loc,
      env: labelsRow.env,
      ip_lists: {href: ipListRow.href, key: 'ip_lists', name: ipListRow.name},
    };
    const editRowkey = `${getId(ipListRow.href)}${getId(labelsRow.role.href)}${getId(labelsRow.app.href)}${getId(
      labelsRow.loc.href,
    )}${getId(labelsRow.env.href)}`;

    this.setState({showEditSubnetModal: true, editSubnet: true, formLabels, editRowkey});
  }

  handleEditDefaultClose() {
    this.setState({showEditSubnetModal: false});
  }

  handleEditDefaultSubmit(values, isEdit, key) {
    const {originalSecureGateway} = this.props;
    const {rows} = this.state;

    if (isEdit) {
      const previousRows = this.state.rows;
      const editedGridRows = formatEditedRows(values, previousRows, key);

      return this.setState({rows: editedGridRows, rowAddedOrEditedOrDeleted: true});
    }

    if (originalSecureGateway.secure_networks && originalSecureGateway.secure_networks.length) {
      values.id = _.max(originalSecureGateway.secure_networks, subnet => subnet.id).id + 1;
    } else {
      values.id = 1;
    }

    const newRow = formatNewRow({values});

    this.setState({rows: [...rows, newRow], rowAddedOrEditedOrDeleted: true});
  }

  // Handle Edit Error
  handleErrorClose() {
    this.setState({error: null});
  }

  handleSubnetRemove() {
    this.setState({remove: true});
  }

  handleRemoveSubnetClose() {
    this.setState(getEmptyState());
  }

  handleRemoveSubnetConfirm() {
    const {rows, selectedKeySet} = this.state;

    const newGridRows = rows.filter(row => !selectedKeySet.has(row.key));

    this.setState({
      rows: newGridRows,
      rowAddedOrEditedOrDeleted: true,
      remove: false,
      selectedKeySetToRemove: new Set(),
      selectedKeySet: new Set(),
    });
  }

  handleRemoveEnterSubnet() {
    if (this.state.selectedKeySetToRemove.size) {
      this.setState(state => ({
        extraPropsKeyMap: new Map(Array.from(state.selectedKeySetToRemove, key => [key, removeRowHighLight])),
      }));
    }
  }

  handleRemoveLeaveSubnet() {
    // Drop highlight if user moved out cursor and haven't opened remove dialog
    if (!this.state.remove && this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  async handleSave() {
    const {values} = this.formik;
    const {fetcher, navigate} = this.context;
    const {isEdit, rows} = this.state;

    try {
      const gateway = getWriteableData(
        values,
        rows.map(row => row.data),
      );
      let id;

      await reactUtils.setStateAsync({saving: true}, this);

      if (isEdit) {
        id = this.props.currentRouteParams.id;
        await fetcher.spawn(updateSecureGateway, gateway);
      } else {
        const {
          data: {href},
        } = await fetcher.spawn(createSecureGateway, gateway);

        id = getId(href);
      }

      await fetcher.fork(fetchSecureGatewayItem, {params: {id, pversion: 'draft'}}, true);

      // Wait progress on save button to finish
      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false}));
      // Navigate to a view page
      navigate({to: 'secureGateways.item', params: {id}});
    } catch (error) {
      this.setState({error, saving: false});
      // Call formik method to set isSubmitting to false
      this.formik.setSubmitting(false);
    }
  }

  handleAddSubnet() {
    this.setState({
      showEditSubnetModal: true,
      formLabels: {role: {}, app: {}, loc: {}, env: {}, ip_lists: {}},
      editSubnet: false,
    });
  }

  handleSelect({affectedRows, selecting}) {
    this.setState(state => {
      const selectedKeySet = new Set(state.selectedKeySet);
      const selectedKeySetToRemove = new Set(state.selectedKeySetToRemove);

      for (const row of affectedRows) {
        selectedKeySet[selecting ? 'add' : 'delete'](row.key);

        if (selecting) {
          if (row.removable) {
            selectedKeySetToRemove.add(row.key);
          }
        } else {
          selectedKeySetToRemove.delete(row.key);
        }
      }

      return {selectedKeySet, selectedKeySetToRemove};
    });
  }

  renderRemove() {
    const {
      state: {selectedKeySet, selectedKeySetToRemove, rows},
    } = this;

    const renderList = array =>
      array.map(key => {
        const rowToDisplay = rows.find(row => row.key === key);

        return <li key={rowToDisplay.key}>{rowToDisplay?.data.ipList?.name}</li>;
      });

    const confirmationSections = [
      <div>
        {intl('SecureGateway.DeleteSubnetConfirm', {count: selectedKeySetToRemove.size})}
        <ol>{renderList([...selectedKeySetToRemove])}</ol>
      </div>,
    ];

    const unableToRemove = _.difference([...selectedKeySet], [...selectedKeySetToRemove]);

    if (unableToRemove.length > 0) {
      confirmationSections.push(
        <div>
          <TypedMessages>
            {[
              {
                icon: 'warning',
                content: (
                  <>
                    {intl('SecureGateway.List.Message.CannotDeleteNumber', {count: unableToRemove.length})}:
                    <ol>{renderList(unableToRemove)}</ol>
                  </>
                ),
              },
            ]}
          </TypedMessages>
        </div>,
      );
    }

    return (
      <Modal.Confirmation
        title={intl('SecureGateway.DeleteSubnet', {count: selectedKeySetToRemove.size})}
        defaultConfirmProps={{tid: 'ok', text: intl('Common.Remove')}}
        onCancel={this.handleRemoveSubnetClose}
        onConfirm={this.handleRemoveSubnetConfirm}
      >
        {confirmationSections}
      </Modal.Confirmation>
    );
  }

  // Render alert message when edit or create fails
  renderEditAlert() {
    const {error, isEdit} = this.state;
    const token = _.get(error, 'data[0].token');
    const title = isEdit ? intl('SecureGateway.Errors.Edit') : intl('SecureGateway.Errors.Create');
    const message = (token && intl(`ErrorsAPI.err:${token}`)) || _.get(error, 'data[0].message', error.message);

    return (
      <Modal.Alert title={title} onClose={this.handleErrorClose} buttonProps={{tid: 'ok', text: intl('Common.OK')}}>
        <TypedMessages>{[{icon: 'error', content: message}]}</TypedMessages>
      </Modal.Alert>
    );
  }

  renderForm(options) {
    const {values, isValid} = options;

    this.formik = options;

    const {
      saving,
      onSaveDone,
      error,
      selectedKeySetToRemove,
      showEditSubnetModal,
      isEdit,
      editSubnet,
      formLabels,
      selectedKeySet,
      extraPropsKeyMap,
      remove,
      rowAddedOrEditedOrDeleted,
      editRowkey,
      rows,
    } = this.state;
    const {
      userIsReadOnly,
      currentRouteParams: {id},
    } = this.props;
    const {store} = this.context;
    const extraPropsKeyMapGrid = new Map(extraPropsKeyMap);

    gridSettings().columns.status.hidden = true;

    const gridData = getGridSelector(store.getState(), {
      settings: gridSettings,
      rows,
    });

    return (
      <>
        <ToolBar>
          <ToolGroup>
            <Button
              icon="save"
              tid="save"
              disabled={isValid === false && !rowAddedOrEditedOrDeleted}
              text={intl('Common.Save')}
              progressCompleteWithCheckmark
              progress={saving}
              progressError={Boolean(error)}
              type="submit"
              onProgressDone={onSaveDone}
            />
            <Button.Link
              color="standard"
              disabled={saving || Boolean(onSaveDone)}
              icon="cancel"
              tid="cancel"
              text={intl('Common.Cancel')}
              link={isEdit ? {to: 'secureGateways.item', params: {id}} : 'secureGateways.list'}
            />
          </ToolGroup>
        </ToolBar>
        <AttributeList valuesGap="gap">
          {[
            {divider: true},
            {title: intl('Common.General'), tid: 'general'},
            {
              tid: 'name',
              key: <Form.Label name="name" title={intl('Common.Name')} />,
              value: <Form.Input name="name" tid="name" placeholder={intl('SecureGateway.TypeGatewayName')} />,
            },
            {
              tid: 'description',
              key: <Form.Label name="description" title={intl('Common.Description')} />,
              value: (
                <Form.Textarea
                  name="description"
                  tid="description"
                  placeholder={intl('SecureGateway.TypeGatewayDesc')}
                />
              ),
            },
            {
              tid: 'gatewayIPAddress',
              key: <Form.Label name="addresses" title={intl('SecureGateway.GatewayIPAddress')} />,
              value: (
                <Form.Input name="addresses" tid="gatewayIPAddress" placeholder={intl('SecureGateway.TypeIPAddress')} />
              ),
            },
            {divider: true},
            {title: intl('SecureGateway.VPNConfiguration'), tid: 'configuration'},
            {
              tid: 'certificate-and-psk-radio',
              key: <Form.Label name="authmode" title={intl('Common.Authentication')} />,
              value: (
                <Form.RadioGroup name="authMode" tid="certificate-and-psk-radio">
                  <Form.Radio value="certificate" tid="certificate" label={intl('SecureGateway.CertificateRsaSig')} />
                  <Form.Radio value="psk" tid="psk" label={intl('SecureGateway.PreSharedKey')} />
                </Form.RadioGroup>
              ),
            },
            values.authMode === 'psk'
              ? {
                  tid: 'preSharedKey',
                  key: <Form.Label name="psk" title={intl('SecureGateway.PreSharedKey')} />,
                  value: (
                    <Form.Input
                      name="psk"
                      type="password"
                      tid="preSharedKey"
                      placeholder={isEdit ? '••••••••••' : intl('SecureGateway.TypePreSharedKey')}
                    />
                  ),
                }
              : null,
            values.authMode === 'certificate'
              ? {
                  value: (
                    <>
                      <Form.Input
                        name="ca_id"
                        tid="certificate"
                        placeholder={intl('SecureGateway.TypeIssuerDN')}
                        onChange={this.handleInputChange}
                      />
                      <InfoCard trigger={this.infoCardIconRef}>
                        {() => [
                          {
                            header: intl('SecureGateway.IssuerDistinguishedName'),
                            message: `${intl('SecureGateway.EnterCertificateIssuerName')}: ${intl(
                              'SecureGateway.CertificateIssuerNameExample',
                            )}`,
                          },
                        ]}
                      </InfoCard>
                    </>
                  ),
                  tid: 'certificate',
                  key: <Form.Label name="ca_id" title={intl('SecureGateway.CertificateAuthorityID')} />,
                  icon: <InfoCard.Icon ref={this.infoCardIconRef} />,
                }
              : null,
            values.authMode === 'certificate'
              ? {
                  value: (
                    <>
                      <Form.Input
                        name="local_id"
                        tid="localID"
                        placeholder={intl('SecureGateway.TypeGatewaySubjectDN')}
                      />
                      <InfoCard trigger={this.infoCardIconRef2}>
                        {() => [
                          {
                            header: intl('SecureGateway.WorkloadSubjectDN'),
                            message: `${intl('SecureGateway.EnterWorkloadDN')}: ${intl(
                              'SecureGateway.WorkloadSubjectDNExample',
                            )}`,
                          },
                        ]}
                      </InfoCard>
                    </>
                  ),
                  tid: 'localID',
                  key: <Form.Label name="local_id" title={intl('SecureGateway.LocalID')} />,
                  icon: <InfoCard.Icon ref={this.infoCardIconRef2} />,
                }
              : null,
            values.authMode === 'certificate'
              ? {
                  value: (
                    <>
                      <Form.Input
                        name="remote_id"
                        tid="remoteID"
                        placeholder={intl('SecureGateway.TypeWorkloadSubjectDN')}
                      />
                      <InfoCard trigger={this.infoCardIconRef3}>
                        {() => [
                          {
                            header: intl('SecureGateway.GatewaySubjectDN'),
                            message: `${intl('SecureGateway.EnterSubjectName')}: ${intl(
                              'SecureGateway.SubjectNameExample',
                            )}`,
                          },
                        ]}
                      </InfoCard>
                    </>
                  ),
                  tid: 'remoteID',
                  key: <Form.Label name="remoteid" title={intl('SecureGateway.RemoteID')} />,
                  icon: <InfoCard.Icon ref={this.infoCardIconRef3} />,
                }
              : null,
            {
              tid: 'idleMinuteTimeout',
              key: <Form.Label name="idle_timeout_min" title={intl('SecureGateway.IdleTimeout')} />,
              value: (
                <Form.Input
                  name="idle_timeout_min"
                  tid="idleMinuteTimeout"
                  placeholder={intl('SecureGateway.TypeIdleTimeout')}
                />
              ),
            },
            {divider: true},
            {title: intl('Common.Rules'), tid: 'Rules'},
            {
              contentGap: 'gap', // Make it inherit page gap size
              content: (
                <>
                  <ToolBar>
                    <ToolGroup>
                      <Button
                        icon="add"
                        text={intl('Common.Add')}
                        textIsHideable
                        tid="add-subnet-row-in-grid"
                        theme={buttonsTheme}
                        onClick={this.handleAddSubnet}
                        disabled={userIsReadOnly}
                      />
                      <Button
                        color="standard"
                        icon="remove"
                        text={intl('Common.Remove')}
                        textIsHideable
                        tid="remove-subnet-row-in-grid"
                        theme={buttonsTheme}
                        counter={selectedKeySetToRemove.size}
                        counterColor="red"
                        disabled={userIsReadOnly || !selectedKeySetToRemove.size}
                        onClick={this.handleSubnetRemove}
                        onMouseEnter={this.handleRemoveEnterSubnet}
                        onMouseLeave={this.handleRemoveLeaveSubnet}
                      />
                    </ToolGroup>
                  </ToolBar>
                  <Grid
                    component={this}
                    grid={gridData}
                    theme={styles}
                    selectedKeySet={selectedKeySet}
                    dontHighlightSelected={extraPropsKeyMapGrid.size > 0}
                    extraPropsKeyMap={extraPropsKeyMapGrid}
                    onSelect={this.handleSelect}
                    emptyMessage={intl('Common.NoData')}
                  />
                </>
              ),
            },
          ]}
        </AttributeList>

        {showEditSubnetModal && (
          <SecureGatewayEditModal
            initLabels={formLabels}
            rowKeyOnEdit={editRowkey}
            gridRows={rows}
            isEdit={editSubnet}
            onSubmit={this.handleEditDefaultSubmit}
            onClose={this.handleEditDefaultClose}
          />
        )}
        {remove && this.renderRemove()}
        {error && this.renderEditAlert()}
      </>
    );
  }

  render() {
    const {
      state: {isEdit},
      props: {
        currentRouteParams: {id},
      },
    } = this;

    return (
      <>
        <HeaderProps
          title={intl('Common.SecureConnectGateways')}
          label={`(${intl(isEdit ? 'Common.Edit' : 'Common.Create')})`}
          up={isEdit ? {to: 'secureGateways.item', params: {id}} : 'secureGateways.list'}
        />
        <Form
          enableReinitialize
          schemas={this.schemas}
          initialValues={this.state.initialValues}
          onSubmit={this.handleSave}
        >
          {this.renderForm}
        </Form>
      </>
    );
  }
}
