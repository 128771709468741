/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteCurrentParams, isSupercluster, isEdge, isCrowdstrike} from 'containers/App/AppState';
import {getSupportReports} from 'containers/SupportBundles/VENSupportReports/List/SupportReportsListState';
import {isSupportReportsEnabled} from 'containers/SupportBundles/VENSupportReports/SupportReportsState';

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'VENS_GET_ITEM':
        return action.item;
      default:
        return state;
    }
  },
};

export const getVenInstance = state => state.ven.instance;

export const isVenEditEnabled = createSelector(
  getVenInstance,
  ven => Array.isArray(ven.caps) && ven.caps.includes('write'),
);

export const isVenUpgradeEnabled = createSelector(
  getVenInstance,
  ven => Array.isArray(ven.caps) && ven.caps.includes('upgrade'),
);

export const isGenerateSupportReportEnabled = createSelector(
  getVenInstance,
  ven => Array.isArray(ven.caps) && ven.caps.includes('generate_support_report'),
);

export const isUnpairEnabled = createSelector(
  getVenInstance,
  ven => Array.isArray(ven.caps) && ven.caps.includes('unpair'),
);

export const getVenItemPage = createSelector(
  [
    getVenInstance,
    isVenEditEnabled,
    isVenUpgradeEnabled,
    isUnpairEnabled,
    getRouteCurrentParams,
    isSupercluster,
    getSupportReports,
    isSupportReportsEnabled,
    isGenerateSupportReportEnabled,
    isEdge,
    isCrowdstrike,
  ],
  (
    ven,
    enableEdit,
    enableUpgrade,
    enableUnpair,
    currentRouteParams,
    isSuperCluster,
    supportReports,
    supportReportsIsEnabled,
    generateSupportReportIsEnabled,
    edgeEnabled,
    crowdstrikeEnabled,
  ) => ({
    ven,
    enableEdit,
    enableUpgrade,
    enableSuspend: enableEdit && !ven?.container_cluster,
    enableUnpair,
    currentRouteParams,
    isSuperCluster,
    supportReports,
    supportReportsIsEnabled,
    generateSupportReportIsEnabled,
    edgeEnabled,
    crowdstrikeEnabled,
  }),
);
