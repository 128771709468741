/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {generateRules} from 'containers/Workload/WorkloadRulesUtils';
import {gridSettings, forwardgridSettings, customIpgridSettings, admingridSettings} from './WorkloadRulesConfig';
import {enforcementgridSettings} from '../EnforcementBoundaries/WorkloadEnforcementBoundariesConfig';
import {isEdge} from 'containers/App/AppState';

export default {
  rules(state = {}, action) {
    switch (action.type) {
      case 'WORKLOAD_GET_RULES':
        return action.data;
      default:
        return state;
    }
  },
};

export const getWorkloadRules = state => state.workload.rules;
export const getWorkloadInstance = state => state.workload.instance;

export const getWorkloadAllRules = createSelector([getWorkloadRules], ruleView => {
  const {
    inboundRules,
    outboundRules,
    customIpTableRules,
    forwardRules,
    adminRules,
    inboundEnforcement,
    outboundEnforcement,
  } = generateRules(ruleView);

  const inboundRulesData = inboundRules.map((item, idx) => ({
    key: idx,
    ...item,
  }));

  // Edge selector
  const adminRulesData = adminRules?.map((item, idx) => ({
    key: idx,
    ...item,
  }));

  const outboundRulesData = outboundRules.map((item, idx) => ({
    key: idx,
    ...item,
  }));

  const customIpTableData = customIpTableRules.map((item, idx) => ({
    key: idx,
    ...item,
  }));

  const forwardRulesData = forwardRules.map((item, idx) => ({
    key: idx,
    ...item,
  }));

  const inboundEnforcementData = inboundEnforcement.map((item, idx) => ({
    key: idx,
    ...item,
  }));

  const outboundEnforcementData = outboundEnforcement.map((item, idx) => ({
    key: idx,
    ...item,
  }));

  return {
    inboundRulesData,
    outboundRulesData,
    forwardRulesData,
    customIpTableData,
    adminRulesData,
    inboundEnforcementData,
    outboundEnforcementData,
  };
});

export const getWorkloadInboundRulesRows = createSelector(
  [getWorkloadAllRules],
  ({inboundRulesData}) => inboundRulesData,
);

// Edge selector
export const getWorkloadAdminRulesRows = createSelector([getWorkloadAllRules], ({adminRulesData}) => adminRulesData);

export const getWorkloadOutboundRulesRows = createSelector(
  [getWorkloadAllRules],
  ({outboundRulesData}) => outboundRulesData,
);

export const getWorkloadforwardRulesRows = createSelector(
  [getWorkloadAllRules],
  ({forwardRulesData}) => forwardRulesData,
);

export const getWorkloadcustomIpTableRulesRows = createSelector(
  [getWorkloadAllRules],
  ({customIpTableData}) => customIpTableData,
);

export const getWorkloadInboundEnforcementRows = createSelector(
  [getWorkloadAllRules],
  ({inboundEnforcementData}) => inboundEnforcementData,
);

export const getWorkloadOutboundEnforcementRows = createSelector(
  [getWorkloadAllRules],
  ({outboundEnforcementData}) => outboundEnforcementData,
);

export const inboundGridSettings = createSelector([isEdge, gridSettings], (isEdge, gridSettings) => {
  const columns = {...gridSettings.columns};

  columns.secConnect.disabled = isEdge;
  columns.machineAuth.disabled = isEdge;
  columns.stateless.disabled = isEdge;
  columns.networkType.disabled = !isEdge;

  return {...gridSettings, id: 'inbound', columns};
});

export const adminGridSettings = createSelector([admingridSettings], admingridSettings => {
  const columns = {...admingridSettings.columns};

  return {...admingridSettings, id: 'admin', columns};
});

export const outboundGridSettings = createSelector([isEdge, gridSettings], (isEdge, gridSettings) => {
  const columns = {...gridSettings.columns};

  columns.secConnect.disabled = isEdge;
  columns.machineAuth.disabled = isEdge;
  columns.stateless.disabled = isEdge;
  columns.networkType.disabled = !isEdge;

  return {...gridSettings, id: 'outbound', columns};
});
export const forwardGridSettings = createSelector([forwardgridSettings], forwardgridSettings => ({
  ...forwardgridSettings,
  id: 'forward',
}));
export const customIpGridSettings = createSelector([customIpgridSettings], customIpgridSettings => ({
  ...customIpgridSettings,
  id: 'customip',
}));

export const inboundEnforcementGridSettings = createSelector([enforcementgridSettings], enforcementgridSettings => ({
  ...enforcementgridSettings,
  id: 'inboundenforcement',
}));

export const outboundEnforcementGridSettings = createSelector([enforcementgridSettings], enforcementgridSettings => ({
  ...enforcementgridSettings,
  id: 'outboundenforcement',
}));

const getInboundGrid = state =>
  getGridSelector(state, {
    settings: inboundGridSettings,
    rows: getWorkloadInboundRulesRows,
  });

// Edge selector
const getAdminGrid = state =>
  getGridSelector(state, {
    settings: adminGridSettings,
    rows: getWorkloadAdminRulesRows,
  });

const getOutboundGrid = state =>
  getGridSelector(state, {
    settings: outboundGridSettings,
    rows: getWorkloadOutboundRulesRows,
  });

const getForwardGrid = state =>
  getGridSelector(state, {
    settings: forwardGridSettings,
    rows: getWorkloadforwardRulesRows,
  });

const getcustomIpGrid = state =>
  getGridSelector(state, {
    settings: customIpGridSettings,
    rows: getWorkloadcustomIpTableRulesRows,
  });

const getinboundEnforcementGrid = state =>
  getGridSelector(state, {
    settings: inboundEnforcementGridSettings,
    rows: getWorkloadInboundEnforcementRows,
  });

const getoutboundEnforcementGrid = state =>
  getGridSelector(state, {
    settings: outboundEnforcementGridSettings,
    rows: getWorkloadOutboundEnforcementRows,
  });

export const getWorkloadRulesPage = createSelector(
  [
    getInboundGrid,
    getAdminGrid,
    getOutboundGrid,
    getcustomIpGrid,
    getForwardGrid,
    getinboundEnforcementGrid,
    getoutboundEnforcementGrid,
    getWorkloadRules,
    getWorkloadInstance,
    isEdge,
  ],
  (
    inboundGrid,
    adminGrid,
    outboundGrid,
    customIpGrid,
    forwardGrid,
    inboundEnforcementGrid,
    outboundEnforcementGrid,
    ruleView,
    workload,
    edgeEnabled,
  ) => ({
    inboundGrid,
    adminGrid,
    outboundGrid,
    customIpGrid,
    forwardGrid,
    inboundEnforcementGrid,
    outboundEnforcementGrid,
    ruleView,
    workload,
    edgeEnabled,
  }),
);
