/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {mixThemeWithProps} from '@css-modules-theme/react';
import {Menu} from 'components';
import styles from './HeaderMenu.css';

const HeaderMenu = props => <Menu {...mixThemeWithProps(styles, props)} />;

export default HeaderMenu;
