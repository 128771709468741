/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import {connect} from 'react-redux';
import {getSecureGatewaysViewPage} from './SecureGatewayViewState';
import {fetchSecureGateways} from '../../List/SecureGatewayListSaga';
import {fetchSecureGatewaySummary, deleteSecureGateway} from '../SecureGatewayItemSaga';
import {AttributeList, Button, Diff, Grid, ModalMachineAuto, ToolBar, ToolGroup} from 'components';
import {UserName} from 'containers';
import styles from './SecureGatewayView.css';

@connect(getSecureGatewaysViewPage)
export default class SecureGatewayView extends Component {
  static prefetch = fetchSecureGatewaySummary;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      extraPropsKeyMap: new Map(),
      selectedKeySet: new Set(),
      remove: null,
    };

    this.handleRemove = this.handleRemove.bind(this);
    this.handleOnDone = this.handleOnDone.bind(this);
  }

  // Handle Modal Dialog to display or not display
  handleRemove() {
    this.setState(({remove}) => ({remove: !remove}));
  }

  handleOnDone() {
    const {navigate, fetcher} = this.context;

    navigate({to: 'secureGateways.list'});

    return fetcher.fork(fetchSecureGateways);
  }

  generateDiffAttribute(label, {value, prevValue, updateType}, tid, showIfEmpty) {
    const {
      versions: {prevPversionObj},
    } = this.props;
    const noDiff = !prevPversionObj || (!prevValue && updateType !== 'create');

    return value || prevValue || showIfEmpty
      ? [{tid, key: label, value: <Diff.Text value={value} oldValue={prevValue} noDiff={noDiff} />}]
      : [];
  }

  renderRemoveConfirmation() {
    return (
      <ModalMachineAuto onClose={this.handleRemove} onDone={this.handleOnDone} saga={deleteSecureGateway}>
        {{
          title: intl('SecureGateway.SecureConnectDelete', {count: 1}),
          confirmMessage: intl('SecureGateway.SecureConnectDeleteConfirm', {count: 1}),
        }}
      </ModalMachineAuto>
    );
  }

  render() {
    const {
      grid,
      count,
      userIsReadOnly,
      isSuperclusterMember,
      isOldVersion,
      id,
      diff,
      createdBy,
      updatedBy,
      secureGateway: {created_at: createdAt, updated_at: updatedAt},
      versions: {draft},
    } = this.props;
    const {remove} = this.state;
    const disabledButton = userIsReadOnly || isSuperclusterMember || (draft && draft.update_type === 'delete');

    return (
      <>
        {!isOldVersion && (
          <ToolBar>
            <ToolGroup>
              <Button.Link
                text={intl('Common.Edit')}
                icon="edit"
                autoFocus
                tid="edit"
                link={{to: 'secureGateways.item.edit', params: {id, pversion: 'draft'}}}
                disabled={disabledButton}
              />
              <Button
                color="standard"
                disabled={disabledButton}
                icon="remove"
                tid="remove"
                text={intl('Common.Remove')}
                onClick={this.handleRemove}
              />
            </ToolGroup>
          </ToolBar>
        )}
        <AttributeList>
          {[
            {divider: true},
            {
              title: intl('Common.General'),
              tid: 'General',
            },
            ...this.generateDiffAttribute(intl('Common.Name'), diff.name, 'name'),
            ...this.generateDiffAttribute(intl('Common.Description'), diff.description, 'desc', true),
            ...this.generateDiffAttribute(intl('Common.IPAddress'), diff.addresses, 'ipaddress'),
            {
              tid: 'created',
              key: intl('Common.Created'),
              value: UserName.dateAtTimeBy(createdAt, createdBy, 'full_name'),
            },
            ...((updatedAt && updatedAt !== createdAt) || (updatedBy && updatedBy.href !== createdBy.href)
              ? [
                  {
                    tid: 'updated',
                    key: intl('Common.LastModified'),
                    value: UserName.dateAtTimeBy(updatedAt, updatedBy, 'full_name'),
                  },
                ]
              : []),

            {divider: true},
            {
              title: intl('SecureGateway.VPNConfiguration'),
              tid: 'VPNConfiguration',
            },
            ...this.generateDiffAttribute(intl('SecureGateway.CertificateAuthorityID'), diff.ca_id),
            !diff.ca_id.value && {
              key: intl('SecureGateway.PreSharedKey'),
              value: '••••••••••',
            },
            ...this.generateDiffAttribute(intl('SecureGateway.LocalID'), diff.local_id),
            ...this.generateDiffAttribute(intl('SecureGateway.RemoteID'), diff.remote_id),
            ...this.generateDiffAttribute(intl('SecureGateway.IdleTimeout'), diff.idle_timeout_min),

            {divider: true},
            {title: intl('Common.Rules'), tid: 'Rules'},
            {
              content: (
                <Grid
                  component={this}
                  grid={grid}
                  theme={styles}
                  count={count}
                  dontHighlightSelected
                  emptyMessage={intl('Common.NoData')}
                />
              ),
            },

            {divider: true},
            {
              title: intl('SecureGateway.IPsecConfiguration'),
              tid: 'IPsecConfiguration',
            },
            {
              key: intl('Common.Type'),
              value: intl('SecureGateway.TunnelMode'),
            },
            {
              key: intl('Common.Authentication'),
              value: intl('SecureGateway.PublicKey'),
            },
            {
              key: intl('Settings.ESPEncriptionAndIntegrity'),
              value: 'SHA1-AES-256-CBC',
            },
            {
              key: intl('Settings.IKEv12PRF'),
              value: intl('Common.None'),
            },
            {
              key: intl('Settings.IKEv12Integrity'),
              value: 'HMAC-SHA-1',
            },
            {
              key: intl('Settings.IKEv12DHGroup'),
              value: 'Group-2',
            },
            {
              key: intl('Settings.SALifetime'),
              value: intl('Common.HoursNumber', {count: 1}),
            },
            {
              key: intl('SecureGateway.WindowsVersion'),
              value: intl('Settings.IKEv1L2TP'),
            },
            {
              key: intl('SecureGateway.LinuxVersion'),
              value: intl('Settings.IKEv2'),
            },
            {
              key: intl('SecureGateway.LinuxIKEv2DPD'),
              value: intl('Common.On'),
            },
            {
              key: intl('SecureGateway.LinuxIKEv2NAT'),
              value: intl('Common.On'),
            },
          ]}
        </AttributeList>
        {remove && this.renderRemoveConfirmation()}
      </>
    );
  }
}
