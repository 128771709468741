/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {Pill} from 'components/index';
import {hrefUtils} from 'utils';
import _ from 'lodash';
import type {GroupProps} from './Group';
import type {PillDiffProps} from '../PillDiff';
import type {Group} from 'illumio';

export type GroupsDiffProps = {
  value?: Group | Group[];
  oldValue?: Group | Group[];
  // policy version
  pversion?: string | number;
  onClick?(groupList: Group, ...rest: Parameters<Exclude<GroupProps['onClick'], undefined>>): void;
} & Omit<PillDiffProps, 'value' | 'oldValue' | 'onClick'>;

export default function GroupsDiff(props: GroupsDiffProps): JSX.Element {
  const {value, oldValue, onClick, ...extra} = props;

  const getGroupPill = (groupList?: Group) =>
    groupList && {
      key: hrefUtils.getId(groupList.href),
      pill: <Pill.Group onClick={onClick && _.partial(onClick, groupList)} value={groupList} />,
    };

  const pillDiffProps: PillDiffProps = extra;

  pillDiffProps.value = Array.isArray(value) ? value.map(getGroupPill) : [getGroupPill(value)];
  pillDiffProps.oldValue = Array.isArray(oldValue) ? oldValue.map(getGroupPill) : [getGroupPill(oldValue)];

  return <Pill.Diff {...pillDiffProps} />;
}
