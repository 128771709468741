/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {getLabelsObject} from 'components/Pill/Label/LabelUtils';
import {createSelector} from 'reselect';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {getRouteName, getRoutePreviousName, getRouteCurrentParams} from 'containers/App/AppState';
import {getServiceInstance} from 'containers/Service/Item/ServiceItemState';
import {getVirtualServerVersions} from '../VirtualServerItemState';
import {getLoadBalancersMap} from 'containers/LoadBalancer/Item/LoadBalancerItemState';
import {
  getAllUsersMap,
  isUserReadOnly,
  isUserGlobalObjectProvisioner,
  isUserRulesetProvisioner,
} from 'containers/User/UserState';
import {lookupProtocol} from 'containers/Service/ServiceUtils';
import {hrefUtils} from 'utils';

export default {
  discovered(state = {}, action) {
    switch (action.type) {
      case 'DISCOVERED_VIRTUAL_SERVER_GET_INSTANCE':
        return action.data.discoveredVirtualServer;
      default:
        return state;
    }
  },
};

export const getDiscoveredVirtualServer = state => state.virtualservers.discovered;

export const getVirtualServerSummary = createSelector(
  [
    getVirtualServerVersions,
    getServiceInstance,
    getDiscoveredVirtualServer,
    getLoadBalancersMap,
    isUserReadOnly,
    isUserGlobalObjectProvisioner,
    isUserRulesetProvisioner,
    getRoutePreviousName,
    getRouteName,
    getRouteCurrentParams,
    getAllUsersMap,
  ],
  (
    {versions, isOldVersion, pversionObjIsDeleted},
    service,
    dvs,
    SLBMap,
    userIsReadOnly,
    userIsGlobalObjectProvisioner,
    userIsRulesetProvisioner,
    previousRouteName,
    routeName,
    currentRouteParams,
    usersMap,
  ) => {
    // add aggregated ports for services of virtual server
    let ports = '';

    if (
      service &&
      service.service_ports &&
      service.service_ports.length &&
      service.service_ports[0].port !== -1 &&
      service.service_ports[0].protocol !== -1
    ) {
      ports = service.service_ports
        .map(port => {
          let range = port.port;

          if (port.to_port) {
            range = `${range} - ${port.to_port}`;
          }

          const friendlyProtocol = lookupProtocol(port.proto || port.protocol);

          return `${range || ''} ${friendlyProtocol}`;
        })
        .join(', ');
    }

    const userIsReadOnlyAndNotGPOP = userIsReadOnly && !userIsGlobalObjectProvisioner;
    const {pversion} = currentRouteParams;

    versions = Object.entries(versions).reduce(
      (result, [pversion, data]) => ({
        ...result,
        [pversion]: data && {
          ...data,
          id: hrefUtils.getId(data.href),
          extended_service: service ? {...service, agg_ports: ports} : null,
          dvs_detail: dvs,
          slb_detail: SLBMap.get(dvs.slb.href),
          labels_obj: getLabelsObject(data.labels),
          createdBy: fillUserInfo(usersMap, data.created_by),
          updatedBy: fillUserInfo(usersMap, data.updated_by),
        },
      }),
      {},
    );

    return {
      id: hrefUtils.getId(versions.pversionObj.href),
      versions,
      pversion,
      isOldVersion,
      pversionObjIsDeleted,
      userIsReadOnly,
      userIsReadOnlyAndNotGPOP,
      userIsRulesetProvisioner,
      previousRouteName,
      routeName,
      currentRouteParams,
    };
  },
);
