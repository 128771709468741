/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {isAPIAvailable} from 'api/apiUtils';

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'GLOBAL_IPS_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getCorporatePublicIPsInstance = state => state.corporatepublicips.instance;

export const getCorporatePublicIPsView = createSelector([getCorporatePublicIPsInstance], ({public_snat_ips = []}) => {
  const hasWritePermission = isAPIAvailable('corporate_ips_setting.update');

  return {
    hasWritePermission,
    instance: public_snat_ips,
  };
});
