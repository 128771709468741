/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {hrefUtils, portUtils} from 'utils';
import * as GridUtils from 'components/Grid/GridUtils';
import {Pill, Link} from 'components';
import {getPortOverride, getPortOverrideValue} from 'containers/VirtualService/Item/VirtualServiceItemUtils';
import {getNodeAppGroupParent} from 'containers/Explorer/ExplorerUtils';
import {createSelector} from 'reselect';

export const gridSettings = createSelector([], () => ({
  id: 'workloadvs',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    name: {
      header: intl('Common.Name'),
      value: ({row}) => row.virtual_service,
      format: ({value}) => {
        if (!value.href) {
          return value.name;
        }

        return (
          <Link to="virtualServices.item" params={{id: hrefUtils.getId(value.href)}}>
            {value.name}
          </Link>
        );
      },
    },
    service: {
      header: intl('Common.Service'),
      format: ({row}) => {
        if (row.virtual_service && row.virtual_service.service) {
          const id = hrefUtils.getId(row.virtual_service.service.href);

          return (
            <Link to="services.item" params={{id, pversion: 'active'}}>
              {row.virtual_service.service.name}
            </Link>
          );
        }

        if (row.resolved_service_ports && row.resolved_service_ports.length) {
          return [...new Set(row.resolved_service_ports.map(item => portUtils.stringifyPortObjectReadonly(item)))].map(
            item => <div key={item}>{item}</div>,
          );
        }
      },
    },
    role: {
      header: intl('Common.Role'),
      value: ({row}) => row.virtual_service.labels.find(label => label.key === 'role'),
      format: ({value}) =>
        value ? (
          <Pill.Label type={value.key} href={value.href}>
            {value.value}
          </Pill.Label>
        ) : null,
    },
    group: {
      header: intl('Common.AppGroup'),
      format: ({row}) => {
        const appGroupTypes = row.appGroupTypes;
        const appGroup = getNodeAppGroupParent(row.virtual_service, appGroupTypes);
        const appLabel = row.virtual_service.labels.find(label => label.key === 'app');
        const envLabel = row.virtual_service.labels.find(label => label.key === 'env');
        const locLabel = row.virtual_service.labels.find(label => label.key === 'loc');

        let groupName = [];
        const groupId = [];

        if (appLabel && (!appGroup || appGroupTypes.includes('app'))) {
          groupName.push(appLabel.value);
          groupId.push(appLabel.href.split('/').pop());
        }

        if (envLabel && (!appGroup || appGroupTypes.includes('env'))) {
          groupName.push(envLabel.value);
          groupId.push(envLabel.href.split('/').pop());
        }

        if (locLabel && (!appGroup || appGroupTypes.includes('loc'))) {
          groupName.push(locLabel.value);
          groupId.push(locLabel.href.split('/').pop());
        }

        groupName = groupName.length ? groupName.join(' | ') : intl('Map.DiscoveredGroup');

        const groupParams = {id: groupId.length ? groupId.sort((a, b) => a - b).join('x') : 'discovered'};

        // If there is no app group and this is a scoped user, don't link them to the group page
        // because the group pages are based on illumination which is not available
        if (!appGroup && row.userIsScoped) {
          return groupName;
        }

        return (
          <Link to={appGroup ? 'appGroups.detail.workloads' : 'group.workloads'} params={groupParams}>
            {groupName}
          </Link>
        );
      },
    },
    port_overrides: {
      header: intl('VirtualServices.PortOverride'),
      value: ({row}) => getPortOverride(row),
      format: ({value}) => getPortOverrideValue(value),
    },
  },

  templates: [
    [
      {columns: ['name'], size: 'minmax(100px, auto)'},
      {columns: ['service'], size: 'minmax(100px, auto)'},
      {columns: ['role'], size: 'minmax(100px, auto)'},
      {columns: ['group'], size: 'minmax(100px, auto)'},
      {columns: ['port_overrides'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['service'], size: 'minmax(100px, auto)'},
            {columns: ['role'], size: 'minmax(100px, auto)'},
            {columns: ['group'], size: 'minmax(100px, auto)'},
            {columns: ['port_overrides'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['service'], size: 'minmax(100px, auto)'},
          {columns: ['role'], size: 'minmax(100px, auto)'},
          {columns: ['group'], size: 'minmax(100px, auto)'},
          {columns: ['port_overrides'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['name', 'service'], size: 'minmax(200px, auto)'},
            {columns: ['role', 'group', 'port_overrides'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['name', 'service'], size: 'minmax(200px, auto)'},
          {columns: ['role', 'group', 'port_overrides'], size: 'minmax(200px, auto)'},
        ];
      },
    },
  ],
}));
