/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {Component, createRef} from 'react';
import * as PropTypes from 'prop-types';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {isIntersectionObserverSupported, isMotionReduced} from 'utils/dom';
import {mutuallyExclusiveTruePropsSpread} from 'utils/react';
import styles from './ModalStickyShadow.css';

export default class ModalStickyShadow extends Component {
  static propTypes = {
    // A side to stick to. Default is 'top'
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    ...mutuallyExclusiveTruePropsSpread('top', 'bottom'), // Declare sizes to be mutually exclusive

    // Top/Bottom position when the element should get stuck, default is var(--0px);
    // For example, if it is negative, like -5px, then element will stick later (5px will creep under the fold)
    // If it is positive, like 5px, then element will stick sooner, before touching the fold
    position: PropTypes.string,

    // Don't show shadow
    noShadow: PropTypes.bool,
    // By default shadow is shown when the element gets stuck, default is var(--0px).
    // If you want to start showing shadow in advance, before it is stuck, pass negative value to shadowBacklash, if later - positive.
    shadowBacklash: PropTypes.string,

    // Sticky element can have any content inside.
    // In this case shadow will be applied to the element itself, otherwise (if there is no content) shadow is a pseudo element
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);

    this.state = {shadow: false};
    // On the first render shadow should not be animated, but rather shown immediately if container is scrollable
    this.animate = false;

    this.observerStuckRef = createRef();
    this.observerShadowRef = createRef();
  }

  componentDidMount() {
    if (isIntersectionObserverSupported) {
      this.headerShadowObserver = new IntersectionObserver(entries => {
        for (const entry of entries) {
          if (entry.target === this.observerShadowRef.current) {
            if (!entry.isIntersecting && !this.state.shadow) {
              this.setState({shadow: true});
            } else if (entry.isIntersecting && this.state.shadow) {
              this.setState({shadow: false});
            } else if (!this.animate) {
              // If it the first render and no shadow is needed, mark it animated in the future when user will start scrolling
              this.animate = true;
            }
          }
        }
      });

      this.headerShadowObserver.observe(this.observerStuckRef.current);
      this.headerShadowObserver.observe(this.observerShadowRef.current);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.animate && this.state.shadow !== prevState.shadow) {
      // If it the first render and no shadow is needed, mark it animated in the future when user will start scrolling
      this.animate = true;
    }
  }

  componentWillUnmount() {
    if (this.headerShadowObserver) {
      this.headerShadowObserver.disconnect();
    }
  }

  render() {
    const {
      children,
      top,
      bottom,
      noShadow,
      position = 'var(--0px)',
      shadowBacklash = 'var(--0px)',
      theme,
      ...skickyElementProps
    } = mixThemeWithProps(styles, this.props);
    const {
      animate,
      state: {shadow},
    } = this;
    const vars = {'--modal-sticky-position': position, '--modal-sticky-shadow': shadowBacklash};
    const side = bottom ? theme.bottom : theme.top;

    skickyElementProps.style = {...skickyElementProps.style, ...vars};
    skickyElementProps.className = cx(skickyElementProps.className, theme.sticky, side, {
      [theme.pseudo]: !children,
      [theme.shadow]: shadow && !noShadow,
      [theme.animate]: animate && !isMotionReduced(),
    });

    const sticky = <div {...skickyElementProps}>{children}</div>;
    const observers = (
      <div className={cx(theme.observers, side)} style={vars}>
        <div className={theme.observerStuck} ref={this.observerStuckRef} />
        <div className={theme.observerShadow} ref={this.observerShadowRef} />
      </div>
    );

    return bottom ? (
      <>
        {sticky}
        {observers}
      </>
    ) : (
      <>
        {observers}
        {sticky}
      </>
    );
  }
}
