/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {Component} from 'react';
import {select} from 'redux-saga/effects';
import {connect} from 'react-redux';
import {RedirectError} from 'errors';
import {AppContext} from 'containers/App/AppUtils';
import {HeaderProps, UserName} from 'containers';
import {hrefUtils} from 'utils';
import {
  AttributeList,
  Badge,
  Button,
  Diff,
  GridLocal,
  ModalMachineAuto,
  Notifications,
  ToolBar,
  ToolGroup,
} from 'components';
import {isAPIAvailable, edge} from 'api/apiUtils';
import {getGridSettings} from './ServiceViewConfig';
import {removeServices} from 'containers/Service/List/ServiceListSaga';
import {getServiceItem, getServiceVersions} from 'containers/Service/Item/ServiceItemState';
import {getServiceDefinitionRows, getOs} from 'containers/Service/ServiceUtils';
import ProvisioningNotification from 'containers/Provisioning/ProvisioningNotification';
import {isEntityEdited} from 'utils/dataValidation';

const allPortsServiceDefRow = {key: intl('Common.ALL'), data: {portProto: intl('Common.ALL')}};

// we need to ignore selector when container is controlled, otherwise selector will overwrite props
const mapStateToProps = (state, props) => (props.controlled ? {} : getServiceItem(state));

@connect(mapStateToProps, null, null, {forwardRef: true})
export default class ServiceView extends Component {
  static contextType = AppContext;
  static prefetch = function* () {
    const {versions} = yield select(getServiceVersions);

    if (!versions?.pversionObj) {
      throw new RedirectError({to: 'services.list', proceedFetching: true, thisFetchIsDone: true});
    }
  };

  static propTypes = {
    versions: PropTypes.shape({
      pversionObj: PropTypes.object,
      prevPversionObj: PropTypes.object,
      draft: PropTypes.object,
      active: PropTypes.object,
    }),
    pversion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    isProvisionDisabled: PropTypes.bool,

    // Props that can be passed by parent to handle any action/navigation for e.g. useful when rendering page in a Modal
    onPversionNavigate: PropTypes.func,
    controlled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      serviceId: hrefUtils.getId(props.versions.pversionObj.href),
      remove: null,
    };

    this.handleProvisioningDone = this.handleProvisioningDone.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.onRemoveServices = this.onRemoveServices.bind(this);
    this.handleRemoveOnDone = this.handleRemoveOnDone.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.versions !== prevState.versions) {
      const nextState = {
        ...nextProps,
        pversionObj: nextProps.versions.pversionObj,
        prevPversionObj: nextProps.versions.prevPversionObj,
      };

      nextState.os = getOs(nextState.pversionObj);
      nextState.gridSettings = getGridSettings(nextState.os);

      if (nextState.os === 'windows') {
        nextState.serviceDefRows = getServiceDefinitionRows(nextState.pversionObj.windows_services);
        nextState.prevServiceDefRows = getServiceDefinitionRows(nextState.prevPversionObj?.windows_services);
      } else if (__ANTMAN__ && nextState.os === 'windows_egress') {
        nextState.serviceDefRows = getServiceDefinitionRows(nextState.pversionObj.windows_egress_services, true);
        nextState.prevServiceDefRows = getServiceDefinitionRows(
          nextState.prevPversionObj?.windows_egress_services,
          true,
        );
      } else {
        nextState.serviceDefRows = getServiceDefinitionRows(nextState.pversionObj.service_ports);
        nextState.prevServiceDefRows = getServiceDefinitionRows(nextState.prevPversionObj?.service_ports);
      }

      return nextState;
    }

    return null;
  }

  async handleProvisioningDone() {
    const {
      state: {serviceId},
      context: {navigate},
    } = this;

    navigate({params: {id: serviceId, pversion: 'active'}});
  }

  handleRemove() {
    this.setState(({remove}) => ({remove: !remove}));
  }

  handleRemoveOnDone() {
    this.context.navigate({to: 'services.list'});
  }

  onRemoveServices() {
    const {
      context: {fetcher},
      props: {
        versions: {
          pversionObj: {href},
        },
      },
    } = this;

    return fetcher.spawn(removeServices, {hrefs: [href]});
  }

  renderRemoveConfirmation() {
    return (
      <ModalMachineAuto saga={this.onRemoveServices} onClose={this.handleRemove} onDone={this.handleRemoveOnDone}>
        {{
          title: intl('Services.Delete', {count: '1'}),
          confirmMessage: intl('Services.DeleteConfirm'),
          error: {
            title: intl('Services.Delete', {count: '1'}),
            customErrorMessage: {
              service_referenced: intl('Services.List.CannotDeleteBoundToRules', {count: 1}),
              service_referenced_by_virtual_service: intl('Services.List.CannotDeleteBoundToBPS', {count: 1}),
              service_referenced_by_enforcement_boundary: intl('Services.List.CannotDeleteBoundToEnforcementBoundary', {
                count: 1,
              }),
            },
          },
        }}
      </ModalMachineAuto>
    );
  }

  render() {
    const {
      props: {
        versions: {pversionObj, prevPversionObj, draft, active},
        pversion,
        pversionObjIsDeleted,
        isProvisionDisabled,
        controlled,
        onPversionNavigate,
      },
      state: {remove, serviceId, gridSettings, serviceDefRows, prevServiceDefRows},
    } = this;

    const isOldVersion = !_.isNaN(pversion) && pversion > 0;
    const isAllServices = pversionObj.name === intl('Common.AllServices');
    // hide action button if controlled prop is passed OR if it is an old verison of Service
    const actionButtonsHidden = isOldVersion || controlled || isAllServices;
    const actionButtonsDisabled = draft?.update_type === 'delete' || !isAPIAvailable('service.update');

    const osValue =
      __ANTMAN__ && pversionObj.windows_egress_services
        ? intl('Services.Mixin.Os.WindowsOutbound.Title')
        : pversionObj.windows_services
        ? intl('Services.Mixin.Os.Windows.Title')
        : intl('Services.Mixin.Os.All.Title');

    return (
      <>
        {!controlled && <HeaderProps title={intl('Common.Services')} subtitle={pversionObj.name} up="services" />}
        {isAllServices ? (
          <Notifications sidebar>
            {[
              {
                type: 'warning',
                title: intl('Common.ModificationNotAllowed'),
                message: intl('Services.List.CannotDeleteAny'),
              },
            ]}
          </Notifications>
        ) : (
          // show notification for older and draft versions
          (isOldVersion || draft?.update_type) && (
            <ProvisioningNotification
              disabled={isProvisionDisabled}
              params={{active: {pversion: 'active', id: serviceId}, draft: {pversion: 'draft', id: serviceId}}}
              pversion={pversion}
              onDone={this.handleProvisioningDone}
              objectsToProvision={{services: [{href: draft?.href}]}}
              active={active}
              draft={draft}
              pversionObjIsDeleted={pversionObjIsDeleted}
              onNavigate={onPversionNavigate}
              hideButtons={controlled}
            />
          )
        )}
        {!actionButtonsHidden && (
          <ToolBar>
            <ToolGroup>
              <Button.Link
                icon="edit"
                text={intl('Common.Edit')}
                tid="edit"
                disabled={actionButtonsDisabled}
                link={{
                  to: 'services.item.edit',
                  params: {id: serviceId, pversion: 'draft'},
                }}
              />
              <Button
                icon="remove"
                text={intl('Common.Remove')}
                tid="remove"
                color="standard"
                disabled={actionButtonsDisabled}
                onClick={this.handleRemove}
              />
            </ToolGroup>
          </ToolBar>
        )}
        <AttributeList>
          {[
            controlled ? null : {divider: true},
            {
              title: intl('Common.General'),
            },
            {
              tid: 'name',
              key: intl('Common.Name'),
              value: <Diff.Text value={pversionObj.name} oldValue={prevPversionObj?.name} noDiff={!prevPversionObj} />,
            },
            {
              tid: 'description',
              key: intl('Common.Description'),
              value: (
                <Diff.Text
                  value={pversionObj.description}
                  oldValue={prevPversionObj?.description}
                  noDiff={!prevPversionObj}
                />
              ),
            },
            {
              tid: 'created',
              key: intl('Common.Created'),
              value: UserName.dateAtTimeBy(pversionObj.created_at, pversionObj.createdBy, 'full_name'),
            },
            draft?.update_type !== 'create'
              ? {
                  tid: 'modified',
                  key: intl('Common.LastModified'),
                  value: UserName.dateAtTimeBy(pversionObj.updated_at, pversionObj.updatedBy, 'full_name'),
                }
              : null,
            !edge && pversionObj.external_data_set !== undefined && pversionObj.external_data_set !== null
              ? {
                  tid: 'extdataset',
                  key: intl('Common.ExternalSet'),
                  value: (
                    <Diff.Option
                      value={pversionObj.external_data_set}
                      oldValue={prevPversionObj?.external_data_set}
                      noDiff={!prevPversionObj?.external_data_set}
                    />
                  ),
                }
              : null,
            !edge && pversionObj.external_data_reference !== undefined && pversionObj.external_data_reference !== null
              ? {
                  tid: 'extdataref',
                  key: intl('Common.ExternalReference'),
                  value: isEntityEdited(pversionObj.external_data_reference) ? (
                    <>
                      <Badge type="updated" style={{lineHeight: 'var(--21px)'}}>
                        {intl('Common.Edited')}
                      </Badge>
                      {pversionObj.external_data_reference}
                    </>
                  ) : (
                    <Diff.Option
                      value={pversionObj.external_data_reference}
                      oldValue={prevPversionObj?.external_data_reference}
                      noDiff={!prevPversionObj?.external_data_reference}
                    />
                  ),
                }
              : null,
            {
              divider: true,
            },
            {
              title: intl('Common.Attributes'),
            },
            {
              tid: 'os',
              key: intl('Services.Mixin.Os.Title'),
              value: osValue,
            },
            {
              tid: 'serviceDefinition',
              key: intl('Services.ServiceDefinitions'),
              value: (
                <GridLocal.Diff
                  secondary
                  settings={gridSettings}
                  {...(controlled && {offset: '0px'})}
                  rows={serviceDefRows.length ? serviceDefRows : [allPortsServiceDefRow]}
                  prevRows={prevServiceDefRows.length ? prevServiceDefRows : [allPortsServiceDefRow]}
                  noDiff={!prevPversionObj}
                />
              ),
            },
          ]}
        </AttributeList>
        {remove && this.renderRemoveConfirmation()}
      </>
    );
  }
}
