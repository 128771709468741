/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {fork, call, select, put, all} from 'redux-saga/effects';
import {getPermissions} from './PermissionState';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {fetchAllAuthSecPrincipals} from './AuthSecPrincipalSaga';
import apiSaga from 'api/apiSaga';
import {hrefUtils} from 'utils';

export function* fetchCommonRBACData({force = false} = {}) {
  yield fork(fetchAllUsers, {force});
  yield fork(fetchAllAuthSecPrincipals, {force});
  yield fork(fetchAllPermissions, {force});
}

export function* fetchAllPermissions({force = false} = {}) {
  // representation: 'permission_scope_auth_security_principal' will return scope's key and value
  // e.g. scope: [{href: "/orgs/1/labels/855", key: "app", value: "rbac-a-075070"}]
  return yield call(apiSaga, 'org_permissions.get_collection', {
    cache: !force,
    query: {representation: 'permission_scope_auth_security_principal'},
    beforeFetch: put({type: 'PERMISSION_GETTING_ALL'}),
    *onDone({data: permissions}) {
      if (force || permissions !== (yield select(getPermissions))) {
        yield put({type: 'PERMISSION_GET_ALL', data: {permissions}});
      }

      return {permissions};
    },
  });
}

export function* removePermission({href}) {
  yield call(apiSaga, 'org_permission.delete', {
    params: {permission_id: hrefUtils.getId(href)},
    hrefs: [href],
  });
}

export function* removePermissions(permissionHrefs) {
  const removed = [];
  const errors = new Map();

  yield all(
    permissionHrefs.map(function* (href) {
      try {
        yield call(apiSaga, 'org_permission.delete', {
          params: {permission_id: hrefUtils.getId(href)},
        });

        removed.push(href);
      } catch (err) {
        const message = err.data?.[0]?.message ?? err.message;
        const hrefs = errors.get(message) || [];

        hrefs.push(href);
        errors.set(message, hrefs);
      }
    }),
  );

  return {removed, errors};
}

export function* updatePermissions({permissionHrefs, updateData}) {
  const updated = [];
  const errors = new Map();

  yield all(
    permissionHrefs.map(function* (href) {
      try {
        yield call(apiSaga, 'org_permission.update', {
          params: {permission_id: hrefUtils.getId(href)},
          data: updateData,
        });

        updated.push(href);
      } catch (err) {
        const message = err.data?.[0]?.message ?? err.message;
        const hrefs = errors.get(message) || [];

        hrefs.push(href);
        errors.set(message, hrefs);
      }
    }),
  );

  return {updated, errors};
}

/*
 *  permissionsToCreate is list of permissionModels
 *  [<permissionModel>, ...]
 *
 *  each permissionModel = {
 *    scope: [{href: <labelHref>}]  //array of up to three label hrefs
 *    role: {href: <roleHref>}
 *    authSecPrincipal: {href: <authSecPrincipalHref>}
 *  }
 */
export function* addPermissions(permissionsToCreate) {
  const created = [];
  const errors = new Map();

  yield all(
    permissionsToCreate.map(function* (permissionModel) {
      try {
        const {
          data: {href},
        } = yield call(apiSaga, 'org_permissions.create', {
          data: permissionModel,
        });

        created.push(href);
      } catch (error) {
        const errorData = _.get(error, 'data[0]');
        const message = (errorData && errorData.message) || error.message;
        const roles = errors.get(message)?.roles || [];

        error.roles = [...roles, permissionModel.role];
        error.errorData = errorData;
        errors.set(message, error);
      }
    }),
  );

  return {created, errors};
}
