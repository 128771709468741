/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import listReducers from './List/ContainerWorkloadListState';
import instanceReducers from 'containers/ContainerWorkload/Item/ContainerWokloadItemState';
import containersReducers from 'containers/ContainerWorkload/Item/Containers/ContainerWorkloadContainerState';
import rulesReducers from 'containers/ContainerWorkload/Item/Rules/ContainerWorkloadRulesState';

export default {
  containerWorkloads: combineReducers({
    ...listReducers,
    ...instanceReducers,
    ...containersReducers,
    ...rulesReducers,
  }),
};
