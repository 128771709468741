/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {useCallback, useState} from 'react';
import {Button, ModalMachineAuto} from 'components';
import {removeUnmanagedWorkload} from '../WorkloadSaga';

WorkloadRemove.propTypes = {
  disabled: PropTypes.bool,
  showCounter: PropTypes.bool,
  rowsMap: PropTypes.object.isRequired,
  hrefsToRemove: PropTypes.instanceOf(Set).isRequired,

  onDone: PropTypes.func,
  onButtonHover: PropTypes.func,
};

export default function WorkloadRemove(props) {
  const {hrefsToRemove, rowsMap, showCounter = false, disabled = false, onButtonHover, onDone} = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        color="standard"
        textIsHideable
        icon="remove"
        tid="remove"
        text={intl('Common.Remove')}
        counter={showCounter ? hrefsToRemove.size : undefined}
        counterColor="red"
        disabled={!hrefsToRemove.size || disabled}
        onClick={useCallback(() => !showModal && setShowModal(true), [setShowModal, showModal])}
        onMouseEnter={useCallback(evt => !showModal && onButtonHover?.(evt, 'enter'), [onButtonHover, showModal])}
        onMouseLeave={useCallback(evt => !showModal && onButtonHover?.(evt, 'leave'), [onButtonHover, showModal])}
      />

      {showModal && (
        <ModalMachineAuto
          settled
          onClose={_.partial(setShowModal, false)}
          saga={removeUnmanagedWorkload}
          hrefs={[...hrefsToRemove]}
          onDone={onDone}
          rowsMap={rowsMap}
          listItem={['name']}
        >
          {{
            title: ({selectedHrefs}) => intl('Workloads.Remove.TitleConfirmation', {count: selectedHrefs.length}),
            confirmMessage: ({selectedHrefs}) => intl('Workloads.Remove.Confirm', {count: selectedHrefs.length}),
            submitProps: {tid: 'ok'},
            error: {
              title: ({selectedHrefs}) => intl('Workloads.Remove.TitleResult', {count: selectedHrefs.length}),
              itemSuccessMessage: ({successHrefs}) => intl('Workloads.Remove.Success', {count: successHrefs.length}),
            },
          }}
        </ModalMachineAuto>
      )}
    </>
  );
}
