/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
/* intl-disable - TODO: Add keys to intl lib */
import _ from 'lodash';
import {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import intl from 'intl';
import {formatUtils} from 'utils';
import {Button} from 'components';
import {getSelectorFacets} from 'containers/Selectors/SelectorState';
import VersionPicker from './VersionPicker';
import styles from './VersionRangePicker.css';

@connect(state => ({presets: getSelectorFacets(state, 'vens')?.sort() ?? []}))
export default class VersionRangePicker extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func, // this is provided by selector
    onSave: PropTypes.func, // this is provided by selector
    categoryKey: PropTypes.string.isRequired, // category key for this selector's category
    categoryName: PropTypes.string, // display Name for this selector's category
    presets: PropTypes.array,
  };

  static defaultProps = {
    onClose: _.noop,
    onSave: _.noop,
    categoryName: intl('Common.Version'),
  };

  state = {
    activeInput: 'from',
    fromShowDropdown: false,
    toShowDropdown: false,
  };

  setDropdown = type => {
    if (type === 'from') {
      this.setState({fromShowDropdown: true, toShowDropdown: false});
    } else if (type === 'to') {
      this.setState({fromShowDropdown: false, toShowDropdown: true});
    } else if (type === 'none') {
      this.setState({fromShowDropdown: false, toShowDropdown: false});
    }
  };

  setPreset = (type, val) => {
    if (type === 'from') {
      this.setState({selectedPresetFrom: val});
    } else {
      this.setState({selectedPresetTo: val});
    }
  };

  setActive = type => {
    this.setDropdown('none');
    this.setState({activeInput: type});
  };

  handleKeyDown = event => {
    event.stopPropagation();

    if (event.key === 'Escape') {
      const {fromShowDropdown, toShowDropdown} = this.state;

      if (!fromShowDropdown && !toShowDropdown) {
        this.props.onClose();
      }
    }
  };

  handleClick = () => {
    const {fromShowDropdown, toShowDropdown} = this.state;

    if (fromShowDropdown || toShowDropdown) {
      this.setDropdown('none');
    }
  };

  handleCancel = () => {
    this.props.onClose();
  };

  handleApply = () => {
    const finalValues = this.handleCalcFinalValues();

    this.props.onSave(finalValues);
  };

  handleChange = val => {
    const {activeInput} = this.state;

    this.setState({[activeInput]: val});
  };

  handleRemoveValue(type) {
    this.setState({[type]: null, activeInput: type});
  }

  handleCalcFinalValues = () => {
    const {presets} = this.props;
    const {from, to, selectedPresetFrom, selectedPresetTo} = this.state;
    const hasPresets = presets.length > 0;
    const fromPresetValue = hasPresets && presets.find(preset => preset === selectedPresetFrom);
    const toPresetValue = hasPresets && presets.find(preset => preset === selectedPresetTo);
    const formattedValue = formatUtils.getRangeExpression(from, to, 'version');
    // Pass formatted value to item so Selector can render "pill".
    // Also pass the raw 'from' and 'to' values so other pages can use to send to API
    const item = {
      from: !hasPresets || selectedPresetFrom === 'custom' ? from : fromPresetValue,
      to: !hasPresets || selectedPresetTo === 'custom' ? to : toPresetValue,
      value: formattedValue,
      name: this.props.categoryKey,
      categoryName: this.props.categoryName,
    };

    return item;
  };

  render() {
    const {presets} = this.props;
    const hasPresets = presets.length > 0;
    const {
      from,
      to,
      activeInput,
      fromShowDropdown,
      toShowDropdown,
      selectedPresetFrom,
      selectedPresetTo,
      hoveredPresetFrom,
      hoveredPresetTo,
    } = this.state;

    const disableApply = hasPresets && (!selectedPresetFrom || !selectedPresetTo);

    return (
      <div className={styles.customPicker} onKeyDown={this.handleKeyDown} onClick={this.handleClick}>
        <div className={styles.inputHolder}>
          <div className={styles.inputLabel}>{intl('VEN.Filter.Min')}</div>
          <VersionPicker
            type="from"
            value={from}
            focused={activeInput === 'from'}
            showDropdown={fromShowDropdown && hasPresets}
            presets={presets}
            selectedPreset={selectedPresetFrom}
            hoveredPreset={hoveredPresetFrom}
            placeholder={intl('VEN.Filter.SelectAVersion')}
            setDropdown={this.setDropdown}
            setPreset={this.setPreset}
            setActive={this.setActive}
            onRemoveValue={this.handleRemoveValue}
            onChange={this.handleChange}
          />
        </div>
        <div className={styles.inputHolder}>
          <div className={styles.inputLabel}>{intl('VEN.Filter.Max')}</div>
          <VersionPicker
            type="to"
            value={to}
            focused={activeInput === 'to'}
            showDropdown={toShowDropdown && hasPresets}
            presets={presets}
            selectedPreset={selectedPresetTo}
            hoveredPreset={hoveredPresetTo}
            placeholder={intl('VEN.Filter.SelectAVersion')}
            setDropdown={this.setDropdown}
            setPreset={this.setPreset}
            setActive={this.setActive}
            onRemoveValue={this.handleRemoveValue}
            onChange={this.handleChange}
          />
        </div>
        <div className={styles.actions}>
          <div className={styles.actionButton}>
            <Button noFill text={intl('Common.Cancel')} onClick={this.handleCancel} />
          </div>
          <div className={styles.actionButton}>
            <Button text={intl('Common.Apply')} disabled={disableApply} onClick={this.handleApply} />
          </div>
        </div>
      </div>
    );
  }
}
