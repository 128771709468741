/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {call, put, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {fetchVirtualServerList} from 'containers/VirtualServer/List/VirtualServerListSaga';
import {cachedResponses} from 'api/apiCache';
import {fetchSelectiveUsers} from 'containers/User/UserSagas';
import {getLoadBalancerInstance} from './Item/LoadBalancerItemState';
import {RedirectError} from 'errors';
import {isLoadBalancerEnabled} from './LoadBalancerState';
import {fetchDeviceTypes} from 'containers/LoadBalancer/List/LoadBalancerListSaga';
import {fetchNetworkEnforcementNodes} from '../Switch/NetworkEnforcementNodeSaga';

export function* fetchLoadBalancer({id, force = false}) {
  return yield call(apiSaga, 'slbs.get_instance', {
    params: {slb_id: id},
    cache: !force,
    *onDone({data}) {
      if (force || data !== (yield select(getLoadBalancerInstance))) {
        yield put({type: 'LOADBALANCER_GET_INSTANCE', data});
      }
    },
  });
}

export function* fetchLoadBalancerItem({params: {id}}, refetch = false) {
  yield call(fetchDeviceTypes);

  const loadBalancerIsEnabled = yield select(isLoadBalancerEnabled);

  if (!loadBalancerIsEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  try {
    const {data} = yield call(fetchLoadBalancer, {id, force: refetch});

    yield call(fetchSelectiveUsers, [data.created_by, data.updated_by], refetch);
    yield call(fetchNetworkEnforcementNodes, {force: refetch});
  } catch {
    throw new RedirectError({to: 'loadBalancers.list', proceedFetching: true, thisFetchIsDone: true});
  }
}

export function* fetchLoadBalancerServersPage(route, refetch = false) {
  const {href, name} = yield select(getLoadBalancerInstance); // filter virtual server list to those associated with the current slb

  yield call(fetchVirtualServerList, route, refetch, {fetchSLBs: false, filter: {slbs: [{value: name, href}]}});
}

export function* updateLoadBalancer({id, payload}) {
  yield call(apiSaga, 'slb.update', {
    params: {slb_id: id},
    data: payload,
    *onDone() {
      // Invalidate loadBalancer instance
      cachedResponses.removeByMethodName('slbs.get_instance');
      // Invalidate loadBalancer list
      cachedResponses.removeByMethodName('slbs.get_collection');
    },
  });
}

export function* createVirtualServer(payload, pversion) {
  yield call(apiSaga, 'virtual_servers.create', {
    data: payload,
    params: {pversion: pversion || 'draft'},
    *onDone() {
      // After virtual server is created invalidate virtual server list cache
      cachedResponses.removeByMethodName('discovered_virtual_servers.get_collection');
    },
  });
}
