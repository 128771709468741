/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import apiSaga from 'api/apiSaga';
import {all, call, select, put, retry} from 'redux-saga/effects';
import {cachedResponses} from 'api/apiCache';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {fetchService} from 'containers/Service/Item/ServiceItemSaga';
import {getSelectorSettings} from './ServiceListConfig';
import {hrefUtils} from 'utils';
import {reverseLookupProtocol} from '../ServiceUtils';
import {
  getServices,
  getServicesCount,
  getGridSettings,
  getAllServicesHref,
  getServicesHrefMap,
} from './ServiceListState';
import gridSaga from 'components/Grid/GridSaga';
import {RequestError} from 'errors';

export function* fetchServices({filter, pversion = 'draft', force = false} = {}) {
  const query = {max_results: 500};
  const selectorSettingsObject = yield select(getSelectorSettings);

  if (filter) {
    for (const [name, [value]] of Object.entries(filter)) {
      if (value) {
        if (selectorSettingsObject.staticValues.hasOwnProperty(name)) {
          query.update_type = selectorSettingsObject.staticValues[name][value];
        } else {
          // EYE-50379: get_collection API uses "proto",
          // but facets API still uses "protocol", until the facet API is
          // transition to "proto", leave the filter as "protocol" in UI,
          // and just before hitting get_collection, send "proto" as key instead
          query[name === 'protocol' ? 'proto' : name] = value;
        }
      }
    }
  }

  const {data: list, count} = yield call(apiSaga, 'services.get_collection', {
    query,
    params: {pversion},
    cache: !force,
  });

  if (force || list !== (yield select(getServices)) || count !== (yield select(getServicesCount))) {
    yield put({type: 'SERVICE_GET_LIST', data: {list, count}});
  }

  return {list, count};
}

export function* removeServices({hrefs, pversion = 'draft'}) {
  yield call(apiSaga, 'services.delete', {
    params: {pversion},
    data: {services: hrefs.map(href => ({href}))},
    *onDone() {
      yield put({type: 'SERVICES_REMOVE', data: hrefs});
      // Clear list collection cache
      cachedResponses.removeByMethodName('services.get_collection');
    },
    hrefs,
  });
}

export function* fetchServiceList(route, refetch = false) {
  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getSelectorSettings().filterMap,
    *onSaga({filterParams}) {
      const filter = filterParams.isEmpty ? undefined : filterParams.valid;

      if (filter && filter.protocol) {
        filter.protocol[0] = reverseLookupProtocol(filter.protocol[0]);
      }

      const [{list}] = yield all([
        call(fetchServices, {filter, force: refetch}),
        call(fetchAllUsers, {force: refetch}),
      ]);

      return list.length;
    },
  });
}

export function* fetchServiceFacet({query, params, retries = 2, autocomplete = false}) {
  try {
    // Call facets to get the matching query name
    const facets = yield retry(retries, 0, apiSaga, autocomplete ? 'services.autocomplete' : 'services.facets', {
      params,
      query,
    });

    return facets;
  } catch (error) {
    // Throw error message when request fails
    throw new RequestError({
      message: error,
    });
  }
}

export function* fetchAllServicesHref() {
  let allServicesHref = yield select(getAllServicesHref) ||
    (yield select(getServices)).find(({name}) => name === intl('Common.AllServices'))?.href;

  if (!allServicesHref) {
    const service = yield call(fetchServiceFacet, {
      autocomplete: true,
      query: {facet: 'name', query: intl('Common.AllServices')},
      params: {pversion: 'active'},
    });

    allServicesHref = service?.data.matches[0]?.href;

    yield put({type: 'GET_ALL_SERVICES_HREF', data: allServicesHref});
  }

  return allServicesHref;
}

// Take services from store or make parallel calls to fetch needed service
export function* fetchSelectiveServices(servicesOrHrefs = [], {filterEgress = false, force = false} = {}) {
  const services = yield select(getServicesHrefMap);

  const resultServicesMap = new Map();

  yield all(
    servicesOrHrefs.reduce((result, service) => {
      if (!service) {
        return result;
      }

      if (typeof service.href === 'string' && service.href) {
        if (!force && services[service.href]) {
          // If service already exists in store, just return it
          resultServicesMap.set(service.href, services[service.href]);
        } else {
          // Otherwise, fetch this service and put it into the store
          result.push(
            call(function* () {
              try {
                yield call(fetchService, {id: hrefUtils.getId(service.href), force});

                const services = yield select(getServicesHrefMap);

                resultServicesMap.set(service.href, services[service.href]);
              } catch {
                // A Service in the service's table may not exist even though they exist in object history, that is fine
                resultServicesMap.set(service.href, null);
              }
            }),
          );
        }
      }

      return result;
    }, []),
  );

  return __ANTMAN__
    ? [...resultServicesMap.values()].filter(({windows_egress_services}) =>
        filterEgress ? Boolean(windows_egress_services) : !windows_egress_services,
      )
    : [...resultServicesMap.values()];
}
