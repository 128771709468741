/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import intl from 'intl';
import countries from 'i18n-iso-countries';
import {createSelector} from 'reselect';
import {getRouteName, getRouteCurrentParams} from 'containers/App/AppState';
import {usStates, USA} from '../../MyManagedTenantsUtils';
import {getMSPTenant} from '../MyManagedTenantsItemState';

export const getCountryOptions = createSelector([], () =>
  Object.entries(countries.getNames(intl.lang)).map(country => ({label: country[1], value: country[0]})),
);

export const getUsStatesOptions = createSelector([], () =>
  Object.entries(usStates).map(state => ({label: state[1], value: state[0]})),
);

export const getProductsMap = createSelector(
  [],
  () =>
    new Map([
      ['edge', intl('Common.Edge')],
      ['core', intl('Common.Core')],
      ['corex', intl('Common.Corex')],
    ]),
);

export const getMyManagedTenantsEdit = createSelector(
  [getRouteName, getRouteCurrentParams, getCountryOptions, getUsStatesOptions, getMSPTenant, getProductsMap],
  (routeName, currentRouteParams, countryOptions, usStatesOptions, mspTenant, productMaps) => {
    const tenantId = currentRouteParams.id;
    const isEdit = routeName.endsWith('edit');
    const isCreate = routeName.endsWith('create');
    const product = isEdit ? mspTenant.product_name : currentRouteParams.product;
    const productName = productMaps.get(product) ?? product;

    let initialValues;

    if (isCreate) {
      initialValues = {
        display_name: '',
        domain_name: '',
        web_site: '',
        country: countryOptions.find(option => option.value === 'US') || {},
        address1: '',
        address2: '',
        city: '',
        state: {},
        province: '',
        zip: '',
        product_name: product,
      };
    } else {
      const {state, country, address1, address2, display_name, domain_name, web_site, city, zip} = mspTenant;
      const isUSAddress = country === USA;

      initialValues = {
        display_name: display_name || '',
        domain_name: domain_name || '',
        web_site: web_site || '',
        country: countryOptions.find(option => option.label === country) || {},
        address1: address1 || '',
        address2: address2 || '',
        city: city || '',
        state: isUSAddress ? usStatesOptions.find(option => option.label === state) || {} : {},
        province: !isUSAddress && state ? state : '',
        zip: zip || '',
        product_name: product,
      };
    }

    return {
      isEdit,
      isCreate,
      tenantId,
      initialValues,
      countryOptions,
      usStatesOptions,
      productName,
      name: initialValues.display_name,
      ...(!isCreate && {domainName: initialValues.domain_name}),
    };
  },
);
