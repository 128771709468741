/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {hrefUtils} from 'utils';

export const getUserById = (users, id) => {
  if (!id || !users) {
    return;
  }

  return users.find(user => hrefUtils.getId(user.href) === id);
};

export const getUserByHref = (users, href) => {
  if (!href || !users) {
    return;
  }

  return users.find(user => user.href === href);
};

export const getMergedOrgs = (existingOrgs = [], orgs = []) =>
  existingOrgs.map(org => ({...org, ...orgs.find(({href}) => href === org.href)}));
