/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {createElement, isValidElement} from 'react';
import {Tooltip} from 'components';
import {tidUtils, reactUtils} from 'utils';
import type {ReactStrictNode} from 'utils/types';
import type {ThemeProps} from '@css-modules-theme/react';
import type {TooltipProps} from 'components/Tooltip/Tooltip';

const defaultTid = 'comp-toolgroup';

type ToolGroupProps = {
  tid?: string;
  expand?: boolean;

  /**
   * whether we should render a singleton or not.
   *
   * Default: `false` - by default we render the ToolGroup inside of a singleton
   */
  noSingleton?: boolean;

  /** we can pass props to the singleton if we want */
  singletonProps?: TooltipProps;
  children?: ReactStrictNode;
} & ThemeProps;

export default function ToolGroup(props: ToolGroupProps): JSX.Element {
  const {children, theme, expand, noSingleton = false, singletonProps} = props;

  const items = reactUtils.unwrapChildren(children).map((item, index) => (
    <div
      key={isValidElement(item) && item.key ? item.key : index}
      className={theme!.toolGroupItem}
      data-tid="elem-toolgroup-item"
    >
      {item}
    </div>
  ));

  const toolgroup = createElement(
    'div',
    {
      'data-tid': tidUtils.getTid(defaultTid, props.tid),
      'className': cx(theme!.toolGroup, {[theme!.toolGroupExpand]: expand}),
    },
    ...items,
  );

  return noSingleton ? toolgroup : <Tooltip.Singleton {...singletonProps}>{toolgroup}</Tooltip.Singleton>;
}
