/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import listReducers from './List/ReportsListState';

export default {
  exportReports: combineReducers({
    ...listReducers,
  }),
};
