/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {spawn, all, call, select, put} from 'redux-saga/effects';
import {getAllUsersMap, getUser, getUserId, getUserOrgs} from './UserState';
import {hrefUtils, sagasUtils, webStorageUtils} from 'utils';
import {getMergedOrgs} from './UserUtils';
import apiSaga from 'api/apiSaga';

let pollingTask = null;

export function* loginUser({authToken}) {
  // Try to get user from tessereact jump first. #USERJUMP
  let user = webStorageUtils.getSessionItem('JUMPS_USER', {remove: true});

  if (!user) {
    const options = {logoutOn401: false};

    if (authToken) {
      options.headers = {Authorization: `Token ${authToken}`};
    }

    ({data: user} = yield call(apiSaga, 'users.login', options));
  } else {
    window['--JUMPED_FROM_LEGACY--'] = true;
  }

  yield put({type: 'USER_LOGIN_SUCCESS', data: user});

  return yield select(getUser);
}

let fetchedUsers;

export function* fetchAllUsers({force = false} = {}) {
  return yield call(apiSaga, 'users.get_collection', {
    cache: !force,
    *afterFetch({data}) {
      // Put users into store only if they are really changed
      // We compare manually here instead of using compareResponseOnCacheInvalidation option,
      // because user.all reducer transforms received data,
      // so 'data' never equals to 'yield select(getAllUsers)' even if api response is the same
      if (!_.isEqual(data, fetchedUsers)) {
        yield put({type: 'USER_GET_ALL', data});
        fetchedUsers = data;
      }

      return data;
    },
  });
}

export function* fetchUser({force = false, id} = {}) {
  yield call(apiSaga, 'users.get_instance', {
    cache: !force,
    params: {id},
    *afterFetch({data}) {
      // Set a user instance to the redux store state.users.all
      yield put({type: 'USER_GET_INSTANCE', data});
    },
  });
}

// Take users from store or make parallel calls to fetch needed users
export function* fetchSelectiveUsers(usersOrHrefs = [], force = false) {
  const users = yield select(getAllUsersMap);
  const resultUsersMap = new Map();

  yield all(
    usersOrHrefs.reduce((result, href) => {
      if (!href) {
        return result;
      }

      // If array of user objects is passed, take href from each object first
      if (typeof href === 'object') {
        href = href.href;
      }

      if (typeof href === 'string' && href.length && hrefUtils.getId(href) !== undefined) {
        if (!force && users.has(href)) {
          // If user already exists in store, just return it
          resultUsersMap.set(href, users.get(href));
        } else {
          // Otherwise fetch this user and put it into the store
          result.push(
            call(function* () {
              try {
                yield call(fetchUser, {id: hrefUtils.getIdNumber(href), force});

                const users = yield select(getAllUsersMap);

                resultUsersMap.set(href, users.get(href));
              } catch {
                // A User in the user's table may not exist even though they exist in object history, that is fine
                resultUsersMap.set(href, null);
              }
            }),
          );
        }
      }

      return result;
    }, []),
  );

  return resultUsersMap;
}

export function* fetchUserOrg({userId, force = false} = {}) {
  const currentUserId = yield select(getUserId);

  if (!userId) {
    userId = currentUserId;
  } else if (userId !== currentUserId) {
    // If fetching org for other user, try to fetch all users first
    yield call(fetchAllUsers);
  }

  const {data: orgs} = yield call(apiSaga, 'user.orgs', {
    cache: !force,
    params: {id: userId},
    query: {representation: 'org_permissions'},
  });

  let existingOrgs;

  if (userId === currentUserId) {
    existingOrgs = yield select(getUserOrgs);
  }

  const mergedOrgs = getMergedOrgs(existingOrgs, orgs);

  if (!_.isEqual(existingOrgs, mergedOrgs)) {
    // Put users to store
    yield put({type: 'USER_GET_ORG_SUCCESS', data: {orgs, userId}});
  }
}

export function* fetchUserOrgPermissions({userId, force = false} = {}) {
  const {data} = yield call(apiSaga, 'user.orgs', {
    cache: !force,
    params: {id: userId},
    query: {representation: 'org_permissions'},
  });

  if (Array.isArray(data) && data.length > 0) {
    yield put({type: 'USER_GET_ORG_PERMISSIONS', data: {userId, orgPermissions: data[0]}});
  }
}

export function* startFetchUserOrgPolling({instantStart = false} = {}) {
  if (!pollingTask || !pollingTask.isRunning()) {
    pollingTask = yield spawn(sagasUtils.startPolling, {
      interval: 15_000,
      instantStart,
      *saga() {
        yield call(fetchUserOrg, {force: true});
      },
    });
  }
}

export function* stopFetchUserOrgPolling() {
  if (pollingTask && pollingTask.isRunning()) {
    pollingTask.cancel();
    pollingTask = null;
  }
}
