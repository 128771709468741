/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {ipUtils, eventUtils} from 'utils';
import {createSelector} from 'reselect';
import {UserName} from 'containers';

export const secondsToDays = seconds => {
  const num = Number(seconds);

  return Math.floor(num / (3600 * 24));
};

export const daysToSeconds = days => {
  const num = Number(days);

  return Math.ceil(num * (3600 * 24));
};

// syslog address can either be a valid IP address or a valid domain
export const validateSyslogAddress = address => {
  if (!address) {
    return false;
  }

  /* valid domain regex i.e. "www.google.com" or "mail.google.com"
   * Fixes: https://stash.ilabs.io/plugins/servlet/jira-integration/issues/EYE-82420
   * Broken regex: splhmwdc-01.wlb_234.nam.nsroot.net (fixed now and allowed)
   *
   * ^([\da-z]+(-[\da-z]+)*\.): The domain name should be a-z or A-Z or 0-9 and and not start or end with hyphen (-) (-google.com, google.com-)
   * [a-z]{2,}: Has to be a minimum of 2 and maximum of any number of characters in the end after final period symbol
   * The domain name can be a subdomain (e.g. sub.google.org).
   */
  if (/^([\da-z]+((-|_)[\da-z]+)*\.)+[a-z]{2,}$/.test(address)) {
    return true;
  }

  return ipUtils.validateIPAddress(address);
};

export const severitySetting = createSelector([], () => ({
  error: intl('Common.Error'),
  warning: intl('Common.Warning'),
  informational: intl('SystemSettings.LogForwarding.Severity.Info'),
}));

export const severityOptions = createSelector([], () => [
  {value: 'error', label: intl('Common.Error')},
  {value: 'warning', label: intl('Common.Warning')},
  {value: 'informational', label: intl('SystemSettings.LogForwarding.Severity.Info')},
]);

export const formatOptions = createSelector([], () => [
  {value: 'JSON', label: intl('Common.JSON')},
  {value: 'CEF', label: intl('Events.CEF')},
  {value: 'LEEF', label: intl('Events.LEEF')},
]);

export const eventKeyMap = createSelector([], () => ({
  configuration_event_included: intl('Events.Types.Organizational'),
  system_event_included: intl('Common.System'),
  node_status_included: intl('Events.SystemHealthMessages'),
  traffic_flow_allowed_event_included: intl('Common.Allowed'),
  traffic_flow_potentially_blocked_event_included: intl('Common.PotentiallyBlocked'),
  traffic_flow_blocked_event_included: intl('Common.Blocked'),
}));

export const repositoryDescriptions = createSelector([], () => ({
  local: intl('Events.LocalRepositoryDesc'),
  encrypted: intl('Events.EncryptedDesc'),
  notEncrypted: intl('Events.NotEncryptedDesc'),
}));

export const protocolOptions = createSelector([], () => [
  {value: '6', label: intl('Protocol.TCP')},
  {value: '17', label: intl('Protocol.UDP')},
]);

export const tlsOptions = createSelector([], () => [
  {value: 'enabled', label: intl('Common.Enabled')},
  {value: 'disabled', label: intl('Common.Disabled')},
]);

export const formatGeneratedBy = (by, props) => {
  if (by?.service_account) {
    return <UserName user={by.service_account} {...props} />;
  }

  if (by?.user) {
    return <UserName user={by.user} {...props} />;
  }

  return eventUtils.getEventGeneratedBy(by);
};
