/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import {Icon, Pill, StatusIcon} from 'components';
import {
  formatType,
  formatServices,
  formatIPRanges,
  formatGroups,
  formatButton,
  formatNetworkProfile,
  allowRulesGridId,
  denyRulesGridId,
  defaultRulesGridId,
  defaultRuleKey,
} from './OutboundPolicyUtils';
import {validatePortAndOrProtocol} from 'containers/Service/ServiceUtils';
import {networkTypeMap, networkTypesList} from 'containers/Network/NetworkUtils';
import styles from './OutboundPolicy.css';

export const getSelectorSettings = createSelector([], () => {
  const objectMap = {
    labels: {type: 'labels', key: 'role'},
    services: {type: 'services', pversion: 'draft'},
    ip_lists: {type: 'ip_lists', pversion: 'draft'},
  };
  const filterMap = {
    labels: {value: intl('Common.Groups'), object: objectMap.labels, showFacetName: true},
    ip_lists: {value: intl('IPLists.Mixin.Ranges'), object: objectMap.ip_lists},
    services: {value: intl('Common.Services'), object: objectMap.services},
    portProto: {
      value: intl('Common.PortAndOrProtocol'),
      freeSearch: true,
      validate: validatePortAndOrProtocol,
      object: {},
    },
    networkType: {value: intl('Edge.NetworkProfile'), object: {}},
    all_groups: {value: intl('Common.AllGroups'), showFacetName: false},
  };
  const staticValues = {
    networkType: Object.fromEntries(networkTypesList),
  };

  return {filterMap, objectMap, staticValues};
});

const columns = {
  checkboxes: {},
  diffStatus: {
    headerManager: intl('Common.Status'),
    sortable: false,
    format: ({row}) => (row.diffStatus ? <StatusIcon status={row.diffStatus} /> : null),
  },
  group: {
    header: intl('Explorer.SourceGroups'),
    sortable: false,
    format: obj => {
      const {row, component} = obj;

      if (row.isInEditMode) {
        // edge groups & user groups align map to consumers & consuming_security_principals respectively
        return formatGroups(row, component);
      }

      const {rule, oldRule, pversion} = row.data;

      return (
        <Pill.Endpoint
          type="consumers"
          showUserGroups={row.data.type === 'allow' && row.key !== defaultRuleKey}
          value={rule}
          oldValue={oldRule}
          pversion={pversion}
          showGroupTooltip
        />
      );
    },
  },
  arrowIcon: {
    header: ({
      breakpoint: {
        data: {reverseProviderConsumer},
      },
    }) => <Icon theme={styles} name={`arrow-${reverseProviderConsumer ? 'right' : 'left'}`} />,
    sortable: false,
    format: ({
      breakpoint: {
        data: {reverseProviderConsumer},
      },
    }) => <Icon theme={styles} name={`arrow-${reverseProviderConsumer ? 'right' : 'left'}`} />,
  },
  ipRanges: {
    header: intl('Common.DestinationIPRanges'),
    sortable: false,
    value: 'providers',
    format: ({row, component}) => {
      if (row.isInEditMode) {
        return formatIPRanges(row, component);
      }

      const {rule, oldRule, pversion} = row.data;

      return (
        <Pill.Endpoint noIcon type="providers" value={rule} oldValue={oldRule} pversion={pversion} showIPTooltip />
      );
    },
  },
  service: {
    header: intl('Rulesets.Rules.DestinationServices'),
    sortable: false,
    value: 'ingress_services',
    format: ({row, component}) => {
      if (row.isInEditMode) {
        return formatServices(row, component);
      }

      const {rule, oldRule, pversion} = row.data;

      return (
        <Pill.ServiceDiff
          showPorts
          noIcon
          value={rule.ingress_services || rule.services}
          oldValue={oldRule?.ingress_services || oldRule?.services}
          noDiff={!oldRule}
          pversion={pversion}
          tooltip={intl('Pill.Tooltip.ViewObject', {object: 'service'})}
        />
      );
    },
  },
  networkProfile: {
    header: intl('Edge.NetworkProfile'),
    sortable: false,
    value: 'network_type',
    format: ({row, component}) => {
      if (row.isInEditMode) {
        return formatNetworkProfile(row, component);
      }

      const {rule, oldRule} = row.data;

      return (
        <Pill.Diff
          value={[
            rule.network_type
              ? {key: rule.network_type, pill: <Pill>{networkTypeMap.get(rule.network_type)}</Pill>}
              : undefined,
          ]}
          oldValue={[
            oldRule?.network_type
              ? {key: oldRule.network_type, pill: <Pill>{networkTypeMap.get(oldRule.network_type)}</Pill>}
              : undefined,
          ]}
          noDiff={!oldRule}
        />
      );
    },
  },
  type: {
    value: 'type',
    sortable: false,
    format: ({value}) => formatType(value),
  },
  buttons: {
    sortable: false,
    format: ({row, component}) => formatButton(row, component),
  },
};

const templates = [
  {
    getData() {
      return {
        reverseProviderConsumer: this.props.reverseProviderConsumer,
      };
    },
    template() {
      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        ...(this.props.reverseProviderConsumer
          ? [
              {columns: ['group'], size: 'minmax(120px, 1fr)'},
              {columns: ['arrowIcon'], size: 'max-content'},
              {columns: ['ipRanges'], size: 'minmax(120px, 1fr)', extraClass: 'ipRanges'},
              {columns: ['service'], size: 'minmax(120px, 1fr)', extraClass: 'service'},
            ]
          : [
              {columns: ['service'], size: 'minmax(120px, 1fr)', extraClass: 'service'},
              {columns: ['ipRanges'], size: 'minmax(120px, 1fr)', extraClass: 'ipRanges'},
              {columns: ['arrowIcon'], size: 'max-content'},
              {columns: ['group'], size: 'minmax(120px, 1fr)'},
            ]),
        {columns: ['networkProfile'], size: 'minmax(100px, 1fr)'},
        {columns: ['type'], size: 'minmax(auto, 90px)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
  {
    maxWidth: 950, // stacks networkProfile & type columns
    getData() {
      return {
        reverseProviderConsumer: this.props.reverseProviderConsumer,
      };
    },
    template() {
      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        ...(this.props.reverseProviderConsumer
          ? [
              {columns: ['group'], size: 'minmax(120px, 1fr)'},
              {columns: ['arrowIcon'], size: 'max-content'},
              {columns: ['ipRanges'], size: 'minmax(120px, 1fr)', extraClass: 'ipRanges'},
              {columns: ['service'], size: 'minmax(120px, 1fr)', extraClass: 'service'},
            ]
          : [
              {columns: ['service'], size: 'minmax(120px, 1fr)', extraClass: 'service'},
              {columns: ['ipRanges'], size: 'minmax(120px, 1fr)', extraClass: 'ipRanges'},
              {columns: ['arrowIcon'], size: 'max-content'},
              {columns: ['group'], size: 'minmax(120px, 1fr)'},
            ]),
        {columns: ['networkProfile', 'type'], size: 'minmax(auto, 1fr)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
  {
    maxWidth: 900, // stacks ipranges & service column
    getData() {
      return {
        reverseProviderConsumer: this.props.reverseProviderConsumer,
      };
    },
    template() {
      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        ...(this.props.reverseProviderConsumer
          ? [
              {columns: ['group'], size: 'minmax(120px, 1fr)'},
              {columns: ['arrowIcon'], size: 'max-content'},
              {columns: ['ipRanges', 'service'], size: 'minmax(120px, 1fr)', extraClass: 'ipRanges service'},
            ]
          : [
              {columns: ['service', 'ipRanges'], size: 'minmax(120px, 1fr)', extraClass: 'ipRanges service'},
              {columns: ['arrowIcon'], size: 'max-content'},
              {columns: ['group'], size: 'minmax(120px, 1fr)'},
            ]),
        {columns: ['networkProfile', 'type'], size: 'minmax(auto, 1fr)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
  {
    maxWidth: 750, // stacks network profile, type & group
    getData() {
      return {
        reverseProviderConsumer: this.props.reverseProviderConsumer,
      };
    },
    template() {
      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        ...(this.props.reverseProviderConsumer
          ? [
              {columns: ['group', 'networkProfile', 'type'], size: 'minmax(120px, 1fr)'},
              {columns: ['arrowIcon'], size: 'max-content'},
              {columns: ['ipRanges', 'service'], size: 'minmax(120px, 1fr)', extraClass: 'ipRanges service'},
            ]
          : [
              {columns: ['service', 'ipRanges'], size: 'minmax(120px, 1fr)', extraClass: 'ipRanges service'},
              {columns: ['arrowIcon'], size: 'max-content'},
              {columns: ['group', 'networkProfile', 'type'], size: 'minmax(120px, 1fr)'},
            ]),
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
];

const gridSettings = {
  capacities: [5, 10, 25, 50, 100, 250, 500],
  capacity: 5,
  maxPage: Number.MAX_SAFE_INTEGER,
  showCapacity: true,
  showPagination: true,
  columns,
  templates,
};

export const allowRulesGridSettings = createSelector([], () => ({
  id: allowRulesGridId,
  ...gridSettings,
}));

export const denyRulesGridSettings = createSelector([], () => ({
  id: denyRulesGridId,
  ...gridSettings,
}));

export const defaultRuleGridSettings = createSelector([], () => ({
  id: defaultRulesGridId,
  ...gridSettings,
  showCapacity: false,
  showPagination: false,
}));
