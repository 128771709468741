/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {all, call, select, put, retry} from 'redux-saga/effects';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {getSelectorSettings} from './IPListListConfig';
import {getIPLists, getIPListsCount, getGridSettings} from './IPListListState';
import gridSaga from 'components/Grid/GridSaga';
import {RequestError} from 'errors';

export function* fetchIPLists({filter, pversion = 'draft', force = false} = {}) {
  const query = {max_results: 500};
  const selectorSettingsObject = yield select(getSelectorSettings);

  if (filter) {
    for (const [name, [value]] of Object.entries(filter)) {
      if (value) {
        if (selectorSettingsObject.staticValues.hasOwnProperty(name)) {
          query.update_type = selectorSettingsObject.staticValues[name][value];
        } else {
          query[name] = value;
        }
      }
    }
  }

  return yield call(apiSaga, 'ip_lists.get_collection', {
    query,
    params: {pversion},
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getIPLists)) || count !== (yield select(getIPListsCount))) {
        yield put({type: 'IP_LISTS_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* removeIPLists({hrefs, pversion = 'draft'}) {
  yield call(apiSaga, 'ip_lists.delete', {
    params: {pversion},
    data: {ip_lists: hrefs.map(href => ({href}))},
    hrefs,
    *onDone() {
      yield put({type: 'IPLISTS_REMOVE', data: hrefs});
    },
  });
}

export function* fetchIPListList(route, refetch = false) {
  const selectorSettingsObject = yield select(getSelectorSettings);

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: selectorSettingsObject.filterMap,
    *onSaga({filterParams}) {
      const [{list}] = yield all([
        call(fetchIPLists, {filter: filterParams.isEmpty ? undefined : filterParams.valid, force: refetch}),
        call(fetchAllUsers, {force: refetch}),
      ]);

      return list.length;
    },
  });
}

export function* fetchIPListFacet({query, params, retries = 2, autocomplete = false}) {
  try {
    // Call facets to get the matching query name
    const facets = yield retry(retries, 0, apiSaga, autocomplete ? 'ip_lists.autocomplete' : 'ip_lists.facets', {
      params,
      query,
    });

    return facets;
  } catch (error) {
    // Throw error message when request fails
    throw new RequestError({
      message: error,
    });
  }
}
