/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {composeDownloadUrl, types} from '../ReportsUtils';
import {Button, Link, StatusIcon} from 'components';
import {UserName} from 'containers';
import * as GridUtils from 'components/Grid/GridUtils';
import {createSelector} from 'reselect';

const handleRegenerateClick = (row, component) => async () => {
  const {href, job_type: reportType, description} = row.data;
  // API doesn't return format, so we have to guess based on description for now
  const format = description.includes('CSV') ? 'csv' : 'json';

  // Show progress state on clicked Regenerate button
  component.props.dispatch({type: 'REPORTSLIST_ADD_REGENERATE', href});
  // Call report generation on the component
  await component.generateReport(reportType, format);
  // Remove progress state on clicked Regenerate button
  component.props.dispatch({type: 'REPORTSLIST_REMOVE_REGENERATE', href});
};

/**
 [{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: node | Function,
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = createSelector([], () => ({
  id: 'reportlist',
  sort: '-requestedAt',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    description: {
      header: intl('Exports.FileName'),
      value: 'description',
    },
    jobType: {
      header: intl('Exports.ContainingAll'),
      value: ({row}) => (types()[row.data.job_type] ? types()[row.data.job_type].label : row.data.job_type),
      format: ({row, value}) => {
        const type = types()[row.data.job_type];

        if (type && type.link) {
          return <Link {...type.link}>{value}</Link>;
        }

        return value;
      },
    },
    requestedBy: GridUtils.clickableColumn({
      header: intl('Common.GeneratedBy'),
      value: ({row}) => row.data.requested_by?.username,
      format: ({row, clickableRef}) =>
        row.data.requested_by && <UserName user={row.data.requested_by} ref={clickableRef} />,
    }),
    requestedAt: {
      header: intl('Common.GeneratedAt'),
      isDate: 'L_HH_mm_ss',
      value: 'requested_at',
    },
    status: {
      header: intl('Common.Status'),
      value: 'status',
      format: (() => {
        const statusIconsMap = new Map([
          ['done', {statusIcon: 'check', label: intl('Common.Done')}],
          ['pending', {statusIcon: 'pending', label: intl('Common.Pending')}],
          ['running', {statusIcon: 'syncing', label: intl('Common.Running')}],
          ['failed', {statusIcon: 'error', label: intl('Common.Error')}],
        ]);

        return ({row, value}) => {
          const {statusIcon, label} = statusIconsMap.get(value);

          return (
            <StatusIcon
              status={statusIcon}
              label={label}
              title={(value === 'failed' && row.data.result && row.data.result.message) || label}
            />
          );
        };
      })(),
    },
    regenerate: {
      header: intl('Common.Retry'),
      value: 'result',
      format: ({row, component}) =>
        row.data.status !== 'pending' &&
        row.data.status !== 'running' && (
          <Button
            color="standard"
            icon="syncing"
            text={intl('Common.Regenerate')}
            progress={row.regenerating}
            onClick={handleRegenerateClick(row, component)}
          />
        ),
    },
    download: {
      header: intl('Common.Download'),
      value: 'result',
      format: ({row, value}) =>
        row.data.status === 'done' && (
          <Button.Link
            color="standard"
            icon="download"
            text={intl('Common.Download')}
            link={{href: composeDownloadUrl(value.href, row.data.description), download: row.data.description}}
          />
        ),
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    id: ?string, // Optional breakpoint id, goes to cellFormat function
    data: ?object, // Object with any data, goes to cellFormat function
    maxWidth: number, // Maximum width for breakpoint, minimum will be calculated automatically, goes to cellFormat function
    template: string | Function, // Columns configuration
  }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['description'], size: 'minmax(200px, 340px)'},
      {columns: ['jobType'], size: 'minmax(50px, auto)'},
      {columns: ['requestedBy'], size: 'minmax(120px, auto)'},
      {columns: ['requestedAt'], size: 'minmax(120px, auto)'},
      {columns: ['status'], size: 'minmax(120px, 120px)'},
      {columns: ['regenerate'], size: 'max-content'},
      {columns: ['download'], size: 'max-content'},
    ],
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['description'], size: 'minmax(150px, auto)'},
            {columns: ['jobType'], size: 'minmax(50px, auto)'},
            {columns: ['requestedBy', 'requestedAt'], size: 'minmax(100px, auto)'},
            {columns: ['status'], size: 'minmax(50px, auto)'},
            {columns: ['regenerate'], size: 'max-content'},
            {columns: ['download'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['description'], size: 'minmax(150px, auto)'},
          {columns: ['jobType'], size: 'minmax(50px, auto)'},
          {columns: ['requestedBy', 'requestedAt'], size: 'minmax(100px, auto)'},
          {columns: ['status'], size: 'minmax(50px, auto)'},
          {columns: ['regenerate'], size: 'max-content'},
          {columns: ['download'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['description'], size: 'minmax(200px, auto)'},
            {columns: ['requestedBy', 'requestedAt'], size: 'minmax(100px, auto)'},
            {columns: ['jobType', 'status'], size: 'minmax(50px, auto)'},
            {columns: ['regenerate', 'download'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['description'], size: 'minmax(200px, auto)'},
          {columns: ['requestedBy', 'requestedAt'], size: 'minmax(100px, auto)'},
          {columns: ['jobType', 'status'], size: 'minmax(50px, auto)'},
          {columns: ['regenerate', 'download'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 740,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['description', 'requestedBy', 'requestedAt'], size: 'minmax(200px, auto)'},
            {columns: ['jobType', 'status'], size: 'minmax(50px, auto)'},
            {columns: ['regenerate', 'download'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['description', 'requestedBy', 'requestedAt'], size: 'minmax(200px, auto)'},
          {columns: ['jobType', 'status'], size: 'minmax(50px, auto)'},
          {columns: ['regenerate', 'download'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 500,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['description', 'requestedBy', 'requestedAt'], size: 'minmax(100px, auto)'},
            {columns: ['jobType', 'status', 'regenerate', 'download'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['description', 'requestedBy', 'requestedAt'], size: 'minmax(100px, auto)'},
          {columns: ['jobType', 'status', 'regenerate', 'download'], size: 'minmax(100px, auto)'},
        ];
      },
    },
  ],
}));
