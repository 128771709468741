/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {Pill} from 'components/index';
import {portUtils, hrefUtils} from 'utils';
import type {PillDiffProps} from '../PillDiff';
import type {Service} from 'illumio';

const getKey = (service: Service) =>
  hrefUtils.getId(service.href) || service.name || portUtils.stringifyPortObjectReadonly(service);

export type ServiceDiffProps = {
  value?: Service | Service[];
  oldValue?: Service | Service[];
  // policy version
  pversion?: string | number;
} & Omit<PillDiffProps, 'value' | 'oldValue'>;

export default function ServiceDiff(props: ServiceDiffProps): JSX.Element {
  const {value, oldValue, pversion = 'draft', ...rest} = props;

  const pillDiffProps: PillDiffProps = rest;

  const getServicePill = (service?: Service) =>
    service && {key: getKey(service), pill: <Pill.Service value={service} pversion={pversion} />};

  pillDiffProps.value = Array.isArray(value) ? value.map(service => getServicePill(service)) : [getServicePill(value)];
  pillDiffProps.oldValue = Array.isArray(oldValue)
    ? oldValue.map(service => getServicePill(service))
    : [getServicePill(oldValue)];

  return <Pill.Diff {...pillDiffProps} />;
}
