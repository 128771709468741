/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {Icon, Pill, Button, Badge} from 'components';
import _ from 'lodash';
import stylesUtils from 'utils.css';
import {getBadgeTypeByUpdateType, getBadgeLabelByUpdateType} from 'utils/formats';
import styles from '../List/SecureGatewayList.css';
import {createSelector} from 'reselect';

/**
 [{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: node | Function,
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = createSelector([], () => ({
  id: 'sgrules',
  sort: '-status',
  capacity: 50,
  showManager: false,
  columns: {
    checkboxes: {},
    status: {
      header: intl('Provision.Status'),
      value: ({row}) => getBadgeLabelByUpdateType(row.data.update_type),
      format: ({row, value}) =>
        value ? (
          <Badge type={getBadgeTypeByUpdateType(row.data.update_type)} theme={styles} themePrefix="status-">
            {value}
          </Badge>
        ) : null,
    },
    ipList: {
      header: intl('SecureGateway.ProtectedIPList'),
      value: ({row}) => row.data.ipList.name,
      format: ({row, value}) =>
        value ? <span className={row.data.update_type === 'delete' ? stylesUtils.old : undefined}>{value}</span> : null,
    },
    accby: {
      header: '',
      value: 'accby',
      format: ({row}) => {
        if (row.data.update_type === 'delete') {
          return (
            <div className={stylesUtils.old}>
              <Icon size="medium" name="lock" title={intl('SecureGateway.AccessibleBy')} />
            </div>
          );
        }

        return <Icon size="medium" name="lock" title={intl('SecureGateway.AccessibleBy')} />;
      },
      sortable: false,
    },
    labelRole: {
      header: intl('Common.Role'),
      value: ({row: {data}}) => data.labels.role.value,
      format: ({row, value}) =>
        value && (
          <Pill.Label deleted={row.data.update_type === 'delete'} type="role">
            {value !== 'all' ? value : intl('Common.AllRoles')}
          </Pill.Label>
        ),
    },
    labelApp: {
      header: intl('Common.Application'),
      value: ({row: {data}}) => data.labels.app.value,
      format: ({row, value}) =>
        value && (
          <Pill.Label deleted={row.data.update_type === 'delete'} type="app">
            {value !== 'all' ? value : intl('Common.AllApplications')}
          </Pill.Label>
        ),
    },
    labelEnv: {
      header: intl('Common.Environment'),
      value: ({row: {data}}) => data.labels.env.value,
      format: ({row, value}) =>
        value && (
          <Pill.Label deleted={row.data.update_type === 'delete'} type="env">
            {value !== 'all' ? value : intl('Common.AllEnvironments')}
          </Pill.Label>
        ),
    },
    labelLoc: {
      header: intl('Common.Location'),
      value: ({row: {data}}) => data.labels.loc.value,
      format: ({row, value}) =>
        value && (
          <Pill.Label deleted={row.data.update_type === 'delete'} type="loc">
            {value !== 'all' ? value : intl('Common.AllLocations')}
          </Pill.Label>
        ),
    },
    action: {
      value: 'action',
      format: ({row, component}) =>
        row.selectable && (
          <Button
            color="standard"
            noFill
            icon="edit"
            tid="edit"
            disabled={!row.action}
            onClick={_.partial(component.handleEditSubnet, row)}
          />
        ),
      sortable: false,
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    id: ?string, // Optional breakpoint id, goes to cellFormat function
    data: ?object, // Object with any data, goes to cellFormat function
    maxWidth: number, // Maximum width for breakpoint, minimum will be calculated automatically, goes to cellFormat function
    template: string | Function, // Columns configuration
  }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'minmax(100px, 200px)'},
      {columns: ['ipList'], size: 'minmax(50px, auto)'},
      {columns: ['accby'], size: 'minmax(120px, auto)'},
      {columns: ['labelRole'], size: 'minmax(120px, auto)'},
      {columns: ['labelApp'], size: 'minmax(120px, auto)'},
      {columns: ['labelEnv'], size: 'minmax(120px, auto)'},
      {columns: ['labelLoc'], size: 'minmax(120px, 120px)'},
      {columns: ['action'], size: 'minmax(60px, 60px)'},
    ],
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['status'], size: 'minmax(80px, auto)'},
            {columns: ['ipList'], size: 'minmax(50px, auto)'},
            {columns: ['accby'], size: 'minmax(100px, auto)'},
            {columns: ['labelRole'], size: 'minmax(50px, auto)'},
            {columns: ['labelApp'], size: 'max-content'},
            {columns: ['labelEnv'], size: 'max-content'},
            {columns: ['labelLoc'], size: 'max-content'},
            {columns: ['labelLoc'], size: 'max-content'},
            {columns: ['action'], size: 'minmax(60px, 60px)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'minmax(80px, auto)'},
          {columns: ['ipList'], size: 'minmax(50px, auto)'},
          {columns: ['accby'], size: 'minmax(50px, auto)'},
          {columns: ['labelRole', 'labelApp'], size: 'minmax(100px, auto)'},
          {columns: ['labelEnv', 'labelLoc'], size: 'minmax(100px, auto)'},
          {columns: ['action'], size: 'minmax(60px, 60px)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'minmax(100px, auto)'},
          {columns: ['ipList', 'accby'], size: 'minmax(100px, auto)'},
          {columns: ['labelRole', 'labelApp'], size: 'minmax(50px, auto)'},
          {columns: ['labelEnv', 'labelLoc'], size: 'max-content'},
          {columns: ['action'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 740,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status', 'ipList', 'accby'], size: 'minmax(200px, auto)'},
          {columns: ['labelRole', 'labelApp'], size: 'minmax(50px, auto)'},
          {columns: ['labelEnv', 'labelLoc'], size: 'max-content'},
          {columns: ['action'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 500,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status', 'ipList', 'accby'], size: 'minmax(100px, auto)'},
          {columns: ['labelRole', 'labelApp', 'labelEnv', 'labelLoc'], size: 'minmax(100px, auto)'},
          {columns: ['action'], size: 'max-content'},
        ];
      },
    },
  ],
}));
