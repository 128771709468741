/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createSelector} from 'reselect';
import {hrefUtils} from 'utils';
import {Diff, Pill, Icon, StatusIcon} from 'components';
import styles from './GroupView.css';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import {networkTypeMap} from 'containers/Network/NetworkUtils';

export const workloadListGridId = 'groupWorkloadlist';

export const groupRuleListId = 'groupRuleList';
export const adminGroupList = 'adminGroupList';

export const resourceType = 'rule';

export const getSelectorSettings = createSelector([], () => {
  const objectMap = {
    rule: {type: 'rule', pversion: 'draft'},
    roleLabels: {type: 'labels', key: 'role'},
    ip_list: {type: 'ip_list'},
  };

  const staticMap = {
    service: intl('Common.Service'),
    ip_list: intl('Edge.SourceIPRanges'),
    networkType: intl('Edge.NetworkProfile'),
  };

  const facetMap = {
    service: {value: intl('Common.Service')},
    ip_list: {value: intl('Edge.SourceIPRanges'), object: objectMap.ip_list},
    networkType: {value: intl('Edge.NetworkProfile')},
  };

  const scopeMap = {
    role: {value: intl('Common.Service'), object: objectMap.roleLabels},
    ip_list: {value: intl('Edge.SourceIPRanges'), object: objectMap.ip_list},
    networkType: {value: intl('Edge.NetworkProfile')},
  };

  const filterMap = {...staticMap, ...facetMap, ...scopeMap};

  return {filterMap, scopeMap, facetMap, objectMap};
});

export const getAdminSelectorSettings = createSelector([], () => {
  const objectMap = {
    rule: {type: 'rule', pversion: 'draft'},
    roleLabels: {type: 'labels', key: 'role'},
    group: {type: 'label'},
  };

  const staticMap = {
    service: intl('Common.Service'),
    adminGroups: intl('Edge.AdminGroup.AllowedAdminGroups'),
    encryption: intl('Edge.AdminGroup.AllowedAdminAccess.EncryptTraffic'),
  };

  const facetMap = {
    service: {value: intl('Common.Service'), object: objectMap.rule},
    adminGroups: {value: intl('Edge.AdminGroup.AllowedAdminGroups'), object: objectMap.group},
    encryption: {value: intl('Edge.AdminGroup.AllowedAdminAccess.EncryptTraffic')},
  };

  const scopeMap = {
    service: {value: intl('Common.Service'), object: objectMap.roleLabels},
    adminGroups: {value: intl('Edge.AdminGroup.AllowedAdminGroups'), object: objectMap.group},
    encryption: {value: intl('Edge.AdminGroup.AllowedAdminAccess.EncryptTraffic')},
  };

  const filterMap = {...staticMap, ...facetMap, ...scopeMap};

  return {filterMap, scopeMap, facetMap, objectMap};
});

export const groupViewGridSettings = createSelector([], () => ({
  id: 'groupRuleList',
  sort: 'incomingService',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    status: {
      headerManager: intl('Common.Status'),
      format: ({row}) =>
        row.status ? (
          row.status === 'modified' ? (
            <StatusIcon status={row.status} />
          ) : (
            <Icon name={row.status} theme={styles} themePrefix={`${row.status}-`} />
          )
        ) : null,
    },
    incomingService: {
      header: intl('Common.Service'),
      format: ({row}) => <Pill.Service value={row.data.service} tid="ingress_service" showPorts />,
    },
    permittedIPs: {
      header: intl('Edge.SourceIPRanges'),
      format: ({
        row: {
          status,
          data: {ipRanges, oldIpRanges},
        },
      }) => {
        if (status === 'add') {
          return <Pill.IPListDiff value={ipRanges} />;
        }

        return <Pill.IPListDiff value={ipRanges} oldValue={oldIpRanges} />;
      },
    },
    networkType: {
      header: intl('Edge.NetworkProfile'),
      value: 'network_type',
      sortable: false,
      format: ({
        row: {
          data: {network_type = 'brn', oldNetworkType},
        },
      }) => (
        <Pill.Diff
          value={[
            {
              key: network_type,
              pill: <Pill>{networkTypeMap.get(network_type)}</Pill>,
            },
          ]}
          oldValue={[
            {
              key: oldNetworkType,
              pill: <Pill>{networkTypeMap.get(oldNetworkType)}</Pill>,
            },
          ]}
          noDiff={!oldNetworkType}
        />
      ),
    },
  },
  templates: [
    [
      {columns: ['status'], size: 'min-content', sizeOld: '30px,auto', extraClass: stylesGridUtils.vCenter},
      {columns: ['incomingService'], size: 'minmax(210px,auto)', extraClass: stylesGridUtils.vCenter},
      {columns: ['permittedIPs'], size: 'minmax(120px,auto)', extraClass: stylesGridUtils.vCenter},
      {columns: ['networkType'], size: 'minmax(120px, auto)', extraClass: stylesGridUtils.vCenter},
    ],
  ],
}));

const handleOnClick = (component, item) => {
  component.context.navigate({
    to: 'labels.item.view',
    params: {id: item.id || hrefUtils.getId(item.href)},
  });
};

export const groupAdminAccessGridSettings = createSelector([], () => ({
  id: 'adminGroupList',
  sort: 'incomingService',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    status: {
      headerManager: intl('Common.Status'),
      format: ({row}) =>
        row.status ? (
          row.status === 'modified' ? (
            <StatusIcon status={row.status} />
          ) : (
            <Icon name={row.status} theme={styles} themePrefix={`${row.status}-`} />
          )
        ) : null,
    },
    incomingService: {
      header: intl('Common.Service'),
      format: ({row}) => <Pill.Service value={row.data.service} tid="ingress_service" showPorts />,
    },
    allowedAdminGroups: {
      header: intl('Edge.AdminGroup.AllowedAdminGroups'),
      format: ({
        row: {
          status,
          data: {adminGroups, oldAdminGroups},
        },
        component,
      }) => {
        const adminGroupsArr = adminGroups?.map(adminGroup => ({
          href: adminGroup.href,
          name: adminGroup.value,
          isAdmin: true,
        }));
        const oldAdminGroupsArr = oldAdminGroups?.map(oldAdminGroup => ({
          href: oldAdminGroup.href,
          name: oldAdminGroup.value,
          isAdmin: true,
        }));

        if (status === 'add') {
          return <Pill.GroupsDiff onClick={_.partial(handleOnClick, component)} value={adminGroupsArr} />;
        }

        return (
          <Pill.GroupsDiff
            onClick={_.partial(handleOnClick, component)}
            value={adminGroupsArr}
            oldValue={oldAdminGroupsArr}
            noDiff={!oldAdminGroupsArr}
          />
        );
      },
    },
    encrypt: {
      header: intl('Edge.AdminGroup.AllowedAdminAccess.EncryptTraffic'),
      format: ({
        row: {
          status,
          data: {encrypt, oldEncrypt},
        },
      }) => (
        <Diff.Checkboxes
          value={encrypt}
          oldValue={oldEncrypt}
          noDiff={!status || status === 'add' || status === 'remove'}
        />
      ),
    },
  },
  templates: [
    [
      {columns: ['status'], size: 'min-content', sizeOld: '30px,auto', extraClass: stylesGridUtils.vCenter},
      {columns: ['incomingService'], size: 'minmax(210px,auto)', extraClass: stylesGridUtils.vCenter},
      {columns: ['allowedAdminGroups'], size: 'minmax(120px,auto)', extraClass: stylesGridUtils.vCenter},
      {columns: ['encrypt'], size: 'minmax(120px,auto)', extraClass: stylesGridUtils.vCenter},
    ],
  ],
}));
