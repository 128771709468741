/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import Cell from './GridCellHead';
import {StickyShadow} from 'components';

export default function GridAreaHead(props) {
  const {theme, grid, offset, column, secondary} = props;
  const columnHeaderType = typeof column.header;
  const {showManager} = grid.settings;
  let headContent;
  let headClassName = cx(theme.areaHead, column.extraClass);

  if (!secondary) {
    headClassName = cx(headClassName, theme.areaHeadPrimaryGrid);
  }

  if (column.isExpander || column.size === '0px') {
    // Don't render any content if it's extender column or span column, just area itself
    headContent = null;
  } else if (columnHeaderType === 'undefined' || columnHeaderType === 'function') {
    // Render each cell, if column header is undefined or function
    headContent = column.cells.map(cell => <Cell key={cell.id || cell} {...props} cell={cell} />);

    if (columnHeaderType === 'function') {
      // If header is a function, call it with rendered cells
      headContent = column.header(headContent);
    }
  } else {
    // If column.header is some value (for example, string), render it right away without rendering cells
    headContent = column.header;
    headClassName = cx(headClassName, theme.areaHeadEmpty);
  }

  return (
    <StickyShadow
      shadowDown
      depth={2}
      style={column.style}
      theme={{sticky: headClassName}}
      offset={`calc(${showManager === false ? '' : 'var(--grid-manager-height) + '}${offset})`}
    >
      {headContent}
    </StickyShadow>
  );
}
