/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteParams} from 'containers/App/AppState';
import {getAllUsersMap, isUserReadOnlyClusterInsensitive, isSuperclusterMember} from 'containers/User/UserState';
import {isLocalPce} from 'containers/App/AppUtils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import * as GridUtils from 'components/Grid/GridUtils';
import {getContainerClusterDetail} from '../../ContainerClusterDetailState';
import {getNamespaceTerm} from 'containers/ContainerCluster/ContainerClusterUtils';
import {getClusters, getLocalFQDN} from 'containers/Health/HealthState';
import {inferLabelSource} from '../../ContainerClusterDetailUtils';

export default {
  containerWorkloadProfileDetail(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_WORKLOAD_PROFILE_GET_DETAIL':
        return action.data.containerWorkloadProfileDetail;
      default:
        return state;
    }
  },
};

export const getContainerWorkloadProfileDetail = state => state.containerClusters.containerWorkloadProfileDetail;

const ANY_CONTAINER_LABEL_ACCEPTED = {labels: []};

export const getContainerWorkloadProfileDetailPage = createSelector(
  [
    getContainerClusterDetail,
    getContainerWorkloadProfileDetail,
    getRouteParams,
    getAllUsersMap,
    isSuperclusterMember,
    isUserReadOnlyClusterInsensitive,
    getClusters,
    getLocalFQDN,
  ],
  (
    clusterDetail,
    detail,
    {id, profileId, pairingKey},
    usersMap,
    isMember,
    userIsReadOnlyClusterInsensitive,
    clusters,
    local,
  ) => {
    const {labels, created_by, updated_by} = detail || ANY_CONTAINER_LABEL_ACCEPTED; // defaultProfile for `/create` case

    return {
      isLocalPce: isLocalPce({clusters, local, pceFqdn: clusterDetail.pce_fqdn}),
      allowCreateTypes: isMember ? [] : ['labels'],
      clusterName: clusterDetail.name,
      namespaceTerm: getNamespaceTerm(clusterDetail),
      ...detail,
      ...GridUtils.getLabelsMap(labels),
      flatLabelMap: GridUtils.getFlattenedLabelModeMap(labels),
      roleSource: inferLabelSource('role', labels),
      appSource: inferLabelSource('app', labels),
      envSource: inferLabelSource('env', labels),
      locSource: inferLabelSource('loc', labels),
      id,
      profileId,
      pairingKey,
      userIsReadOnlyClusterInsensitive,
      created_by_user: fillUserInfo(usersMap, created_by),
      updated_by_user: fillUserInfo(usersMap, updated_by),
    };
  },
);
