/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getAllUsersMap, isSuperclusterMember, isUserReadOnly} from 'containers/User/UserState';
import {getRouteParams} from 'containers/App/AppState';
import {diffGateways, mapSecureAPIDataToUIFriendlyVersion} from '../../SecureGatewayUtils';
import {gridSettings} from '../SecureGatewayRulesConfig';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {getSecureGatewayVersions} from '../SecureGatewayItemState';
import {getId} from 'utils/href';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import _ from 'lodash';

export const getSecureGatewayDetail = createSelector(
  [getSecureGatewayVersions, getRouteParams, getAllUsersMap, isSuperclusterMember, isUserReadOnly],
  ({versions, isOldVersion}, {id, pversion}, usersMap, isSuperclusterMember, userIsReadOnly) => {
    const secureGateway = versions.pversionObj;
    const secureGateways = mapSecureAPIDataToUIFriendlyVersion(versions.pversionObj);
    const prevSecureGateways = mapSecureAPIDataToUIFriendlyVersion(versions.prevPversionObj);
    const diff = diffGateways(secureGateways, prevSecureGateways);

    return {
      id,
      diff,
      pversion,
      isOldVersion,
      secureGateway,
      versions,
      userIsReadOnly,
      isSuperclusterMember,
      createdBy: fillUserInfo(usersMap, secureGateway.created_by),
      updatedBy: fillUserInfo(usersMap, secureGateway.updated_by),
    };
  },
);

export const getGridSettings = createSelector(gridSettings, gridSettings => {
  const clonedGrid = _.cloneDeep(gridSettings);

  if (clonedGrid && clonedGrid.columns && clonedGrid.columns.status) {
    clonedGrid.columns.status.hidden = false;
  }

  return {...clonedGrid};
});

const getViewSecureConnectGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: createSelector([getSecureGatewayDetail], securegateway =>
      ((securegateway.diff && securegateway.diff.secure_networks) || []).map(item => ({
        key: `${getId(item.ipList.href)}${getId(item.labels.role.href)}${getId(item.labels.app.href)}${getId(
          item.labels.loc.href,
        )}${getId(item.labels.env.href)}`,
        selectable: false,
        removable: false,
        data: item,
      })),
    ),
  });

export const getSecureGatewaysViewPage = createSelector(
  [getViewSecureConnectGrid, getSecureGatewayDetail],
  (grid, securegateways) => {
    const count = {matched: grid.rows.length, total: grid.rows.length};

    return {grid, count, ...securegateways};
  },
);
