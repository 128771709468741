/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createSelector} from 'reselect';
import * as GridUtils from 'components/Grid/GridUtils';
import {isUserReadOnly, isUserScoped} from 'containers/User/UserState';
import {getGridSelector, getUrlScopeValue} from 'components/Grid/GridSelectors';
import {getLabelsHrefMap} from 'containers/Label/List/LabelListState';
import {gridSettings, getSelectorSettings} from './ContainerWorkloadListConfig';
import {getScopeLabelByHref} from 'containers/Selectors/SelectorUtils';
import {getClusters, getLocalFQDN} from 'containers/Health/HealthState';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'CONTAINER_WORKLOAD_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_WORKLOAD_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  ignoreScope(state = false, action) {
    switch (action.type) {
      case 'CONTAINER_WORKLOAD_GET_LIST':
        return action.data.ignoreScope;
      default:
        return state;
    }
  },
};

export const getContainerWorkloads = state => state.containerWorkloads.list;
export const getContainerWorkloadsCount = state => state.containerWorkloads.count;
export const getContainerWorkloadsIgnoreScope = state => state.containerWorkloads.ignoreScope;

const getContainerWorkloadRows = createSelector(
  [getContainerWorkloads, isUserReadOnly],
  (containerWorkloads, userIsReadOnly) =>
    containerWorkloads.map(item => ({
      key: item.href,
      // Make item created by system not selectable, since it cannot be removed or provisioned
      selectable: !userIsReadOnly,
      // Fill each item labels
      data: {
        ...item,
        labels: GridUtils.getLabelsMap(item.labels),
      },
    })),
);

export const getCalculatedStaticMap = createSelector(
  [getClusters, getContainerWorkloadsIgnoreScope, getSelectorSettings],
  (clusters, ignoreScope, selectorSettingsObject) => {
    const newStaticMap =
      !ignoreScope && clusters.length > 1
        ? {
            pce_fqdn: intl('Common.PCE'),
          }
        : {};

    return {...selectorSettingsObject.staticMap, ...newStaticMap};
  },
);

export const getCalculatedStaticValues = createSelector(
  [getClusters, getLocalFQDN, getContainerWorkloadsIgnoreScope, getSelectorSettings],
  (clusters, localFQDN, ignoreScope, selectorSettingsObject) => {
    const newStaticValues =
      !ignoreScope && clusters.length > 1
        ? {
            pce_fqdn: clusters.reduce((result, cluster) => {
              if (cluster) {
                const label =
                  cluster.fqdn === localFQDN ? intl('Workloads.ThisPCE', {fqdn: cluster.fqdn}) : cluster.fqdn;

                result[label] = cluster.fqdn;
              }

              return result;
            }, {}),
          }
        : {};

    return {...selectorSettingsObject.staticValues, ...newStaticValues};
  },
);

export const getCalculatedFacetMap = createSelector(
  [getSelectorSettings],
  selectorSettingsObject => selectorSettingsObject.facetMap,
);

export const getCalculatedFilterMap = createSelector(
  [getCalculatedStaticMap, getCalculatedFacetMap, getContainerWorkloadsIgnoreScope, isUserScoped, getSelectorSettings],
  (calculatedStaticMap, calculatedFacetMap, ignoreScope, userIsScoped, selectorSettingsObject) => ({
    ...selectorSettingsObject.scopeMap,
    ...calculatedFacetMap,
    ...calculatedStaticMap,
    ...(!ignoreScope &&
      !userIsScoped && {container_clusters: selectorSettingsObject.autocompleteMap.container_clusters}),
  }),
);

export const getCalculatedRouteFilters = createSelector(getCalculatedFilterMap, calculatedFilterMap => ({
  ...calculatedFilterMap,
}));

export const getScopeItems = createSelector(
  [getUrlScopeValue, getLabelsHrefMap, getSelectorSettings],
  (scope, labelsMap, selectorSettingsObject) => {
    if (scope.isEmpty) {
      return [];
    }

    return scope.valid.scope.map(({href}) => {
      const label = labelsMap[href] || getScopeLabelByHref(href) || {};

      return {
        href,
        value: label.value,
        categoryKey: label.key,
        key: label.key,
        scope: true,
        categoryName: _.get(selectorSettingsObject.filterMap[label.key], 'value'),
      };
    });
  },
);

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getContainerWorkloadRows,
    filterMap: getCalculatedRouteFilters,
  });

export const getContainerWorkloadsPage = createSelector(
  [
    getGrid,
    getContainerWorkloadsCount,
    getCalculatedFilterMap,
    getCalculatedStaticValues,
    getScopeItems,
    getCalculatedFacetMap,
    getSelectorSettings,
  ],
  (
    grid,
    count,
    calculatedFilterMap,
    calculatedStaticValues,
    scopeItems,
    calculatedFacetMap,
    selectorSettingsObject,
  ) => {
    const filterItems = Object.keys(grid.filter).reduce((result, categoryKey) => {
      if (selectorSettingsObject.scopeMap[categoryKey]) {
        // in detail page
        grid.filter[categoryKey].forEach(({href, key, value}) => {
          result.push({
            href,
            value,
            categoryKey,
            key,
            categoryName: _.get(calculatedFilterMap[categoryKey], 'value'),
          });
        });
      } else if (selectorSettingsObject.autocompleteMap[categoryKey]) {
        grid.filter[categoryKey].forEach(({href, value}) => {
          result.push({href, value, categoryKey, categoryName: _.get(calculatedFilterMap[categoryKey], 'value')});
        });
      } else {
        result.push({
          categoryKey,
          value: grid.filter[categoryKey][0],
          categoryName: calculatedFilterMap[categoryKey].value || calculatedFilterMap[categoryKey],
        });
      }

      return result;
    }, []);

    // Selector parameters based on filter and config
    const selector = {
      scopeItems,
      filterItems,
      objects: Object.values(selectorSettingsObject.objectMap),
      categories: Object.entries(calculatedFilterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value: value.value || value,
        object: value.object,
        scope: value.scope,
        statics: value.statics,
      })),
      facets: Object.keys(calculatedFacetMap),
      partials: Object.entries(calculatedFacetMap).reduce((result, [categoryKey, value]) => {
        if (value.isPartial) {
          result.push(categoryKey);
        }

        return result;
      }, []),
      statics: Object.entries(calculatedStaticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
    };

    return {grid, count, selector};
  },
);
