/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';

export const getAccessRestrictionOptions = (accessRestrictions = []) => {
  const accessRestrictionOptions = accessRestrictions.reduce(
    (result, item) => {
      result.accessRestrictionMaps[item.name] = item.href;
      result.accessRestrictionStatics.push(item.name);

      return result;
    },
    {
      accessRestrictionMaps: {[intl('Common.None')]: 'none'},
      accessRestrictionStatics: [intl('Common.None')],
    },
  );

  return accessRestrictionOptions;
};
