/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createRef, Component} from 'react';
import {EditorState} from 'draft-js';
import * as PropTypes from 'prop-types';
import Editor from '@draft-js-plugins/editor';
import {addExtendSelection, getModifiedContentBlockTouched, mergeTouchedToBlockData} from '../../DraftJSUtils';
import './ListEditor.css';
import intl from 'intl';

// A workaround issue for DraftJS with Tippy's Tooltip.
addExtendSelection();

const getEditorState = props => {
  const {value} = props;

  return value || EditorState.createEmpty();
};

const getInitialState = props => ({
  editorState: getEditorState(props),
});

export default class ListEditor extends Component {
  static propTypes = {
    value: PropTypes.object,
    // Callback that is called on upon change, required in case of controlled behavior or uncontrolled
    onChange: PropTypes.func,
    // arbitrary unique name
    name: PropTypes.string.isRequired,
    // OnBlur callback
    onBlur: PropTypes.func,
    // OnFocus callback
    onFocus: PropTypes.func,
    // DraftJS-plugin plugins
    plugins: PropTypes.array,
    // DraftJS-plugin decorator
    // Note: Decorators can be also be initialize in DraftJS-plugin
    decorators: PropTypes.array,
    // ReadOnly
    readOnly: PropTypes.bool,
    // placeholder
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    decorators: [],
    plugins: [],
    readOnly: false,
    placeholder: intl('IPLists.TypeOrPasteQualifiedName'),
  };

  constructor(props) {
    super(props);

    // Create an Editor reference to set focus()
    this.myEditorRef = createRef();

    this.state = getInitialState(props);

    // Used to save the last valid key on the content block
    this.lastFocusContentBlockKey = '';

    // this.isOnBlur to determine <Editor/> lost focus during on onBlur
    // Toggling between false->true, true->false does not require a re-render thus use class property
    this.isOnBlur = false;

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.setFocus = this.setFocus.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.onChange) {
      return getInitialState(nextProps);
    }

    return null;
  }

  setFocus() {
    // Set editor to be focused
    this.myEditorRef.current.focus();
  }

  handleOnFocus(event) {
    const {onFocus} = this.props;

    // Set isOnBlur = false when focus
    this.isOnBlur = false;

    if (onFocus) {
      // Controlled
      onFocus(event);
    } else {
      // Uncontrolled
      this.setState({isOnBlur: false});
    }
  }

  handleOnBlur(event) {
    const {onBlur} = this.props;

    // Set isOnBlur = true when focus is lost
    this.isOnBlur = true;

    if (onBlur) {
      // Controlled
      onBlur(event);
    } else {
      // Uncontrolled
      this.setState({isOnBlur: true});
    }
  }

  handleOnChange(editorState) {
    const {onChange} = this.props;

    // Note: When Editor's 'onBlur' or 'onFocus' callback is called, 'handleOnChange' is called immediate after
    if (onChange) {
      let editor;

      if (this.isOnBlur) {
        const selectionState = editorState.getSelection();

        // When onBlur occurs immediate set the 'touched' on the current selected block
        editor = mergeTouchedToBlockData(editorState, selectionState);
      } else {
        const {newEditorsState, updateLastFocusContentBlockKey} = getModifiedContentBlockTouched(
          editorState,
          this.lastFocusContentBlockKey,
        );

        editor = newEditorsState;

        this.lastFocusContentBlockKey = updateLastFocusContentBlockKey;
      }

      onChange(editor);
    } else {
      this.setState({editorState});
    }
  }

  render() {
    const {decorators, plugins, readOnly, placeholder} = this.props;

    return (
      <Editor
        ref={this.myEditorRef}
        editorState={this.state.editorState}
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
        plugins={plugins}
        decorators={decorators}
        readOnly={readOnly}
        placeholder={placeholder}
        onChange={this.handleOnChange}
      />
    );
  }
}
