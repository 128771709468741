/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import {Pill, Icon} from 'components';
import styles from './DateTimeCustomPickerInputWithPresets.css';
import cx from 'classnames';

const iconStyle = {icon: styles.icon};

/*
 *  This is a component which looks like a selector but just holds a value
 *  It is used in the DateTimeCustomPicker for the from and to fields
 */
export default class DateTimeCustomPickerInputWithPresets extends PureComponent {
  static propTypes = {
    focused: PropTypes.bool, // whether or not the input is focused or not (used for blue border styling)
    showDropdown: PropTypes.bool,
    type: PropTypes.oneOf(['from', 'to']),
    value: PropTypes.instanceOf(Date), // current value for the input
    presets: PropTypes.array,
    selectedPreset: PropTypes.string,
    placeholder: PropTypes.string, // string to display when there is no value
    formatValue: PropTypes.func, // fn to format the value (date) to a string
    setDropdown: PropTypes.func,
    setPreset: PropTypes.func,
    removeDateTimeValue: PropTypes.func, // fn to be executed when value is removed from the input. This fn accepts a type argument.
    onDateTimeChange: PropTypes.func, // fn to be executed when value is removed from the input. This fn accepts a type argument.
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.getDisplayValue = this.getDisplayValue.bind(this);
    this.handleRemoveValue = this.handleRemoveValue.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleClickCaret = this.handleClickCaret.bind(this);
    this.handleClickInput = this.handleClickInput.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  getDisplayValue() {
    const {formatValue, presets, selectedPreset, value} = this.props;

    if (selectedPreset === 'custom') {
      return formatValue(value);
    }

    const currentPreset = presets.find(preset => preset.name === selectedPreset);

    return currentPreset.label;
  }

  handleRemoveValue() {
    const {setPreset, setActive, selectedPreset, removeDateTimeValue, type, setDropdown} = this.props;

    setActive(type);

    if (selectedPreset === 'custom') {
      removeDateTimeValue(type);
    }

    setPreset(type, null);
    setDropdown(type);
  }

  handleHover(evt) {
    const current = evt.currentTarget.getAttribute('value');

    this.setState({currentHover: current});
  }

  handleClickItem(evt) {
    evt.stopPropagation();

    const {setPreset, setDropdown, type, selectedPreset, onDateTimeChange} = this.props;
    const selection = evt.currentTarget.getAttribute('value');

    if (selection !== selectedPreset) {
      if (selection === 'custom') {
        onDateTimeChange(new Date());
      }

      setPreset(type, selection);
    }

    setDropdown('none');
  }

  handleClickCaret(evt) {
    evt.stopPropagation();

    const {type, setActive, showDropdown, setDropdown} = this.props;

    this.setState({currentHover: null});
    setActive(type);
    setDropdown(showDropdown ? 'none' : type);
  }

  handleClickInput(evt) {
    evt.stopPropagation();

    const {type, focused, selectedPreset, setActive, setDropdown} = this.props;

    setActive(type);

    if (focused || selectedPreset !== 'custom') {
      setDropdown(type);
    }
  }

  handleKeyDown(evt) {
    const {presets, showDropdown, type, selectedPreset, onDateTimeChange, setPreset, setDropdown} = this.props;
    const {currentHover} = this.state;

    if (evt.key === 'Backspace') {
      this.handleRemoveValue();
    } else if (evt.key === 'Enter') {
      if (currentHover) {
        if (currentHover !== selectedPreset) {
          if (currentHover === 'custom') {
            onDateTimeChange(new Date());
          }

          setPreset(type, currentHover);
        }

        this.setState({currentHover: null});
        setDropdown('none');
      } else {
        this.handleClickInput(evt);
      }
    } else if (evt.key === 'Escape' || evt.key === 'Tab') {
      this.setState({currentHover: null});
      this.props.setDropdown('none');
    } else if (evt.key === 'ArrowUp') {
      evt.preventDefault();

      if (!currentHover) {
        this.props.setDropdown('none');
      } else {
        const hoverIndex = presets.findIndex(preset => preset.name === currentHover);

        if (hoverIndex > 0) {
          this.setState({currentHover: presets[hoverIndex - 1].name});
        }
      }
    } else if (evt.key === 'ArrowDown') {
      evt.preventDefault();

      if (!showDropdown) {
        this.props.setDropdown(type);
      } else if (!currentHover) {
        this.setState({currentHover: presets[0].name});
      } else {
        const hoverIndex = presets.findIndex(preset => preset.name === currentHover);

        if (hoverIndex < presets.length - 1) {
          this.setState({currentHover: presets[hoverIndex + 1].name});
        }
      }
    }
  }

  render() {
    const {focused, showDropdown, presets, selectedPreset, placeholder} = this.props;
    const {currentHover} = this.state;

    const selectorStyle = cx(styles.selector, {
      [styles.focused]: focused,
    });

    return (
      <>
        <div className={selectorStyle} onClick={this.handleClickInput} onKeyDown={this.handleKeyDown} tabIndex={0}>
          <div className={styles.item} data-tid="comp-combobox-selected-value">
            {selectedPreset ? <Pill onClose={this.handleRemoveValue}>{this.getDisplayValue()}</Pill> : placeholder}
          </div>
          <div className={styles.dropdownArrow} data-tid="comp-icon comp-icon-caret-down">
            <Icon theme={iconStyle} name={showDropdown ? 'up' : 'down'} onClick={this.handleClickCaret} />
          </div>
        </div>
        {showDropdown && (
          <div className={styles.dropdown}>
            {presets.map(preset => (
              <li
                key={preset.name}
                tabIndex={-1}
                value={preset.name}
                className={preset.name === currentHover ? styles.dropdownItemHovered : styles.dropdownItem}
                onClick={this.handleClickItem}
                onMouseOver={this.handleHover}
              >
                {preset.label}
              </li>
            ))}
          </div>
        )}
      </>
    );
  }
}
