/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {webStorageUtils} from 'utils';
import {Popups, CertReminderPopup, ReminderPopup, RollingUpgradeReminderPopup} from 'components';
import {formatDate, getDuration} from 'containers/Health/HealthUtils';
import {getCertExpiration, getCertType, isHealthEnabled} from 'containers/User/UserState';
import {getRemainingRollingUpgradeCount, getLocalListenOnlyEnableTime} from 'containers/Health/HealthState';
import {getKbDocsUrl} from 'containers/App/AppState';
import WhatsNewPopup from '../WhatsNewPopup/WhatsNewPopup';

@connect(state => ({
  selfSigned: getCertType(state),
  certExpiration: getCertExpiration(state),
  healthIsEnabled: isHealthEnabled(state),
  listenOnlyEnableTime: getLocalListenOnlyEnableTime(state),
  remainingRollingUpgradeCount: getRemainingRollingUpgradeCount(state),
  kbDocsUrl: getKbDocsUrl(state),
}))
export default class ReminderPopups extends Component {
  constructor(props) {
    super(props);

    this.handleListenOnlyPopupClose = this.handleListenOnlyPopupClose.bind(this);
    this.handleRollingUpgradePopupClose = this.handleRollingUpgradePopupClose.bind(this);
    this.handlePopupClose = this.handlePopupClose.bind(this);
  }

  handlePopupClose(key, value, stringify = true) {
    if (key === 'rollingUpgrade') {
      webStorageUtils.setSessionItem(key, value, {stringify});
    } else {
      webStorageUtils.setItem(key, value, {stringify});
    }

    this.setState({close: true});
  }

  handleListenOnlyPopupClose() {
    this.handlePopupClose('listenOnly', this.props.listenOnlyEnableTime, false);
  }

  handleRollingUpgradePopupClose() {
    this.handlePopupClose('rollingUpgrade', true);
  }

  render() {
    const {selfSigned, certExpiration, healthIsEnabled, listenOnlyEnableTime, remainingRollingUpgradeCount, kbDocsUrl} =
      this.props;

    const popups = [];

    if (!(webStorageUtils.getItem('certWarning') === intl.date(new Date(), 'L')) && certExpiration) {
      popups.push(
        <CertReminderPopup
          tid="pce-cert"
          key="certWarning"
          trustedCertLink={`${kbDocsUrl}/How-to-update-the-PCE-certificates.html`}
          selfSignedCertLink={`${kbDocsUrl}/Understanding-Illumio-Trial-Certificates.html`}
          selfSigned={selfSigned}
          expiration={certExpiration}
          onClose={this.handlePopupClose}
        />,
      );
    }

    const healthLink = healthIsEnabled ? 'health.list' : undefined;

    if (remainingRollingUpgradeCount && !webStorageUtils.getSessionItem('rollingUpgrade')) {
      popups.push(<RollingUpgradeReminderPopup key="rollingUpgrade" onClose={this.handleRollingUpgradePopupClose} />);
    }

    if (listenOnlyEnableTime && !(listenOnlyEnableTime === webStorageUtils.getItem('listenOnly', {parse: false}))) {
      popups.push(
        <ReminderPopup
          tid="listenonly"
          key="listenOnly"
          title={intl('Health.PceInListenOnlyMode', {time: formatDate(listenOnlyEnableTime)}, {html: true})}
          type="warning"
          duration={getDuration(listenOnlyEnableTime)}
          onClose={this.handleListenOnlyPopupClose}
          link={healthLink}
          linkLabel={intl('Health.ViewPCEHealth')}
        />,
      );
    }

    // Antman does not have a flushed out way to handle this behavior yet so we
    // will not show the Whats New popup in Antman for now.
    if (!__ANTMAN__) {
      popups.push(<WhatsNewPopup key="whatsnew" />);
    }

    return <Popups>{popups}</Popups>;
  }
}
