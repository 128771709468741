/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

import intl from 'intl';
import {createSelector} from 'reselect';

export const getSelectorSettings = createSelector([], () => {
  const objectMap = {
    appLabels: {type: 'labels', key: 'app'},
    envLabels: {type: 'labels', key: 'env'},
    locLabels: {type: 'labels', key: 'loc'},
    label_groups: {type: 'label_groups', pversion: 'active'},
  };

  const scopeMap = {
    app: {value: intl('Labels.ApplicationLabels'), object: objectMap.appLabels},
    env: {value: intl('Labels.EnvironmentLabels'), object: objectMap.envLabels},
    loc: {value: intl('Labels.LocationLabels'), object: objectMap.locLabels},
    label_groups: {value: intl('Labels.Groups'), object: objectMap.label_groups},
  };

  const filterMap = {...scopeMap};

  return {objectMap, scopeMap, filterMap};
});
