/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import {fetchContainerClusterContainerWorkloads} from './ContainerClusterContainerWorkloadsSaga';
import ContainerWorkloadList from 'containers/ContainerWorkload/List/ContainerWorkloadList';
import ContainerWorkloadReducers from 'containers/ContainerWorkload/ContainerWorkloadState';
import LabelReducers from 'containers/Label/LabelState';

export default class ContainerClusterContainerWorkloads extends PureComponent {
  static prefetch = fetchContainerClusterContainerWorkloads;
  static contextType = AppContext;
  static reducers = [ContainerWorkloadReducers, LabelReducers];

  constructor(props) {
    super(props);

    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh() {
    return this.context.fetcher.fork(fetchContainerClusterContainerWorkloads.refetch);
  }

  render() {
    return <ContainerWorkloadList onRefresh={this.handleRefresh} isInDetailPage hideHeaderProps />;
  }
}
