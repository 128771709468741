/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import ApplicationError from './Application';
import type {ApplicationErrorOptions} from './Application';

export interface RequestInfo {
  url: string;
  method: string;
  headers: Record<string, string>;
}

interface RequestErrorOptions extends ApplicationErrorOptions {
  noConnection?: boolean;
  request?: RequestInfo;
}

/**
 * Request fetch error
 */
export default class RequestError extends ApplicationError {
  noConnection?: boolean;

  constructor(data: string | RequestErrorOptions = {}) {
    if (typeof data === 'string') {
      data = {code: data};
    }

    const {noConnection, ...details} = data;

    _.defaults(details, {
      code: 'REQUEST_ERROR',
      statusCode: 499,
    });

    super(details);
    this.name = 'RequestError';

    this.noConnection = noConnection;
  }
}
