/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Badge} from 'components';
import {UserName} from 'containers';
import * as GridUtils from 'components/Grid/GridUtils';
import {getBadgeTypeByUpdateType, getBadgeLabelByUpdateType} from 'utils/formats';
import styles from './PendingList.css';
import {createSelector} from 'reselect';

export const getSelectorSettings = createSelector([], () => ({
  staticMap: {
    type: intl('Provision.Item'),
  },
  filterMap: {
    type: intl('Provision.Item'),
  },
}));

export const gridSettings = createSelector([], () => ({
  id: 'pendinglist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: {
      header: intl('Common.Change'),
      value: ({row}) => getBadgeLabelByUpdateType(row.data.update_type),
      format: ({row, value}) =>
        value ? (
          <Badge type={getBadgeTypeByUpdateType(row.data.update_type)} theme={styles} themePrefix="status-">
            {value}
          </Badge>
        ) : null,
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'name',
    },
    type: {
      header: intl('Provision.Item'),
      value: 'typeLabel',
    },
    updatedAt: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.LastModifiedOn'),
      value: 'updated_at',
    },
    updatedBy: GridUtils.clickableColumn({
      header: intl('Common.LastModifiedBy'),
      value: ({row}) => row.data.updated_by.username,
      format: ({row, clickableRef}) => <UserName user={row.data.updated_by} ref={clickableRef} />,
    }),
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to format function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to format function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to format function
    data: ?object,
  }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'minmax(min-content, 130px)'},
      {columns: ['name'], size: 'minmax(320px, auto)'},
      {columns: ['type'], size: 'minmax(150px, auto)'},
      {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
      {columns: ['updatedAt'], size: 'minmax(230px, auto)'},
    ],
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'minmax(min-content, 130px)'},
            {columns: ['name'], size: 'minmax(280px, auto)'},
            {columns: ['type'], size: 'minmax(120px, auto)'},
            {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
            {columns: ['updatedAt'], size: 'minmax(170px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'minmax(min-content, 130px)'},
          {columns: ['name'], size: 'minmax(280px, auto)'},
          {columns: ['type'], size: 'minmax(120px, auto)'},
          {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
          {columns: ['updatedAt'], size: 'minmax(170px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'minmax(min-content, 120px)'},
            {columns: ['name', 'type'], size: 'minmax(300px, auto)'},
            {columns: ['updatedBy', 'updatedAt'], size: 'minmax(230px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'minmax(min-content, 120px)'},
          {columns: ['name', 'type'], size: 'minmax(300px, auto)'},
          {columns: ['updatedBy', 'updatedAt'], size: 'minmax(230px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'type', 'status'], size: 'minmax(140px, auto)'},
            {columns: ['updatedBy', 'updatedAt'], size: 'minmax(150px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'type', 'status'], size: 'minmax(140px, auto)'},
          {columns: ['updatedBy', 'updatedAt'], size: 'minmax(150px, auto)'},
        ];
      },
    },
  ],
}));
