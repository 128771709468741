/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import Diff from './Diff';
import type {DiffProps, DiffPropsBase} from './Diff';

export const Text = (props: DiffProps): JSX.Element => <Diff diffFunc="diffChars" displayType="block" {...props} />;
export const List = (props: DiffPropsBase<string[]>): JSX.Element => (
  <Diff diffFunc="diffArrays" diffFuncUnified="diffChars" displayType="line" {...props} />
);
export const Option = (props: DiffProps): JSX.Element => (
  <Diff diffFunc="diffLines" displayType="line" noTooltip {...props} />
);
export const Checkboxes = (props: DiffPropsBase<string[]>): JSX.Element => (
  <Diff diffFunc="diffArrays" displayType="line" noTooltip {...props} />
);
