/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {Icon} from 'components';
import {formatRuleService, formatRuleAddress, formatCustomRule} from './ContainerWorkloadRulesUtils';
import {createSelector} from 'reselect';

const collator = new Intl.Collator(intl.lang, {sensitivity: 'base'});

export const gridSettings = createSelector([], () => ({
  id: 'containerWorkloadRules',
  sort: 'service',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    service: {
      header: intl('Common.Service'),
      value: ({row}) => row.service,
      format: ({value}) => formatRuleService(value),
    },
    addresses: {
      header: intl('PolicyGenerator.Addresses'),
      value: ({row}) => row.addresses,
      format: ({value}) => formatRuleAddress(value),
      sortFunction: sortvalue =>
        collator.compare(sortvalue.a.addresses.sort().join(','), sortvalue.b.addresses.sort().join(',')),
    },
    secConnect: {
      header: intl('Common.SecureConnect'),
      value: ({row}) => row.secConnect,
      format: ({value}) => (value ? <Icon name="lock" /> : null),
    },
    machineAuth: {
      header: intl('Common.MachineAuthentication'),
      value: ({row}) => row.machineAuth,
      format: ({value}) => (value ? intl('Common.Enabled') : ''),
    },
    stateless: {
      header: intl('Common.Stateless'),
      value: ({row}) => row.stateless,
      format: ({value}) => (value ? intl('Common.Enabled') : ''),
    },
  },

  templates: [
    [
      {columns: ['service'], size: 'minmax(100px, auto)'},
      {columns: ['addresses'], size: 'minmax(100px, auto)'},
      {columns: ['secConnect'], size: 'minmax(100px, auto)'},
      {columns: ['machineAuth'], size: 'minmax(100px, auto)'},
      {columns: ['stateless'], size: 'minmax(75px, auto)'},
    ],
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['service'], size: 'minmax(100px, auto)'},
            {columns: ['addresses'], size: 'minmax(100px, auto)'},
            {columns: ['secConnect'], size: 'minmax(100px, auto)'},
            {columns: ['machineAuth'], size: 'minmax(100px, auto)'},
            {columns: ['stateless'], size: 'minmax(75px, auto)'},
          ];
        }

        return [
          {columns: ['service'], size: 'minmax(100px, auto)'},
          {columns: ['addresses'], size: 'minmax(100px, auto)'},
          {columns: ['secConnect'], size: 'minmax(100px, auto)'},
          {columns: ['machineAuth'], size: 'minmax(100px, auto)'},
          {columns: ['stateless'], size: 'minmax(75px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['service', 'addresses', 'secConnect'], size: 'minmax(200px, auto)'},
            {columns: ['machineAuth', 'stateless'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['service', 'addresses', 'secConnect'], size: 'minmax(200px, auto)'},
          {columns: ['machineAuth', 'stateless'], size: 'minmax(200px, auto)'},
        ];
      },
    },
  ],
}));

export const customIpgridSettings = createSelector([], () => ({
  id: 'containerWorkloadRules',
  sort: 'rules',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    rules: {
      header: intl('Common.Rules'),
      format: ({row}) => formatCustomRule(row),
    },
  },

  templates: [[{columns: ['rules'], size: 'minmax(100px, auto)'}]],
}));
